import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Box, TextField} from "@mui/material";
import {useToast} from "../../../../../../../utils/contexts/toast-context";
import {CustomAxiosError} from "../../../../../../../logics/api_config";
import Loading from "../../../../../../ui/Loading";
import MuiAutocomplete from "../../../../../../ui/mui/mui-input/MuiAutoComplete";
import {
    IRentriStatoFisico
} from "../../../../../../../utils/interfaces/special_wasty/rentri/codifiche/rifiuto/IRentriStatoFisico";
import useApiGetRentriStatoFisico from "./api-hooks/useApiGetRentriStatoFisico";


interface Props {
    obj: IRentriStatoFisico | undefined,
    onChange: (obj: IRentriStatoFisico | undefined) => void,
    className: string,
    label?: string,
    clearable?: boolean
    required?: boolean
}

const RentriStatoFisicoSelect = ({
                                     obj: initialObj,
                                     onChange,
                                     label,
                                     clearable,
                                     required,
                                     ...props
                                 }: Props) => {
    const {displayError, displayMessage} = useToast()
    const {t} = useTranslation();
    const {className = ''} = props;
    const [objs, setObjs] = useState<IRentriStatoFisico[]>([]);
    const [obj, setObj] = useState<IRentriStatoFisico | undefined>(initialObj)

    const {
        data,
        isLoading,
        error,
    } = useApiGetRentriStatoFisico();
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }

    useEffect(() => {
        if (data) {
            setObjs(data);
        }
    }, [data]);

    useEffect(() => {
        if (initialObj) {
            setObj(initialObj);
        } else {
            const foundObj = objs.find(obj => obj.id === 2);
            if (foundObj && !obj) {
                setObj(foundObj);
            }
        }
    }, [initialObj, objs]);

    const handleOnChange = (event: React.SyntheticEvent, newValue: IRentriStatoFisico | null) => {
        setObj(undefined)
        if (newValue) {
            onChange(newValue);
        } else {
            onChange(undefined);
        }

    };


    useEffect(() => {
        onChange(obj);
    }, [obj]);


    return (
        <>
            {isLoading ? (
                <Loading/>
            ) : (
                <>
                    <Box sx={{display: 'flex', alignItems: 'center', width: '100%'}}>

                        <MuiAutocomplete
                            id="stato_fisico-select"
                            options={objs}
                            getOptionLabel={(option) => option.code + ' - ' + option.name}
                            value={obj ? obj : null}
                            disableClearable={true}
                            onChange={handleOnChange}
                            isOptionEqualToValue={(option, value) => option.id === value?.id && option.code === value?.code}
                            renderInput={(params) => (
                                <TextField
                                    sx={{textTransform: 'capitalize'}}
                                    {...params}
                                    label={t(label ? label : '') || t('')}
                                    required={required}
                                />
                            )}
                        />
                    </Box>

                </>
            )}
        </>
    );
}

export default RentriStatoFisicoSelect;