import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {IWasteCode} from "../../../../../../utils/interfaces/special_wasty/waste_code/IWasteCode";


const useApiCreateWasteCode = (site_id: number | undefined): UseMutationResult<IWasteCode, CustomAxiosError, any> => {
    const {selectedCompany} = useAuth()
    const queryClient = useQueryClient();

    const {post} = useApiCalls()

    async function doCreate({companyToken, data}: {
        companyToken?: string,
        data: any
    }): Promise<IWasteCode> {
        try {
            const response = await post(`/api/company/${companyToken}/special_wasty/waste_code?site_id=${site_id}`, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    }


    return useMutation<IWasteCode, CustomAxiosError, any>(
        {
            mutationFn: (
                data
            ) => {
                return doCreate({
                    companyToken: selectedCompany?.company_token,
                    data
                })
            },
            onSuccess: async (obj) => {
                await queryClient.invalidateQueries({queryKey: ["useApiGetWasteCodes", site_id]});
            },
        }
    );
};


export default useApiCreateWasteCode;