import {useMutation, UseMutationResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {IWasteDoc} from "../../../../../../utils/interfaces/special_wasty/waste_doc/IWasteDoc";


const useApiToggleCurrentlyInUseWasteDoc = (): UseMutationResult<IWasteDoc, CustomAxiosError, {
    id: number | undefined;
}, unknown> => {
    const {selectedCompany} = useAuth()

    const {put} = useApiCalls()


    async function doUpdate(company_token: string | undefined,
                            pk: number | undefined,): Promise<IWasteDoc> {
        try {
            const response = await put(`/api/company/${company_token}/special_wasty/waste_doc/${pk}/currently_in_use_toggle`);
            return response.data as IWasteDoc;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<IWasteDoc, CustomAxiosError, {
        id: number | undefined;
    }, unknown>({
        mutationFn: async ({id}) => {
            return await doUpdate(selectedCompany?.company_token, id);
        },
        onSuccess: async (obj) => {
        },
    });
};


export default useApiToggleCurrentlyInUseWasteDoc;