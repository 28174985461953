import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {IDocPayment} from "../../../../../../utils/interfaces/my_documents/doc_payment/IDocPayment";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useAuth} from "../../../../../../utils/contexts/auth-context";

const useApiUnifyRicevuteBancarie = (): UseMutationResult<IDocPayment, CustomAxiosError, {
    data: number[]
}, unknown> => {
    const queryClient = useQueryClient();
    const {selectedCompany} = useAuth();
    const {put} = useApiCalls();

    async function doUpdate(company_token: string | undefined, payload: { data: number[] }): Promise<IDocPayment> {
        try {
            const response = await put(`/api/company/${company_token}/my_documents/doc_payment/unify`, payload);
            return response.data as IDocPayment;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<IDocPayment, CustomAxiosError, { data: number[] }, unknown>({
        mutationFn: async (ids) => {
            return await doUpdate(selectedCompany?.company_token, ids);
        },
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({queryKey: ['useApiGetDocPayment', data.id]});
            await queryClient.invalidateQueries({queryKey: ["useApiGetDocPayments", data.doc?.id]});
            await queryClient.invalidateQueries({queryKey: ['useApiGetRicevuteBancarie']});
        },
    });
};

export default useApiUnifyRicevuteBancarie;
