import React, {useEffect, useMemo, useState} from 'react';
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {
    MaterialReactTable,
    MRT_ActionMenuItem,
    MRT_ColumnDef,
    MRT_Row,
    MRT_RowSelectionState,
    useMaterialReactTable
} from "material-react-table";
import {Avatar, Box} from "@mui/material";
import Grid from '@mui/material/Grid';
import {useQueryClient} from "@tanstack/react-query";
import {
    getMuiTableBodyCellProps,
    getMuiTableBodyRowProps,
    getMuiTableHeadCellProps,
    getMuiTablePaperProps
} from "../../../../ui/mui/mui_react_table/MuiReactTableStyles";
import Loading from "../../../../ui/Loading";
import MuiFabButtonBack from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFetchingSpinner from "../../../../ui/mui/MuiFetchingSpinner";
import {IMyCompanySite} from "../../../../../utils/interfaces/my_company/my_company_site/IMyCompanySite";
import MuiReadOnlyBoxBody from "../../../../ui/mui/mui-display-data/MuiReadOnlyBoxBody";
import {formatDateTimeToDateString} from "../../../../../logics/helpers";
import {IWasteCode} from "../../../../../utils/interfaces/special_wasty/waste_code/IWasteCode";
import {IWasteMovement} from "../../../../../utils/interfaces/special_wasty/waste_movement/IWasteMovement";
import useWasteApiGetWasteCodeMovements from "./api-hooks/useWasteApiGetWasteCodeMovements";
import useCustomCellRenderer from "./useCustomCellRenderer";
import {HiOutlineArrowsRightLeft} from "react-icons/hi2";
import {Delete, Edit} from "@mui/icons-material";
import {MRT_Localization_IT} from "material-react-table/locales/it";
import MuiModal from "../../../../ui/mui/mui_modal/MuiModal";
import WasteMovementForm from "./WasteMovementForm";
import MRTMuiButton from "../../../../ui/mui/mui_react_table/MRTMuiButton";
import MuiAlertDialog from "../../../../ui/mui/mui_modal/MuiAlertDialog";
import useApiDeleteWasteMovement from "./api-hooks/useApiDeleteWasteMovement";
import {AiTwotoneMail} from "react-icons/ai";
import {IWasteMovementType} from "../../../../../utils/interfaces/special_wasty/waste_movement/IWasteMovementType";
import {
    PiDownloadSimpleFill,
    PiPencilSimpleDuotone,
    PiPencilSimpleSlashDuotone,
    PiUploadSimpleFill
} from "react-icons/pi";


interface Props {
    onClose?: () => void;
    my_company_site: IMyCompanySite | undefined,
    waste_code?: IWasteCode
    waste_movements?: IWasteMovement[]
}


const WasteMovementsTable = ({waste_code, onClose, my_company_site, waste_movements}: Props) => {
    const theme = useTheme();
    const {t} = useTranslation()
    const queryClient = useQueryClient();

    const [selectedRow, setSelectedRow] = useState<IWasteMovement | undefined>()
    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const deleteLoadMutation = useApiDeleteWasteMovement(selectedRow?.id);
    const deleteDownloadMutation = useApiDeleteWasteMovement(selectedRow?.id);

    const {mutateAsync: apiDelete} = selectedRow?.movement_type === 'load' ? deleteLoadMutation : deleteDownloadMutation;
    const handleConfirmDelete = async () => {
        try {
            await apiDelete({pk: selectedRow?.id});
        } catch (error) {
        }
        setDeleteConfirmationOpen(false);
        await queryClient.invalidateQueries({queryKey: ["useApiGetWasteOpenLoadsByWasteCodeId", selectedRow?.site.id, selectedRow?.waste_code.id]});
        await queryClient.invalidateQueries({queryKey: ["useWasteApiGetWasteCodeMovements", selectedRow?.site.id, selectedRow?.waste_code.id]});
        await queryClient.invalidateQueries({queryKey: ["useWasteApiGetSiteMovements", selectedRow?.site.id]});
        await queryClient.invalidateQueries({queryKey: ["useApiGetWasteCodes", selectedRow?.site.id]});
        await queryClient.invalidateQueries({queryKey: ["useWasteApiGetOpenLoads", selectedRow?.site.id]});
        await queryClient.invalidateQueries({queryKey: ["useApiGetMyCompanySite", selectedRow?.site.id]});
        await queryClient.invalidateQueries({queryKey: ["useApiGetMyCompanySites"]});
        await queryClient.invalidateQueries({queryKey: ["useWasteApiGetSiteRECTIFIABLEMovements", selectedRow?.site.id]});
        await queryClient.invalidateQueries({queryKey: ["useWasteApiGetSiteNULLABLEMovements", selectedRow?.site.id]});

    };
    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };


    const [selectedRows, setSelectedRows] = useState<IWasteMovement[] | undefined>()
    const [rows, setRows] = useState<IWasteMovement[] | undefined>()
    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)
    const [movementType, setMovementType] = useState<IWasteMovementType>("load")


    const {
        data,
        isLoading,
        isFetching,
    } = useWasteApiGetWasteCodeMovements(my_company_site?.id, waste_code?.id);


    useEffect(() => {
        if (waste_movements) {
            setRows(waste_movements)
        } else if (data) {
            setRows(data);
        }
    }, [data, waste_movements]);

    const CustomCellRenderer: React.FC<{ row: IWasteMovement, theme: any }> = ({row, theme}) => {
        const {renderIcons} = useCustomCellRenderer(row, theme);
        return renderIcons();
    };

    const handleSendMovementsToRentri = async (movements: MRT_Row<IWasteMovement>[]) => {
        const newObjects = movements.map(movement => {
            const original = movement.original;
            console.log(original.site);
            return {
                riferimenti: {
                    anno: new Date(original.movement_date).getFullYear(),
                    progressivo: original.movement_number,
                },
                data_ora_registrazione: new Date(original.movement_date).toISOString(),
                rifiuto: {
                    codice_eer: original.waste_code.codice_eer.code,
                    stato_fisico: original.waste_code.stato_fisico.code,
                    quantita: {
                        valore: original.estimated_quantity,
                        unita_misura: original.waste_code.unita_misura.code,
                    },
                },
                materiali: {
                    materiale: original.waste_code.materiale.code,
                },
                produttore: {
                    indirizzo: {
                        citta: {
                            comune_id: original.site.num_iscr_sito.comune_id,
                        },
                        indirizzo: original.site.num_iscr_sito.indirizzo,
                    }
                },
                trasportatore: {
                    denominazione: original.carrier.name,
                    codice_fiscale: original.carrier.fiscal_code,
                    nazione_id: original.carrier.nation?.code,
                    num_iscrizione_albo: original.carrier.num_iscrizione_albo,

                }

            };
        });

        console.log(newObjects);
    }


    const columns = useMemo<MRT_ColumnDef<IWasteMovement>[]>(
        () => {
            const baseColumns: MRT_ColumnDef<IWasteMovement>[] = [
                {
                    id: "icons",
                    header: "ICONS",
                    columnDefType: "display",
                    Cell: ({row}) => (<CustomCellRenderer row={row.original} theme={theme}/>)
                },
                {
                    header: t('year'),
                    accessorFn: (row) => (row.movement_date ? new Date(row.movement_date).getFullYear() : ''),
                },
                {
                    accessorKey: 'movement_number',
                    header: t('N'),
                },
                {
                    header: t('movement date'),
                    accessorFn: (row) => (row.movement_date ? formatDateTimeToDateString(row.movement_date) : ''),
                },
                {
                    header: t('load'),
                    accessorFn: (row) => (row.recalculated_movement_type === 'load' ? row.recalculated_estimated_quantity : ''),
                },
                {
                    header: t('download'),
                    accessorFn: (row) => (row.recalculated_movement_type === 'download' ? row.recalculated_estimated_quantity : ''),
                },
                {
                    accessorKey: 'balance',
                    header: t('balance'),
                    accessorFn: (row) => (row.balance_of_site),
                },
                {
                    header: t('carrier'),
                    accessorFn: (row) => (row.carrier && row.carrier.name ? row.carrier.name : ''),
                },
                {
                    header: t('intermediary'),
                    accessorFn: (row) => (row.intermediary && row.intermediary.name ? row.intermediary.name : ''),
                },
                {
                    header: t('recipient'),
                    accessorFn: (row) => (row.recipient && row.recipient.name ? row.recipient.name : ''),
                },
                {
                    header: t('waste code'),
                    accessorFn: (row) => (row.waste_code.codice_eer ? row.waste_code.codice_eer.code : 'manca aggancio a RENTRI'),
                },
                {
                    header: t('waste type'),
                    accessorFn: (row) => (row.waste_code.waste_type),
                },
                {
                    header: t('actual'),
                    accessorFn: (row) => (row.confirmed_quantity),
                },
                {
                    header: t('references'),
                    accessorFn: (row) => (row.references),
                },

            ];


            // if (doc) {
            //     baseColumns.push({
            //         accessorKey: 'doc_type',
            //         header: t('payment type'),
            //         accessorFn: (row) => row.payment_type.name,
            //     });
            // }
            //
            // if (!doc) {
            //     baseColumns.push({
            //         accessorKey: 'registry',
            //         header: t('registry'),
            //         accessorFn: (row) => row.doc?.fiscal_registry.registry?.business_name,
            //     });
            // }

            return baseColumns;
        },
        [t]
    );


    const table = useMaterialReactTable<IWasteMovement>({
        columns,
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 80,
        },
        data: rows ? rows : [],
        localization: MRT_Localization_IT,
        columnFilterDisplayMode: 'popover',
        globalFilterFn: 'contains',
        enableFullScreenToggle: false,
        enableFilters: false,
        enableDensityToggle: false,
        muiTableHeadCellProps: getMuiTableHeadCellProps(theme.palette.primary.main),
        muiTableBodyCellProps: getMuiTableBodyCellProps(theme),
        muiTablePaperProps: getMuiTablePaperProps,
        enablePagination: false,
        initialState: {
            density: 'compact',
            pagination: {pageSize: 25, pageIndex: 0},
            columnVisibility: {
                balance: !!waste_code,
            }
        },
        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.backgroundAppGarageColor.main,

        }),
        muiTableBodyRowProps: getMuiTableBodyRowProps(theme),
        renderTopToolbarCustomActions: ({table}) => (
            <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                <>
                    <Avatar sx={{
                        backgroundColor: 'white',
                        border: `2px solid ${theme.palette.primary.main}`,
                        marginRight: 1
                    }}>
                        <HiOutlineArrowsRightLeft color={theme.palette.primary.main}/>
                    </Avatar>
                    <MuiReadOnlyBoxBody label={''}
                                        value={waste_movements ? 'Registro Carico/Scarico' : 'waste movements'}/>
                    {waste_code &&
                        <MRTMuiButton
                            icon={<PiDownloadSimpleFill style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                            onClick={() => {
                                setMovementType('load');
                                setSelectedRow(undefined);
                                setIsEditFormOpen(true)
                            }} label={'add waste load'}/>}
                    {waste_code &&
                        <>
                            <MRTMuiButton
                                icon={<PiUploadSimpleFill
                                    style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                                onClick={() => {
                                    setMovementType('download');
                                    setSelectedRow(undefined);
                                    setIsEditFormOpen(true)
                                }} label={'add waste download'}/>
                            <MRTMuiButton
                                icon={<PiPencilSimpleDuotone
                                    style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                                onClick={() => {
                                    setMovementType('rectification');
                                    setSelectedRow(undefined);
                                    setIsEditFormOpen(true)
                                }} label={'add waste rectification'}/>
                            <MRTMuiButton
                                icon={<PiPencilSimpleSlashDuotone
                                    style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                                onClick={() => {
                                    setMovementType('annulment');
                                    setSelectedRow(undefined);
                                    setIsEditFormOpen(true)
                                }} label={'add waste annulment'}/>
                        </>
                    }
                    {table.getSelectedRowModel().rows.length > 0 &&
                        <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                            <MRTMuiButton
                                bigAtRender={true}
                                icon={<AiTwotoneMail style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                                onClick={() => {
                                    handleSendMovementsToRentri(table.getSelectedRowModel().rows)
                                }}
                                label={'invia registrazioni al rentri'}/>
                        </Box>
                    }
                </>
            </Box>
        ),
        enableRowActions: true,
        renderRowActionMenuItems: ({closeMenu, row}) => [
            <MRT_ActionMenuItem
                icon={<Edit color="primary"/>}
                key="edit"
                label={t("edit")}
                onClick={(event) => {
                    setSelectedRow(row.original);
                    setMovementType(row.original.movement_type)
                    setIsEditFormOpen(true)
                    closeMenu()
                }}
                table={table}
                disabled={!!(row.original.has_been_annulled_by || row.original.has_been_rectified_by)}
            />,
            <MRT_ActionMenuItem
                icon={<Delete color="warning"/>}
                key="delete"
                label={t("delete")}
                onClick={() => {
                    setSelectedRow(row.original);
                    setDeleteConfirmationOpen(true)
                    closeMenu()
                }}
                table={table}
                disabled={!!(row.original.has_been_annulled_by || row.original.has_been_rectified_by)}
            />,
        ],
        enableRowSelection: true,
        positionToolbarAlertBanner: 'none',
        onRowSelectionChange: setRowSelection,
        state: {rowSelection},
    });


    return <>
        {isLoading ? <Loading/> :
            <>
                {onClose &&
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                        <Box className={'mt-2 gap-x-2 mb-2'}>
                            <MuiFabButtonBack onClick={onClose}/>
                        </Box>
                    </Grid>
                }

                <MaterialReactTable table={table}/>

                <MuiAlertDialog
                    open={isDeleteConfirmationOpen}
                    title="delete_confirmation"
                    content="ask_for_delete"
                    onClose={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                />

                <MuiModal
                    top="50%"
                    left="50%"
                    width={'80vh'}
                    open={isEditFormOpen}
                    onClose={() => setIsEditFormOpen(false)}
                >

                    <WasteMovementForm objToEdit={selectedRow}
                                       selected_waste_code={waste_code ? waste_code : selectedRow?.waste_code}
                                       movement_type={movementType}
                                       onClose={() => setIsEditFormOpen(false)}/>


                </MuiModal>


                {isFetching &&
                    <MuiFetchingSpinner text="Fetching data..."/>
                }
            </>}
    </>;
};

export default WasteMovementsTable;
