import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Box, TextField} from "@mui/material";
import {
    IRentriNazione
} from "../../../../../../../utils/interfaces/special_wasty/rentri/codifiche/territoriali/IRentriNazione";
import {useToast} from "../../../../../../../utils/contexts/toast-context";
import useApiGetRentriNazioni from "./api-hooks/useApiGetRentriNazioni";
import {CustomAxiosError} from "../../../../../../../logics/api_config";
import Loading from "../../../../../../ui/Loading";
import MuiAutocomplete from "../../../../../../ui/mui/mui-input/MuiAutoComplete";


interface Props {
    obj: IRentriNazione | undefined,
    onChange: (obj: IRentriNazione | undefined) => void,
    className: string,
    label?: string,
    clearable?: boolean
    required?: boolean
}

const RentriNazioneSelect = ({obj: initialObj, onChange, label, clearable, required, ...props}: Props) => {
    const {displayError, displayMessage} = useToast()
    const {t} = useTranslation();
    const {className = ''} = props;
    const [objs, setObjs] = useState<IRentriNazione[]>([]);
    const [obj, setObj] = useState<IRentriNazione | undefined>(initialObj)

    const {
        data,
        isLoading,
        error,
    } = useApiGetRentriNazioni();
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }

    useEffect(() => {
        if (data) {
            setObjs(data);
        }
    }, [data]);

    useEffect(() => {
        if (initialObj) {
            setObj(initialObj);
        } else if (objs.length > 0) {
            const defaultObj = objs.find(o => o.id === 'IT');
            setObj(defaultObj);
            onChange(defaultObj);
        }
    }, [initialObj, objs]);

    const handleOnChange = (event: React.SyntheticEvent, newValue: IRentriNazione | null) => {
        if (newValue) {
            onChange(newValue);
        } else {
            onChange(undefined);
        }

    };


    useEffect(() => {
        onChange(obj);
    }, [obj]);


    return (
        <>
            {isLoading ? (
                <Loading/>
            ) : (
                <>
                    <Box sx={{display: 'flex', alignItems: 'center', width: '100%'}}>

                        <MuiAutocomplete
                            id="nation-select"
                            options={objs}
                            getOptionLabel={(option) => option.name}
                            value={obj ? obj : null}
                            onChange={handleOnChange}
                            isOptionEqualToValue={(option, value) => option.id === value?.id && option.name === value?.name}
                            renderInput={(params) => (
                                <TextField
                                    sx={{textTransform: 'capitalize'}}
                                    {...params}
                                    label={t(label ? label : '') || t('currency')}
                                    required={required}
                                />
                            )}
                        />
                    </Box>

                </>
            )}
        </>
    );
}

export default RentriNazioneSelect;