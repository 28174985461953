import React, {useEffect, useState} from 'react';
import {useQueryClient} from "@tanstack/react-query";
import {IPrice} from "../../../../../../../utils/interfaces/service/people/product_list/price/IPrice";
import {useToast} from "../../../../../../../utils/contexts/toast-context";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import useApiUpdatePrice from "./api-hooks/useApiUpdatePrice";
import useApiCreatePrice from "./api-hooks/useApiCreatePrice";
import UmSelect from "../../../UmSelect";
import {IUm} from "../../../../../../../utils/interfaces/service/IUm";
import {getCurrentDate} from "../../../../../../../logics/helpers";
import useApiGetUms from "../../../api-hooks/useApiGetUms";
import MuiFabButtonBack from "../../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonSaveAs from "../../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";
import {Box} from "@mui/material";
import MuiInputTextField from "../../../../../../ui/mui/mui-input/MuiInputTextField";
import useApiDeletePrice from "./api-hooks/useApiDeletePrice";
import MuiAlertDialog from "../../../../../../ui/mui/mui_modal/MuiAlertDialog";
import MuiFabButtonDelete from "../../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonDelete";
import {IPeople} from "../../../../../../../utils/interfaces/service/people/IPeople";
import {IProduct} from "../../../../../../../utils/interfaces/service/people/product_list/IProduct";
import WorkingsForm from "../../../../tannery_production/order_schedule/workings/WorkingsForm";
import MuiModal from "../../../../../../ui/mui/mui_modal/MuiModal";
import MuiFabButtonAddWorking from "../../../../../../ui/mui/mui_buttons/fab/extended/dedicated/MuiFabButtonAddWorking";


interface Props {
    priceToEdit: IPrice | null | undefined;
    onClose: () => void;
    person: IPeople,
    product: IProduct,
    scope?: string
}

const PriceForm: React.FC<Props> = ({priceToEdit, onClose, person, product, scope}) => {
    const {displayError, displayMessage} = useToast();
    const {selectedCompany} = useAuth();
    const queryClient = useQueryClient();


    const [workingForm, setWorkingForm] = useState<boolean>(false)
    const handleCloseWorkingForm = () => {
        setWorkingForm(false)
        onClose()

    }


    const updateMutation = useApiUpdatePrice(selectedCompany?.company_token);
    const createMutation = useApiCreatePrice(selectedCompany?.company_token, person.id, product.id);


    const [price, setPrice] = useState<number>();
    const [startDate, setStartDate] = useState<string>(getCurrentDate);
    const [priceDescription, setPriceDescription] = useState<string>();
    const [um, setUm] = useState<IUm | null>();
    const [ums, setUms] = useState<IUm[] | undefined>();

    const {
        data: initialUms
    } = useApiGetUms();

    useEffect(() => {
        if (initialUms) {
            setUms(initialUms)
        }
    }, [initialUms]);


    const handleUm = (um: IUm | null | undefined) => {
        setUm(um);
        if (product?.is_chemical_product || person.peoplecategory_set.some(category => category.category_name === "chemical_supplier")) {
            if (ums) {
                setUm(ums.find((um) => um.um === 'kgs'));
            }
        }
    }

    useEffect(() => {
        if (priceToEdit) {
            setPrice(priceToEdit.price);
            setUm(priceToEdit.um);
            setStartDate(priceToEdit.validity_start_date);
            setPriceDescription(priceToEdit.price_description);
        } else if (ums && ums.length > 0 && !um) {
            if (product?.is_chemical_product || person.peoplecategory_set.some(category => category.category_name === "chemical_supplier")) {
                setUm(ums.find((um) => um.um === 'kgs'));
            }
        }
    }, [priceToEdit, ums]);


    const handleSubmit = async () => {
        try {
            const data = {
                price: price,
                um_id: um?.id,
                price_description: priceDescription || null,
                validity_start_date: startDate || null,
            };


            if (priceToEdit) {
                await updateMutation.mutateAsync({
                    people_id: person.id,
                    product_id: product.id,
                    id: priceToEdit.id,
                    data: data,
                });
                await queryClient.invalidateQueries({queryKey: ['getPeopleProducts']});
                displayMessage('Price updated successfully');
            } else {
                await createMutation.mutateAsync(
                    data,
                );
                await queryClient.invalidateQueries({queryKey: ['getPeopleProducts']});
                displayMessage('Price added successfully');
            }

            onClose(); // Close the form
        } catch (error) {
        }
    };

    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };


    const deleteMutation = useApiDeletePrice(selectedCompany?.company_token, person?.id, product?.id, priceToEdit?.id);
    const {mutateAsync: apiDelete} = deleteMutation;
    const handleConfirmDelete = async () => {
        if (priceToEdit) {
            try {
                await apiDelete({
                    companyToken: selectedCompany?.company_token,
                    people_id: person.id,
                    product_id: product.id,
                    pk: priceToEdit?.id
                });
            } catch (error) {
            }
            setDeleteConfirmationOpen(false);
            onClose();
            await queryClient.invalidateQueries({queryKey: ['getPeopleProducts']});
        }
    }


    return (
        <>
            <Box className="space-y-6">
                <form>
                    <MuiInputTextField
                        label="validity_start_date"
                        type="date"
                        value={startDate}
                        onChange={setStartDate}
                        required={true}
                    />
                    <MuiInputTextField
                        label="price"
                        type="number"
                        value={price || ''}
                        onChange={(e) => setPrice(parseFloat(e.target.value))}
                        required={true}
                    />
                    <MuiInputTextField
                        label="description"
                        type="text"
                        value={priceDescription || ''}
                        onChange={(e) => setPriceDescription(e.target.value)}
                        required={false}
                    />

                    <UmSelect obj={um || undefined} onChange={handleUm} className={''}/>

                    <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                        <MuiFabButtonBack onClick={onClose}/>
                        {priceToEdit &&
                            <MuiFabButtonDelete onClick={() => {
                                setDeleteConfirmationOpen(true)
                            }}/>}
                        {priceToEdit ?
                            <MuiFabButtonSaveAs onClick={handleSubmit}/>
                            :
                            <MuiFabButtonSave onClick={handleSubmit}/>}
                        {scope === 'add_working' &&
                            <MuiFabButtonAddWorking onClick={() => setWorkingForm(true)}/>
                        }
                    </Box>
                </form>
                <MuiAlertDialog
                    open={isDeleteConfirmationOpen}
                    title="delete_confirmation"
                    content="ask_for_delete"
                    onClose={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                />
            </Box>
            <MuiModal
                top="50%"
                left="50%"
                width={'50vh'}
                open={workingForm}
                onClose={handleCloseWorkingForm}
            >
                <WorkingsForm
                    price_id={priceToEdit?.id}
                    workingToEdit={null}
                    onClose={handleCloseWorkingForm}
                />
            </MuiModal>
        </>

    );
};

export default PriceForm;
