import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {IDocPayment} from "../../../../../../utils/interfaces/my_documents/doc_payment/IDocPayment";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useAuth} from "../../../../../../utils/contexts/auth-context";


const useApiGetRicevuteBancarie = (): UseQueryResult<IDocPayment[], CustomAxiosError> => {
    const {selectedCompany} = useAuth();

    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined): Promise<IDocPayment[]> {
        try {
            const response = await get(`/api/company/${company_token}/my_documents/doc_payment?payment_type=2`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetRicevuteBancarie"],
        queryFn: () => doGet(selectedCompany?.company_token,),
    })
};

export default useApiGetRicevuteBancarie