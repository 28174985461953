import React from 'react';
import MaxWidthDiv from "../../../../ui/MaxWitdthDiv";
import FiscalRegistryTable from "./FiscalRegistryTable";
import {MyDocumentsProvider} from "../../../../../utils/contexts/my_documents/my_documents-context";


const FiscalRegistryScreen = () => {


    return (
        <>

            <MaxWidthDiv maxWidth='1980px'>
                <MyDocumentsProvider>
                    <FiscalRegistryTable/>
                </MyDocumentsProvider>


            </MaxWidthDiv>


        </>
    );
};

export default FiscalRegistryScreen;
