import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {IWasteDoc} from "../../../../../../utils/interfaces/special_wasty/waste_doc/IWasteDoc";


const useApiGetWasteDocsByWasteCode = (doc_type: string, waste_code_id: number | undefined, site_id: number | undefined): UseQueryResult<IWasteDoc[], CustomAxiosError> => {
    const {selectedCompany} = useAuth();

    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined): Promise<IWasteDoc[]> {
        try {
            const response = await get(`/api/company/${company_token}/special_wasty/waste_doc?doc_type=${doc_type}&waste_code_id=${waste_code_id}&site_id=${site_id}`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetWasteDocsByWasteCode", doc_type, waste_code_id, site_id],
        queryFn: () => doGet(selectedCompany?.company_token,),
        enabled: !!site_id && !!waste_code_id,
    })
};

export default useApiGetWasteDocsByWasteCode