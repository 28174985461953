import React, {useEffect, useState} from 'react';
import {Box} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import LabelledMuiTypography from "../../../../ui/mui/mui-display-data/LabelledMuiTypography";
import {useTranslation} from "react-i18next";
import {IWasteMovement} from "../../../../../utils/interfaces/special_wasty/waste_movement/IWasteMovement";
import useApiGetWasteMovement from "./api-hooks/useApiGetWasteMovement";


interface Props {
    obj: IWasteMovement | undefined,
}

const WasteMovementCard = ({
                               obj: initialObj,
                           }: Props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const [obj, setObj] = useState<IWasteMovement | undefined>(initialObj)

    useEffect(() => {
        if (initialObj) {
            setObj(initialObj)
        }
    }, [initialObj]);

    const {
        data: annulledWasteMovement,
    } = useApiGetWasteMovement(initialObj?.annulled_waste_movement_id);

    const {
        data: rectifiedWasteMovement,
    } = useApiGetWasteMovement(initialObj?.rectified_waste_movement_id);


    return (
        <>
            <Box sx={{
                display: 'flex-1',
                flexDirection: 'column',
                alignItems: 'flex-end',
                border: '1px solid lightgrey',
                borderRadius: '4px',
                padding: '10px',
                backgroundColor: theme.palette.backgroundAppGarageColor.gray_50
            }}>
                {obj?.has_been_annulled_by &&
                    <LabelledMuiTypography label={'Annullato da:'}
                                           value={`${obj.has_been_annulled_by.movement_number}/${new Date(obj.has_been_annulled_by.movement_date).getFullYear()}`}
                                           upperCaseValue={true}/>}
                {obj?.has_been_rectified_by &&
                    <LabelledMuiTypography label={'Modificato da:'}
                                           value={`${obj.has_been_rectified_by.movement_number}/${new Date(obj.has_been_rectified_by.movement_date).getFullYear()}`}
                                           upperCaseValue={true}/>}
                {annulledWasteMovement &&
                    <LabelledMuiTypography label={'Annulla il movimento:'}
                                           value={`${annulledWasteMovement.movement_number}/${new Date(annulledWasteMovement.movement_date).getFullYear()}`}
                                           upperCaseValue={true}/>}

                {rectifiedWasteMovement &&
                    <LabelledMuiTypography label={'Rettifica il movimento:'}
                                           value={`${rectifiedWasteMovement.movement_number}/${new Date(rectifiedWasteMovement.movement_date).getFullYear()}`}
                                           upperCaseValue={true}/>}

            </Box>
        </>

    );
}

export default WasteMovementCard;