import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {useApiRentriCalls} from "../../../api-hooks/api_rentri_config";
import {CustomAxiosError} from "../../../../../../../../logics/api_config";
import {
    IRentriRegistro
} from "../../../../../../../../utils/interfaces/special_wasty/rentri/rentri_registro/IRentriRegistro";


const useApiGetRentriRegistri = (num_iscr: string | undefined, num_iscr_sito: string | undefined): UseQueryResult<IRentriRegistro[], CustomAxiosError> => {
    const {get} = useApiRentriCalls()

    async function doGet(): Promise<IRentriRegistro[]> {
        try {
            const response = await get(`/anagrafiche/v1.0/operatore/${num_iscr}/siti/${num_iscr_sito}/registri`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetRentriRegistri", num_iscr, num_iscr_sito],
        queryFn: () => doGet(),
        enabled: !!num_iscr && !!num_iscr_sito,
    })
};

export default useApiGetRentriRegistri