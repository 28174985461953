import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import useApiGetRentriCertificate from "../../rentri_certificate/api-hooks/useApiGetRentriCertificate";

const useApiGetRentriToken = (): UseQueryResult<{ token: string }, CustomAxiosError> => {
    const {selectedCompany} = useAuth()
    const {data: rentri_certificate} = useApiGetRentriCertificate()

    const {post} = useApiCalls()

    async function doGet(): Promise<{ token: string }> {
        try {
            const response = await post(`/api/company/${selectedCompany?.company_token}/special_wasty/rentri_certificate/${rentri_certificate?.id}/jwt_token`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetRentriToken"],
        queryFn: () => doGet(),
        enabled: !!selectedCompany && !!rentri_certificate?.id && !!rentri_certificate.certificate_info
    })

};

export default useApiGetRentriToken