import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";


const useApiDeleteManualWarehouseProductMovement = (warehouse_id: number, product_id: number, pk: number | undefined): UseMutationResult<void, CustomAxiosError, {
    pk?: number
}, unknown> => {
    const {selectedCompany} = useAuth()
    const queryClient = useQueryClient();

    const {DELETE} = useApiCalls()

    async function doDelete({pk}: {
        pk?: number
    }): Promise<void> {
        try {
            await DELETE(`/api/company/${selectedCompany?.company_token}/my_warehouse/${warehouse_id}/products/${product_id}/movements/manual_movement/${pk}/`);
        } catch (error) {
            throw error;
        }
    }

    return useMutation<void, CustomAxiosError, { pk?: number }>(
        {
            mutationFn: () => doDelete({pk: pk,}),
            onSuccess: async () => {
                await queryClient.invalidateQueries({queryKey: ['useApiGetManualWarehouseProductMovement', warehouse_id, product_id, pk]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetWarehouseProductMovements", warehouse_id, product_id]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetWarehouseProducts", warehouse_id]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetWarehouseParentProducts", warehouse_id]});
            },
        })
}


export default useApiDeleteManualWarehouseProductMovement;