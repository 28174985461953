import {useEffect} from 'react';
import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';
import {useToast} from "../../../../../../utils/contexts/toast-context";
import {useTranslation} from "react-i18next";
import useApiGetRentriToken from "./useApiGetRentriToken";
import {RentriAxiosError} from "./api_rentri_config";
import {useQueryClient} from "@tanstack/react-query";

type PayloadType = { content_type: string; content: any };

type UseApiRentriAxiosInstanceParams = {
    config?: AxiosRequestConfig;
    getAgidJwtToken: (payload: PayloadType) => Promise<{ token: string, digest: string } | undefined>;
};

const useApiRentriAxiosInstance = ({
                                       config = {},
                                       getAgidJwtToken
                                   }: UseApiRentriAxiosInstanceParams): { interceptedAxiosInstance: AxiosInstance } => {
    const ENDPOINT = process.env.REACT_APP_RENTRI_ENDPOINT || '';
    const {displayError} = useToast();
    const {t} = useTranslation();
    const queryClient = useQueryClient();

    const {data: rentri_token, refetch: fetchRentriToken} = useApiGetRentriToken();

    const customAxiosInstance = axios.create({
        baseURL: ENDPOINT,
        ...config
    });

    useEffect(() => {
        const requestInterceptor = customAxiosInstance.interceptors.request.use(
            async (requestConfig) => {
                try {
                    const tokenResponse = await fetchRentriToken();
                    const token = tokenResponse?.data?.token;

                    if (token) {
                        requestConfig.headers['Authorization'] = `Bearer ${token}`;
                        await queryClient.invalidateQueries({queryKey: ['useApiGetRentriToken']});
                    }

                    if ((requestConfig.method === 'post' || requestConfig.method === 'put') && getAgidJwtToken) {
                        const payload: PayloadType = requestConfig.data;
                        const tokenResponse = await getAgidJwtToken(payload);
                        // console.log('tokenResponse?.token', tokenResponse?.token)

                        if (tokenResponse?.token) {
                            requestConfig.headers['Agid-JWT-Signature'] = `${tokenResponse.token}`;
                            requestConfig.headers['Digest'] = `${tokenResponse.digest}`;
                            requestConfig.headers['Content-Type'] = `application/json`;
                        }
                    }

                    return requestConfig;
                } catch (error) {
                    displayError("Failed to retrieve token");
                    return Promise.reject(error);
                }
            }
        );

        const responseInterceptor = customAxiosInstance.interceptors.response.use(
            (response: AxiosResponse) => response,
            async (error: RentriAxiosError) => {
                if (error.response && error.response.status >= 400) {
                    console.log('error.response.data', error.response.data)
                    let error_message = "Rentri Error: " + error.response.data.model_state.generic[0] || 'An unknown error occurred';
                    displayError(t(error_message.trim()));
                    return Promise.reject(error);
                }

                if (error.response && error.response.status === 500) {
                    displayError('500 Error');
                    return Promise.reject(error);
                }

                return Promise.reject(error);
            }
        );

        return () => {
            customAxiosInstance.interceptors.request.eject(requestInterceptor);
            customAxiosInstance.interceptors.response.eject(responseInterceptor);
        };
    }, [customAxiosInstance, displayError, fetchRentriToken, getAgidJwtToken, t]);

    return {interceptedAxiosInstance: customAxiosInstance};
};

export default useApiRentriAxiosInstance;
