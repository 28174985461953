import React from 'react';
import MaxWidthDiv from "../../../../ui/MaxWitdthDiv";
import DocPaymentTable from "../doc/doc_payment/DocPaymentTable";


const RicevuteBancarieScreen = () => {


    return (
        <>

            <MaxWidthDiv maxWidth='1980px'>
                <DocPaymentTable/>


            </MaxWidthDiv>


        </>
    );
};

export default RicevuteBancarieScreen;
