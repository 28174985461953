import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError} from "../../../../../../../../logics/api_config";
import {useApiRentriCalls} from "../../../api-hooks/api_rentri_config";
import {
    IRentriRegistro
} from "../../../../../../../../utils/interfaces/special_wasty/rentri/rentri_registro/IRentriRegistro";


const useApiUpdateRentriRegistro = (): UseMutationResult<IRentriRegistro, CustomAxiosError, {
    id: string;
    data: any
}, unknown> => {
    const queryClient = useQueryClient();
    const {put} = useApiRentriCalls()

    async function doUpdate(pk: string | undefined,
                            data: any): Promise<IRentriRegistro> {
        try {
            const response = await put(`/anagrafiche/v1.0/operatore/registri/${pk} `, data);
            return response.data as IRentriRegistro;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<IRentriRegistro, CustomAxiosError, {
        id: string;
        data: any
    }, unknown>({
        mutationFn: async ({id, data}) => {
            return await doUpdate(id, data);
        },
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({queryKey: ['useApiGetRentriRegistro', data.identificativo]});
        },
    });
};


export default useApiUpdateRentriRegistro;