import React from 'react';
import {useToast} from "../../../../../../utils/contexts/toast-context";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {useTanneryProduction} from "../../../../../../utils/contexts/tannery_production/tannery_production-context";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import Loading from "../../../../../ui/Loading";
import useApiGetRawChoiceOrders from "./api-hooks/useApiGetRawChoiceOrders";
import LotsTable from "../../../tannery_raw_material/lots/LotsTable";
import {Box, Grid} from "@mui/material";
import MuiFabButtonBack from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import RawMaterialLinkedTable from "./RawMaterialLinkedTable";
import MuiReadOnlyBoxBody from "../../../../../ui/mui/mui-display-data/MuiReadOnlyBoxBody";
import MuiBorderedBox from "../../../../../ui/mui/MuiBorderedBox";

interface Props {
    onClose: () => void;
    isOpen: boolean,
}

const RawMaterialLinker: React.FC<Props> = ({
                                                onClose,
                                                isOpen,
                                            }) => {
    const {displayError} = useToast();
    const {selectedCompany} = useAuth();
    const {selectedCompanyOrderSchedule} = useTanneryProduction()


    const {
        data,
        isLoading,
        error
    } = useApiGetRawChoiceOrders(selectedCompany?.company_token, selectedCompanyOrderSchedule?.id);
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }


    return (
        <>
            {isOpen && (
                <Box sx={{padding: 2}}>
                    {isLoading ? (
                        <Loading/>
                    ) : (
                        <>
                            <Grid item xs={12} style={{textAlign: 'center'}}>
                                <Box className={'mt-2 gap-x-2 mb-2'}>
                                    <MuiFabButtonBack onClick={onClose}/>
                                </Box>
                            </Grid>

                            <Box sx={{flexDirection: 'column'}}>
                                <MuiReadOnlyBoxBody label={'order_info'}
                                                    value={`${selectedCompanyOrderSchedule?.order_id} - 
                                    ${selectedCompanyOrderSchedule?.article_name} - 
                                    ${selectedCompanyOrderSchedule?.color.color_name} 
                                    `}/>


                                <MuiBorderedBox>
                                    <RawMaterialLinkedTable/>
                                </MuiBorderedBox>
                                <MuiBorderedBox>
                                    <LotsTable isFromPeopleArticle={true}/>
                                </MuiBorderedBox>
                            </Box>
                        </>

                    )}
                </Box>
            )}
        </>
    );

};

export default RawMaterialLinker;
