import React from 'react';
import MaxWidthDiv from "../../../../ui/MaxWitdthDiv";
import WastyIntermediaryTable from "./WastyIntermediaryTable";
import SpecialWastyScreen from "../SpecialWastyScreen";


const WastyIntermediaryScreen = () => {


    return (
        <>
            <SpecialWastyScreen>
                <MaxWidthDiv maxWidth='1024px'>
                    <WastyIntermediaryTable/>
                </MaxWidthDiv>
            </SpecialWastyScreen>

        </>
    );
};

export default WastyIntermediaryScreen;
