import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {ITanneryDyeingRecipe} from "../../../../../../../utils/interfaces/tannery_dyeing/ITanneryDyeingRecipe";


const useApiToggleCompletedAtTanneryDyeingRecipe = (
    companyToken: string | undefined
): UseMutationResult<ITanneryDyeingRecipe, CustomAxiosError, {
    tannery_dyeing_id: number | undefined;
    id: number;
}, unknown> => {
    const queryClient = useQueryClient();
    const {put} = useApiCalls();


    const doUpdate = async (company_token: string | undefined,
                            tannery_dyeing_id: number | undefined,
                            pk: number): Promise<ITanneryDyeingRecipe> => {
        try {
            const response = await put(`/api/company/${company_token}/tannery_dyeing/${tannery_dyeing_id}/recipe/${pk}/completed_at_toggle`);
            return response.data;
        } catch (error) {
            throw error;
        }
    };


    return useMutation<ITanneryDyeingRecipe, CustomAxiosError, {
        tannery_dyeing_id: number | undefined;
        id: number;
    }>({

        mutationFn: async ({id, tannery_dyeing_id}) => {
            return await doUpdate(companyToken, tannery_dyeing_id, id);
        },

        onSettled: (newData) => {
            queryClient.invalidateQueries({queryKey: ['getTanneryDyeingRecipe', newData?.tannery_dyeing]})
            queryClient.invalidateQueries({queryKey: ["getTanneryDyeingSchedule"]});
        }
    });
};


export default useApiToggleCompletedAtTanneryDyeingRecipe;