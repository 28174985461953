import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomAxiosError} from "../../../../../../../../logics/api_config";
import {useApiRentriCalls} from "../../../api-hooks/api_rentri_config";
import {
    IRentriCodiceEer
} from "../../../../../../../../utils/interfaces/special_wasty/rentri/codifiche/rifiuto/IRentriCodiceEer";


const useApiGetRentriCodiciEer = (): UseQueryResult<IRentriCodiceEer[], CustomAxiosError> => {
    const {get} = useApiRentriCalls()

    async function doGet(): Promise<IRentriCodiceEer[]> {
        try {
            const response = await get(`/codifiche/v1.0/lookup/codici-eer`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetRentriCodiciEer"],
        queryFn: () => doGet(),
    })
};

export default useApiGetRentriCodiciEer