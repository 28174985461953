import React, {useEffect, useMemo, useState} from 'react';
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {MaterialReactTable, MRT_ActionMenuItem, MRT_ColumnDef, useMaterialReactTable} from "material-react-table";
import {Close, Edit} from "@mui/icons-material";
import {Avatar, Box} from "@mui/material";
import Grid from '@mui/material/Grid';
import AddIcon from "@mui/icons-material/Add";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import {useQueryClient} from "@tanstack/react-query";
import {
    IRentriRegistro
} from "../../../../../../../utils/interfaces/special_wasty/rentri/rentri_registro/IRentriRegistro";
import useApiDeleteRentriRegistro from "./api-hooks/useApiDeleteRentriRegistro";
import useApiGetRentriRegistri from "./api-hooks/useApiGetRentriRegistri";
import useCustomCellRenderer from "./useCustomCellRenderer";
import {MRT_Localization_IT} from "material-react-table/locales/it";
import {
    getMuiTableBodyCellProps,
    getMuiTableBodyRowProps,
    getMuiTableHeadCellProps,
    getMuiTablePaperProps
} from "../../../../../../ui/mui/mui_react_table/MuiReactTableStyles";
import MuiReadOnlyBoxBody from "../../../../../../ui/mui/mui-display-data/MuiReadOnlyBoxBody";
import MRTMuiButton from "../../../../../../ui/mui/mui_react_table/MRTMuiButton";
import Loading from "../../../../../../ui/Loading";
import MuiFabButtonBack from "../../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiAlertDialog from "../../../../../../ui/mui/mui_modal/MuiAlertDialog";
import MuiModal from "../../../../../../ui/mui/mui_modal/MuiModal";
import RentriRegistroForm from "./RentriRegistroForm";
import MuiFetchingSpinner from "../../../../../../ui/mui/MuiFetchingSpinner";
import {formatDateTimeToDateTimeString} from "../../../../../../../logics/helpers";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";


interface Props {
    onSelect?: (obj: IRentriRegistro | undefined) => void,
    num_iscr_operatore: string | undefined,
    num_iscr_sito: string | undefined,
    onClose?: () => void;
}


const RentriRegistroTable = ({onSelect, onClose, num_iscr_operatore, num_iscr_sito}: Props) => {
    const theme = useTheme();
    const {t} = useTranslation()
    const queryClient = useQueryClient();

    const [selectedRow, setSelectedRow] = useState<IRentriRegistro | undefined>()
    const [selectedRows, setSelectedRows] = useState<IRentriRegistro[] | undefined>()
    const [rows, setRows] = useState<IRentriRegistro[] | undefined>()

    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)


    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const deleteMutation = useApiDeleteRentriRegistro(selectedRow?.identificativo);
    const {mutateAsync: apiDelete} = deleteMutation;


    const handleConfirmDelete = async () => {
        try {
            if (onSelect) {
                onSelect(undefined);
            }
            await apiDelete({pk: selectedRow?.identificativo});
        } catch (error) {
        }
        setDeleteConfirmationOpen(false);
        await queryClient.invalidateQueries({queryKey: ['useApiGetRentriRegistri', num_iscr_operatore, num_iscr_sito]});
    };
    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };


    const {
        data,
        isLoading,
        isFetching,
    } = useApiGetRentriRegistri(num_iscr_operatore, num_iscr_sito);


    useEffect(() => {
        if (data) {
            setRows(data);
        }
    }, [data]);


    const CustomCellRenderer: React.FC<{
        row: IRentriRegistro,
        theme: any,
        onOpenLoadsTable: () => void,
        onOpenMovementsTable: () => void
    }> = ({
              row,
              theme
          }) => {
        const {renderIcons} = useCustomCellRenderer(
            row,
            theme,
        );
        return renderIcons();
    };


    const columns = useMemo<MRT_ColumnDef<IRentriRegistro>[]>(
        () => {
            const baseColumns: MRT_ColumnDef<IRentriRegistro>[] = [
                {
                    accessorKey: 'identificativo',
                    header: t('identificativo'),
                },
                {
                    accessorKey: 'descrizione',
                    header: t('descrizione'),
                },
                {
                    accessorKey: 'data_creazione',
                    header: t('data creazione'),
                    accessorFn: (row) => (row.data_creazione ? formatDateTimeToDateTimeString(row.data_creazione) : ''),
                },
                {
                    accessorKey: 'data_modifica',
                    header: t('data modifica'),
                    accessorFn: (row) => (row.data_modifica ? formatDateTimeToDateTimeString(row.data_modifica) : ''),
                },
                {
                    accessorKey: 'data_chiusura',
                    header: t('data chiusura'),
                    accessorFn: (row) => (row.data_chiusura ? formatDateTimeToDateTimeString(row.data_chiusura) : ''),
                },


            ];

            // if (doc) {
            //     baseColumns.push({
            //         accessorKey: 'doc_type',
            //         header: t('payment type'),
            //         accessorFn: (row) => row.payment_type.name,
            //     });
            // }
            //
            // if (!doc) {
            //     baseColumns.push({
            //         accessorKey: 'registry',
            //         header: t('registry'),
            //         accessorFn: (row) => row.doc?.fiscal_registry.registry?.business_name,
            //     });
            // }
            // note_marco baseColumns.push

            return baseColumns;
        },
        [t]
    );


    const table = useMaterialReactTable<IRentriRegistro>({
        columns,
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 80,
        },
        localization: MRT_Localization_IT,
        data: rows ? rows : [],
        columnFilterDisplayMode: 'popover',
        globalFilterFn: 'contains',
        enableFullScreenToggle: false,
        enableFilters: true,
        enableColumnActions: false,
        enableDensityToggle: false,
        muiTableHeadCellProps: getMuiTableHeadCellProps(theme.palette.primary.main),
        muiTableBodyCellProps: getMuiTableBodyCellProps(theme),
        muiTablePaperProps: getMuiTablePaperProps,
        enablePagination: false,
        initialState: {
            density: 'compact',
            pagination: {pageSize: 25, pageIndex: 0},
        },
        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.backgroundAppGarageColor.main,

        }),
        // note_marco: bg color on row selected
        muiTableBodyRowProps: ({row}) => ({
            ...getMuiTableBodyRowProps(theme),
            // sx: {
            //     backgroundColor: row.original.id === selectedRow?.id ? theme.palette.action.selected : undefined,
            // }
        }),

        enableRowActions: true,
        renderRowActionMenuItems: ({closeMenu, row}) => [
            onSelect && onClose && (
                <MRT_ActionMenuItem
                    icon={<AdsClickIcon color="primary"/>}
                    key="select"
                    label={t("select")}
                    onClick={() => {
                        onSelect(row.original);
                        onClose();
                    }}
                    table={table}
                />),
            <MRT_ActionMenuItem
                icon={<Edit color="primary"/>}
                key="edit"
                label={t("edit")}
                onClick={(event) => {
                    setSelectedRow(row.original);
                    setIsEditFormOpen(true)
                    closeMenu()
                }}
                table={table}
                disabled={!!row.original.data_chiusura}
            />,
            <MRT_ActionMenuItem
                icon={<Close color="warning"/>}
                key="delete"
                label={t("close")}
                onClick={() => {
                    setSelectedRow(row.original);
                    setDeleteConfirmationOpen(true)
                    closeMenu()
                }}
                table={table}
                disabled={!!row.original.data_chiusura}
            />,

        ],
        renderTopToolbarCustomActions: ({table}) => (
            <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                <>
                    <Avatar sx={{
                        backgroundColor: 'white',
                        border: `2px solid ${theme.palette.primary.main}`,
                        marginRight: 1
                    }}>
                        <AppRegistrationIcon style={{color: theme.palette.primary.main}}/>
                    </Avatar>
                    <MuiReadOnlyBoxBody label={''} value={'registri'}/>
                    <MRTMuiButton
                        icon={<AddIcon style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                        onClick={() => {
                            setSelectedRow(undefined);
                            setIsEditFormOpen(true)
                        }} label={'add'}/>
                </>
            </Box>
        )
    });


    return <>
        {isLoading ? <Loading/> :
            <>
                {onClose &&
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                        <Box className={'mt-2 gap-x-2 mb-2'}>
                            <MuiFabButtonBack onClick={onClose}/>
                        </Box>
                    </Grid>
                }

                <MaterialReactTable table={table}/>


                <MuiAlertDialog
                    open={isDeleteConfirmationOpen}
                    title="delete_confirmation"
                    content="ask_for_delete"
                    onClose={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                />

                <MuiModal
                    top="50%"
                    left="50%"
                    width={'90vh'}
                    open={isEditFormOpen}
                    disableBackdropClick={true}
                    onClose={() => setIsEditFormOpen(false)}
                >

                    <RentriRegistroForm
                        objToEdit={selectedRow || null}
                        onClose={() => setIsEditFormOpen(false)}
                    />
                </MuiModal>


                {isFetching &&
                    <MuiFetchingSpinner text="Fetching data..."/>
                }
            </>}
    </>;
};

export default RentriRegistroTable;
