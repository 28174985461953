import React, {useEffect, useMemo, useState} from 'react';
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {MaterialReactTable, MRT_ActionMenuItem, MRT_ColumnDef, useMaterialReactTable} from "material-react-table";
import {Delete, Edit} from "@mui/icons-material";
import {Avatar, Box} from "@mui/material";
import Grid from '@mui/material/Grid';
import AddIcon from "@mui/icons-material/Add";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import {useQueryClient} from "@tanstack/react-query";
import {
    getMuiTableBodyCellProps,
    getMuiTableBodyRowProps,
    getMuiTableHeadCellProps,
    getMuiTablePaperProps
} from "../../../../ui/mui/mui_react_table/MuiReactTableStyles";
import MRTMuiButton from "../../../../ui/mui/mui_react_table/MRTMuiButton";
import Loading from "../../../../ui/Loading";
import MuiFabButtonBack from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiAlertDialog from "../../../../ui/mui/mui_modal/MuiAlertDialog";
import MuiFetchingSpinner from "../../../../ui/mui/MuiFetchingSpinner";
import useApiDeleteWasteDoc from "./api-hooks/useApiDeleteWasteDoc";
import useApiGetWasteDocs from "./api-hooks/useApiGetWasteDocs";
import MuiModal from "../../../../ui/mui/mui_modal/MuiModal";
import {IWasteDoc, IWasteDocType} from "../../../../../utils/interfaces/special_wasty/waste_doc/IWasteDoc";
import useCustomCellRenderer from "./useCustomCellRenderer";
import WasteDocForm from "./WasteDocForm";
import {IWastyRecipient} from "../../../../../utils/interfaces/special_wasty/wasty_recipient/IWastyRecipient";
import {IoDocumentsSharp} from "react-icons/io5";
import {IWastyCarrier} from "../../../../../utils/interfaces/special_wasty/wasty_carrier/IWastyCarrier";
import useApiGetWasteDocsByRecipient from "./api-hooks/useApiGetWasteDocsByRecipient";
import MuiReadOnlyBoxBody from "../../../../ui/mui/mui-display-data/MuiReadOnlyBoxBody";
import {IWastyIntermediary} from "../../../../../utils/interfaces/special_wasty/wasty_intermediary/IWastyIntermediary";
import {IWasteCode} from "../../../../../utils/interfaces/special_wasty/waste_code/IWasteCode";
import {IMyCompanySite} from "../../../../../utils/interfaces/my_company/my_company_site/IMyCompanySite";
import useApiGetWasteDocsByCarrier from "./api-hooks/useApiGetWasteDocsByCarrier";
import useApiGetWasteDocsByIntermediary from "./api-hooks/useApiGetWasteDocsByIntermediary";
import useApiGetWasteDocsByWasteCode from "./api-hooks/useApiGetWasteDocsByWasteCode";
import {formatDateTimeToDateString} from "../../../../../logics/helpers";
import useApiToggleCurrentlyInUseWasteDoc from "./api-hooks/useApiToggleCurrentlyInUseWasteDoc";


interface Props {
    onSelect?: (obj: IWasteDoc | undefined) => void,
    doc_type: IWasteDocType
    onClose?: () => void;
    recipient?: IWastyRecipient,
    carrier?: IWastyCarrier,
    intermediary?: IWastyIntermediary,
    waste_code?: IWasteCode,
    my_company_site?: IMyCompanySite
}


const WasteDocTable = ({
                           onSelect,
                           onClose,
                           doc_type,
                           recipient,
                           carrier,
                           intermediary,
                           waste_code,
                           my_company_site
                       }: Props) => {
    const theme = useTheme();
    const {t} = useTranslation()
    const queryClient = useQueryClient();

    const [selectedRow, setSelectedRow] = useState<IWasteDoc | undefined>()
    const [selectedRows, setSelectedRows] = useState<IWasteDoc[] | undefined>()
    const [rows, setRows] = useState<IWasteDoc[] | undefined>()

    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)

    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const deleteMutation = useApiDeleteWasteDoc(selectedRow?.id);
    const {mutateAsync: apiDelete} = deleteMutation;


    const handleConfirmDelete = async () => {
        try {
            if (onSelect) {
                onSelect(undefined);
            }
            await apiDelete({pk: selectedRow?.id});
        } catch (error) {
        }
        setDeleteConfirmationOpen(false);
        await queryClient.invalidateQueries({queryKey: ['useApiGetWasteDoc', selectedRow?.id]});
        await queryClient.invalidateQueries({queryKey: ["useApiGetWasteDocs"]});
        if (recipient) {
            await queryClient.invalidateQueries({queryKey: ["useApiGetWasteDocsByRecipient", selectedRow?.doc_type, selectedRow?.recipient.id]});
            await queryClient.invalidateQueries({queryKey: ["useApiGetWastyRecipient", selectedRow?.recipient.id]});
            await queryClient.invalidateQueries({queryKey: ["useApiGetWastyRecipients"]});
        }
        if (carrier) {
            await queryClient.invalidateQueries({queryKey: ["useApiGetWasteDocsByCarrier", selectedRow?.doc_type, selectedRow?.carrier.id]});
            await queryClient.invalidateQueries({queryKey: ["useApiGetWastyCarrier", selectedRow?.carrier.id]});
            await queryClient.invalidateQueries({queryKey: ["useApiGetWastyCarriers"]});
        }
        if (intermediary) {
            await queryClient.invalidateQueries({queryKey: ["useApiGetWasteDocsByIntermediary", selectedRow?.doc_type, selectedRow?.intermediary.id]});
            await queryClient.invalidateQueries({queryKey: ["useApiGetWastyIntermediary", selectedRow?.intermediary.id]});
            await queryClient.invalidateQueries({queryKey: ["useApiGetWastyIntermediaries"]});
        }
        if (waste_code && my_company_site) {
            await queryClient.invalidateQueries({queryKey: ["useApiGetWasteDocsByWasteCode", selectedRow?.doc_type, selectedRow?.waste_code.id, selectedRow?.site.id]});
            await queryClient.invalidateQueries({queryKey: ["useApiGetWasteCode", selectedRow?.waste_code.id, selectedRow?.site.id]});
            await queryClient.invalidateQueries({queryKey: ["useApiGetWasteCodes", selectedRow?.site.id]});
        }


    };
    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };

    const updateMutation = useApiToggleCurrentlyInUseWasteDoc();
    const handleToggleCurrentlyInUse = async (row: IWasteDoc) => {
        try {
            let newData;

            newData = await updateMutation.mutateAsync({id: row?.id});

            await queryClient.invalidateQueries({queryKey: ['useApiGetWasteDoc', newData?.id]});
            await queryClient.invalidateQueries({queryKey: ["useApiGetWasteDocs"]});
            if (recipient) {
                await queryClient.invalidateQueries({queryKey: ["useApiGetWasteDocsByRecipient", newData?.doc_type, newData?.recipient.id]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetWastyRecipient", newData?.recipient.id]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetWastyRecipients"]});
            }
            if (carrier) {
                await queryClient.invalidateQueries({queryKey: ["useApiGetWasteDocsByCarrier", newData?.doc_type, newData?.carrier.id]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetWastyCarrier", newData?.carrier.id]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetWastyCarriers"]});
            }
            if (intermediary) {
                await queryClient.invalidateQueries({queryKey: ["useApiGetWasteDocsByIntermediary", newData?.doc_type, newData?.intermediary.id]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetWastyIntermediary", newData?.intermediary.id]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetWastyIntermediaries"]});
            }
            if (waste_code && my_company_site) {
                await queryClient.invalidateQueries({queryKey: ["useApiGetWasteDocsByWasteCode", newData?.doc_type, newData?.waste_code.id, newData?.site.id]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetWasteCode", newData?.waste_code.id, newData.site.id]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetWasteCodes", newData.site.id]});
            }

        } catch (error) {
        }
    }


    const {
        data,
        isLoading,
        isFetching,
    } = useApiGetWasteDocs();

    const {
        data: recipientData,
    } = useApiGetWasteDocsByRecipient(doc_type, recipient?.id);

    const {
        data: carrierData,
    } = useApiGetWasteDocsByCarrier(doc_type, carrier?.id);

    const {
        data: intermediaryData,
    } = useApiGetWasteDocsByIntermediary(doc_type, intermediary?.id);

    const {
        data: wasteCodeData,
    } = useApiGetWasteDocsByWasteCode(doc_type, waste_code?.id, my_company_site?.id);


    useEffect(() => {
        if (data) {
            setRows(data);
        }
        if (recipient && recipientData) {
            setRows(recipientData)
        }
        if (carrier && carrierData) {
            setRows(carrierData)
        }
        if (intermediary && intermediaryData) {
            setRows(intermediaryData)
        }
        if (waste_code && my_company_site && wasteCodeData) {
            setRows(wasteCodeData)
        }
    }, [carrier, carrierData, data, intermediary, intermediaryData, my_company_site, recipient, recipientData, wasteCodeData, waste_code]);


    const CustomCellRenderer: React.FC<{ row: IWasteDoc, theme: any }> = ({
                                                                              row,
                                                                              theme
                                                                          }) => {
        const {renderIcons} = useCustomCellRenderer(row, theme, handleToggleCurrentlyInUse);
        return renderIcons();
    };


    const columns = useMemo<MRT_ColumnDef<IWasteDoc>[]>(
        () => {
            const baseColumns: MRT_ColumnDef<IWasteDoc>[] = [
                {
                    id: "icons",
                    header: "ICONS",
                    columnDefType: "display",
                    Cell: ({row}) => (<CustomCellRenderer row={row.original} theme={theme}/>)
                },
                {
                    accessorKey: 'description',
                    header: t('description'),
                },
                {
                    header: t('document date'),
                    accessorFn: (row) => (row.doc_date ? formatDateTimeToDateString(row.doc_date) : ''),
                },
                {
                    header: t('expiring date'),
                    accessorFn: (row) => (row.expiring_date ? formatDateTimeToDateString(row.expiring_date) : ''),
                },

            ];


            // if (doc) {
            //     baseColumns.push({
            //         accessorKey: 'doc_type',
            //         header: t('payment type'),
            //         accessorFn: (row) => row.payment_type.name,
            //     });
            // }
            //
            // if (!doc) {
            //     baseColumns.push({
            //         accessorKey: 'registry',
            //         header: t('registry'),
            //         accessorFn: (row) => row.doc?.fiscal_registry.registry?.business_name,
            //     });
            // }

            return baseColumns;
        },
        [t]
    );


    const table = useMaterialReactTable<IWasteDoc>({
        columns,
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 80,
        },
        data: rows ? rows : [],
        columnFilterDisplayMode: 'popover',
        globalFilterFn: 'contains',
        enableFullScreenToggle: false,
        enableFilters: false,
        enableDensityToggle: false,
        muiTableHeadCellProps: getMuiTableHeadCellProps(theme.palette.primary.main),
        muiTableBodyCellProps: getMuiTableBodyCellProps(theme),
        muiTablePaperProps: getMuiTablePaperProps,
        enablePagination: false,
        initialState: {
            density: 'compact',
            pagination: {pageSize: 25, pageIndex: 0},
        },
        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.backgroundAppGarageColor.main,

        }),
        muiTableBodyRowProps: getMuiTableBodyRowProps(theme),

        enableRowActions: true,
        renderRowActionMenuItems: ({closeMenu, row}) => [
            onSelect && onClose && (
                <MRT_ActionMenuItem
                    icon={<AdsClickIcon color="primary"/>}
                    key="select"
                    label={t("select")}
                    onClick={() => {
                        onSelect(row.original);
                        onClose();
                    }}
                    table={table}
                />),
            <MRT_ActionMenuItem
                icon={<Edit color="primary"/>}
                key="edit"
                label={t("edit")}
                onClick={(event) => {
                    setSelectedRow(row.original);
                    setIsEditFormOpen(true)
                    closeMenu()
                }}
                table={table}
            />,
            <MRT_ActionMenuItem
                icon={<Delete color="warning"/>}
                key="delete"
                label={t("delete")}
                onClick={() => {
                    setSelectedRow(row.original);
                    setDeleteConfirmationOpen(true)
                    closeMenu()
                }}
                table={table}
            />,
        ],
        renderTopToolbarCustomActions: ({table}) => (
            <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                <>
                    <Avatar sx={{
                        backgroundColor: 'white',
                        border: `2px solid ${theme.palette.primary.main}`,
                        marginRight: 1
                    }}>
                        <IoDocumentsSharp color={theme.palette.primary.main}/>
                    </Avatar>
                    <MuiReadOnlyBoxBody label={''} value={t(doc_type)}/>

                    <MRTMuiButton
                        icon={<AddIcon style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                        onClick={() => {
                            setSelectedRow(undefined);
                            setIsEditFormOpen(true)
                        }} label={'add'}/>
                </>
            </Box>
        ),
    });


    return <>
        {isLoading ? <Loading/> :
            <>
                {onClose &&
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                        <Box className={'mt-2 gap-x-2 mb-2'}>
                            <MuiFabButtonBack onClick={onClose}/>
                        </Box>
                    </Grid>
                }

                <MaterialReactTable table={table}/>

                <MuiAlertDialog
                    open={isDeleteConfirmationOpen}
                    title="delete_confirmation"
                    content="ask_for_delete"
                    onClose={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                />

                <MuiModal
                    top="50%"
                    left="50%"
                    width={'80vh'}
                    open={isEditFormOpen}
                    onClose={() => setIsEditFormOpen(false)}
                >

                    <WasteDocForm
                        doc_type={doc_type}
                        recipient={recipient}
                        carrier={carrier}
                        intermediary={intermediary}
                        waste_code={waste_code}
                        my_company_site={my_company_site}
                        objToEdit={selectedRow || null}
                        onClose={() => setIsEditFormOpen(false)}
                    />
                </MuiModal>


                {isFetching &&
                    <MuiFetchingSpinner text="Fetching data..."/>
                }
            </>}
    </>;
};

export default WasteDocTable;
