import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Box, TextField} from "@mui/material";
import {useToast} from "../../../../../../../utils/contexts/toast-context";
import {CustomAxiosError} from "../../../../../../../logics/api_config";
import Loading from "../../../../../../ui/Loading";
import MuiAutocomplete from "../../../../../../ui/mui/mui-input/MuiAutoComplete";
import {
    IRentriProvince
} from "../../../../../../../utils/interfaces/special_wasty/rentri/codifiche/territoriali/IRentriProvince";
import useApiGetRentriProvince from "./api-hooks/useApiGetRentriProvince";


interface Props {
    obj: IRentriProvince | undefined,
    onChange: (obj: IRentriProvince | undefined) => void,
    className: string,
    label?: string,
    clearable?: boolean
    required?: boolean
}

const RentriProvinceSelect = ({obj: initialObj, onChange, label, clearable, required}: Props) => {
    const {displayError} = useToast()
    const {t} = useTranslation();
    const [objs, setObjs] = useState<IRentriProvince[]>([]);
    const [obj, setObj] = useState<IRentriProvince | undefined>(initialObj)

    const {
        data,
        isLoading,
        error,
    } = useApiGetRentriProvince();
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }

    useEffect(() => {
        if (data) {
            setObjs(data);
        }
    }, [data]);

    useEffect(() => {
        if (initialObj) {
            setObj(initialObj);
        } else if (objs.length > 0) {
            const defaultObj = objs.find(o => o.id === '024');
            setObj(defaultObj);
            onChange(defaultObj);
        }
    }, [initialObj, objs]);

    const handleOnChange = (event: React.SyntheticEvent, newValue: IRentriProvince | null) => {
        if (newValue) {
            onChange(newValue);
        } else {
            onChange(undefined);
        }

    };


    useEffect(() => {
        onChange(obj);
    }, [obj]);


    return (
        <>
            {isLoading ? (
                <Loading/>
            ) : (
                <>
                    <Box sx={{display: 'flex', alignItems: 'center', width: '100%'}}>

                        <MuiAutocomplete
                            id="province-select"
                            options={objs}
                            getOptionLabel={(option) => option.name}
                            value={obj ? obj : null}
                            onChange={handleOnChange}
                            isOptionEqualToValue={(option, value) => option.id === value?.id && option.name === value?.name}
                            renderInput={(params) => (
                                <TextField
                                    sx={{textTransform: 'capitalize'}}
                                    {...params}
                                    label={t(label ? label : '') || t('currency')}
                                    required={required}
                                />
                            )}
                        />
                    </Box>

                </>
            )}
        </>
    );
}

export default RentriProvinceSelect;