import React, {useEffect, useMemo, useState} from 'react';
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {MaterialReactTable, MRT_ActionMenuItem, MRT_ColumnDef, useMaterialReactTable} from "material-react-table";
import {Delete, Edit} from "@mui/icons-material";
import {Avatar, Box} from "@mui/material";
import Grid from '@mui/material/Grid';
import AddIcon from "@mui/icons-material/Add";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import {useQueryClient} from "@tanstack/react-query";
import {
    getMuiTableBodyCellProps,
    getMuiTableBodyRowProps,
    getMuiTableHeadCellProps,
    getMuiTablePaperProps
} from "../../../../ui/mui/mui_react_table/MuiReactTableStyles";
import MuiReadOnlyBoxBody from "../../../../ui/mui/mui-display-data/MuiReadOnlyBoxBody";
import MRTMuiButton from "../../../../ui/mui/mui_react_table/MRTMuiButton";
import Loading from "../../../../ui/Loading";
import MuiFabButtonBack from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiAlertDialog from "../../../../ui/mui/mui_modal/MuiAlertDialog";
import MuiFetchingSpinner from "../../../../ui/mui/MuiFetchingSpinner";
import {IWasteCode} from "../../../../../utils/interfaces/special_wasty/waste_code/IWasteCode";
import {IMyCompanySite} from "../../../../../utils/interfaces/my_company/my_company_site/IMyCompanySite";
import useApiDeleteWasteCode from "./api-hooks/useApiDeleteWasteCode";
import useApiGetWasteCodes from "./api-hooks/useApiGetWasteCodes";
import useCustomCellRenderer from "./useCustomCellRenderer";
import {FaRecycle} from "react-icons/fa";
import WasteCodeForm from "./WasteCodeForm";
import MuiModal from "../../../../ui/mui/mui_modal/MuiModal";
import WasteOpenLoadsTable from "../waste_open_loads/WasteOpenLoadsTable";
import {MRT_Localization_IT} from "material-react-table/locales/it";
import WasteMovementsTable from "../waste_movements/WasteMovementsTable";
import {useSpecialWasty} from "../../../../../utils/contexts/special_wasty/specialWasty-context";
import {IoDocumentAttachOutline, IoDocumentsSharp} from "react-icons/io5";
import {IWasteDocType} from "../../../../../utils/interfaces/special_wasty/waste_doc/IWasteDoc";
import WasteDocTable from "../waste_doc/WasteDocTable";


interface Props {
    onSelect?: (obj: IWasteCode | undefined) => void,
    my_company_site: IMyCompanySite | undefined,
    onClose?: () => void;
}


const WasteCodeTable = ({onSelect, onClose, my_company_site}: Props) => {
    const theme = useTheme();
    const {t} = useTranslation()
    const queryClient = useQueryClient();
    const {selectWasteCode} = useSpecialWasty()

    const [selectedRow, setSelectedRow] = useState<IWasteCode | undefined>()
    const [selectedRows, setSelectedRows] = useState<IWasteCode[] | undefined>()
    const [rows, setRows] = useState<IWasteCode[] | undefined>()

    const [isWasteDocTableOpen, setIsWasteDocTableOpen] = useState<boolean>(false)

    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)
    const [isMovementsTableOpen, setIsMovementsTableOpen] = useState<boolean>(false)
    const [isOpenLoadsTableOpen, setIsOpenLoadsTableOpen] = useState<boolean>(false)

    const [docType, setDocType] = useState<IWasteDocType>('analysis')


    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const deleteMutation = useApiDeleteWasteCode(selectedRow?.id);
    const {mutateAsync: apiDelete} = deleteMutation;

    const handleOpenMovementsTable = (row: IWasteCode) => {
        setSelectedRow(row);
        selectWasteCode(row);
        setIsMovementsTableOpen(true);
    }

    // const {data: proviamo} = useApiGetRentriAttivita()
    // console.log(proviamo)

    const handleConfirmDelete = async () => {
        try {
            if (onSelect) {
                onSelect(undefined);
            }
            await apiDelete({pk: selectedRow?.id});
        } catch (error) {
        }
        setDeleteConfirmationOpen(false);
        await queryClient.invalidateQueries({queryKey: ['useApiGetWasteCode', selectedRow?.id, my_company_site?.id]});
        await queryClient.invalidateQueries({queryKey: ["useApiGetWasteCodes"]});
    };
    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };


    const {
        data,
        isLoading,
        isFetching,
    } = useApiGetWasteCodes(my_company_site?.id);


    useEffect(() => {
        if (data) {
            setRows(data);
        }
    }, [data]);

    const handleOpenLoadsTable = (row: IWasteCode) => {
        setSelectedRow(row);
        setIsOpenLoadsTableOpen(true);
    };


    const CustomCellRenderer: React.FC<{
        row: IWasteCode,
        theme: any,
        onOpenLoadsTable: () => void,
        onOpenMovementsTable: () => void
    }> = ({
              row,
              theme
          }) => {
        const {renderIcons} = useCustomCellRenderer(
            row,
            theme,
            () => handleOpenLoadsTable(row),
            () => handleOpenMovementsTable(row),
        );
        return renderIcons();
    };


    const columns = useMemo<MRT_ColumnDef<IWasteCode>[]>(
        () => {
            const baseColumns: MRT_ColumnDef<IWasteCode>[] = [
                {
                    id: "icons",
                    header: "",
                    columnDefType: "display",
                    Cell: ({row}) => (<CustomCellRenderer row={row.original} theme={theme}
                                                          onOpenLoadsTable={() => setIsOpenLoadsTableOpen(true)}
                                                          onOpenMovementsTable={() => setIsMovementsTableOpen(true)}
                    />)
                },
                {
                    accessorKey: 'waste_code',
                    header: t('waste code'),
                    accessorFn: (row) => row.codice_eer.code
                },
                {
                    accessorKey: 'waste_type',
                    header: t('waste type'),
                },
                {
                    accessorKey: 'balance_of_site',
                    header: t('balance of site'),
                    accessorFn: (row) => row.balance_of_site + " kg"
                },

            ];


            // if (doc) {
            //     baseColumns.push({
            //         accessorKey: 'doc_type',
            //         header: t('payment type'),
            //         accessorFn: (row) => row.payment_type.name,
            //     });
            // }
            //
            // if (!doc) {
            //     baseColumns.push({
            //         accessorKey: 'registry',
            //         header: t('registry'),
            //         accessorFn: (row) => row.doc?.fiscal_registry.registry?.business_name,
            //     });
            // }

            return baseColumns;
        },
        [t]
    );


    const table = useMaterialReactTable<IWasteCode>({
        columns,
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 80,
        },
        localization: MRT_Localization_IT,
        data: rows ? rows : [],
        columnFilterDisplayMode: 'popover',
        globalFilterFn: 'contains',
        enableFullScreenToggle: false,
        enableFilters: true,
        enableColumnActions: false,
        enableDensityToggle: false,
        muiTableHeadCellProps: getMuiTableHeadCellProps(theme.palette.primary.main),
        muiTableBodyCellProps: getMuiTableBodyCellProps(theme),
        muiTablePaperProps: getMuiTablePaperProps,
        enablePagination: false,
        initialState: {
            density: 'compact',
            pagination: {pageSize: 25, pageIndex: 0},
        },
        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.backgroundAppGarageColor.main,

        }),
        // note_marco: bg color on row selected
        muiTableBodyRowProps: ({row}) => ({
            ...getMuiTableBodyRowProps(theme),
            // sx: {
            //     backgroundColor: row.original.id === selectedRow?.id ? theme.palette.action.selected : undefined,
            // }
        }),

        enableRowActions: true,
        renderRowActionMenuItems: ({closeMenu, row}) => [
            onSelect && onClose && (
                <MRT_ActionMenuItem
                    icon={<AdsClickIcon color="primary"/>}
                    key="select"
                    label={t("select")}
                    onClick={() => {
                        onSelect(row.original);
                        onClose();
                    }}
                    table={table}
                />),
            <MRT_ActionMenuItem
                icon={<Edit color="primary"/>}
                key="edit"
                label={t("edit")}
                onClick={(event) => {
                    setSelectedRow(row.original);
                    setIsEditFormOpen(true)
                    closeMenu()
                }}
                table={table}
            />,
            <MRT_ActionMenuItem
                icon={<Delete color="warning"/>}
                key="delete"
                label={t("delete")}
                onClick={() => {
                    setSelectedRow(row.original);
                    setDeleteConfirmationOpen(true)
                    closeMenu()
                }}
                table={table}
            />,
            <MRT_ActionMenuItem
                icon={<IoDocumentsSharp color={theme.palette.primary.main}/>}
                key="waste_docs"
                label={t("analysis")}
                onClick={() => {
                    setDocType('analysis')
                    setSelectedRow(row.original);
                    setIsWasteDocTableOpen(true)
                    closeMenu()
                }}
                table={table}
            />,
            <MRT_ActionMenuItem
                icon={<IoDocumentAttachOutline color={theme.palette.primary.main}/>}
                key="waste_docs"
                label={t("homologation_forms")}
                onClick={() => {
                    setDocType('homologation_forms')
                    setSelectedRow(row.original);
                    setIsWasteDocTableOpen(true)
                    closeMenu()
                }}
                table={table}
            />,
        ],
        renderTopToolbarCustomActions: ({table}) => (
            <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                <>
                    <Avatar sx={{
                        backgroundColor: 'white',
                        border: `2px solid ${theme.palette.primary.main}`,
                        marginRight: 1
                    }}>
                        <FaRecycle color={theme.palette.primary.main}/>
                    </Avatar>
                    <MuiReadOnlyBoxBody label={''} value={'waste_codes'}/>
                    <MRTMuiButton
                        icon={<AddIcon style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                        onClick={() => {
                            setSelectedRow(undefined);
                            setIsEditFormOpen(true)
                        }} label={'add'}/>
                </>
            </Box>
        )
    });


    return <>
        {isLoading ? <Loading/> :
            <>
                {onClose &&
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                        <Box className={'mt-2 gap-x-2 mb-2'}>
                            <MuiFabButtonBack onClick={onClose}/>
                        </Box>
                    </Grid>
                }


                {!isMovementsTableOpen &&
                    <MaterialReactTable table={table}/>}
                {isMovementsTableOpen && selectedRow &&
                    <>
                        <WasteMovementsTable my_company_site={my_company_site}
                                             waste_code={selectedRow}
                                             onClose={() => setIsMovementsTableOpen(false)}
                        />
                    </>
                }

                <MuiAlertDialog
                    open={isDeleteConfirmationOpen}
                    title="delete_confirmation"
                    content="ask_for_delete"
                    onClose={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                />

                <MuiModal
                    top="50%"
                    left="50%"
                    width={'90vh'}
                    open={isEditFormOpen}
                    disableBackdropClick={true}
                    onClose={() => setIsEditFormOpen(false)}
                >

                    <WasteCodeForm
                        my_company_site={my_company_site}
                        objToEdit={selectedRow || null}
                        onClose={() => setIsEditFormOpen(false)}
                    />
                </MuiModal>

                <MuiModal
                    top="50%"
                    left="50%"
                    width={'80vh'}
                    open={isOpenLoadsTableOpen}
                    onClose={() => setIsOpenLoadsTableOpen(false)}
                >
                    <WasteOpenLoadsTable my_company_site={my_company_site}
                                         waste_code={selectedRow}
                                         onClose={() => setIsOpenLoadsTableOpen(false)}/>
                </MuiModal>

                <MuiModal
                    top="50%"
                    left="50%"
                    width={'80vh'}
                    open={isWasteDocTableOpen}
                    onClose={() => setIsWasteDocTableOpen(false)}
                >

                    <WasteDocTable
                        doc_type={docType}
                        waste_code={selectedRow}
                        my_company_site={my_company_site}
                        onClose={() => setIsWasteDocTableOpen(false)}
                    />

                </MuiModal>

                {/*<MuiModal*/}
                {/*    top="50%"*/}
                {/*    left="50%"*/}
                {/*    width={'100vh'}*/}
                {/*    open={isMovementsTableOpen}*/}
                {/*    onClose={() => setIsMovementsTableOpen(false)}*/}
                {/*>*/}
                {/*    {selectedRow &&*/}
                {/*        <WasteMovementsTable my_company_site={my_company_site}*/}
                {/*                             waste_code={selectedRow}*/}
                {/*                             onClose={() => setIsMovementsTableOpen(false)}/>}*/}
                {/*</MuiModal>*/}


                {isFetching &&
                    <MuiFetchingSpinner text="Fetching data..."/>
                }
            </>}
    </>;
};

export default WasteCodeTable;
