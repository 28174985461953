import React, {useEffect, useState} from 'react';

import {Box} from "@mui/material";
import MuiInputTextField from "../../../../ui/mui/mui-input/MuiInputTextField";
import MuiFabButtonBack from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonSaveAs from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";
import {IWasteMovement} from "../../../../../utils/interfaces/special_wasty/waste_movement/IWasteMovement";
import {useSpecialWasty} from "../../../../../utils/contexts/special_wasty/specialWasty-context";
import {useAppGarage} from "../../../../../utils/contexts/appGarage-context";
import useApiCreateWasteMovement from "./api-hooks/useApiCreateWasteMovement";
import useApiUpdateWasteMovement from "./api-hooks/useApiUpdateWasteMovement";
import useApiGetWasteMovement from "./api-hooks/useApiGetWasteMovement";
import {IWastyCarrier} from "../../../../../utils/interfaces/special_wasty/wasty_carrier/IWastyCarrier";
import {IWastyRecipient} from "../../../../../utils/interfaces/special_wasty/wasty_recipient/IWastyRecipient";
import {getCurrentDate} from "../../../../../logics/helpers";
import {IWastyIntermediary} from "../../../../../utils/interfaces/special_wasty/wasty_intermediary/IWastyIntermediary";
import {
    IRentriCausaleOperazione
} from "../../../../../utils/interfaces/special_wasty/rentri/codifiche/fir_registro/IRentriCausaleOperazione";
import RentriCausaliOperazioneSelect from "../rentri/codifiche/fir_registro/RentriCausaliOperazioneSelect";
import {IWasteMovementType} from "../../../../../utils/interfaces/special_wasty/waste_movement/IWasteMovementType";
import WasteMovementSelect from "./WasteMovementSelect";
import {useQueryClient} from "@tanstack/react-query";
import {IWasteCode} from "../../../../../utils/interfaces/special_wasty/waste_code/IWasteCode";


interface Props {
    objToEdit: IWasteMovement | null | undefined;
    movement_type: IWasteMovementType,
    onClose: () => void;
    onSelect?: (obj: IWasteMovement | undefined) => void,
    selected_waste_code: IWasteCode | undefined,
}

const WasteMovementForm: React.FC<Props> = ({objToEdit, onClose, onSelect, movement_type, selected_waste_code}) => {
    const queryClient = useQueryClient();
    const {selectedWasteCode} = useSpecialWasty()
    const {selectedMyCompanySite} = useAppGarage()

    const [estimateQuantity, setEstimateQuantity] = useState<number>();
    const [movementNumber, setMovementNumber] = useState<number>();
    const [movementDate, setMovementDate] = useState<string>(getCurrentDate());
    const [carrier, setCarrier] = useState<IWastyCarrier>()
    const [recipient, setRecipient] = useState<IWastyRecipient>()
    const [intermediary, setIntermediary] = useState<IWastyIntermediary>()
    const [formDate, setFormDate] = useState<string>(getCurrentDate());
    const [formNumber, setFormNumber] = useState<string>();
    const [causaleOperazione, setCausaleOperazione] = useState<IRentriCausaleOperazione>();
    // const [wasteMovementToBeAnnulled, setWasteMovementToBeAnnulled] = useState<IWasteMovement | null | undefined>();
    // const [wasteMovementToBeRectified, setWasteMovementToBeRectified] = useState<IWasteMovement | null | undefined>();
    const [wasteMovementToBeAnnulledId, setWasteMovementToBeAnnulledId] = useState<number>();
    const [wasteMovementToBeRectifiedId, setWasteMovementToBeRectifiedId] = useState<number>();
    const [references, setReferences] = useState<string>();


    const createMutation = useApiCreateWasteMovement();
    const updateMutation = useApiUpdateWasteMovement();

    const {
        data: detailedObj,
    } = useApiGetWasteMovement(objToEdit?.id);


    useEffect(() => {
        if (detailedObj) {
            setEstimateQuantity(detailedObj.estimated_quantity);
            setMovementNumber(detailedObj.movement_number);
            setMovementDate(detailedObj.movement_date);
            setCarrier(detailedObj.carrier);
            setRecipient(detailedObj.recipient);
            setIntermediary(detailedObj.intermediary);
            setFormNumber(detailedObj.form_number);
            setFormDate(detailedObj.form_date);
            setCausaleOperazione(detailedObj.causale_operazione);
            setWasteMovementToBeAnnulledId(detailedObj.annulled_waste_movement_id);
            setWasteMovementToBeRectifiedId(detailedObj.rectified_waste_movement_id);
            setReferences(detailedObj.references);
        }
    }, [detailedObj]);

    const {
        data: annulledWasteMovement,
    } = useApiGetWasteMovement(wasteMovementToBeAnnulledId);


    const {
        data: wasteMovementToBeRectifiedDetailed,
    } = useApiGetWasteMovement(wasteMovementToBeRectifiedId);


    useEffect(() => {
        if (wasteMovementToBeRectifiedDetailed && !objToEdit) {
            setEstimateQuantity(wasteMovementToBeRectifiedDetailed.estimated_quantity);
            setCarrier(wasteMovementToBeRectifiedDetailed.carrier);
            setRecipient(wasteMovementToBeRectifiedDetailed.recipient);
            setIntermediary(wasteMovementToBeRectifiedDetailed.intermediary);
            setFormNumber(wasteMovementToBeRectifiedDetailed.form_number);
            setFormDate(wasteMovementToBeRectifiedDetailed.form_date);
            setCausaleOperazione(wasteMovementToBeRectifiedDetailed.causale_operazione);
        }
    }, [wasteMovementToBeRectifiedDetailed]);


    const handleSubmit = async () => {
        try {
            const payload = {
                estimated_quantity: estimateQuantity,
                waste_code_id: selectedWasteCode?.id,
                site_id: selectedMyCompanySite?.id,
                movement_date: movementDate,
                movement_number: movementNumber,
                carrier_id: carrier?.id,
                recipient_id: recipient?.id,
                intermediary_id: intermediary?.id,
                form_date: formDate,
                form_number: formNumber,
                movement_type: movement_type,
                causale_operazione_id: causaleOperazione ? causaleOperazione.id : null,
                references: references,
                annulled_waste_movement_id: annulledWasteMovement?.id,
                rectified_waste_movement_id: wasteMovementToBeRectifiedDetailed?.id,

            };

            let newData;

            if (detailedObj !== null && detailedObj !== undefined) {
                newData = await updateMutation.mutateAsync({id: detailedObj.id, data: payload});
            } else {
                newData = await createMutation.mutateAsync(payload);
            }
            onClose();
            onSelect && onSelect(newData);
            await queryClient.invalidateQueries({queryKey: ["useApiGetWasteOpenLoadsByWasteCodeId", newData.site.id, newData.waste_code.id]});
            await queryClient.invalidateQueries({queryKey: ["useWasteApiGetWasteCodeMovements", newData.site.id, newData.waste_code.id]});
            await queryClient.invalidateQueries({queryKey: ["useApiGetWasteCodes", newData.site.id]});
            await queryClient.invalidateQueries({queryKey: ["useWasteApiGetSiteMovements", newData?.site.id]});
            await queryClient.invalidateQueries({queryKey: ["useWasteApiGetOpenLoads", newData.site.id]});
            await queryClient.invalidateQueries({queryKey: ["useApiGetMyCompanySite", newData.site.id]});
            await queryClient.invalidateQueries({queryKey: ["useApiGetMyCompanySites"]});
            await queryClient.invalidateQueries({queryKey: ["useWasteApiGetSiteRECTIFIABLEMovements", newData.site.id]});
            await queryClient.invalidateQueries({queryKey: ["useWasteApiGetSiteNULLABLEMovements", newData.site.id]});

        } catch (error) {
        }
    }


    return (
        <Box sx={{padding: 2}}>
            {movement_type === 'annulment' && <WasteMovementSelect
                waste_code={selected_waste_code}
                movement_type={movement_type}
                onChange={(obj) => setWasteMovementToBeAnnulledId(obj?.id)}
                className={''}
                obj={annulledWasteMovement}/>
            }

            {movement_type === 'rectification' && <WasteMovementSelect
                waste_code={selected_waste_code}
                movement_type={movement_type}
                onChange={(obj) => setWasteMovementToBeRectifiedId(obj?.id)}
                className={''}
                obj={wasteMovementToBeRectifiedDetailed}/>
            }

            <MuiInputTextField
                onChange={setMovementDate}
                label='movement date'
                type="date"
                value={movementDate}
                required={true}
            />

            <MuiInputTextField
                onChange={(e) => setReferences(e.target.value)}
                label='annotazioni'
                type="text"
                value={references ? references : movement_type === 'annulment' ? 'annotazione obbligatoria' : ''}
                required={movement_type === 'annulment'}
                rows={5}
            />

            {movement_type
                !== 'annulment' && <>
                    <Box className="grid grid-cols-2 gap-4">
                        <MuiInputTextField
                            onChange={(e) => setEstimateQuantity(parseFloat(e.target.value))}
                            label='estimate quantity'
                            type="number"
                            value={estimateQuantity}
                            required={true}
                        />

                    </Box>

                    {movement_type == 'download' &&
                        <>
                            <Box className="grid grid-cols-2 gap-4">
                                <MuiInputTextField
                                    onChange={(e) => setFormNumber(e.target.value)}
                                    label='form number'
                                    type="text"
                                    value={formNumber}
                                    required={false}
                                />
                                <MuiInputTextField
                                    onChange={setFormDate}
                                    label='form date'
                                    type="date"
                                    value={formDate}
                                    required={false}
                                />
                            </Box>
                        </>
                    }


                    {/*{(movement_type === 'download' || movement_type === 'rectification') &&*/}
                    {/*    <>*/}
                    {/*        <WastyCarrierSelect obj={carrier} onChange={(obj) => setCarrier(obj)} className={''}*/}
                    {/*                            required={true}*/}
                    {/*                            withDefaultValueIfEmpty={true}/>*/}
                    {/*        <WastyRecipientSelect obj={recipient} onChange={(obj) => setRecipient(obj)}*/}
                    {/*                              className={''}*/}
                    {/*                              required={true} withDefaultValueIfEmpty={true}/>*/}
                    {/*        <WastyIntermediarySelect obj={intermediary} onChange={(obj) => setIntermediary(obj)}*/}
                    {/*                                 className={''}*/}
                    {/*                                 required={false}/>*/}
                    {/*    </>*/}
                    {/*}*/}

                    {(movement_type === 'download' || movement_type === 'load') &&
                        <RentriCausaliOperazioneSelect obj={causaleOperazione}
                                                       movement_type={movement_type}
                                                       onChange={(obj) => setCausaleOperazione(obj)}
                                                       className={''}/>}
                </>}


            <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                <MuiFabButtonBack onClick={onClose}/>
                {/*{objToEdit &&*/}
                {/*    <MuiFabButtonDelete onClick={() => {*/}
                {/*        setConfirmationOpen(true)*/}
                {/*    }}/>}*/}
                {objToEdit ?
                    <MuiFabButtonSaveAs onClick={handleSubmit}/>
                    :
                    <MuiFabButtonSave onClick={handleSubmit}/>}
            </Box>
        </Box>
    );
};

export default WasteMovementForm;