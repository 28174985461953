import React from 'react';
import MaxWidthDiv from "../../../../ui/MaxWitdthDiv";
import {useAppGarage} from "../../../../../utils/contexts/appGarage-context";
import WasteOpenLoadsTable from "./WasteOpenLoadsTable";
import SpecialWastyScreen from "../SpecialWastyScreen";


const WasteOpenLoadsScreen = () => {
    const {selectedMyCompanySite} = useAppGarage()


    return (
        <>
            <SpecialWastyScreen>
                <MaxWidthDiv maxWidth='1024px'>

                    {selectedMyCompanySite && <WasteOpenLoadsTable my_company_site={selectedMyCompanySite}/>}

                </MaxWidthDiv>
            </SpecialWastyScreen>

        </>
    );
};

export default WasteOpenLoadsScreen;
