import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomAxiosError} from "../../../../../../../../logics/api_config";
import {useApiRentriCalls} from "../../../api-hooks/api_rentri_config";
import {
    IRentriCausaleOperazione
} from "../../../../../../../../utils/interfaces/special_wasty/rentri/codifiche/fir_registro/IRentriCausaleOperazione";


const useApiGetRentriCausaliOperazione = (): UseQueryResult<IRentriCausaleOperazione[], CustomAxiosError> => {
    const {get} = useApiRentriCalls()

    async function doGet(): Promise<IRentriCausaleOperazione[]> {
        try {
            const response = await get(`/codifiche/v1.0/lookup/causali-operazione`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetRentriCausaliOperazione"],
        queryFn: () => doGet(),
    })
};

export default useApiGetRentriCausaliOperazione