import React, {useEffect, useState} from 'react';
import {useQueryClient} from "@tanstack/react-query";
import {useTranslation} from "react-i18next";
import {useTanneryDyeing} from "../../../../../../utils/contexts/tannery_dyeing/tannery_dyeing-context";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {useToast} from "../../../../../../utils/contexts/toast-context";
import useApiUpdateTanneryDyeingRecipe from "./api-hooks/useApiUpdateTanneryDyeingRecipe";
import useApiCreateTanneryDyeingRecipe from "./api-hooks/useApiCreateTanneryDyeingRecipe";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import {ITanneryDyeingRecipe} from "../../../../../../utils/interfaces/tannery_dyeing/ITanneryDyeingRecipe";
import TanneryDyeingProductSelect from "./TanneryDyeingProductSelect";
import {ITanneryDyeingProduct} from "../../../../../../utils/interfaces/tannery_dyeing/ITanneryDyeingProduct";
import {Box} from "@mui/material";
import TextField from '@mui/material/TextField';
import MuiFabButtonBack from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonDelete from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonDelete";
import MuiFabButtonSaveAs from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiInputTextField from "../../../../../ui/mui/mui-input/MuiInputTextField";
import useApiDeleteTanneryDyeingRecipe from "./api-hooks/useApiDeleteTanneryDyeingRecipe";
import MuiAlertDialog from "../../../../../ui/mui/mui_modal/MuiAlertDialog";
import MuiFabButtonAdd from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonAdd";
import PriceSelect from "../../../service/people/product/price/PriceSelect";
import {IPrice} from "../../../../../../utils/interfaces/service/people/product_list/price/IPrice";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, {Dayjs} from "dayjs";


interface Props {
    onClose: () => void;
    objToEdit?: ITanneryDyeingRecipe
}

const TanneryDyeingRecipeForm: React.FC<Props> = ({onClose, objToEdit}: Props) => {
    const {t} = useTranslation();

    const {
        selectedTanneryDyeing,
        selectedTanneryDyeingRecipe,
        isEditable
    } = useTanneryDyeing()
    const {displayError, displayMessage, displayWarning} = useToast();
    const {selectedCompany, userLevel} = useAuth();
    const queryClient = useQueryClient();

    const updateMutation = useApiUpdateTanneryDyeingRecipe(selectedCompany?.company_token);
    const createMutation = useApiCreateTanneryDyeingRecipe();

    const [description, setDescription] = useState<string | undefined>();
    const [percentage, setPercentage] = useState<number | undefined>(objToEdit?.percentage);
    const [temperature, setTemperature] = useState<number | undefined>(objToEdit?.temperature);
    const [ph, setPh] = useState<string | undefined>(objToEdit?.ph);
    const [rotations, setRotations] = useState<number | undefined>();
    const [rowPosition, setRowPosition] = useState<number | undefined>();
    const [product, setProduct] = useState<ITanneryDyeingProduct | undefined>()
    const [price, setPrice] = useState<IPrice | null>(null)
    const [allRequirementsAreSatisfied, setAllRequirementsAreSatisfied] = useState<boolean>(false)
    const [completedAt, setCompletedAt] = React.useState<Dayjs | null>(
        objToEdit?.completed_at ? dayjs(objToEdit.completed_at) : null
    );

    const handleProduct = (product: ITanneryDyeingProduct | null) => {
        setPrice(null);
        if (product) {
            setProduct(product);
        } else {
            setProduct(undefined)
        }
    };


    useEffect(() => {
        if (objToEdit) {
            setDescription(objToEdit.description);
            setPercentage(objToEdit.percentage);
            setRotations(objToEdit.rotations);
            setRowPosition(objToEdit.row_position);
            setProduct(objToEdit.product);
            setPrice(objToEdit?.price)
            setTemperature(objToEdit.temperature)
            setPh(objToEdit.ph)
        }
    }, [objToEdit]);


    useEffect(() => {
        if (!objToEdit && selectedTanneryDyeingRecipe) {
            setRowPosition(selectedTanneryDyeingRecipe.row_position + 1)
        }
    }, [selectedTanneryDyeingRecipe]);

    useEffect(() => {
        setAllRequirementsAreSatisfied(true);
        if (product && !percentage) {
            setAllRequirementsAreSatisfied(false);
        }
    }, [percentage, product]);

    useEffect(() => {
        if (!product && percentage) {
            // setPercentage(undefined);
        }
    }, [objToEdit, product]);

    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const deleteMutation = useApiDeleteTanneryDyeingRecipe(selectedCompany?.company_token, selectedTanneryDyeing?.id, objToEdit?.id);
    const {mutateAsync: apiDeleteLwgSite} = deleteMutation;
    const handleConfirmDelete = async () => {
        if (!isEditable) {
            displayWarning(t('not_modifiable'))
            return;
        }
        try {

            await apiDeleteLwgSite({
                companyToken: selectedCompany?.company_token,
                tannery_dyeing_id: selectedTanneryDyeing?.id,
                pk: objToEdit?.id
            });
            // displayMessage('Recipe Row deleted successfully');

        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data && axiosError.response.data.detail) {
                displayError(axiosError.response.data.detail);
            } else {
                displayError('An error occurred while deleting the Recipe Row ');
            }
        }
        setDeleteConfirmationOpen(false);
        onClose();
        await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingRecipe']});
    };

    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };


    const handleSubmit = async () => {
        if (!isEditable) {
            displayWarning(t('not_modifiable'))
            return;
        }
        try {
            const mutation_data = {
                description: description || null,
                percentage: product ? percentage : null,
                rotations: rotations || null,
                row_position: rowPosition || '9999',
                product: product?.id || null,
                price: price?.id || product?.valid_price_id,
                temperature: temperature,
                ph: ph,
                completed_at: completedAt
            };

            if (objToEdit !== undefined && objToEdit !== null) {
                await updateMutation.mutateAsync({
                    tannery_dyeing_id: selectedTanneryDyeing?.id,
                    id: objToEdit.id,
                    data: mutation_data
                });
                // displayMessage('Tannery Dyeing Recipe updated successfully');
            } else {
                await createMutation.mutateAsync({
                    tannery_dyeing_id: selectedTanneryDyeing?.id,
                    data: mutation_data
                });
                await queryClient.invalidateQueries({queryKey: ['getFiscalRegistry']});
                setRowPosition(rowPosition && rowPosition + 1)
            }
            objToEdit && onClose();
            setPercentage(undefined);
            setProduct(undefined);
            setRotations(undefined);
            setDescription(undefined);
            await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingRecipe']});

        } catch (error) {
        }
    }

    useEffect(() => {
        setAllRequirementsAreSatisfied(true);
        if (product && !percentage) {
            setAllRequirementsAreSatisfied(false);
        }
    }, []);

    return (

        <>
            <Box>
                <form>
                    <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                        <TanneryDyeingProductSelect
                            product={product ? product : null}
                            onChange={handleProduct}
                            className={'text-black shadow mb-2'}
                            label='product'
                            isDisabled={!isEditable}
                        />
                        {product &&
                            <Box sx={{marginTop: '24px'}}>
                                <MuiInputTextField
                                    label="percentage"
                                    type="number"
                                    value={percentage}
                                    onChange={(e) => setPercentage(parseFloat(e.target.value))}
                                    required={!!product}
                                    disabled={!isEditable}
                                />
                                {price && userLevel >= 99 &&
                                    <PriceSelect person_id={product.people_id} product_id={product?.id} obj={price}
                                                 onChange={setPrice}
                                                 disableClearable={true}
                                                 className={''}/>
                                }
                            </Box>
                        }
                    </Box>

                    <Box display="flex" flexDirection="row" justifyContent="space-between" gap={2}>
                        <MuiInputTextField
                            label="rotations"
                            type="number"
                            value={rotations || ''}
                            onChange={(e) => setRotations(parseFloat(e.target.value))}
                            required={false}
                            disabled={!isEditable}
                        />
                        <MuiInputTextField
                            label="temperature"
                            type="number"
                            value={temperature || ''}
                            onChange={(e) => setTemperature(parseFloat(e.target.value))}
                            required={false}
                            disabled={!isEditable}
                        />
                        <MuiInputTextField
                            label="ph"
                            type="text"
                            value={ph || ''}
                            onChange={(e) => setPh(e.target.value)}
                            required={false}
                            disabled={!isEditable}
                        />
                    </Box>

                    <MuiInputTextField
                        label="description"
                        type="text"
                        value={description || ''}
                        onChange={(e) => setDescription(e.target.value)}
                        required={false}
                        rows={1}
                        disabled={!isEditable}
                    />

                    {objToEdit?.rotations &&

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                label="Select Date and Time"
                                value={completedAt}
                                onChange={(newValue) => setCompletedAt(newValue)}
                                disabled={!isEditable}
                                slotProps={{
                                    popper: {
                                        disablePortal: false,
                                        placement: 'auto',
                                    },
                                }}
                                slots={{textField: (params) => <TextField {...params} />}}
                            />
                        </LocalizationProvider>}


                    <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                        {objToEdit && <MuiFabButtonBack onClick={onClose}/>}
                        {isEditable && objToEdit &&
                            <MuiFabButtonDelete onClick={() => {
                                setDeleteConfirmationOpen(true)
                            }}/>}
                        {isEditable &&
                            <>
                                {objToEdit ?
                                    <MuiFabButtonSaveAs onClick={handleSubmit}
                                                        isDisabled={!allRequirementsAreSatisfied}/>
                                    :
                                    <MuiFabButtonAdd onClick={handleSubmit}
                                                     isDisabled={!allRequirementsAreSatisfied}
                                                     label={product ? 'add_product' : 'add_blank_row'}/>}
                            </>
                        }
                        {!objToEdit && <Box style={{display: 'flex', justifyContent: 'center'}} className={''}>
                            <MuiFabButtonBack onClick={onClose}/>
                        </Box>}
                    </Box>
                </form>
            </Box>
            <MuiAlertDialog
                open={isDeleteConfirmationOpen}
                title="delete_confirmation"
                content="ask_for_delete"
                onClose={handleCancelDelete}
                onConfirm={handleConfirmDelete}
            />
        </>


    );
};

export default TanneryDyeingRecipeForm;
