import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useTheme} from "@mui/material/styles";

interface MuiFetchingSpinnerProps {
    text: string;
}

const MuiFixedMessage: React.FC<MuiFetchingSpinnerProps> = ({text}) => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                position: 'fixed',
                top: '16px',
                right: '100px',
                mt: '2',
                mr: '6',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-center',
                zIndex: 9999,
            }}
        >
            <Typography variant="body2" fontStyle="italic" fontSize="small"
                        sx={{
                            color: 'white',
                            bgcolor: 'transparent',
                        }}>
                {text}
            </Typography>
            <Box sx={{
                display: 'flex', justifyContent: 'center', color: 'white',
                bgcolor: 'transparent',
            }}>

            </Box>
        </Box>
    );
};

export default MuiFixedMessage;
