import {useMutation, UseMutationResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {IWasteMovement} from "../../../../../../utils/interfaces/special_wasty/waste_movement/IWasteMovement";
import {useAppGarage} from "../../../../../../utils/contexts/appGarage-context";


const useApiUpdateWasteMovement = (): UseMutationResult<IWasteMovement, CustomAxiosError, {
    id: number;
    data: any
}, unknown> => {
    const {selectedCompany} = useAuth()
    const {selectedMyCompanySite} = useAppGarage()

    const {put} = useApiCalls()


    async function doUpdate(company_token: string | undefined,
                            pk: number | undefined,
                            data: any): Promise<IWasteMovement> {
        try {
            const response = await put(`/api/company/${company_token}/special_wasty/waste_movement/${pk}?site_id=${selectedMyCompanySite?.id}`, data);
            return response.data as IWasteMovement;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<IWasteMovement, CustomAxiosError, {
        id: number;
        data: any
    }, unknown>({
        mutationFn: async ({id, data}) => {
            return await doUpdate(selectedCompany?.company_token, id, data);
        },
        onSuccess: async (obj) => {
        },
    });
};


export default useApiUpdateWasteMovement;