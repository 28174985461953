import React, {useEffect, useState} from 'react';

import {Box} from "@mui/material";
import {IWastyRecipient} from "../../../../../utils/interfaces/special_wasty/wasty_recipient/IWastyRecipient";
import useApiCreateWastyRecipient from "./api-hooks/useApiCreateWastyRecipient";
import useApiUpdateWastyRecipient from "./api-hooks/useApiUpdateWastyRecipient";
import useApiGetWastyRecipient from "./api-hooks/useApiGetWastyRecipient";
import MuiInputTextField from "../../../../ui/mui/mui-input/MuiInputTextField";
import MuiFabButtonBack from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonSaveAs from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";
import RentriNazioneSelect from "../rentri/codifiche/territoriali/RentriNazioneSelect";
import {
    IRentriNazione
} from "../../../../../utils/interfaces/special_wasty/rentri/codifiche/territoriali/IRentriNazione";
import {
    IRentriProvince
} from "../../../../../utils/interfaces/special_wasty/rentri/codifiche/territoriali/IRentriProvince";
import RentriProvinceSelect from "../rentri/codifiche/territoriali/RentriProvinceSelect";
import {IRentriComune} from "../../../../../utils/interfaces/special_wasty/rentri/codifiche/territoriali/IRentriComune";
import RentriComuniSelect from "../rentri/codifiche/territoriali/RentriComuniSelect";


interface Props {
    objToEdit: IWastyRecipient | null | undefined;
    onClose: () => void;
    onSelect?: (obj: IWastyRecipient | undefined) => void,
}

const WastyRecipientForm: React.FC<Props> = ({objToEdit, onClose, onSelect}) => {

    const [name, setName] = useState<string>();
    const [address, setAddress] = useState<string | undefined>()
    const [city, setCity] = useState<IRentriComune>()
    const [province, setProvince] = useState<IRentriProvince>()
    const [nation, setNation] = useState<IRentriNazione>()
    const [fiscalCode, setFiscalCode] = useState<string | undefined>();
    const [nIscrizioneAlbo, setNIscrizioneAlbo] = useState<string | undefined>()


    const createMutation = useApiCreateWastyRecipient();
    const updateMutation = useApiUpdateWastyRecipient();

    const {
        data: detailedObj,
    } = useApiGetWastyRecipient(objToEdit?.id);


    useEffect(() => {
        if (detailedObj) {
            setName(detailedObj.name);
            setAddress(detailedObj?.address);
            setCity(detailedObj?.city);
            setProvince(detailedObj?.province);
            setNation(detailedObj?.nation)
            setFiscalCode(detailedObj.fiscal_code || '');
            setNIscrizioneAlbo(detailedObj.num_iscrizione_albo)
        }
    }, [detailedObj]);


    const handleSubmit = async () => {
        try {
            const payload = {
                name: name,
                address: address,
                city_id: city?.id,
                province_id: province?.id,
                nation_id: nation?.id,
                regione_id: province?.properties.regione_id,
                fiscal_code: fiscalCode || null,
                num_iscrizione_albo: nIscrizioneAlbo || null,
            };

            let newData;

            if (detailedObj !== null && detailedObj !== undefined) {
                newData = await updateMutation.mutateAsync({id: detailedObj.id, data: payload});
            } else {
                newData = await createMutation.mutateAsync(payload);
            }
            onClose();
            onSelect && onSelect(newData);

        } catch (error) {
        }
    }


    return (
        <Box sx={{padding: 2}}>
            <MuiInputTextField
                onChange={(e) => setName(e.target.value)}
                label='name'
                type="text"
                value={name}
                required={true}
            />
            <MuiInputTextField
                onChange={(e) => setAddress(e.target.value)}
                label='address'
                type="text"
                value={address}
                required={false}
            />

            <Box className="grid grid-cols-2 gap-4">
                <MuiInputTextField
                    label="fiscal_code"
                    type="text"
                    value={fiscalCode}
                    onChange={(e) => setFiscalCode(e.target.value)}
                    required={false}
                />
                <MuiInputTextField
                    label="n iscrizione albo"
                    type="text"
                    value={nIscrizioneAlbo}
                    onChange={(e) => setNIscrizioneAlbo(e.target.value)}
                    required={false}
                />


            </Box>

            <Box className="grid grid-cols-2 gap-4">

                <RentriProvinceSelect
                    label={'province'}
                    obj={province}
                    onChange={(obj) => setProvince(obj)}
                    className={''}
                    required={false}
                ></RentriProvinceSelect>

                <RentriComuniSelect
                    label={'comune'}
                    obj={city}
                    provincia_id={province?.id}
                    onChange={(obj) => setCity(obj)}
                    className={''}
                    required={false}
                ></RentriComuniSelect>
            </Box>

            <Box className="grid grid-cols-2 gap-4">
                {/*<RentriRegioniSelect*/}
                {/*    label={'regione'}*/}
                {/*    obj={regione}*/}
                {/*    onChange={(obj) => setRegione(obj)}*/}
                {/*    className={''}*/}
                {/*    required={false}*/}
                {/*></RentriRegioniSelect>*/}

                <RentriNazioneSelect
                    label={'nation'}
                    obj={nation}
                    onChange={(obj) => setNation(obj)}
                    className={''}
                    required={false}
                ></RentriNazioneSelect>
            </Box>


            <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                <MuiFabButtonBack onClick={onClose}/>
                {/*{objToEdit &&*/}
                {/*    <MuiFabButtonDelete onClick={() => {*/}
                {/*        setConfirmationOpen(true)*/}
                {/*    }}/>}*/}
                {objToEdit ?
                    <MuiFabButtonSaveAs onClick={handleSubmit}/>
                    :
                    <MuiFabButtonSave onClick={handleSubmit}/>}
            </Box>
        </Box>
    );
};

export default WastyRecipientForm;