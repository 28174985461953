import React, {useEffect, useState} from 'react';
import {Avatar, Box, Container} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useToast} from "../../../../../../../utils/contexts/toast-context";
import {IDocCompanyDocReason} from "../../../../../../../utils/interfaces/my_documents/doc_reason/IDocCompanyDocReason";
import {IFiscalRegistry} from "../../../../../../../utils/interfaces/my_documents/fiscal_registry/IFiscalRegistry";
import {ICompanyDocType} from "../../../../../../../utils/interfaces/my_documents/doc_type/ICompanyDocType";
import DocReasonSelect from "../../doc_reason/DocReasonSelect";
import DocTypeSelect from "../../doc_type/DocTypeSelect";
import FiscalRegistrySelect from "../../../fiscal_registry/FiscalRegistrySelect";
import MuiInputTextField from "../../../../../../ui/mui/mui-input/MuiInputTextField";
import {getCurrentDate} from "../../../../../../../logics/helpers";
import MuiFabButtonBack from "../../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import {useService} from "../../../../../../../utils/contexts/service/service-context";
import MuiBorderedBox from "../../../../../../ui/mui/MuiBorderedBox";
import {useAppGarage} from "../../../../../../../utils/contexts/appGarage-context";
import MuiReadOnlyBoxBody from "../../../../../../ui/mui/mui-display-data/MuiReadOnlyBoxBody";
import {useTheme} from "@mui/material/styles";
import {LiaFileInvoiceDollarSolid} from "react-icons/lia";
import MuiFabButtonBase from "../../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBase";
import useApiCreateDocsInvoicing from "./api-hooks/useApiCreateDocsInvoicing";
import MuiAlertDialog from "../../../../../../ui/mui/mui_modal/MuiAlertDialog";
import {CustomAxiosError} from "../../../../../../../logics/api_config";


interface Props {
    onClose: () => void;
    isOpen: boolean,
}

const DocsInvoicingForm: React.FC<Props> = ({onClose, isOpen}) => {
    const theme = useTheme();
    const {displayMessage} = useToast();
    const {t} = useTranslation()
    const {selectedSystemYear} = useAppGarage()
    const {selectPerson} = useService()

    const [isConfirmationOpen, setConfirmationOpen] = useState(false);
    const [isOnlyForCloseConfirmationOpen, setIsOnlyForCloseConfirmationOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')

    const [docReason, setDocReason] = useState<IDocCompanyDocReason | undefined>()
    const [fiscalRegistry, setFiscalRegistry] = useState<IFiscalRegistry | undefined>()
    const [docType, setDocType] = useState<ICompanyDocType | undefined>()
    const [startDate, setStartDate] = useState<string>()
    const [endDate, setEndDate] = useState<string>()
    const [docPrefix, setDocPrefix] = useState<string | undefined>(selectedSystemYear?.toString().slice(-2))
    const [invoiceType, setInvoiceType] = useState<ICompanyDocType | undefined>()
    const [invoiceDate, setInvoiceDate] = useState<string>(getCurrentDate())
    const [invoiceYear, setInvoiceYear] = useState<string | undefined>(selectedSystemYear?.toString())
    const [invoicePrefix, setInvoicePrefix] = useState<string | undefined>(selectedSystemYear?.toString().slice(-2))

    const handleCompanyDocTypeChange = (companyDocType: ICompanyDocType | undefined) => {
        setDocType(companyDocType);
    };

    const handleCompanyDocReasonChange = (companyDocReason: IDocCompanyDocReason | undefined) => {
        setDocReason(companyDocReason);
    };

    const handleFiscalRegistryChange = (fiscalRegistry: IFiscalRegistry | undefined) => {
        selectPerson(fiscalRegistry?.people)
        setFiscalRegistry(fiscalRegistry);
    };

    const handleInvoiceCompanyDocTypeChange = (companyDocType: ICompanyDocType | undefined) => {
        setInvoiceType(companyDocType);
    };


    const createMutation = useApiCreateDocsInvoicing();
    const handleSubmitPreview = async (action: 'preview' | 'invoicing' | 'only_release_invoicing_lock') => {
        try {
            const payload = {
                doc_type_id: docType?.id,
                invoice_date: invoiceDate,
                invoice_type_id: invoiceType?.id,
                invoice_year: invoiceYear,
                invoice_prefix: invoicePrefix,
                action: action
            };

            await createMutation.mutateAsync({data: payload});

            if (action === 'preview') {
                setConfirmationOpen(true)
            } else {
                onClose()
            }
        } catch (error) {
            const axiosError = error as CustomAxiosError;
            setConfirmationOpen(false)
            setIsOnlyForCloseConfirmationOpen(true)
            setErrorMessage(axiosError.response.data.detail)
        }
    }

    const [allRequirementAreSatisfied, setAllRequirementAreSatisfied] = useState(false);
    useEffect(() => {
        setAllRequirementAreSatisfied(false);
        if (docType && invoiceDate && invoiceType && invoiceYear && invoicePrefix) {
            setAllRequirementAreSatisfied(true);
        }
    }, [docType, invoiceDate, invoicePrefix, invoiceType, invoiceYear]);


    return (
        <>
            {isOpen && (
                <Box>
                    <Container>
                        <Box>
                            <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                                <Avatar sx={{
                                    backgroundColor: 'white',
                                    border: `2px solid ${theme.palette.primary.main}`,
                                    marginRight: 1
                                }}>
                                    <LiaFileInvoiceDollarSolid color={theme.palette.primary.main}/>
                                </Avatar>
                                <MuiReadOnlyBoxBody label={''} value={'invoice docs'}/>
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between', // space between the boxes
                                    // flexWrap: 'wrap', // wrap to next row if needed
                                    gap: 2, // spacing between boxes
                                    marginTop: 2
                                }}
                            >
                                <MuiBorderedBox label={'non-optional parameters'}>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 1,
                                        paddingY: 1,
                                        width: '100%'
                                    }}>
                                        <DocTypeSelect
                                            label={'doc_type.name'}
                                            doc_type={docType}
                                            onChange={handleCompanyDocTypeChange}
                                            className={''}
                                            required={true}
                                            clearable={false}
                                            only_to_be_invoiced={true}
                                        ></DocTypeSelect>
                                    </Box>
                                </MuiBorderedBox>


                                <MuiBorderedBox label={'optional parameters'}>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 1,
                                        paddingY: 1,
                                        width: '100%'
                                    }}>

                                        <DocReasonSelect
                                            label={'doc_reason.name'}
                                            doc_reason={docReason}
                                            onChange={handleCompanyDocReasonChange}
                                            className={'mt-1'}
                                            required={false}
                                            clearable={true}
                                        ></DocReasonSelect>
                                        <FiscalRegistrySelect
                                            label={'customer'}
                                            fiscal_registry={fiscalRegistry}
                                            onChange={handleFiscalRegistryChange}
                                            className={''}
                                            required={false}
                                            withEditCreateButtons={false}
                                        ></FiscalRegistrySelect>
                                        <MuiInputTextField
                                            label="doc_prefix"
                                            type="text"
                                            value={docPrefix}
                                            onChange={(e) => setDocPrefix(e.target.value)}
                                            required={false}
                                        />
                                        <MuiInputTextField
                                            label="start_date"
                                            type="date"
                                            value={startDate}
                                            onChange={setStartDate}
                                            required={false}
                                        />
                                        <MuiInputTextField
                                            label="end_date"
                                            type="date"
                                            value={endDate}
                                            onChange={setEndDate}
                                            required={false}
                                        />
                                    </Box>
                                </MuiBorderedBox>
                                <MuiBorderedBox label={'invoice parameters'}>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 1,
                                        paddingY: 1,
                                        width: '100%'
                                    }}>
                                        <DocTypeSelect
                                            label={'doc_type.name'}
                                            doc_type={invoiceType}
                                            onChange={handleInvoiceCompanyDocTypeChange}
                                            className={''}
                                            required={true}
                                            clearable={false}
                                            only_with_sign_in_sales={true}
                                        ></DocTypeSelect>
                                        <MuiInputTextField
                                            label="invoice date"
                                            type="date"
                                            value={invoiceDate}
                                            onChange={setInvoiceDate}
                                            required={true}
                                        />
                                        <MuiInputTextField
                                            label="invoice prefix"
                                            type="text"
                                            value={invoicePrefix}
                                            onChange={(e) => setInvoicePrefix(e.target.value)}
                                            required={true}
                                        />
                                        <MuiInputTextField
                                            label="invoice year"
                                            type="text"
                                            value={invoiceYear}
                                            onChange={(e) => setInvoiceYear(e.target.value)}
                                            required={true}
                                        />
                                    </Box>
                                </MuiBorderedBox>
                            </Box>
                        </Box>
                    </Container>


                    <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                        <MuiFabButtonBack onClick={onClose}/>
                        {allRequirementAreSatisfied &&
                            <MuiFabButtonBase
                                onClick={() => handleSubmitPreview('preview')}
                                label={'preview invoice docs'}
                                icon={<LiaFileInvoiceDollarSolid/>}
                            />}
                    </Box>

                    <MuiAlertDialog
                        open={isConfirmationOpen}
                        title="invoicing confirmation"
                        content="Confirm invoicing procedure?"
                        ask_for_delete={false}
                        onClose={() => handleSubmitPreview('only_release_invoicing_lock')}
                        onConfirm={() => handleSubmitPreview('invoicing')}
                    />

                    <MuiAlertDialog
                        open={isOnlyForCloseConfirmationOpen}
                        title=""
                        content={t(errorMessage)}
                        onClose={onClose}
                        ask_for_delete={false}
                    />
                </Box>)}
        </>

    );
};

export default DocsInvoicingForm;