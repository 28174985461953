import React from 'react';
import WasteCodeTable from "./WasteCodeTable";
import {useAppGarage} from "../../../../../utils/contexts/appGarage-context";
import SpecialWastyScreen from "../SpecialWastyScreen";
import {Box} from "@mui/material";


const WasteCodeScreen = () => {
    const {selectedMyCompanySite} = useAppGarage()


    return (
        <>
            <SpecialWastyScreen>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    marginTop: 0,
                    paddingLeft: 2,
                    paddingRight: 2,
                    position: 'relative',
                }}>
                    {selectedMyCompanySite && <WasteCodeTable my_company_site={selectedMyCompanySite}/>}
                </Box>


            </SpecialWastyScreen>

        </>
    );
};

export default WasteCodeScreen;
