import React from 'react';
import {Box, Button, IconButton, Tooltip, Typography} from '@mui/material';
import {Clear, FileUpload} from '@mui/icons-material';
import {useTranslation} from "react-i18next";

interface MuiUploadPdfFileProps {
    pdfFileName: string | undefined;
    handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleClearFile?: () => void;
    label?: string;
    toolTipInfo?: string;
}

const MuiUploadFile: React.FC<MuiUploadPdfFileProps> = ({
                                                            pdfFileName,
                                                            handleFileChange,
                                                            handleClearFile,
                                                            label,
                                                            toolTipInfo
                                                        }) => {
    const {t} = useTranslation();

    return (
        <Tooltip
            placement="top"
            disableFocusListener
            title={t(toolTipInfo ? toolTipInfo : '')}
            sx={{fontWeight: 'bold'}}
        >
            <Box className="flex flex-col mb-3">
                <Button
                    variant="contained"
                    component="label"
                    startIcon={<FileUpload/>}
                    className="input-field"
                >
                    {label ? t(label) : 'Upload File'}
                    <input
                        type="file"
                        accept=".pdf"
                        onChange={handleFileChange}
                        hidden
                    />
                </Button>
                <Box className="text-aGgreen font-bold text-xs tracking-wider">
                    {pdfFileName && (
                        <Typography variant="body2" display="flex" alignItems="center">
                            Existing File: {pdfFileName}
                            {handleClearFile &&
                                <IconButton
                                    onClick={handleClearFile}
                                    size="small"
                                    color="secondary"
                                >
                                    <Clear/>
                                </IconButton>}
                        </Typography>
                    )}
                </Box>
            </Box>
        </Tooltip>

    );
};

export default MuiUploadFile;