import React, {useEffect, useState} from 'react';
import {Box, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import LabelledMuiTypography from "../../../../ui/mui/mui-display-data/LabelledMuiTypography";
import {
    ICurrentlyInUseWasteDoc,
    IWasteDocReduced
} from "../../../../../utils/interfaces/special_wasty/waste_doc/ICurrentlyInUseWasteDoc";
import {formatDate} from "../../../../../logics/helpers";
import {motion} from "framer-motion";
import WasteDocForm from "./WasteDocForm";
import MuiModal from "../../../../ui/mui/mui_modal/MuiModal";
import {Edit} from "@mui/icons-material";
import MRTMuiButton from "../../../../ui/mui/mui_react_table/MRTMuiButton";
import {IWastyRecipient} from "../../../../../utils/interfaces/special_wasty/wasty_recipient/IWastyRecipient";
import {IWastyCarrier} from "../../../../../utils/interfaces/special_wasty/wasty_carrier/IWastyCarrier";
import {IWastyIntermediary} from "../../../../../utils/interfaces/special_wasty/wasty_intermediary/IWastyIntermediary";
import {IWasteCode} from "../../../../../utils/interfaces/special_wasty/waste_code/IWasteCode";
import {IMyCompanySite} from "../../../../../utils/interfaces/my_company/my_company_site/IMyCompanySite";
import useApiGetWastyCarrier from "../wasty_carrier/api-hooks/useApiGetWastyCarrier";
import useApiGetWastyIntermediary from "../wasty_intermediary/api-hooks/useApiGetWastyIntermediary";
import useApiGetWastyRecipient from "../wasty_recipient/api-hooks/useApiGetWastyRecipient";
import useApiGetWasteCode from "../waste_code/api-hooks/useApiGetWasteCode";
import {FaSkull} from "react-icons/fa";
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';


interface Props {
    recipient?: IWastyRecipient,
    carrier?: IWastyCarrier,
    intermediary?: IWastyIntermediary,
    waste_code?: IWasteCode,
    my_company_site?: IMyCompanySite
}

const CurrentlyInUseWasteDocCard = ({
                                        recipient: initialRecipient,
                                        carrier: initialCarrier,
                                        intermediary: initialIntermediary,
                                        waste_code: initialWasteCode,
                                        my_company_site: initialMyCompanySite,
                                    }: Props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const [obj, setObj] = useState<ICurrentlyInUseWasteDoc | undefined>();
    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)
    const [objToEdit, setObjToEdit] = useState<IWasteDocReduced | undefined>();


    const handleDownloadPdf = (row: IWasteDocReduced) => {
        if (row.pdf_doc) {
            console.log(row.pdf_doc)
            const anchor = document.createElement('a');
            anchor.href = row.pdf_doc;
            anchor.target = '_blank';
            anchor.download = 'document.pdf';
            anchor.click();
            anchor.remove();
        }
    };


    const {
        data: detailedCarrier,
    } = useApiGetWastyCarrier(initialCarrier?.id);

    const {
        data: detailedIntermediary,
    } = useApiGetWastyIntermediary(initialIntermediary?.id);

    const {
        data: detailedRecipient,
    } = useApiGetWastyRecipient(initialRecipient?.id);


    const {
        data: detailedWasteCode,
    } = useApiGetWasteCode(initialWasteCode?.id, initialMyCompanySite?.id);


    useEffect(() => {
        if (detailedRecipient) {
            setObj(detailedRecipient.currently_in_use_docs);
        }
        if (detailedCarrier) {
            setObj(detailedCarrier.currently_in_use_docs);
        }
        if (detailedIntermediary) {
            setObj(detailedIntermediary.currently_in_use_docs);
        }
        if (detailedWasteCode) {
            setObj(detailedWasteCode.currently_in_use_docs);
        }


    }, [detailedRecipient, detailedCarrier, detailedIntermediary, detailedWasteCode]);


    return (
        <>
            <Box sx={{
                marginTop: 1,
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                border: '1px solid lightgrey',
                borderRadius: '4px',
                padding: '10px',
                backgroundColor: theme.palette.backgroundAppGarageColor.gray_50,
            }}>
                <Typography
                    variant="subtitle2"
                    sx={{
                        color: theme.palette.primary.main,
                        position: 'absolute',
                        top: -12,
                        left: 0,
                        backgroundColor: 'transparent',
                        paddingX: 2,
                        textTransform: 'capitalize',
                    }}
                >
                    {t('currently in use docs')}
                </Typography>

                {obj?.docs && obj.docs.map((doc) => {

                    const divider_color = doc.show_alert ? theme.palette.warning.main : theme.palette.primary.light;

                    return (

                        <Box key={doc.id} sx={{marginTop: 1, paddingLeft: 2, borderLeft: '1px solid lightgrey'}}>
                            <Box sx={{
                                position: 'relative'
                            }}>


                                <motion.div
                                    className="filter_motion_div-divider-bar"
                                    initial={{scaleX: 0, transformOrigin: "50% 100%"}}
                                    animate={{scaleX: 1}}
                                    transition={{duration: 0.6}}
                                    style={{
                                        height: '3px',
                                        backgroundColor: theme.palette.primary.main,
                                        marginTop: '8px',
                                        marginBottom: '8px',
                                    }}
                                />

                                <LabelledMuiTypography label={t('document date')}
                                                       value={doc.doc_date ? formatDate(doc.doc_date) : 'N/A'}/>
                                <LabelledMuiTypography label={t('description')} value={doc.description ?? 'N/A'}/>
                                <LabelledMuiTypography label={t('expiring date')}
                                                       value={formatDate(doc.expiring_date) ?? 'N/A'}/>
                                <LabelledMuiTypography label={t('waste document type')} value={doc.doc_type ?? 'N/A'}/>
                                <Box sx={{display: 'flex', flexDirection: 'row', gap: 0}}>
                                    <LabelledMuiTypography label={t('remaining days')}
                                                           value={doc.remaining_days?.toString() ?? 'N/A'}/>
                                    {doc.show_alert &&
                                        <FaSkull color={theme.palette.warning.main} className={'animate-pulse'}/>}
                                </Box>


                                <Box sx={{display: 'flex', flexDirection: 'row', gap: 0}}>
                                    <MRTMuiButton
                                        icon={<Edit sx={{color: theme.palette.primary.main}}/>}
                                        onClick={
                                            () => {
                                                setObjToEdit(doc);
                                                setIsEditFormOpen(true)
                                            }}
                                        label={'edit'}
                                    />
                                    {doc.pdf_doc ? (
                                        <MRTMuiButton
                                            icon={<CloudDownloadOutlinedIcon sx={{color: theme.palette.primary.main}}/>}
                                            onClick={() => {
                                                setObjToEdit(doc);
                                                handleDownloadPdf(doc)
                                            }}
                                            label={'download pdf'}
                                        />
                                    ) : null}
                                </Box>

                                <motion.div
                                    className="filter_motion_div-divider-bar"
                                    initial={{scaleX: 0, transformOrigin: "50% 100%"}}
                                    animate={{scaleX: 1}}
                                    transition={{duration: 0.6}}
                                    style={{
                                        height: '3px',
                                        backgroundColor: theme.palette.primary.main,
                                        marginTop: '8px',
                                        marginBottom: '8px',
                                    }}
                                />
                            </Box>
                            <MuiModal
                                top="50%"
                                left="50%"
                                width={'40vh'}
                                open={isEditFormOpen}
                                onClose={() => setIsEditFormOpen(false)}
                            >
                                <WasteDocForm
                                    doc_type={doc.doc_type}
                                    recipient={initialRecipient}
                                    carrier={initialCarrier}
                                    intermediary={initialIntermediary}
                                    waste_code={initialWasteCode}
                                    my_company_site={initialMyCompanySite}
                                    objToEdit={objToEdit}
                                    onClose={() => setIsEditFormOpen(false)}
                                />
                            </MuiModal>
                        </Box>
                    )
                })}
            </Box>
        </>
    );
}

export default CurrentlyInUseWasteDocCard;