import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {IWasteDoc} from "../../../../../../utils/interfaces/special_wasty/waste_doc/IWasteDoc";


const useApiUpdateWasteDoc = (): UseMutationResult<IWasteDoc, CustomAxiosError, {
    id: number;
    data: any
}, unknown> => {
    const queryClient = useQueryClient();
    const {selectedCompany} = useAuth()

    const {putWithFile, put} = useApiCalls()


    async function doUpdate(company_token: string | undefined,
                            pk: number | undefined,
                            data: any): Promise<IWasteDoc> {
        try {
            if (data.pdf_doc) {
                await putWithFile(`/api/company/${company_token}/special_wasty/waste_doc/${pk}`, data);
                delete data.pdf_doc;
            }

            const response = await put(`/api/company/${company_token}/special_wasty/waste_doc/${pk}`, data);
            return response.data as IWasteDoc;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<IWasteDoc, CustomAxiosError, {
        id: number;
        data: any
    }, unknown>({
        mutationFn: async ({id, data}) => {
            return await doUpdate(selectedCompany?.company_token, id, data);
        },
        onSuccess: async (obj) => {
        },
    });
};


export default useApiUpdateWasteDoc;