import React, {useEffect, useState} from 'react';
import {useToast} from "../../../../../utils/contexts/toast-context";
import {useAuth} from "../../../../../utils/contexts/auth-context";
import {useQueryClient} from "@tanstack/react-query";
import {
    IOrderScheduleDetail
} from "../../../../../utils/interfaces/tannery_production/order_schedule/IOrderScheduleDetail";
import useApiUpdateCompanyOrderSchedule from "./api-hooks/useApiUpdateCompanyOrderSchedule";
import useApiCreateCompanyOrderSchedule from "./api-hooks/useApiCreateCompanyOrderSchedule";
import {getCurrentDate} from "../../../../../logics/helpers";
import useApiGetNextCompanyOrderScheduleid from "./api-hooks/useApiGetNextCompanyOrderScheduleid";
import {IColor} from "../../../../../utils/interfaces/tannery_production/articles/IColor";
import useApiGetCompanyOrderScheduleDetail from "./api-hooks/useApiGetCompanyOrderScheduleDetail";
import {Box} from "@mui/material";
import MuiFabButtonBack from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonSaveAs from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";
import MuiInputTextField from "../../../../ui/mui/mui-input/MuiInputTextField";
import {IMyCompanyWarehouse} from "../../../../../utils/interfaces/my_company/my_company_warehouse/IMyCompanyWarehouse";
import {IWarehouseProduct} from "../../../../../utils/interfaces/my_warehouse/IWarehouseProduct";
import {useTheme} from "@mui/material/styles";
import {useAppGarage} from "../../../../../utils/contexts/appGarage-context";
import MuiFabButtonBase from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBase";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import {GiBabyBottle} from "react-icons/gi";
import MyCompanyWarehouseSelect from "../../my_company/my_company_warehouse/MyCompanyWarehouseSelect";
import MuiBorderedBox from "../../../../ui/mui/MuiBorderedBox";
import MyWarehouseProductSelect from "../../my_warehouse/MyWarehouseProducts/MyWarehouseProductSelect";
import {motion} from "framer-motion";
import useApiGetNextCompanyBabyOrderScheduleid from "./api-hooks/useApiGetNextCompanyBabyOrderScheduleid";
import MRTMuiButton from "../../../../ui/mui/mui_react_table/MRTMuiButton";
import {HiSwitchHorizontal} from "react-icons/hi";
import DescriptionSelect from "../../service/description/DescriptionSelect";
import {IDescription} from "../../../../../utils/interfaces/service/description/IDescription";


interface OrderScheduleFormProps {
    companyOrderScheduleToEdit: IOrderScheduleDetail | null;
    color: IColor | undefined;
    onClose: () => void;
}

const ArticleForm: React.FC<OrderScheduleFormProps> = ({
                                                           companyOrderScheduleToEdit,
                                                           color: initialColor,
                                                           onClose
                                                       }) => {
    const {displayError, displayMessage} = useToast();
    const {availableCompanyUserApplications} = useAppGarage()
    const {selectedCompany} = useAuth();
    const queryClient = useQueryClient();
    const theme = useTheme();

    const [color, setColor] = useState<IColor | undefined>(initialColor)


    const [quantity, setQuantity] = useState<number | undefined>()
    const [order_date, setOrder_date] = useState<string | undefined>(getCurrentDate)
    const [order_info, setOrder_info] = useState<string | undefined>()
    const [order_code, setOrder_code] = useState<string | undefined>()
    const [parentOrder, setParentOrder] = useState<IOrderScheduleDetail | null>(null)
    const [parentOrderId, setParentOrderId] = useState<number | null>()
    const [estimated_delivery_date, setEstimated_delivery_date] = useState<string | undefined | null>()
    const [myCompanyWarehouse, setMyCompanyWarehouse] = useState<IMyCompanyWarehouse | undefined>()
    const [parentWarehouse, setParentWarehouse] = useState<IMyCompanyWarehouse | undefined>()
    const [parentProduct, setParentProduct] = useState<IWarehouseProduct | undefined>()
    const [suggestBabyId, setSuggestBabyId] = useState<boolean>(false)
    const [description, setDescription] = useState<IDescription>()

    const mutationHook = companyOrderScheduleToEdit ? useApiUpdateCompanyOrderSchedule : useApiCreateCompanyOrderSchedule;
    const mutation = mutationHook(selectedCompany?.company_token, color?.id);

    const {
        data: detailedObj,
    } = useApiGetCompanyOrderScheduleDetail(selectedCompany?.company_token, companyOrderScheduleToEdit?.id);


    const [parentFormOpen, setParentFormOpen] = useState(!!detailedObj?.parent_product)

    const {data: nextOrderId} = useApiGetNextCompanyOrderScheduleid(selectedCompany?.company_token);

    const {data: nextBabyOrderId} = useApiGetNextCompanyBabyOrderScheduleid(selectedCompany?.company_token);


    useEffect(() => {
        if (!companyOrderScheduleToEdit && nextOrderId && nextOrderId.suggested_id) {
            if (nextOrderId) {
                setOrder_code(nextOrderId.suggested_id);
            }
            if (suggestBabyId && nextBabyOrderId) {
                setOrder_code(nextBabyOrderId.suggested_id);
            }

        }
    }, [companyOrderScheduleToEdit, nextOrderId, nextBabyOrderId, parentFormOpen, suggestBabyId]);

    const {
        data: parent,
    } = useApiGetCompanyOrderScheduleDetail(selectedCompany?.company_token, companyOrderScheduleToEdit?.parent_id);


    const [myWarehouseFormOpen, setMyWarehouseFormOpen] = useState(!!detailedObj?.my_company_warehouse)

    // console.log("companyOrderScheduleToEdit", companyOrderScheduleToEdit)
    // console.log("parent detail", parent)

    useEffect(() => {
        if (parent) {
            setParentOrder(parent);
        } else {
            setParentOrder(null);
        }
    }, [parent]);


    useEffect(() => {
        if (detailedObj) {
            setQuantity(detailedObj.quantity)
            setOrder_date(detailedObj.order_date)
            setOrder_info(detailedObj.order_info)
            setOrder_code(detailedObj.order_id)
            setEstimated_delivery_date(detailedObj.estimated_delivery_date)
            setParentOrderId(detailedObj.parent_id)
            setMyCompanyWarehouse(detailedObj.my_company_warehouse)
            setParentWarehouse(detailedObj.parent_warehouse)
            setParentProduct(detailedObj.parent_product)
        }
    }, [detailedObj]);

    const handleParent = (parent: IOrderScheduleDetail | null) => {
        if (parent) {
            setParentOrder(parent);
            setParentOrderId(parent.id);
        } else {
            setParentOrder(null);
            setParentOrderId(undefined);
        }
    }

    const handleMyCompanyWarehouseChange = (warehouse: IMyCompanyWarehouse | undefined) => {
        setMyCompanyWarehouse(warehouse);
    }

    const handleParentWarehouseChange = (warehouse: IMyCompanyWarehouse | undefined) => {
        setParentWarehouse(warehouse);
    }

    const handleParentProductChange = (obj: IWarehouseProduct | undefined) => {
        setParentProduct(obj);
    }

    const handleSubmit = async () => {
        const parent_order_id = parentOrderId ? parentOrderId : null;
        const edd = estimated_delivery_date ? estimated_delivery_date : null;

        try {
            const orderData = {
                quantity: quantity || 0,
                order_date: order_date || '',
                order_info: order_info || '',
                order_id: order_code || '',
                estimated_delivery_date: edd,
                parent_id: parent_order_id,
                my_company_warehouse: myCompanyWarehouse?.id,
                parent_warehouse: parentWarehouse?.id,
                parent_product: parentProduct?.id,
                description_id: description?.id,
                // Add other fields as needed
            };

            if (companyOrderScheduleToEdit !== null) {
                // Assuming your mutation updates the existing order
                await mutation.mutateAsync({
                    color_id: color?.id,
                    id: companyOrderScheduleToEdit.id,
                    data: orderData,
                });
                displayMessage('Order updated successfully');

                // Optimistically update the data for the updated order
                // queryClient.setQueryData<IOrderScheduleDetail[]>(['getCompanyOrderSchedule'], (old) => {
                //     if (!old) return [];
                //     return old.map((item) =>
                //         item.id === companyOrderScheduleToEdit.id ? {...item, ...orderData} : item
                //     );
                // });
            } else {
                // Assuming your mutation returns the newly created order
                const newOrder = await mutation.mutateAsync(orderData);
                displayMessage('Order added successfully');

                // Optimistically update the data
                queryClient.setQueryData<IOrderScheduleDetail[]>(['getCompanyOrderSchedule'], (old) => {
                    const updatedData = [...(old || []), newOrder];
                    return updatedData.filter((item): item is IOrderScheduleDetail => item !== null && item !== undefined);
                });


            }

            onClose();
            await queryClient.invalidateQueries({queryKey: ['getCompanyOrderScheduleByColor']});
            await queryClient.invalidateQueries({queryKey: ['getParentsCompanyOrderSchedule']});
            await queryClient.invalidateQueries({queryKey: ['getCompanyOrderSchedule']});
            await queryClient.invalidateQueries({queryKey: ['getPeopleArticles']});
        } catch (error) {

        }
    };


    return (
        <Box sx={{padding: 2}}>
            {availableCompanyUserApplications?.some(app => app.in_project_application_name === 'my_warehouse') &&
                <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                    <MuiFabButtonBase icon={<WarehouseIcon/>}
                                      bg_color={detailedObj?.my_company_warehouse ? theme.palette.primary.main : theme.palette.disabledColor.main}
                                      onClick={() => setMyWarehouseFormOpen(!myWarehouseFormOpen)}></MuiFabButtonBase>
                    <MuiFabButtonBase
                        icon={<GiBabyBottle title=''/>}
                        bg_color={detailedObj?.parent_product ? theme.palette.primary.main : theme.palette.disabledColor.main}
                        onClick={() => setParentFormOpen(!parentFormOpen)}></MuiFabButtonBase>
                </Box>}


            {myWarehouseFormOpen &&
                <>
                    <MyCompanyWarehouseSelect obj={myCompanyWarehouse} onChange={handleMyCompanyWarehouseChange}
                                              label={'will charge this warehouse...'}
                                              className={''}/>
                </>

            }


            {parentFormOpen &&
                <>
                    <MuiBorderedBox>
                        <>
                            <MyCompanyWarehouseSelect obj={parentWarehouse} onChange={handleParentWarehouseChange}
                                                      label={'satisfy this order with this warehouse...'}
                                                      className={''}/>
                            {parentWarehouse && (
                                <Box marginTop={2}>
                                    <MyWarehouseProductSelect
                                        obj={parentProduct}
                                        onChange={handleParentProductChange}
                                        label={'satisfy this order with this color...'}
                                        warehouse={parentWarehouse}
                                    />
                                </Box>
                            )}
                        </>
                    </MuiBorderedBox>
                </>

            }
            <motion.div
                className="filter_motion_div-divider-bar"
                initial={{scaleX: 0, transformOrigin: "50% 100%"}}
                animate={{scaleX: 1}}
                transition={{duration: 0.6}}
                style={{
                    height: '3px',
                    backgroundColor: theme.palette.primary.light,
                    marginTop: '16px',
                    marginBottom: '20px',
                }}
            />
            <Box className="grid grid-cols-2 gap-4">
                <Box>
                    <MuiInputTextField
                        label="movement_date"
                        type="date"
                        value={order_date}
                        onChange={setOrder_date}
                        required={true}
                    />
                    <MuiInputTextField
                        label="estimated_delivery_date"
                        type="date"
                        value={estimated_delivery_date || order_date}
                        onChange={setEstimated_delivery_date}
                        required={true}
                    />
                </Box>
                <Box>
                    <MuiInputTextField
                        label="order_code"
                        type="text"
                        value={order_code || ''}
                        onChange={(e) => setOrder_code(e.target.value)}
                        required={false}
                    >
                        <MRTMuiButton
                            label={'switch suggested code'}
                            isDisabled={!!detailedObj}
                            small={true}
                            icon={<HiSwitchHorizontal
                                color={detailedObj ? theme.palette.disabledColor.main : theme.palette.primary.main}/>} // Corrected JSX syntax
                            onClick={() => {
                                setSuggestBabyId(!suggestBabyId)
                            }}
                        />
                    </MuiInputTextField>
                    <MuiInputTextField
                        label="order_info"
                        type="text"
                        value={order_info}
                        onChange={(e) => setOrder_info(e.target.value)}
                        required={false}
                    />


                </Box>
                <Box>


                    <DescriptionSelect obj={description} onChange={(obj) => setDescription(obj)} className={''}
                                       scope={'tannery_order_schedule'}/>

                </Box>
                <Box>
                    <MuiInputTextField
                        label="quantity"
                        type="text"
                        value={quantity}
                        onChange={(e) => setQuantity(parseFloat(e.target.value))}
                        required={false}
                    />

                </Box>


            </Box>


            {/*<OrderScheduleParentsSelect orderSchedule={parentOrder}*/}
            {/*                            onChange={handleParent}*/}
            {/*                            className={'text-black shadow'}/>*/}


            <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                <MuiFabButtonBack onClick={onClose}/>
                {/*<MuiFabButtonDelete onClick={() => {*/}
                {/*    setDeleteConfirmationOpen(true)*/}
                {/*}}/>*/}
                {companyOrderScheduleToEdit ?
                    <MuiFabButtonSaveAs onClick={handleSubmit}/>
                    :
                    <MuiFabButtonSave onClick={handleSubmit}/>}
            </Box>
        </Box>
    );
};

export default ArticleForm;
