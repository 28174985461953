import {useMutation, UseMutationResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {IWasteMovement} from "../../../../../../utils/interfaces/special_wasty/waste_movement/IWasteMovement";
import {useAppGarage} from "../../../../../../utils/contexts/appGarage-context";


const useApiCreateWasteMovement = (): UseMutationResult<IWasteMovement, CustomAxiosError, any> => {
    const {selectedCompany} = useAuth()
    const {selectedMyCompanySite} = useAppGarage()

    const {post} = useApiCalls()

    async function doCreate({companyToken, data}: {
        companyToken?: string,
        data: any
    }): Promise<IWasteMovement> {
        try {
            const response = await post(`/api/company/${companyToken}/special_wasty/waste_movement?site_id=${selectedMyCompanySite?.id}`, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    }


    return useMutation<IWasteMovement, CustomAxiosError, any>(
        {
            mutationFn: (
                data
            ) => {
                return doCreate({
                    companyToken: selectedCompany?.company_token,
                    data
                })
            },
            onSuccess: async (obj) => {
            },
        }
    );
};


export default useApiCreateWasteMovement;