import {useApiCalls} from "../../../../../../logics/api_config";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import useApiGetRentriCertificate from "../../rentri_certificate/api-hooks/useApiGetRentriCertificate";

const useApiGetRentriAgidJwtSignatureToken = () => {
    const {selectedCompany} = useAuth();
    const {data: rentri_certificate} = useApiGetRentriCertificate();
    const {post} = useApiCalls();


    const fetchAgidJwtToken = async (payload: { content_type: string; content: any }) => {
        if (!selectedCompany || !rentri_certificate?.id) return undefined;
        const formattedPayload = {
            content_type: "application/json",
            content: JSON.stringify(payload)
        };

        try {
            const response = await post(`/api/company/${selectedCompany.company_token}/special_wasty/rentri_certificate/${rentri_certificate.id}/jwt_token`, formattedPayload);
            return response?.data;
        } catch (error) {
            throw error;
        }
    };

    return {fetchAgidJwtToken};
};

export default useApiGetRentriAgidJwtSignatureToken;
