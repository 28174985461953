import React, {useEffect, useState} from 'react';
import Loading from "../../../../ui/Loading";
import {useTranslation} from "react-i18next";
import {Box, TextField} from "@mui/material";
import MRTMuiButton from "../../../../ui/mui/mui_react_table/MRTMuiButton";
import {Add, Edit} from "@mui/icons-material";
import MuiAutocomplete from "../../../../ui/mui/mui-input/MuiAutoComplete";
import {useTheme} from "@mui/material/styles";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import MuiModal from "../../../../ui/mui/mui_modal/MuiModal";
import {IMyCompanySite} from "../../../../../utils/interfaces/my_company/my_company_site/IMyCompanySite";
import useApiGetMyCompanySites from "./api-hooks/useApiGetMyCompanySites";
import useApiGetMyCompanySite from "./api-hooks/useApiGetMyCompanySite";
import MyCompanySiteForm from "./MyCompanySiteForm";
import MyCompanySiteTable from "./MyCompanySiteTable";
import {FaRecycle} from "react-icons/fa";
import {useAppGarage} from "../../../../../utils/contexts/appGarage-context";
import {useNavigate} from "react-router-dom";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import useApiGetRentriCertificate from "../../special_wasty/rentri_certificate/api-hooks/useApiGetRentriCertificate";
import RentriRegistroTable from "../../special_wasty/rentri/anagrafiche/rentri-registro/RentriRegistroTable";


interface Props {
    obj: IMyCompanySite | undefined,
    onChange: (obj: IMyCompanySite | undefined) => void,
    className: string,
    label?: string,
    required?: boolean
    withDefaultValueIfEmpty?: boolean
}

const MyCompanySiteSelect = ({
                                 obj: initialObj,
                                 label,
                                 onChange,
                                 required,
                                 withDefaultValueIfEmpty = false,
                                 ...props
                             }: Props) => {

    const {selectMyCompanySite, selectedMyCompanySite} = useAppGarage()
    const theme = useTheme();
    const {t} = useTranslation()
    const {availableCompanyUserApplications} = useAppGarage();
    const navigate = useNavigate();


    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)
    const [isListTableOpen, setIsListTableOpen] = useState<boolean>(false)
    const [isRentriRegistroTableOpen, setIsRentriRegistroTableOpen] = useState<boolean>(false)


    const [objs, setObjs] = useState<IMyCompanySite[]>([]);
    const [obj, setObj] = useState<IMyCompanySite | undefined>(initialObj)


    useEffect(() => {
        setObj(undefined);
        if (initialObj) {
            setObj(initialObj)
        }
    }, [initialObj]);


    const {
        data,
        isLoading,
    } = useApiGetMyCompanySites();


    useEffect(() => {
        if (data) {
            setObjs(data)
            if (!initialObj && data.length > 0 && withDefaultValueIfEmpty) {
                setObj(data[0]);
                onChange(data[0]);
            }
        }
    }, [data]);


    const {
        data: detailedObj,
    } = useApiGetMyCompanySite(obj?.id);


    useEffect(() => {
        if (detailedObj) {
            setObj(detailedObj);
        }
    }, [detailedObj]);

    const handleOnChange = (event: React.SyntheticEvent, newValue: IMyCompanySite | null) => {
        if (newValue) {
            onChange(newValue);
            setObj(newValue);
        } else {
            onChange(undefined);
        }
    };

    const handleChange = (obj: IMyCompanySite | undefined) => {
        onChange(obj)
    }

    useEffect(() => {
        onChange(obj);
    }, [obj]);

    const [showSpecialWastyGeneralAlert, setShowSpecialWastyGeneralAlert] = useState(false);

    useEffect(() => {
        const hasSpecialWastyAlert = objs.some(obj => obj.show_special_wasty_alert);
        setShowSpecialWastyGeneralAlert(hasSpecialWastyAlert);
    }, [objs]);

    const {data: rentriCertificate} = useApiGetRentriCertificate();


    return (
        <>
            {isLoading ? (
                <Loading/>
            ) : (
                <>
                    <Box sx={{alignItems: 'center', width: '100%'}}>
                        <MuiAutocomplete
                            id="site-select"
                            options={objs}
                            getOptionLabel={(option) => option.name}
                            value={detailedObj ? detailedObj : null}
                            onChange={handleOnChange}
                            isOptionEqualToValue={(option, value) => option.id === value?.id && option.name === value?.name}
                            renderInput={(params) => (
                                <TextField
                                    sx={{textTransform: 'capitalize'}}
                                    {...params}
                                    label={label ? t(label) : '' || t('site')}
                                    required={required}
                                />
                            )}
                            borderColor={theme.palette.primary.light}
                            onFocusBorderColor={theme.palette.primary.dark}
                            minWidth={300}
                        >

                            {availableCompanyUserApplications?.some(app => app.in_project_application_name === 'special_wasty' && selectedMyCompanySite && selectedMyCompanySite.num_iscr_sito && rentriCertificate && rentriCertificate.num_iscr_operatore) &&
                                <>
                                    <MRTMuiButton
                                        small={true}
                                        icon={
                                            <AppRegistrationIcon
                                                sx={{color: theme.palette.primary.main}}/>

                                        }
                                        onClick={() => {
                                            setIsRentriRegistroTableOpen(true);
                                        }}
                                        label={obj?.show_special_wasty_alert ? 'open loads needs unload' : ''}
                                        label_position={'bottom'}
                                    />
                                    <MRTMuiButton
                                        small={true}
                                        icon={
                                            <FaRecycle
                                                color={detailedObj?.show_special_wasty_alert ? theme.palette.warning.main : theme.palette.primary.main}
                                            />
                                        }
                                        // onClick={() => {
                                        //     navigate('/apps/special_wasty/waste_code');
                                        // }}
                                        onClick={() => setIsListTableOpen(true)}
                                        animated={obj?.show_special_wasty_alert}
                                        label={obj?.show_special_wasty_alert ? 'open loads needs unload' : ''}
                                        label_position={'bottom'}
                                    />
                                </>

                            }
                            <MRTMuiButton
                                icon={<FormatListBulletedIcon
                                    sx={{color: theme.palette.primary.main}}/>}
                                onClick={() => setIsListTableOpen(true)}
                                small={true}
                                label={''}
                                label_position={'bottom'}
                            />
                            <MRTMuiButton
                                icon={obj ?
                                    <Edit
                                        sx={{color: !obj?.num_iscr_sito ? theme.palette.warning.main : theme.palette.primary.main}}/> :
                                    <Add sx={{color: theme.palette.primary.main}}/>}
                                onClick={() => setIsEditFormOpen(true)}
                                small={true}
                                label={obj ? !obj?.num_iscr_sito ? 'manca numero iscrizione al sito' : 'edit' : 'add'}
                                label_position={'bottom'}
                                animated={!obj?.num_iscr_sito}
                            />
                        </MuiAutocomplete>
                    </Box>

                    <MuiModal
                        top="50%"
                        left="50%"
                        width={'80vh'}
                        open={isEditFormOpen}
                        onClose={() => setIsEditFormOpen(false)}
                    >
                        <MyCompanySiteForm objToEdit={obj ? obj : null}
                                           onSelect={onChange}
                                           onClose={() => setIsEditFormOpen(false)}
                        />
                    </MuiModal>


                    <MuiModal top="50%" left="50%" width={'120vh'} open={isListTableOpen}
                              onClose={() => setIsListTableOpen(false)}
                              disableBackdropClick={true}
                    >
                        <MyCompanySiteTable
                            alreadySelectedObj={obj}
                            onSelect={handleChange}
                            onClose={() => setIsListTableOpen(false)}
                        />
                    </MuiModal>

                    <MuiModal
                        top="50%"
                        left="50%"
                        width="90%"
                        open={isRentriRegistroTableOpen}
                        onClose={() => setIsRentriRegistroTableOpen(false)}
                        disableBackdropClick={true}
                    >
                        <RentriRegistroTable
                            onClose={() => setIsRentriRegistroTableOpen(false)}
                            num_iscr_operatore={rentriCertificate?.num_iscr_operatore}
                            num_iscr_sito={selectedMyCompanySite?.num_iscr_sito.num_iscr_sito}
                        />
                    </MuiModal>


                </>
            )}
        </>
    );
}

export default MyCompanySiteSelect;