import {HiHome, HiOutlineArrowRightOnRectangle} from "react-icons/hi2";
import {RiDraftLine} from "react-icons/ri";
import {SiTestcafe, SiTextpattern} from "react-icons/si";
import {MdWorkspaces} from "react-icons/md";
import {BiLeaf, BiSolidFactory} from "react-icons/bi";
import {FaCashRegister, FaRecycle, FaSitemap, FaWarehouse, FaWrench} from "react-icons/fa";
import {SlPeople} from "react-icons/sl";
import {IoDocuments, IoDocumentSharp} from "react-icons/io5";
import {GiBarrel, GiCow, GiFizzingFlask} from "react-icons/gi";
import {HiOutlineViewList} from "react-icons/hi";
import {AiOutlineBorderlessTable} from "react-icons/ai";
import {LuChefHat} from "react-icons/lu";
import {GoHistory} from "react-icons/go";
import {IoIosFlask} from "react-icons/io";
import PaymentIcon from "@mui/icons-material/Payment";
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';


const MyWarehouseSubItems = [
    {
        id: 1,
        name: "my_warehouse",
        to: "/apps/my_warehouse",
        icon: <FaWarehouse/>,
    },
];


const MyCompanySubItems = [
    {
        id: 1,
        name: "my_company_registry",
        to: "/apps/my_company/registry",
        icon: <FaCashRegister/>,
    },
];

const ServiceSubItems = [
    {
        id: 1,
        name: "people",
        to: "/apps/service/people",
        icon: <SlPeople/>,
    },
];

const MyDocumentsSubItems = [
    {
        id: 1,
        name: "fiscal registries",
        to: "/apps/my_documents/fiscal_registry",
        icon: <PermContactCalendarIcon/>,
    },
    {
        id: 2,
        name: "doc_list",
        to: "/apps/my_documents/doc",
        icon: <IoDocuments/>,
    },
    {
        id: 3,
        name: "ricevute bancarie",
        to: "/apps/my_documents/ricevute_bancarie",
        icon: <PaymentIcon/>,
    },
];

const TanneryRawMaterialSubItems = [
    {
        id: 1,
        name: "suppliers",
        to: "/apps/tannery_raw_material/people",
        icon: <SlPeople/>,
    },
    {
        id: 1,
        name: "lwg_sites",
        to: "/apps/tannery_raw_material/lwg_sites",
        icon: <SiTestcafe/>,
    },
    {
        id: 2,
        name: "tannery_lots",
        to: "/apps/tannery_raw_material/lots",
        icon: <HiOutlineViewList/>,
    },
];

const TanneryProductionSubItems = [
    {
        id: 1,
        name: "articles",
        to: "/apps/tannery_production/articles",
        icon: <FaSitemap/>,
    },
    {
        id: 2,
        name: "order_schedule",
        to: "/apps/tannery_production/order_schedule",
        icon: <AiOutlineBorderlessTable/>,
    },
    {
        id: 3,
        name: "subcontractors",
        to: "/apps/tannery_production/people",
        icon: <SlPeople/>,
    },
    {
        id: 4,
        name: "workings",
        to: "/apps/tannery_production/workings",
        icon: <MdWorkspaces/>,
    },
];

const TanneryDyeingSubItems = [
    {
        id: 1,
        name: "chemical_suppliers",
        to: "/apps/tannery_dyeing/dyeing_schedule/people",
        icon: <SlPeople/>,
    },
    {
        id: 2,
        name: "dyeing_schedule_current",
        to: "/apps/tannery_dyeing/dyeing_schedule/current",
        icon: <LuChefHat/>,
    },
    {
        id: 3,
        name: "dyeing_schedule_history",
        to: "/apps/tannery_dyeing/dyeing_schedule/history",
        icon: <GoHistory/>,
    },
    {
        id: 4,
        name: "dyeing_schedule_drafts",
        to: "/apps/tannery_dyeing/dyeing_schedule/drafts",
        icon: <RiDraftLine/>,
    },
];

const ChemicalsManagementSubItems = [
    {
        id: 1,
        name: "people",
        to: "/apps/chemicals_management/people",
        icon: <SlPeople/>,
    },
    {
        id: 2,
        name: "chemical_products",
        to: "/apps/chemicals_management/products",
        icon: <GiFizzingFlask/>,
    },
];

const LwgDashboardSubItems = [
    {
        id: 1,
        name: "lwg_sites",
        to: "/apps/tannery_raw_material/lwg_sites",
        icon: <SiTestcafe/>,
    },
    {
        id: 2,
        name: "production_report",
        to: "/apps/lwg_dashboard/production_report",
        icon: <SiTextpattern/>,
    },
    {
        id: 3,
        name: "workings_report",
        to: "/apps/lwg_dashboard/workings_report",
        icon: <MdWorkspaces/>,
    },
];


export const contentNavbarEntries = [
    {
        id: 0,
        name: "logout",
        to: "/logout",
        icon: <HiOutlineArrowRightOnRectangle/>,
    },
    {
        id: 1,
        name: "home",
        to: "/home",
        icon: <HiHome/>,
    },
    {
        id: 2,
        name: "service",
        icon: <FaWrench/>,
        submenu: ServiceSubItems,
    },
    {
        id: 3,
        name: "my_company",
        icon: <BiSolidFactory/>,
        submenu: MyCompanySubItems,
    },
    {
        id: 4,
        name: "my_documents",
        icon: <IoDocumentSharp/>,
        submenu: MyDocumentsSubItems,
    },
    {
        id: 5,
        name: "tannery_raw_material",
        icon: <GiCow/>,
        submenu: TanneryRawMaterialSubItems,
    },
    {
        id: 6,
        name: "tannery_production",
        icon: <SiTextpattern/>,
        submenu: TanneryProductionSubItems,
    },
    {
        id: 7,
        name: "my_warehouse",
        icon: <FaWarehouse/>,
        submenu: MyWarehouseSubItems,
    },
    {
        id: 8,
        name: "tannery_dyeing",
        icon: <GiBarrel/>,
        submenu: TanneryDyeingSubItems,
    },
    {
        id: 9,
        name: "chemicals_management",
        icon: <IoIosFlask/>,
        submenu: ChemicalsManagementSubItems,
    },
    {
        id: 10,
        name: "lwg_dashboard",
        icon: <BiLeaf/>,
        submenu: LwgDashboardSubItems,
    },
    {
        id: 11,
        name: "special_wasty",
        icon: <FaRecycle/>,
        to: " /apps/special_wasty/waste_code",
    },

];


