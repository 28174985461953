import React, {useEffect, useState} from 'react';

import {Box} from "@mui/material";
import MuiInputTextField from "../../../../ui/mui/mui-input/MuiInputTextField";
import MuiFabButtonBack from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonSaveAs from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";
import {IWasteDoc, IWasteDocType} from "../../../../../utils/interfaces/special_wasty/waste_doc/IWasteDoc";
import useApiCreateWasteDoc from "./api-hooks/useApiCreateWasteDoc";
import useApiUpdateWasteDoc from "./api-hooks/useApiUpdateWasteDoc";
import useApiGetWasteDoc from "./api-hooks/useApiGetWasteDoc";
import {IWastyRecipient} from "../../../../../utils/interfaces/special_wasty/wasty_recipient/IWastyRecipient";
import {getCurrentDate} from "../../../../../logics/helpers";
import {IWastyCarrier} from "../../../../../utils/interfaces/special_wasty/wasty_carrier/IWastyCarrier";
import {IWastyIntermediary} from "../../../../../utils/interfaces/special_wasty/wasty_intermediary/IWastyIntermediary";
import {IMyCompanySite} from "../../../../../utils/interfaces/my_company/my_company_site/IMyCompanySite";
import {IWasteCode} from "../../../../../utils/interfaces/special_wasty/waste_code/IWasteCode";
import {useQueryClient} from "@tanstack/react-query";
import MuiUploadFile from "../../../../ui/mui/mui-input/MuiUploadFile";
import {IWasteDocReduced} from "../../../../../utils/interfaces/special_wasty/waste_doc/ICurrentlyInUseWasteDoc";


interface Props {
    objToEdit: IWasteDoc | IWasteDocReduced | null | undefined;
    onClose: () => void;
    doc_type: IWasteDocType,
    onSelect?: (obj: IWasteDoc | undefined) => void,
    recipient?: IWastyRecipient,
    carrier?: IWastyCarrier,
    intermediary?: IWastyIntermediary,
    waste_code?: IWasteCode,
    my_company_site?: IMyCompanySite

}

const WasteDocForm: React.FC<Props> = ({
                                           objToEdit,
                                           onClose,
                                           doc_type,
                                           onSelect,
                                           recipient,
                                           carrier,
                                           intermediary,
                                           waste_code,
                                           my_company_site
                                       }) => {

    const queryClient = useQueryClient();

    const [description, setDescription] = useState<string>();
    const [docDate, setDocDate] = useState<string>(getCurrentDate());
    const [expiringDate, setExpiringDate] = useState<string>();
    const [pdfFile, setPdfFile] = useState<File | null>(null);
    const [pdfFileName, setPdfFileName] = useState<string | undefined>();
    const [currentlyInUse, setCurrentlyInUse] = useState<boolean>(true)


    const createMutation = useApiCreateWasteDoc();
    const updateMutation = useApiUpdateWasteDoc();

    const {
        data: detailedObj,
    } = useApiGetWasteDoc(objToEdit?.id);


    useEffect(() => {
        if (detailedObj) {
            setDescription(detailedObj.description);
            setDocDate(detailedObj.doc_date);
            setExpiringDate(detailedObj.expiring_date);
            setCurrentlyInUse(detailedObj.currently_in_use);

            const pdfDocUrl = detailedObj.pdf_doc;
            if (pdfDocUrl) {
                const fetchFile = async (url: string) => {
                    try {
                        const response = await fetch(url);
                        const blob = await response.blob();
                        let fileName = decodeURIComponent(new URL(url).pathname.split('/').pop() as string);

                        const lastUnderscoreIndex = fileName.lastIndexOf('_');
                        if (lastUnderscoreIndex !== -1) {
                            fileName = `${fileName.substring(0, lastUnderscoreIndex)}.pdf`;
                        }

                        const file = new File([blob], fileName, {type: blob.type});
                        setPdfFile(file);
                        setPdfFileName(fileName);
                    } catch (error) {
                        console.error("Error fetching the PDF file:", error);
                    }
                };

                fetchFile(pdfDocUrl);
            }
        }
    }, [detailedObj]);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];
        setPdfFile(file);
        if (file) {
            setPdfFileName(file.name);
        }
    };


    const handleSubmit = async () => {

        try {
            const payload: any = {
                description: description,
                doc_date: docDate,
                doc_type: doc_type,
                expiring_date: expiringDate ? expiringDate : null,
                pdf_doc: pdfFile ? pdfFile : null,
                currently_in_use: currentlyInUse,
                ...(recipient && {recipient_id: recipient.id}),
                ...(carrier && {carrier_id: carrier.id}),
                ...(intermediary && {intermediary_id: intermediary.id}),
                ...(waste_code && {waste_code_id: waste_code.id}),
                ...(my_company_site && {site_id: my_company_site.id}),
            };


            let newData;

            if (detailedObj !== null && detailedObj !== undefined) {
                newData = await updateMutation.mutateAsync({id: detailedObj.id, data: payload});
            } else {
                newData = await createMutation.mutateAsync(payload);
            }
            onClose();
            onSelect && onSelect(newData);
            await queryClient.invalidateQueries({queryKey: ['useApiGetWasteDoc', newData?.id]});
            await queryClient.invalidateQueries({queryKey: ["useApiGetWasteDocs"]});
            if (recipient) {
                await queryClient.invalidateQueries({queryKey: ["useApiGetWasteDocsByRecipient", newData?.doc_type, newData?.recipient.id]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetWastyRecipient", newData?.recipient.id]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetWastyRecipients"]});
            }
            if (carrier) {
                await queryClient.invalidateQueries({queryKey: ["useApiGetWasteDocsByCarrier", newData?.doc_type, newData?.carrier.id]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetWastyCarrier", newData?.carrier.id]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetWastyCarriers"]});
            }
            if (intermediary) {
                await queryClient.invalidateQueries({queryKey: ["useApiGetWasteDocsByIntermediary", newData?.doc_type, newData?.intermediary.id]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetWastyIntermediary", newData?.intermediary.id]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetWastyIntermediaries"]});
            }
            if (waste_code && my_company_site) {
                await queryClient.invalidateQueries({queryKey: ["useApiGetWasteDocsByWasteCode", newData?.doc_type, newData?.waste_code.id, newData?.site.id]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetWasteCode", newData?.waste_code.id, newData.site.id]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetWasteCodes", newData.site.id]});
            }

        } catch (error) {
            console.log(error)
        }
    }

    const handleClearFile = () => {
        setPdfFileName(undefined);
        setPdfFile(null);
    };


    return (
        <Box sx={{padding: 2}}>
            <MuiInputTextField
                onChange={(e) => setDescription(e.target.value)}
                label='description'
                type="text"
                value={description}
                required={false}
            />
            <MuiInputTextField
                onChange={setDocDate}
                label='document date'
                type="date"
                value={docDate}
                required={true}
            />
            <MuiInputTextField
                onChange={setExpiringDate}
                label='expiring date'
                type="date"
                value={expiringDate}
                required={false}
            />

            <MuiUploadFile
                pdfFileName={pdfFileName}
                handleFileChange={handleFileChange}
                handleClearFile={handleClearFile}
            />


            <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                <MuiFabButtonBack onClick={onClose}/>
                {/*{objToEdit &&*/}
                {/*    <MuiFabButtonDelete onClick={() => {*/}
                {/*        setConfirmationOpen(true)*/}
                {/*    }}/>}*/}
                {objToEdit ?
                    <MuiFabButtonSaveAs onClick={handleSubmit}/>
                    :
                    <MuiFabButtonSave onClick={handleSubmit}/>}
            </Box>
        </Box>
    );
};

export default WasteDocForm;