import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {IWasteMovement} from "../../../../../../utils/interfaces/special_wasty/waste_movement/IWasteMovement";


const useApiGetWasteMovement = (pk: number | undefined): UseQueryResult<IWasteMovement, CustomAxiosError> => {
    const {selectedCompany} = useAuth();

    const {get} = useApiCalls()

    async function doGet(pk: number | undefined): Promise<IWasteMovement> {
        try {
            const response = await get(`/api/company/${selectedCompany?.company_token}/special_wasty/waste_movement/${pk}`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetWasteMovement", pk],
        queryFn: () => doGet(pk),
        enabled: !!pk,
    })

};

export default useApiGetWasteMovement