import React, {useEffect, useState} from 'react';
import {useToast} from "../../../../../../utils/contexts/toast-context";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import Loading from "../../../../../ui/Loading";
import {useTranslation} from "react-i18next";
import {ICompanyDocType} from "../../../../../../utils/interfaces/my_documents/doc_type/ICompanyDocType";
import useApiGetCompanyDocTypes from "./api-hooks/useApiGetCompanyDocTypes";
import {Box, TextField} from "@mui/material";
import MuiAutocomplete from "../../../../../ui/mui/mui-input/MuiAutoComplete";
import useApiGetCompanyDocType from "./api-hooks/useApiGetCompanyDocType";


interface Props {
    doc_type: ICompanyDocType | undefined,
    onChange: (doc_type: ICompanyDocType | undefined) => void,
    className: string,
    label?: string,
    clearable?: boolean
    required?: boolean
    only_to_be_invoiced?: boolean
    only_with_sign_in_sales?: boolean
}

const DocTypeSelect = ({
                           doc_type: initialObj,
                           onChange,
                           label,
                           clearable,
                           required,
                           only_to_be_invoiced = false,
                           only_with_sign_in_sales = false,
                       }: Props) => {
    const {displayError} = useToast()
    const {t} = useTranslation();
    const [objs, setObjs] = useState<ICompanyDocType[]>([]);
    const [obj, setObj] = useState<ICompanyDocType | undefined>(initialObj)

    const {
        data,
        isLoading,
        error,
    } = useApiGetCompanyDocTypes();
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }


    useEffect(() => {
        if (data) {
            switch (true) {
                case only_with_sign_in_sales:
                    setObjs(data.filter(obj => obj.doc_type.sign_in_sales !== 0));
                    break;
                case only_to_be_invoiced:
                    setObjs(data.filter(obj => obj.doc_type.requires_to_be_invoiced));
                    break;
                default:
                    setObjs(data);
            }
        }
    }, [data, only_to_be_invoiced, only_with_sign_in_sales]);

    useEffect(() => {
        if (initialObj) {
            setObj(initialObj)
        }

    }, [initialObj]);


    const {
        data: detailedObj,
    } = useApiGetCompanyDocType(obj?.id);


    useEffect(() => {
        onChange(obj);
    }, [obj]);

    const handleOnChange = (event: React.SyntheticEvent, newValue: ICompanyDocType | null) => {
        if (newValue) {
            onChange(newValue);
        } else {
            onChange(undefined);
        }

    };

    return (
        <>
            {isLoading ? (
                <Loading/>
            ) : (
                <>
                    <Box sx={{alignItems: 'center', width: '100%'}}>
                        <MuiAutocomplete
                            id="curreny-select"
                            options={objs}
                            getOptionLabel={(option) => option.doc_type.name + ' - ' + option.doc_type.extended_name}
                            value={detailedObj ? detailedObj : null}
                            onChange={handleOnChange}
                            isOptionEqualToValue={(option, value) => option.id === value?.id && option.doc_type.name === value?.doc_type.name}
                            renderInput={(params) => (
                                <TextField
                                    sx={{textTransform: 'capitalize'}}
                                    {...params}
                                    label={t(label ? label : '') || t('currency')}
                                    required={required}
                                />
                            )}
                        />
                    </Box>

                </>
            )}
        </>
    );
}

export default DocTypeSelect;