import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {IFiscalRegistry} from "../../../../../../utils/interfaces/my_documents/fiscal_registry/IFiscalRegistry";
import {useAuth} from "../../../../../../utils/contexts/auth-context";


const useApiCreateFiscalRegistry = (): UseMutationResult<IFiscalRegistry, CustomAxiosError, any> => {
    const {selectedCompany} = useAuth()
    const queryClient = useQueryClient();
    let _people_id: number | undefined;

    const {post} = useApiCalls()

    async function doCreate({companyToken, data}: {
        companyToken?: string,
        data: any
    }): Promise<IFiscalRegistry> {
        try {
            const response = await post(`/api/company/${companyToken}/my_documents/fiscal_registry/`, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<IFiscalRegistry, CustomAxiosError, any>(
        {
            mutationFn: ({data}: {
                data: any
            }) => {

                return doCreate({
                    companyToken: selectedCompany?.company_token,
                    data
                })
            },
            onSuccess: async (data) => {
                await queryClient.invalidateQueries({queryKey: ["useApiGetFiscalRegistry", data.id]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetFiscalRegistries"]});
            },
        }
    );
};


export default useApiCreateFiscalRegistry;