import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {IWasteCode} from "../../../../../../utils/interfaces/special_wasty/waste_code/IWasteCode";


const useApiGetWasteCodes = (site_id: number | undefined): UseQueryResult<IWasteCode[], CustomAxiosError> => {
    const {selectedCompany} = useAuth();

    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined): Promise<IWasteCode[]> {
        try {
            const response = await get(`/api/company/${company_token}/special_wasty/waste_code?site_id=${site_id}`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetWasteCodes", site_id],
        queryFn: () => doGet(selectedCompany?.company_token,),
        enabled: !!site_id
    })
};

export default useApiGetWasteCodes