import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {IWarehouseParentProduct} from "../../../../../../utils/interfaces/my_warehouse/IWarehouseParentProduct";


const useApiGetWarehouseParentProducts = (warehouse_id: number): UseQueryResult<IWarehouseParentProduct[], CustomAxiosError> => {
    const {selectedCompany} = useAuth();

    const {get} = useApiCalls()

    async function doGet(warehouse_id: number): Promise<IWarehouseParentProduct[]> {
        try {
            const response = await get(`/api/company/${selectedCompany?.company_token}/my_warehouse/${warehouse_id}/parent_products`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetWarehouseParentProducts", warehouse_id],
        queryFn: () => doGet(warehouse_id),
        enabled: !!warehouse_id,
    })
};

export default useApiGetWarehouseParentProducts