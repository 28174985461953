import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomAxiosError} from "../../../../../../../../logics/api_config";
import {useApiRentriCalls} from "../../../api-hooks/api_rentri_config";
import {
    IRentriAttivitaRs
} from "../../../../../../../../utils/interfaces/special_wasty/rentri/codifiche/rifiuto/IRentriAttivitaRs";


const useApiGetRentriAttivitaRs = (): UseQueryResult<IRentriAttivitaRs[], CustomAxiosError> => {
    const {get} = useApiRentriCalls()

    async function doGet(): Promise<IRentriAttivitaRs[]> {
        try {
            const response = await get(`/codifiche/v1.0/lookup/attivita-rs`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetRentriAttivitaRs"],
        queryFn: () => doGet(),
    })
};

export default useApiGetRentriAttivitaRs