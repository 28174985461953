import React, {useEffect, useState} from 'react';
import {Box} from "@mui/material";
import MuiInputTextField from "../../../../ui/mui/mui-input/MuiInputTextField";
import MuiFabButtonBack from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonSaveAs from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";
import useApiCreateDescription from "./api-hooks/useApiCreateDescription";
import useApiUpdateDescription from "./api-hooks/useApiUpdateDescription";
import useApiGetDescription from "./api-hooks/useApiGetDescription";
import {IDescription} from "../../../../../utils/interfaces/service/description/IDescription";

interface Props {
    objToEdit: IDescription | null | undefined;
    onClose: () => void;
    onSelect?: (obj: IDescription | undefined) => void,
    scope: string;
}

const DescriptionForm: React.FC<Props> = ({objToEdit, onClose, onSelect, scope}) => {

    const [text, setText] = useState<string>();


    const createMutation = useApiCreateDescription();
    const updateMutation = useApiUpdateDescription();

    const {
        data: detailedObj,
    } = useApiGetDescription(objToEdit?.id);


    useEffect(() => {
        if (detailedObj) {
            setText(detailedObj.text);
        }
    }, [detailedObj]);


    const handleSubmit = async () => {
        try {
            const payload = {
                text: text,
                scope: scope,
            };

            let newData;

            if (detailedObj !== null && detailedObj !== undefined) {
                newData = await updateMutation.mutateAsync({id: detailedObj.id, data: payload});
            } else {
                newData = await createMutation.mutateAsync(payload);
            }
            onClose();
            onSelect && onSelect(newData);

        } catch (error) {
        }
    }


    return (
        <Box sx={{padding: 2}}>

            <MuiInputTextField
                onChange={(e) => setText(e.target.value)}
                label='description'
                type="text"
                value={text}
                required={true}
            />

            <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                <MuiFabButtonBack onClick={onClose}/>
                {/*{objToEdit &&*/}
                {/*    <MuiFabButtonDelete onClick={() => {*/}
                {/*        setConfirmationOpen(true)*/}
                {/*    }}/>}*/}
                {objToEdit ?
                    <MuiFabButtonSaveAs onClick={handleSubmit}/>
                    :
                    <MuiFabButtonSave onClick={handleSubmit}/>}
            </Box>

        </Box>
    );
};

export default DescriptionForm;