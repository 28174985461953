import React, {useEffect, useMemo, useState} from 'react';
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {useQueryClient} from "@tanstack/react-query";
import {MaterialReactTable, MRT_ColumnDef, useMaterialReactTable} from "material-react-table";
import Papa from "papaparse";


import {IMyCompanyWarehouse} from "../../../../../utils/interfaces/my_company/my_company_warehouse/IMyCompanyWarehouse";
import {
    getMuiTableBodyCellProps,
    getMuiTableBodyRowProps,
    getMuiTableHeadCellProps,
    getMuiTablePaperProps
} from "../../../../ui/mui/mui_react_table/MuiReactTableStyles";
import Loading from "../../../../ui/Loading";
import MuiFetchingSpinner from "../../../../ui/mui/MuiFetchingSpinner";
import {Avatar, Box} from "@mui/material";
import {CSVLink} from "react-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import MuiBorderedBox from "../../../../ui/mui/MuiBorderedBox";
import MuiReadOnlyBoxBody from "../../../../ui/mui/mui-display-data/MuiReadOnlyBoxBody";
import {FaList} from "react-icons/fa";
import {IWarehouseParentProduct} from "../../../../../utils/interfaces/my_warehouse/IWarehouseParentProduct";
import useApiGetWarehouseParentProducts from "./api-hooks/useApiGetWarehouseParentProducts";
import useMyWarehouseParentProductCustomCellRenderer from "./useMyWarehouseParentProductCustomCellRenderer";
import MyWarehouseProductsTable from "../MyWarehouseProducts/MyWarehouseProductsTable";
import MyWarehouseProductMovementsTable
    from "../MyWarehouseProducts/MyWarehouseProductMovements/MyWarehouseProductMovementsTable";

interface IObjCustomField extends IWarehouseParentProduct {
    custom_field: JSX.Element;
}

interface Props {
    warehouse: IMyCompanyWarehouse,
}


const MyWarehouseParentProductsTable = ({warehouse}: Props) => {
    const theme = useTheme();
    const {t} = useTranslation()
    const queryClient = useQueryClient();

    const [selectedRow, setSelectedRow] = useState<IWarehouseParentProduct | undefined>()
    const [rows, setRows] = useState<IWarehouseParentProduct[] | undefined>()

    const {
        data,
        isLoading,
        error,
        isFetching,
    } = useApiGetWarehouseParentProducts(warehouse.id);


    useEffect(() => {
        if (data) {
            setRows(data);
        }
    }, [data]);

    const CustomCellRenderer: React.FC<{ row: IWarehouseParentProduct, theme: any }> = ({row, theme}) => {
        const {renderIcons} = useMyWarehouseParentProductCustomCellRenderer(row, theme);
        return renderIcons();
    };


    const [objsWithCustomField, setObjsWithCustomField] = useState<IObjCustomField[] | undefined>();
    useEffect(() => {
        const dataWithCustomField: IObjCustomField[] | undefined = rows?.map((row) => ({
            ...row,
            custom_field: <CustomCellRenderer row={row} theme={theme}/>
        }));
        setObjsWithCustomField(dataWithCustomField);
    }, [rows, theme]);


    const convertArrayOfObjectsToCSV = (data: IWarehouseParentProduct[]): string => {
        // Create new array of objects without the unnecessary fields
        const reducedData = data.map(({movements, ...rest}) => rest);

        // Convert to CSV
        return Papa.unparse(reducedData, {
            delimiter: ';',
        });
    };


    const columns = useMemo<MRT_ColumnDef<IWarehouseParentProduct>[]>(
        () => [
            {
                accessorKey: 'product_name',
                header: t('product_name'),
                filterFn: 'contains',

            },
            {
                accessorKey: 'balance',
                header: t('balance'),
                accessorFn: (row) => row.balance.toFixed(2) + " mq"
            },
        ],
        [rows]
    );


    const table = useMaterialReactTable<IWarehouseParentProduct>({
        columns,
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 80,
        },
        data: objsWithCustomField ? objsWithCustomField : [],
        columnFilterDisplayMode: 'popover',
        globalFilterFn: 'contains',
        enableFullScreenToggle: false,
        enableFilters: true,
        enableDensityToggle: true,
        muiTableHeadCellProps: getMuiTableHeadCellProps(theme.palette.primary.main),
        muiTableBodyCellProps: getMuiTableBodyCellProps(theme),
        muiTablePaperProps: getMuiTablePaperProps,
        enablePagination: objsWithCustomField && objsWithCustomField.length > 10,
        initialState: {
            density: 'compact',
            pagination: {pageSize: 25, pageIndex: 0},
        },
        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.backgroundAppGarageColor.main,

        }),
        muiTableBodyRowProps: getMuiTableBodyRowProps(theme),
        enableRowActions: false,
        renderTopToolbarCustomActions: ({table}) => (
            <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                <>
                    <Avatar sx={{
                        backgroundColor: 'white',
                        border: `2px solid ${theme.palette.primary.main}`,
                        marginRight: 1
                    }}>
                        <FaList color={theme.palette.primary.main}/>
                    </Avatar>
                    <MuiReadOnlyBoxBody label={''} value={'products'}/>
                    {rows &&
                        <CSVLink data={convertArrayOfObjectsToCSV(rows)} filename={'all_rows.csv'}>
                            {<FileDownloadIcon
                                style={{color: theme.palette.primary.main, fontSize: '2rem', marginTop: 8}}/>}
                        </CSVLink>
                    }

                </>
            </Box>),
        enableExpandAll: false,
        displayColumnDefOptions: {
            'mrt-row-expand': {
                header: t('products/movements'),
            },
        },
        muiDetailPanelProps: () => ({
            sx: (theme) => ({
                backgroundColor: theme.palette.backgroundAppGarageColor.dark
            }),
        }),
        muiExpandButtonProps: ({row, table}) => ({
            onClick: () => table.setExpanded({[row.id]: !row.getIsExpanded()}),
            sx: {
                transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
                transition: 'transform 0.2s',
                color: theme.palette.primary.main,
            },
        }),
        renderDetailPanel: ({row}) =>
            (<>
                    {
                        row.original.children_products.length > 0 &&
                        <MuiBorderedBox
                        >
                            <MyWarehouseProductsTable warehouse={warehouse} products={row.original.children_products}/>
                        </MuiBorderedBox>}
                    {
                        row.original.movements.length > 0 &&
                        <MuiBorderedBox
                        >
                            <MyWarehouseProductMovementsTable warehouse={warehouse} product={row.original}/>
                        </MuiBorderedBox>}
                </>

            )

    });


    return <>
        {isLoading ? <Loading/> :
            <>

                <MaterialReactTable table={table}/>


                {isFetching &&
                    <MuiFetchingSpinner text="Fetching data..."/>
                }
            </>}
    </>;
};

export default MyWarehouseParentProductsTable;
