import React from 'react';
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {Button, Popover, Tooltip} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import MRTFiltersBox from "../../../../../ui/mui/mui_react_table/MRTFiltersBox";
import MuiInputTextField from "../../../../../ui/mui/mui-input/MuiInputTextField";
import MuiTypography from "../../../../../ui/mui/mui-display-data/MuiTypography";


interface MRTFiltersTooltipProps {
    onToggle: () => void;
    startDateFilter: string | undefined;
    setStartDateFilter: (value: string) => void;
    endDateFilter: string | undefined;
    setEndDateFilter: (value: string) => void;
}

const MRTWorkingsFiltersPopOver: React.FC<MRTFiltersTooltipProps> = ({
                                                                         onToggle,
                                                                         startDateFilter,
                                                                         setStartDateFilter,
                                                                         endDateFilter,
                                                                         setEndDateFilter,
                                                                     }) => {
    const theme = useTheme();
    const {t} = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleToggle = () => {
        onToggle()
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    return (
        <>
            <Tooltip disableFocusListener
                     title={t('additional_filters')}
                     placement="top"
            >
                <Button onClick={handleClick}>
                    <FilterAltIcon
                        sx={{
                            color: !!startDateFilter || !!endDateFilter
                                ? theme.palette.filterColor.main
                                : theme.palette.disabledColor.main,
                            '&:hover': {
                                color: theme.palette.filterColor.main,
                                cursor: 'pointer',
                            }, fontSize: '2rem'
                        }}
                    />
                </Button>
            </Tooltip>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MRTFiltersBox onToggle={handleToggle}>
                    <MuiTypography value={'returning_date_filter'}/>
                    <MuiInputTextField
                        onChange={setStartDateFilter}
                        label='starting_date'
                        type="date"
                        value={startDateFilter}
                        required={false}
                    />

                    <MuiInputTextField
                        onChange={setEndDateFilter}
                        label='ending_date'
                        type="date"
                        value={endDateFilter}
                        required={false}
                    />
                </MRTFiltersBox>
            </Popover>
        </>
    );
};

export default MRTWorkingsFiltersPopOver;
