export const formatDate = (d: any) => {
    if (d) {
        const [year, month, day] = d.split("-");
        return `${day}-${month}-${year}`;
    } else {
        return d;
    }
};

export const formatDateTimeToDateString = (d: any) => {
    if (d) {
        d = d.slice(0, 10)
        const [year, month, day] = d.split("-");
        return `${day}-${month}-${year}`;
    } else {
        return d;
    }
};

export const formatDateTimeToDateTimeString = (d: any) => {
    if (d) {
        // Extract the date part (first 10 characters)
        const datePart = d.slice(0, 10);
        const [year, month, day] = datePart.split("-");

        // Extract the time part (hour and minute) from the DateTime string
        const timePart = d.slice(11, 16); // Get "HH:MM"

        // Return the formatted date and time as "DD-MM-YYYY HH:MM"
        return `${day}-${month}-${year} ${timePart}`;
    } else {
        return d;
    }
};


export const formatDateTimeForInput = (d: any) => {
    if (d) {
        return d.split('T')[0];
    } else {
        return '';
    }
};

export const cutString = (nome: string, max_chars: number) => {
    if (nome) {
        if (nome.length > max_chars) {
            return nome.substring(0, max_chars) + '...';
        }
        return nome;
    }
    return nome;

}

export const convertDateTimeToInteger = (timestampString: string | undefined): number | undefined => {
    return timestampString ? Date.parse(timestampString) / 1000 : undefined;
};


export const getFilteredItems = (
    items_list: any,
    filter: any,
    filteredField: string
) => {
    if (filteredField) {
        if (typeof filter === "string") {
            const results = items_list.filter((item: any) => {
                if (item[filteredField]) {
                    return item[filteredField]
                        .toString()
                        .toLowerCase()
                        .includes(filter.toLowerCase());
                }
            });
            return results;
        }

        if ((typeof filter === "boolean" || typeof filter === "number")) {
            const results = items_list.filter((item: any) => {
                return item[filteredField] === filter;
            });
            return results;
        }
    }

    return items_list;
};


export const globalHandleChange = (event: any, setMov: any, setToSubmit?: any) => {
    const {type, name, value, checked} = event.target;
    let updatedValue = type === 'checkbox' ? checked : value;
    if (type === 'date' && updatedValue === '') {
        updatedValue = null;
    }
    if (type === 'datetime-local' && updatedValue === '') {
        updatedValue = null;
    }

    if (type === 'datetime-local' && updatedValue !== null) {
        const selectedDateTime = new Date(event.target.value); // Convert input value to local Date object;
        const utcDateTime = new Date(selectedDateTime.getTime() - (selectedDateTime.getTimezoneOffset() * 60000)); // Convert local time to UTC
        updatedValue = utcDateTime;
    }

    if (type === 'number') {
        updatedValue = Number(updatedValue);
    }
    const change = {
        [name]: updatedValue,
    };
    const newMov = ((mov: any) => {
        return {...mov, ...change}
    })
    setMov(newMov);
    if (setToSubmit) {
        setToSubmit(true)
    }

}

export function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so we add 1 and pad with leading zeros.
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}


export function formatFloatToEuro(total_value_eur: number) {
    if (!total_value_eur) {
        return '';
    }

    const formattedValue = total_value_eur.toLocaleString('it-IT', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    return `${formattedValue} €`;
}

export function formatFloatToLocale(total_value_eur: number) {
    if (!total_value_eur) {
        return '';
    }

    const formattedValue = total_value_eur.toLocaleString('it-IT', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    return formattedValue;
}

export function formatIntegerToLocale(total_value_eur: number) {
    if (!total_value_eur) {
        return '';
    }

    const formattedValue = total_value_eur.toLocaleString('it-IT', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });

    return formattedValue;
}
