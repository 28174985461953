import React, {useEffect, useMemo, useState} from 'react';
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {MaterialReactTable, MRT_ActionMenuItem, MRT_ColumnDef, useMaterialReactTable} from "material-react-table";
import {Delete, Edit} from "@mui/icons-material";
import {Avatar, Box} from "@mui/material";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import {IFiscalRegistry} from "../../../../../utils/interfaces/my_documents/fiscal_registry/IFiscalRegistry";
import {useQueryClient} from "@tanstack/react-query";
import useApiDeleteFiscalRegistry from "./api-hooks/useApiDeleteFiscalRegistry";
import useCustomCellRenderer from "./useCustomCellRenderer";
import {
    getMuiTableBodyCellProps,
    getMuiTableBodyRowProps,
    getMuiTableHeadCellProps,
    getMuiTablePaperProps
} from "../../../../ui/mui/mui_react_table/MuiReactTableStyles";
import MuiReadOnlyBoxBody from "../../../../ui/mui/mui-display-data/MuiReadOnlyBoxBody";
import useApiGetFiscalRegistries from "./api-hooks/useApiGetFiscalRegistries";
import Loading from "../../../../ui/Loading";
import MuiFabButtonBack from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiAlertDialog from "../../../../ui/mui/mui_modal/MuiAlertDialog";
import FiscalRegistryForm from "./FiscalRegistryForm";
import MuiFetchingSpinner from "../../../../ui/mui/MuiFetchingSpinner";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import MuiModal from "../../../../ui/mui/mui_modal/MuiModal";
import AddIcon from "@mui/icons-material/Add";
import MRTMuiButton from "../../../../ui/mui/mui_react_table/MRTMuiButton";


interface Props {
    onSelect?: (obj: IFiscalRegistry | undefined) => void,
    onClose?: () => void;
}


const FiscalRegistryTable = ({onSelect, onClose}: Props) => {
    const theme = useTheme();
    const {t} = useTranslation()
    const queryClient = useQueryClient();


    const [selectedRow, setSelectedRow] = useState<IFiscalRegistry | undefined>()
    const [rows, setRows] = useState<IFiscalRegistry[] | undefined>()

    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)

    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const deleteMutation = useApiDeleteFiscalRegistry(selectedRow?.id);
    const {mutateAsync: apiDelete} = deleteMutation;

    const handleConfirmDelete = async () => {
        try {
            await apiDelete({pk: selectedRow?.id});
        } catch (error) {
        }
        setDeleteConfirmationOpen(false);
    };
    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };

    const {data, isLoading, isFetching} = useApiGetFiscalRegistries()
    useEffect(() => {
        setRows(data);
    }, [data]);


    // note_marco: customCellRenderer in hook example
    const CustomCellRenderer: React.FC<{ row: IFiscalRegistry, theme: any }> = ({row, theme}) => {
        const {renderIcons} = useCustomCellRenderer(row, theme);
        return renderIcons();
    };


    const columns = useMemo<MRT_ColumnDef<IFiscalRegistry>[]>(
        () => {
            const baseColumns: MRT_ColumnDef<IFiscalRegistry>[] = [
                {
                    accessorKey: 'custom_field',
                    header: '',
                    enableColumnActions: false,
                    enableColumnFilter: false,
                    enableColumnOrdering: false,
                    enableSorting: false,
                },

                {
                    header: t('people_name'),
                    accessorFn: (row) => row.people.people_name
                },
                {
                    header: t('vat_number'),
                    accessorFn: (row) => row.vat_number
                },
                {
                    header: t('registry'),
                    accessorFn: (row) => row.registry?.business_name
                },
                {
                    header: t('payment'),
                    accessorFn: (row) => row.payment_method_description
                },
                {
                    header: t('customer bank'),
                    accessorFn: (row) => row.bank ? row.bank.abi + ' / ' + row.bank?.cab : ''
                },

            ];


            return baseColumns;
        },
        [t]
    );


    const table = useMaterialReactTable<IFiscalRegistry>({
        columns,
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 80,
        },
        data: rows ? rows : [],
        columnFilterDisplayMode: 'popover',
        globalFilterFn: 'contains',
        enableFullScreenToggle: false,
        enableFilters: false,
        enableDensityToggle: false,
        muiTableHeadCellProps: getMuiTableHeadCellProps(theme.palette.primary.main),
        muiTableBodyCellProps: getMuiTableBodyCellProps(theme),
        muiTablePaperProps: getMuiTablePaperProps,
        enablePagination: false,
        initialState: {
            density: 'compact',
            pagination: {pageSize: 25, pageIndex: 0},
        },
        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.backgroundAppGarageColor.main,

        }),
        muiTableBodyRowProps: getMuiTableBodyRowProps(theme),

        enableRowActions: true,
        renderRowActionMenuItems: ({closeMenu, row}) => [
            onSelect && onClose && (
                <MRT_ActionMenuItem
                    icon={<AdsClickIcon color="primary"/>}
                    key="select"
                    label={t("select")}
                    onClick={() => {
                        onSelect(row.original);
                        onClose();
                    }}
                    table={table}
                />),
            <MRT_ActionMenuItem
                icon={<Edit color="primary"/>}
                key="edit"
                label={t("edit")}
                onClick={(event) => {
                    setSelectedRow(row.original);
                    setIsEditFormOpen(true)
                    closeMenu()
                }}
                table={table}
            />,
            <MRT_ActionMenuItem
                icon={<Delete color="warning"/>}
                key="delete"
                label={t("delete")}
                onClick={() => {
                    setSelectedRow(row.original);
                    setDeleteConfirmationOpen(true)
                    closeMenu()
                }}
                table={table}
            />,
        ],
        renderTopToolbarCustomActions: ({table}) => (
            <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                <>
                    <Avatar sx={{
                        backgroundColor: 'white',
                        border: `2px solid ${theme.palette.primary.main}`,
                        marginRight: 1
                    }}>
                        <PermContactCalendarIcon color={'primary'}/>
                    </Avatar>
                    <MuiReadOnlyBoxBody label={''} value={'fiscal registries'}/>
                    <MRTMuiButton
                        icon={<AddIcon style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                        onClick={() => {
                            setSelectedRow(undefined);
                            setIsEditFormOpen(true)
                        }} label={'add fiscal registry'}/>
                </>
            </Box>
        ),

    });


    return <>
        {isLoading ? <Loading/> :
            <>
                {onClose &&
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100vh"
                    >
                        <Box className={'mt-2 gap-x-2 mb-2'}>
                            <MuiFabButtonBack onClick={onClose}/>
                        </Box>
                    </Box>}

                <MaterialReactTable table={table}/>

                <MuiAlertDialog
                    open={isDeleteConfirmationOpen}
                    title="delete_confirmation"
                    content="ask_for_delete"
                    onClose={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                />

                <MuiModal
                    top="50%"
                    left="50%"
                    width={'120vh'}
                    height={'95vh'}
                    open={isEditFormOpen}
                    disableBackdropClick={true}
                    onClose={() => setIsEditFormOpen(false)}
                >
                    <FiscalRegistryForm
                        objToEdit={selectedRow || null}
                        onClose={() => setIsEditFormOpen(false)}
                    />
                </MuiModal>


                {isFetching &&
                    <MuiFetchingSpinner text="Fetching data..."/>
                }
            </>}
    </>;
};

export default FiscalRegistryTable;
