import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Box, TextField} from "@mui/material";
import {useToast} from "../../../../../../../utils/contexts/toast-context";
import {CustomAxiosError} from "../../../../../../../logics/api_config";
import Loading from "../../../../../../ui/Loading";
import MuiAutocomplete from "../../../../../../ui/mui/mui-input/MuiAutoComplete";
import {
    IRentriComune
} from "../../../../../../../utils/interfaces/special_wasty/rentri/codifiche/territoriali/IRentriComune";
import useApiGetRentriComuni from "./api-hooks/useApiGetRentriComuni";


interface Props {
    obj: IRentriComune | undefined,
    onChange: (obj: IRentriComune | undefined) => void,
    className: string,
    label?: string,
    clearable?: boolean
    required?: boolean
    provincia_id?: string | undefined
}

const RentriComuniSelect = ({
                                obj: initialObj,
                                onChange,
                                label,
                                clearable,
                                required,
                                provincia_id: initial_provincia_id,
                                ...props
                            }: Props) => {
    const {displayError, displayMessage} = useToast()
    const {t} = useTranslation();
    const {className = ''} = props;
    const [objs, setObjs] = useState<IRentriComune[]>([]);
    const [obj, setObj] = useState<IRentriComune | undefined>(initialObj)
    const [provincia_id, setProvinciaId] = useState<string | undefined>(initial_provincia_id)

    useEffect(() => {
        if (initial_provincia_id) {
            setProvinciaId(initial_provincia_id);
            setObj(undefined);
            onChange(undefined);
        }
    }, [initial_provincia_id, provincia_id]);

    const {
        data,
        isLoading,
        error,
    } = useApiGetRentriComuni(provincia_id);
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }

    useEffect(() => {
        if (data) {
            setObjs(data);
        }
    }, [data]);

    useEffect(() => {
        if (initialObj) {
            setObj(initialObj);
        }
    }, [initialObj, objs, initial_provincia_id]);

    const handleOnChange = (event: React.SyntheticEvent, newValue: IRentriComune | null) => {
        if (newValue) {
            onChange(newValue);
        } else {
            onChange(undefined);
        }

    };


    useEffect(() => {
        onChange(obj);
    }, [obj]);


    return (
        <>
            {isLoading ? (
                <Loading/>
            ) : (
                <>
                    <Box sx={{display: 'flex', alignItems: 'center', width: '100%'}}>

                        <MuiAutocomplete
                            id="comuni-select"
                            options={objs}
                            getOptionLabel={(option) => option.name}
                            value={obj ? obj : null}
                            onChange={handleOnChange}
                            isOptionEqualToValue={(option, value) => option.id === value?.id && option.name === value?.name}
                            renderInput={(params) => (
                                <TextField
                                    sx={{textTransform: 'capitalize'}}
                                    {...params}
                                    label={t(label ? label : '') || t('comune')}
                                    required={required}
                                />
                            )}
                        />
                    </Box>

                </>
            )}
        </>
    );
}

export default RentriComuniSelect;