import React from 'react';
import {Box} from "@mui/material";
import {IconContext} from "react-icons";
import {useTranslation} from "react-i18next";
import {IWasteOpenLoad} from "../../../../../utils/interfaces/special_wasty/waste_movement/IWasteOpenLoad";
import {GrStatusGoodSmall} from "react-icons/gr";

// note_marco: customCellRenderer in hook example
const useCustomCellRenderer = (row: IWasteOpenLoad, theme: any) => {
    const {t} = useTranslation();

    const getIconsForCustomField = (row: IWasteOpenLoad, theme: any) => {
        const icons = [];

        icons.push(
            <GrStatusGoodSmall
                key="GrStatusGoodSmall"
                color={row.waste_load.show_alert ? theme.palette.warning.main : theme.palette.primary.main}
                className={row.waste_load.show_alert ? 'animate-pulse' : ''}
            />
        );

        return icons;
    };

    const renderIcons = () => {
        const icons = getIconsForCustomField(row, theme);
        return (
            <IconContext.Provider value={{size: "1.3rem"}}>
                <Box display="flex" flexDirection="row" sx={{gap: 0.5}}>
                    {icons}
                </Box>
            </IconContext.Provider>
        );
    };

    return {renderIcons};
};

export default useCustomCellRenderer;