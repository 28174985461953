import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import {IWarehouseProductMovement} from "../../../../../../../utils/interfaces/my_warehouse/IWarehouseProductMovement";


const useApiCreateManualWarehouseProductMovement = (warehouse_id: number, product_id: number): UseMutationResult<IWarehouseProductMovement, CustomAxiosError, any> => {
    const {selectedCompany} = useAuth()
    const queryClient = useQueryClient();

    const {post} = useApiCalls()

    async function doCreate({data}: {
        companyToken?: string,
        data: any
    }): Promise<IWarehouseProductMovement> {
        try {
            const response = await post(`/api/company/${selectedCompany?.company_token}/my_warehouse/${warehouse_id}/products/${product_id}/movements/manual_movement`, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    }


    return useMutation<IWarehouseProductMovement, CustomAxiosError, any>(
        {
            mutationFn: (
                data
            ) => {
                return doCreate({
                    data
                })
            },
            onSuccess: async (obj) => {
                await queryClient.invalidateQueries({queryKey: ["useApiGetWarehouseProductMovements", warehouse_id, product_id]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetWarehouseProducts", warehouse_id]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetWarehouseParentProducts", warehouse_id]});
            },
        }
    );
};


export default useApiCreateManualWarehouseProductMovement;