import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {IWasteMovement} from "../../../../../../utils/interfaces/special_wasty/waste_movement/IWasteMovement";


const useWasteApiGetSiteRECTIFIABLEMovements = (site_id: number | undefined, waste_code_id: number | undefined): UseQueryResult<IWasteMovement[], CustomAxiosError> => {
    const {selectedCompany} = useAuth();

    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined): Promise<IWasteMovement[]> {
        try {
            const response = await get(`/api/company/${company_token}/special_wasty/waste_movement?site_id=${site_id}&waste_code_id=${waste_code_id}&only_rectifiable=true`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useWasteApiGetSiteRECTIFIABLEMovements", site_id, waste_code_id],
        queryFn: () => doGet(selectedCompany?.company_token,),
        enabled: !!site_id && !!waste_code_id,
    })
};

export default useWasteApiGetSiteRECTIFIABLEMovements