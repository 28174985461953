import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomAxiosError} from "../../../../../../../../logics/api_config";
import {useApiRentriCalls} from "../../../api-hooks/api_rentri_config";
import {
    IRentriAttivita
} from "../../../../../../../../utils/interfaces/special_wasty/rentri/codifiche/rifiuto/IRentriAttivita";


const useApiGetRentriAttivita = (): UseQueryResult<IRentriAttivita[], CustomAxiosError> => {
    const {get} = useApiRentriCalls()

    async function doGet(): Promise<IRentriAttivita[]> {
        try {
            const response = await get(`/codifiche/v1.0/lookup/attivita`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetRentriAttivita"],
        queryFn: () => doGet(),
    })
};

export default useApiGetRentriAttivita