import React, {useState} from "react";
import {IWasteCode} from "../../interfaces/special_wasty/waste_code/IWasteCode";


type SpecialWastyContextType = {
    selectedWasteCode: IWasteCode | undefined,
    selectWasteCode: (selectedWasteCode: IWasteCode | undefined) => void,

}

const SpecialWastyContext = React.createContext<SpecialWastyContextType | undefined>(undefined);

function useSpecialWastyContextValue() {
    const [selectedWasteCode, setSelectedWasteCode] = useState<IWasteCode | undefined>();
    const selectWasteCode = (obj: IWasteCode | undefined) => {
        setSelectedWasteCode(obj)
    }

    return {
        selectedWasteCode, selectWasteCode,
    }
}

function SpecialWastyProvider({children}: { children: React.ReactNode }) {
    const value = useSpecialWastyContextValue();
    return <SpecialWastyContext.Provider value={value}>{children}</SpecialWastyContext.Provider>
}

function useSpecialWasty() {
    const context = React.useContext(SpecialWastyContext);
    if (!context) {
        throw new Error('useSpecialWastyContextValue must be used within a SpecialWastyProvider');
    }
    return context;
}

export {
    SpecialWastyProvider,
    useSpecialWasty,
}
