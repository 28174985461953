import React, {useEffect, useState} from 'react';
import {Box} from "@mui/material";
import {IRentriSite} from "../../../../../../../utils/interfaces/special_wasty/rentri/anagrafiche/IRentriSite";
import RentriSitiSelect from "../RentriSitiSelect";
import useApiGetRentriCertificate from "../../../rentri_certificate/api-hooks/useApiGetRentriCertificate";
import MuiFabButtonBack from "../../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonSaveAs from "../../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";
import useApiCreateRentriRegistro from "./api-hooks/useApiCreateRentriRegistro";
import useApiUpdateRentriRegistro from "./api-hooks/useApiUpdateRentriRegistro";
import useApiGetRentriRegistro from "./api-hooks/useApiGetRentriRegistro";
import {
    IRentriAttivita
} from "../../../../../../../utils/interfaces/special_wasty/rentri/codifiche/rifiuto/IRentriAttivita";
import RentriAttivitaSelect from "../../codifiche/rifiuto/RentriAttivitaSelect";
import {
    IRentriAttivitaRs
} from "../../../../../../../utils/interfaces/special_wasty/rentri/codifiche/rifiuto/IRentriAttivitaRs";
import RentriAttivitaRsSelect from "../../codifiche/rifiuto/RentriAttivitaRsSelect";
import MuiInputTextField from "../../../../../../ui/mui/mui-input/MuiInputTextField";
import {
    IRentriRegistro
} from "../../../../../../../utils/interfaces/special_wasty/rentri/rentri_registro/IRentriRegistro";
import useApiGetRentriSiti from "../api-hooks/useApiGetRentriSiti";
import useApiGetRentriAttivita from "../../codifiche/rifiuto/api-hooks/useApiGetRentriAttivita";
import {useQueryClient} from "@tanstack/react-query";
import {useAppGarage} from "../../../../../../../utils/contexts/appGarage-context";
import useApiGetRentriSito from "../api-hooks/useApiGetRentriSito";
import {ClearIcon} from "@mui/x-date-pickers";
import IconButton from "@mui/material/IconButton";

interface Props {
    objToEdit: IRentriRegistro | null | undefined;
    onClose: () => void;
    onSelect?: (obj: IRentriRegistro | undefined) => void,
}

const RentriRegistroForm: React.FC<Props> = ({objToEdit, onClose, onSelect}) => {
    const queryClient = useQueryClient();
    const {selectedMyCompanySite} = useAppGarage()
    const [rentriSite, setRentriSite] = useState<IRentriSite | undefined>();
    const [selectedAttivita, setSelectedAttivita] = useState<IRentriAttivita | undefined>();
    const [selectedAttivitaFromSite, setSelectedAttivitaFromSite] = useState<IRentriAttivita | undefined>();
    const [selectedAttivitaRs, setSelectedAttivitaRs] = useState<string[]>([]);
    const [descrizione, setDescrizione] = useState<string>();

    const createMutation = useApiCreateRentriRegistro();
    const updateMutation = useApiUpdateRentriRegistro();

    const {data: detailedObj} = useApiGetRentriRegistro(objToEdit?.identificativo);


    useEffect(() => {
        if (detailedObj) {
            setDescrizione(detailedObj.descrizione);
        } else {
            setRentriSite(selectedMyCompanySite?.num_iscr_sito)
            setSelectedAttivita(selectedAttivitaFromSite);
        }
    }, [detailedObj, selectedAttivitaFromSite, selectedMyCompanySite?.num_iscr_sito]);

    const handleSelectAttivita = (obj: IRentriAttivita | undefined) => {
        setSelectedAttivita(obj);
    };

    const handleSelectAttivitaRs = (obj: IRentriAttivitaRs | undefined) => {
        if (obj && !selectedAttivitaRs.includes(obj.code)) {
            setSelectedAttivitaRs([...selectedAttivitaRs, obj.code]);
        }
    };

    const handleRemoveAttivitaRs = (code: string) => {
        setSelectedAttivitaRs(selectedAttivitaRs.filter(item => item !== code));
    };

    const handleSubmit = async () => {
        try {
            const payload = {
                num_iscr_sito: rentriSite ? rentriSite.num_iscr_sito : null,
                attivita: selectedAttivita ? [selectedAttivita.code] : null,
                attivita_rec_smalt: selectedAttivitaRs.length ? selectedAttivitaRs : null,
                descrizione: descrizione ? descrizione : null,
            };

            let newData;

            if (detailedObj !== null && detailedObj !== undefined) {
                newData = await updateMutation.mutateAsync({id: detailedObj.identificativo, data: payload});
            } else {
                newData = await createMutation.mutateAsync(payload);
            }
            onClose();
            onSelect && onSelect(newData);
            await queryClient.invalidateQueries({queryKey: ['useApiGetRentriRegistri', rentriCertificate?.num_iscr_operatore, detailedObj?.num_iscr_sito]});

        } catch (error) {
            // Handle errors if necessary
        }
    };

    const {data: rentriCertificate} = useApiGetRentriCertificate();

    const {data: rentriSites,} = useApiGetRentriSiti(rentriCertificate?.num_iscr_operatore);
    useEffect(() => {
        if (rentriSites && rentriSites.length > 0) {
            const foundSite = rentriSites.find(site => site.num_iscr_sito === detailedObj?.num_iscr_sito);
            if (foundSite) {
                setRentriSite(foundSite);
            }
        }
    }, [detailedObj?.num_iscr_sito, rentriSites]);

    const {data: rentriAttivita,} = useApiGetRentriAttivita();
    useEffect(() => {
        if (rentriAttivita && rentriAttivita.length > 0) {
            const foundAttivita = rentriAttivita.find(attivita => attivita.code === detailedObj?.attivita[0]);
            if (foundAttivita) {
                setSelectedAttivita(foundAttivita);
            }
        }
    }, [detailedObj?.attivita, rentriAttivita]);

    const {data: detailedRentriSite} = useApiGetRentriSito(rentriCertificate?.num_iscr_operatore, selectedMyCompanySite?.num_iscr_sito.num_iscr_sito);
    useEffect(() => {
        if (rentriAttivita && rentriAttivita.length > 0) {
            const foundAttivita = rentriAttivita.find(attivita => attivita.code === detailedRentriSite?.attivita[0]);
            if (foundAttivita) {
                setSelectedAttivitaFromSite(foundAttivita);
            }
        }
    }, [detailedRentriSite, rentriAttivita]);


    return (
        <Box sx={{padding: 2}}>
            <RentriSitiSelect
                obj={rentriSite}
                onChange={(obj) => setRentriSite(obj)}
                className={''}
                label={'Numero di Iscrizione del Sito sul RENTRI'}
                num_iscr_operatore={rentriCertificate?.num_iscr_operatore}
            />

            <RentriAttivitaSelect
                obj={selectedAttivita}
                label={'attivita'}
                onChange={handleSelectAttivita}
                className={''}
                required={false}
            />


            <RentriAttivitaRsSelect
                obj={undefined}
                label={'attivita rs'}
                onChange={handleSelectAttivitaRs}
                className={''}
                required={false}
            />

            {selectedAttivitaRs.length > 0 && (
                <Box>
                    {selectedAttivitaRs.map((code, index) => (
                        <Box key={index} sx={{display: 'flex', alignItems: 'center', marginBottom: 1}}>
                            <span>{code}</span>
                            <IconButton onClick={() => handleRemoveAttivitaRs(code)} aria-label="remove">
                                <ClearIcon/>
                            </IconButton>
                        </Box>
                    ))}
                </Box>
            )}

            <MuiInputTextField
                onChange={(e) => setDescrizione(e.target.value)}
                label='descrizione'
                type="text"
                value={descrizione}
            />

            <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                <MuiFabButtonBack onClick={onClose}/>
                {objToEdit ?
                    <MuiFabButtonSaveAs onClick={handleSubmit}/>
                    :
                    <MuiFabButtonSave onClick={handleSubmit}/>}
            </Box>
        </Box>
    );
};

export default RentriRegistroForm;