import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useQueryClient} from "@tanstack/react-query";
import {MaterialReactTable, MRT_ActionMenuItem, MRT_ColumnDef, useMaterialReactTable} from "material-react-table";
import {useTheme} from "@mui/material/styles";
import {Avatar, Box, FormControlLabel, Popover, Switch, Tooltip} from "@mui/material";
import {Delete, Edit} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import {useLocation, useNavigate} from "react-router-dom";
import {GiBarrel} from "react-icons/gi";
import {FaBalanceScale} from "react-icons/fa";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {ITanneryDyeingSchedule} from "../../../../../utils/interfaces/tannery_dyeing/ITanneryDyeingSchedule";
import {useAuth} from "../../../../../utils/contexts/auth-context";
import {useToast} from "../../../../../utils/contexts/toast-context";
import useApiGetTanneryDyeingScheduleCurrents from "./api-hooks/useApiGetTanneryDyeingScheduleCurrents";
import {CustomAxiosError} from "../../../../../logics/api_config";
import useApiGetTanneryDyeingScheduleHistorical from "./api-hooks/useApiGetTanneryDyeingScheduleHistorical";
import useApiGetTanneryDyeingScheduleDrafts from "./api-hooks/useApiGetTanneryDyeingScheduleDrafts";
import {useTanneryDyeing} from "../../../../../utils/contexts/tannery_dyeing/tannery_dyeing-context";
import useApiDeleteTanneryDyeingSchedule from "./api-hooks/useApiDeleteTanneryDyeingSchedule";
import customCellRenderer from "./customCellRenderer";
import {
    getMuiTableBodyCellProps,
    getMuiTableBodyRowProps,
    getMuiTableHeadCellProps,
    getMuiTablePaperProps
} from "../../../../ui/mui/mui_react_table/MuiReactTableStyles";
import MuiReadOnlyBoxBody from "../../../../ui/mui/mui-display-data/MuiReadOnlyBoxBody";
import MRTMuiButton from "../../../../ui/mui/mui_react_table/MRTMuiButton";
import MuiBorderedBox from "../../../../ui/mui/MuiBorderedBox";
import MuiAlertDialog from "../../../../ui/mui/mui_modal/MuiAlertDialog";
import MuiModal from "../../../../ui/mui/mui_modal/MuiModal";
import TanneryDyeingScheduleForm from "./TanneryDyeingScheduleForm";
import MuiFetchingSpinner from "../../../../ui/mui/MuiFetchingSpinner";
import MuiFabButtonBase from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBase";
import {GoHistory} from "react-icons/go";
import {RiDraftLine} from "react-icons/ri";
import OrderScheduleTanneryDyeingTable
    from "../../tannery_production/order_schedule/dye/OrderScheduleTanneryDyeingTable";
import useApiGetDyeToDye from "../../tannery_production/order_schedule/dye/api-hooks/useApiGetDyeToDye";
import MRTTanneryDyeingScheduleFiltersPopOver from "./MRTTanneryDyeingScheduleFiltersPopOver";
import {filterTanneryDyeingList} from "./filterUtils";
import IconButton from "@mui/material/IconButton";
import {LuChefHat} from "react-icons/lu";
import TanneryDyeingRecipe from "./tannery_dyeing_recipe/TanneryDyeingRecipe";
import MuiInputTextField from "../../../../ui/mui/mui-input/MuiInputTextField";
import {useAppGarage} from "../../../../../utils/contexts/appGarage-context";
import {formatDateTimeToDateTimeString} from "../../../../../logics/helpers";


interface ITanneryDyeingScheduleWithCustomField extends ITanneryDyeingSchedule {
    custom_field: JSX.Element;
}

const TanneryDyeingScheduleTable = () => {
    const {selectedCompany, userLevel} = useAuth();
    const location = useLocation();
    const {displayError, displayMessage} = useToast()
    const theme = useTheme();
    const {t} = useTranslation()
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const {availableCompanyApplications} = useAppGarage()
    const {
        selectTanneryDyeing,
        selectedTanneryDyeing,
        isEditable,
        setIsEditable,
        setAvatarImage,
        avatarImage
    } = useTanneryDyeing();


    const [isDraft, setIsDraft] = useState(false);
    const [isHistorical, setIsHistorical] = useState(false);
    const [isCurrent, setIsCurrent] = useState(false);
    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false);
    const [isCopyFormOpen, setIsCopyFormOpen] = useState<boolean>(false);
    const [isToDyeModalOpen, setIsToDyeModalOpen] = useState<boolean>(false);
    const [isSelectSourceFormOpen, setIsSelectSourceFormOpen] = useState<boolean>(false);
    const [openFilters, setOpenFilter] = useState<boolean>(true);
    const [showOnlyWithRecipe, setShowOnlyWithRecipe] = useState(false)


    const [filterTanneryDyeingId, setFilterTanneryDyeingId] = useState('');
    const [filterTanneryDyeingDescription, setFilterTanneryDyeingDescription] = useState('');
    const [filterOrderId, setFilterOrderId] = useState('');
    const [filterPerson, setFilterPerson] = useState<string>('');
    const [filterArticle, setFilterArticle] = useState('');
    const [filterThickness, setFilterThickness] = useState('');
    const [filterColor, setFilterColor] = useState('');

    const [tableLabel, setTableLabel] = useState('tannery_dyeing_schedules')


    const inputRef = useRef<HTMLInputElement | null>(null);


    const handleResetFilters = () => {
        setFilterPerson('');
        setFilterArticle('');
        setFilterColor('');
        setFilterThickness('');
        setFilterOrderId('');
        setFilterTanneryDyeingId('');
        setFilterTanneryDyeingDescription('');
    };

    const handleToggleFilters = () => {
        handleResetFilters()
        setOpenFilter(!openFilters);
    };

    const [showRecipe, setShowRecipe] = useState(false)
    const handleInfoClick = (rowData: ITanneryDyeingSchedule) => {
        selectTanneryDyeing(rowData);
        setSelectedRow(rowData);
        setShowRecipe(true);
    };

    const handleWithRecipeToggle = () => {
        setShowOnlyWithRecipe(!showOnlyWithRecipe)
    }

    const {
        data: toDyeData,
        error
    } = useApiGetDyeToDye(selectedCompany?.company_token);
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }

    const {
        data: currentData,
        isLoading: currentLoading,
        error: currentError,
        isFetching: currentFetching,
        refetch: refecthCurrentData,
    }
        = useApiGetTanneryDyeingScheduleCurrents(selectedCompany?.company_token);
    if (currentError) {
        const axiosError = currentError as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }

    const {
        data: historicalData,
        isLoading: historicalLoading,
        error: historicalError,
        isFetching: historicalFetching,
    } = useApiGetTanneryDyeingScheduleHistorical(selectedCompany?.company_token);
    if (historicalError) {
        const axiosError = historicalError as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }

    const {
        data: draftData,
        isLoading: draftLoading,
        error: draftError,
        isFetching: draftFetching
    } = useApiGetTanneryDyeingScheduleDrafts(selectedCompany?.company_token);
    if (historicalError) {
        const axiosError = historicalError as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }


    const [isLoading, setIsLoading] = useState<boolean>()
    const [rows, setRows] = useState<ITanneryDyeingSchedule[] | undefined>()
    const [selectedRow, setSelectedRow] = useState<ITanneryDyeingSchedule | undefined>()
    useEffect(() => {
        selectTanneryDyeing(undefined);
        setIsDraft(false)
        setIsHistorical(false)
        setIsCurrent(false)
        setShowOnlyWithRecipe(false)
        handleResetFilters()
        if (location.pathname.startsWith('/apps/tannery_dyeing/dyeing_schedule/history')) {
            setRows(historicalData);
            setIsLoading(historicalLoading);
            setIsHistorical(true);
            setShowOnlyWithRecipe(false);
            setAvatarImage(<GoHistory color={theme.palette.primary.main}/>)
            setTableLabel('dyeing_schedule_history')
        } else if (location.pathname.startsWith('/apps/tannery_dyeing/dyeing_schedule/current')) {
            setRows(currentData);
            setIsLoading(currentLoading);
            setIsCurrent(true)
            setAvatarImage(<LuChefHat color={theme.palette.primary.main}/>)
            setTableLabel('dyeing_schedule_current')
        } else if (location.pathname.startsWith('/apps/tannery_dyeing/dyeing_schedule/drafts')) {
            setRows(draftData);
            setIsDraft(true)
            setIsLoading(draftLoading);
            setShowOnlyWithRecipe(true);
            setAvatarImage(<RiDraftLine color={theme.palette.primary.main}/>)
            setTableLabel('dyeing_schedule_drafts')
        } else {
            setRows([]);
        }
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [location.pathname, currentData, historicalData, draftData]);

    useEffect(() => {
        selectTanneryDyeing(undefined);
        setShowRecipe(false);
    }, [location.pathname]);

    const [filteredData, setFilteredData] = useState<ITanneryDyeingSchedule[]>()
    useEffect(() => {
        if (rows) {
            const filteredData = filterTanneryDyeingList(rows, filterTanneryDyeingId,
                filterOrderId,
                filterPerson,
                filterArticle,
                filterThickness,
                filterColor,
                filterTanneryDyeingDescription,
                showOnlyWithRecipe
            );
            setFilteredData(filteredData);
        }
    }, [filterArticle, filterColor, filterOrderId, filterPerson, filterTanneryDyeingDescription, filterTanneryDyeingId, filterThickness, rows, showOnlyWithRecipe]);

    // const whereAmI = location.pathname.startsWith('/apps/tannery_dyeing/dyeing_schedule/current');
    const whereAmI = location.pathname;
    useEffect(() => {
        const editablePaths = [
            '/apps/tannery_dyeing/dyeing_schedule/current',
            // '/apps/tannery_dyeing/dyeing_schedule/draft',
        ];

        const isPathEditable = editablePaths.some(path => whereAmI.startsWith(path));
        setIsEditable(isPathEditable);
    }, [whereAmI]);


    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const deleteMutation = useApiDeleteTanneryDyeingSchedule(selectedCompany?.company_token, selectedRow?.id);
    const {mutateAsync: apiDelete} = deleteMutation;

    const handleConfirmDelete = async () => {
        if (selectedRow) {
            try {
                await apiDelete({companyToken: selectedCompany?.company_token, pk: selectedRow.id});
                displayMessage('TanneryDyeing deleted successfully');

            } catch (error) {
                const axiosError = error as CustomAxiosError;
                if (axiosError.response && axiosError.response.data && axiosError.response.data.detail) {
                    displayError(axiosError.response.data.detail);
                } else {
                    displayError('An error occurred while deleting the TanneryDyeing');
                }
            }
            selectTanneryDyeing(undefined);
            setDeleteConfirmationOpen(false);
            await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingScheduleCurrent']});
            await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingSchedule']});
            await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingScheduleHistorical']});
            await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingScheduleDrafts']});
        }
    };
    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };


    const [tanneryDyeingSchedulesWithCustomField, setTanneryDyeingSchedulesWithCustomField] = useState<ITanneryDyeingScheduleWithCustomField[] | undefined>();
    useEffect(() => {
        const dataWithCustomField: ITanneryDyeingScheduleWithCustomField[] | undefined = filteredData?.map((row) => ({
            ...row,
            custom_field: customCellRenderer(row, theme),
        }));
        setTanneryDyeingSchedulesWithCustomField(dataWithCustomField);
    }, [filteredData]);


    const columns = useMemo<MRT_ColumnDef<ITanneryDyeingSchedule>[]>(
        () => [
            {
                accessorKey: 'recipe',
                header: t('recipe'),
                enableColumnActions: false,
                enableColumnFilter: false,
                enableColumnOrdering: false,
                enableSorting: false,
                Cell: ({row}) => (
                    <Tooltip
                        placement="top"
                        title={row.original.user_action_required ? t('action required') : ''}
                        sx={{fontWeight: 'bold'}}
                    >
                        <IconButton aria-label="info" onClick={() => handleInfoClick(row.original)}>
                            {row.original.user_action_required ?

                                <LuChefHat
                                    className={'animate-pulse'}
                                    color={row.original.has_recipe ? theme.palette.warning.main : theme.palette.action.disabled}
                                />


                                :
                                <LuChefHat
                                    color={row.original.has_recipe ? theme.palette.primary.main : theme.palette.action.disabled}
                                />
                            }
                        </IconButton>
                    </Tooltip>
                ),
            },
            {
                accessorKey: 'drum',
                header: t('drum'),
                Cell: ({row}) => (
                    <MuiFabButtonBase icon={<GiBarrel/>}
                                      label={row.original.drum.toString()}
                                      font_size={'1.5rem'}
                                      font_weight={'bold'}
                                      onClick={() => handleInfoClick(row.original)}
                    />
                ),

            },
            {
                accessorKey: 'tannery_dyeing_id',
                header: t('tannery_dyeing_id'),

            },
            {
                accessorKey: 'weight',
                header: t('weight'),

            },
            {
                header: t('next_user_action_required_at'),
                accessorFn: (row) => row.next_user_action_required_at ? formatDateTimeToDateTimeString(row.next_user_action_required_at) : t('READY TO START'),
            },

        ],
        [filteredData]
    );


    const table = useMaterialReactTable<ITanneryDyeingSchedule>({
        columns,
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 80,
        },
        data: tanneryDyeingSchedulesWithCustomField ? tanneryDyeingSchedulesWithCustomField : [],
        columnFilterDisplayMode: 'popover',
        globalFilterFn: 'contains',
        enableFullScreenToggle: false,
        enablePagination: true,
        enableFilters: true,
        enableColumnActions: false,
        enableDensityToggle: false,
        enableHiding: false,
        enableGlobalFilter: false,
        muiTableHeadCellProps: getMuiTableHeadCellProps(theme.palette.primary.main),
        muiTableBodyCellProps: getMuiTableBodyCellProps(theme, '1.1rem'),
        muiTablePaperProps: getMuiTablePaperProps,
        initialState: {density: 'compact', pagination: {pageSize: 10, pageIndex: 0}, expanded: true},
        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.backgroundAppGarageColor.main,

        }),
        muiTableBodyRowProps: getMuiTableBodyRowProps(theme),

        enableRowActions: true,
        renderRowActionMenuItems: ({closeMenu, row}) => [
            <MRT_ActionMenuItem
                icon={<Edit color="primary"/>}
                key="edit"
                label={t("edit")}
                onClick={() => {
                    setSelectedRow(row.original);
                    setIsEditFormOpen(true)
                    closeMenu();
                }}
                table={table}
            />,
            <MRT_ActionMenuItem
                icon={<ContentCopyIcon color="primary"/>}
                key="copy"
                label={t("copy_in_new_drum")}
                onClick={() => {
                    setSelectedRow(row.original);
                    setIsCopyFormOpen(true);
                    closeMenu();
                }}
                table={table}
            />,
            <MRT_ActionMenuItem
                icon={<Delete color="warning"/>}
                key="delete"
                label={t("delete")}
                onClick={() => {
                    setSelectedRow(row.original);
                    setDeleteConfirmationOpen(true);
                    closeMenu();
                }}
                disabled={!isEditable}
                table={table}
            />,

        ],
        renderTopToolbarCustomActions: ({table}) => (
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Avatar sx={{
                    backgroundColor: 'white',
                    border: `2px solid ${theme.palette.primary.main}`,
                    marginRight: 1
                }}>
                    <GiBarrel color={theme.palette.primary.main}/>
                </Avatar>
                <Avatar sx={{
                    backgroundColor: 'white',
                    border: `2px solid ${theme.palette.primary.main}`,
                    marginRight: 1
                }}>
                    {avatarImage}
                </Avatar>

                <MuiReadOnlyBoxBody label={''} value={tableLabel}/>

                {isEditable &&
                    <MRTMuiButton
                        icon={<AddIcon style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                        onClick={() => {
                            setSelectedRow(undefined);
                            setIsSelectSourceFormOpen(!isSelectSourceFormOpen);
                        }} label={'add_tannery_dyeing'}/>}


                <MRTTanneryDyeingScheduleFiltersPopOver
                    onToggle={handleToggleFilters}
                    filterTanneryDyeingId={filterTanneryDyeingId}
                    setFilterTanneryDyeingId={setFilterTanneryDyeingId}
                    filterOrderId={filterOrderId}
                    setFilterOrderId={setFilterOrderId}
                    filterPerson={filterPerson}
                    setFilterPerson={setFilterPerson}
                    filterArticle={filterArticle}
                    setFilterArticle={setFilterArticle}
                    filterThickness={filterThickness}
                    setFilterThickness={setFilterThickness}
                    filterColor={filterColor}
                    setFilterColor={setFilterColor}
                    filterTanneryDyeingDescription={filterTanneryDyeingDescription}
                    setFilterTanneryDyeingDescription={setFilterTanneryDyeingDescription}
                />

                {!isCurrent &&
                    <Box sx={{marginLeft: 2}}>
                        <FormControlLabel control={
                            <Switch
                                color="primary"
                                onChange={handleWithRecipeToggle}
                            />
                        } label={!showOnlyWithRecipe ? t('show_all') : t('show_only_with_recipe')}
                                          style={{color: theme.palette.primary.main}}
                        />

                    </Box>
                }

                {toDyeData && toDyeData.length > 0 && !selectedTanneryDyeing && !isToDyeModalOpen && isEditable &&
                    <MRTMuiButton
                        icon={<FaBalanceScale style={{color: theme.palette.warning.main, fontSize: '2rem'}}/>}
                        animated={true}
                        onClick={() => {
                            setIsToDyeModalOpen(true);
                        }} label={'show_orders_ready_to_drum'}/>
                }

                {!isCurrent && availableCompanyApplications?.some(app => app.in_project_application_name === 'tannery_production') &&
                    <MuiInputTextField label="Color filter..."
                                       value={filterColor}
                                       borderColor={theme.palette.filterColor.main}
                                       onFocusBorderColor={theme.palette.filterColor.dark}
                                       onChange={(e) => setFilterColor(e.target.value)}
                                       inputRef={inputRef}/>}


            </Box>
        ),

        enableExpandAll: true,
        displayColumnDefOptions: {
            'mrt-row-expand': {
                header: t('orders'),
            },
        },
        muiDetailPanelProps: () => ({
            sx: (theme) => ({
                backgroundColor: theme.palette.backgroundAppGarageColor.dark
            }),
        }),
        muiExpandButtonProps: ({row, table}) => ({
            onClick: () => table.setExpanded({[row.id]: !row.getIsExpanded()}),
            sx: {
                transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
                transition: 'transform 0.2s',
                color: theme.palette.primary.main,
            },
        }),
        renderDetailPanel: ({row}) =>
            (!isDraft && availableCompanyApplications?.some(app => app.in_project_application_name === 'tannery_production')) ?
                <MuiBorderedBox>
                    <OrderScheduleTanneryDyeingTable orders={row.original.orders_schedule}
                                                     scope={'unlink'}
                                                     tannery_dyeing={row.original}
                    />
                </MuiBorderedBox> : null,
    });


    return <>
        {showRecipe ?
            <TanneryDyeingRecipe tannery_dyeing={selectedRow} onClose={() => setShowRecipe(false)}/>
            :
            <>
                <MaterialReactTable table={table}/>

                <MuiAlertDialog
                    open={isDeleteConfirmationOpen}
                    title="delete_confirmation"
                    content="ask_for_delete"
                    onClose={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                />

                <MuiModal
                    top="50%"
                    left="50%"
                    width={'45vh'}
                    open={isEditFormOpen}
                    onClose={() => setIsEditFormOpen(false)}
                >
                    <TanneryDyeingScheduleForm
                        objToEdit={selectedRow ? selectedRow : null}
                        onClose={() => setIsEditFormOpen(false)}/>
                </MuiModal>

                {currentFetching && <MuiFetchingSpinner text="Fetching current data..."/>}
                {draftFetching && <MuiFetchingSpinner text="Fetching Draft data..."/>}
                {historicalFetching && <MuiFetchingSpinner text="Fetching historical data..."/>}

                {isSelectSourceFormOpen &&
                    <Popover
                        id={'isSelectSourceFormOpen'}
                        open={isSelectSourceFormOpen}
                        onClose={() => setIsSelectSourceFormOpen(false)}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                    >
                        <Box display="flex" flexDirection="column" gap={2} padding={4}
                             sx={{backgroundColor: theme.palette.background.default}}>
                            <MuiFabButtonBase
                                onClick={() => {
                                    setIsEditFormOpen(true);
                                    setIsSelectSourceFormOpen(false);
                                }
                                }
                                label={'add_tannery_dyeing'}
                                icon={<GiBarrel/>}
                            />
                            <MuiFabButtonBase
                                onClick={() => {
                                    setIsSelectSourceFormOpen(false);
                                    navigate('/apps/tannery_dyeing/dyeing_schedule/history')
                                }}
                                label={'add_tannery_dyeing_from_historical'}
                                icon={<GoHistory/>}
                            />
                            <MuiFabButtonBase
                                onClick={() => {
                                    setIsSelectSourceFormOpen(false);
                                    navigate('/apps/tannery_dyeing/dyeing_schedule/drafts')
                                }}
                                label={'add_tannery_dyeing_from_models'}
                                icon={<RiDraftLine/>}
                            />
                        </Box>
                    </Popover>

                }

                <MuiModal
                    top="50%"
                    left="50%"
                    width={'70vh'}
                    open={isToDyeModalOpen}
                    onClose={() => setIsToDyeModalOpen(false)}
                >
                    <OrderScheduleTanneryDyeingTable onClose={() => setIsToDyeModalOpen(false)}/>
                </MuiModal>

                <MuiModal
                    top="50%"
                    left="50%"
                    width={'70vh'}
                    open={isCopyFormOpen}
                    onClose={() => setIsCopyFormOpen(false)}
                >
                    <TanneryDyeingScheduleForm objToEdit={null}
                                               oldTanneryDyeing={selectedRow}
                                               onClose={() => setIsCopyFormOpen(false)}
                    />
                </MuiModal>

            </>}
    </>;
};

export default TanneryDyeingScheduleTable;
