import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Box} from "@mui/material";
import {IDocPayment} from "../../../../../utils/interfaces/my_documents/doc_payment/IDocPayment";
import MuiFabButtonBack from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonSave from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";
import BankSelect from "../fiscal_registry/bank/BankSelect";
import {IBank} from "../../../../../utils/interfaces/my_documents/IBank";


interface Props {
    doc_payments: IDocPayment[];
    onClose: () => void;
}

const RicevuteBancarieCreationForm: React.FC<Props> = ({doc_payments: initialDocPayments, onClose}) => {
    const {t} = useTranslation()

    const [objs, setObjs] = useState<IDocPayment[]>()
    const [bank, setBank] = useState<IBank>()

    const handleBankChange = (obj: IBank | undefined) => {
        setBank(obj);
    };

    const handleSubmit = () => {
        onClose()
    }

    useEffect(() => {
        setObjs(initialDocPayments)
    }, [initialDocPayments]);


    return (
        <Box sx={{padding: 2, marginBottom: 6}}>

            <BankSelect obj={bank} onChange={handleBankChange} className={''}/>

            <Box
                className="flex justify-center gap-x-2"
                sx={{
                    position: 'fixed',   // Keep it fixed on the screen
                    bottom: 10,           // Stick it to the bottom of the screen
                    left: 0,
                    right: 0,            // Full width
                    padding: '16px',     // Add some padding
                }}
            >

                <MuiFabButtonBack onClick={onClose}/>
                <MuiFabButtonSave onClick={() => handleSubmit()} isDisabled={!bank}/>

            </Box>

        </Box>
    );
};

export default RicevuteBancarieCreationForm;