import React from 'react';
import {
    IOrderScheduleDetail
} from '../../../../../utils/interfaces/tannery_production/order_schedule/IOrderScheduleDetail';
import {GiBabyBottle, GiBarrel, GiCow} from "react-icons/gi";
import {FaBalanceScale, FaWarehouse} from "react-icons/fa";
import {MdWorkspaces} from "react-icons/md";
import {PiRuler} from "react-icons/pi";
import {Box} from "@mui/material";
import {IconContext} from "react-icons";


const getIconsForCustomField = (companyOrderSchedule: IOrderScheduleDetail, theme: any) => {
    const icons = [];


    const disabled_color = theme.palette.disabledColor.main


    if (companyOrderSchedule.parent_product) {
        icons.push(<GiBabyBottle color={theme.palette.primary.light} title='CHILDREN ORDER'/>);
    } else {
        icons.push(
            <FaWarehouse
                title='for warehouse'
                color={companyOrderSchedule.my_company_warehouse ? theme.palette.primary.light : 'transparent'}/>,

            <GiCow color={companyOrderSchedule.has_choices ? theme.palette.primary.light : theme.palette.warning.main}
                   title='RAW MATERIAL'/>,

            <FaBalanceScale
                color={companyOrderSchedule.has_dyeings ? theme.palette.primary.light : theme.palette.backgroundAppGarageColor.main}
                title="READY FOR DYEING"
            />,

            <GiBarrel
                color={
                    companyOrderSchedule.has_tannery_dyeings
                        ? companyOrderSchedule.has_open_tannery_dyeings
                            ? theme.palette.secondary.main
                            : theme.palette.primary.light
                        : theme.palette.backgroundAppGarageColor.main
                }
                title='TANNERY DYEING'
            />
            ,
            companyOrderSchedule.has_workings ? (
                <MdWorkspaces
                    color={companyOrderSchedule.has_open_workings ? theme.palette.warning.main : theme.palette.primary.light}
                    title='WORKINGS'/>
            ) : (
                <MdWorkspaces color={disabled_color} title='WORKINGS'/>
            ),
            companyOrderSchedule.has_final_measurement ? (
                <PiRuler
                    color={companyOrderSchedule.has_open_final_measurement ? theme.palette.warning.main : theme.palette.primary.light}
                    title='FINAL MEASUREMENT'
                />
            ) : (
                <PiRuler color={disabled_color} title='FINAL MEASUREMENT'/>
            )
        );
    }

    return icons;
};

export const customCellRenderer = (rowData: IOrderScheduleDetail, theme: any) => {
    const icons = getIconsForCustomField(rowData, theme);
    return (
        <IconContext.Provider
            value={{size: "1.3rem"}}
        >
            <Box display="flex" sx={{gap: 0.5}}>
                {icons.map((icon, index) => (
                    <span key={index}>{icon}</span>
                ))}
            </Box>
        </IconContext.Provider>

    );
};

export default customCellRenderer;
