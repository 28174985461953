import React from 'react';
import {Box} from "@mui/material";
import {IconContext} from "react-icons";
import {IWarehouseProductMovement} from "../../../../../../utils/interfaces/my_warehouse/IWarehouseProductMovement";
import {IoIosHand} from "react-icons/io";
import {useTranslation} from "react-i18next";


const useMyWarehouseProductCustomCellRenderer = (row: IWarehouseProductMovement, theme: any) => {
    const {t} = useTranslation()


    const getIconsForCustomField = (row: IWarehouseProductMovement, theme: any) => {
        const icons = [];


        if (row.is_manual) {
            icons.push(
                // <MRTMuiButton
                //     small={true}
                //     icon={<BackHandIcon color={theme.palette.secondary.main}/>}
                //     onClick={() => {
                //     }}
                //     label={''}/>,
                <IoIosHand
                    color={theme.palette.primary.main}
                    title={t('manual movement')}/>,
            )
        }


        return icons;
    };

    const renderIcons = () => {
        const icons = getIconsForCustomField(row, theme);
        return (
            <IconContext.Provider value={{size: "1.3rem"}}>
                <Box display="flex" sx={{gap: 0.5}}>
                    {icons.map((icon, index) => (
                        <span key={index}>{icon}</span>
                    ))}
                </Box>
            </IconContext.Provider>
        );
    };

    return {renderIcons};
};

export default useMyWarehouseProductCustomCellRenderer;