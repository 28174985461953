import React, {useEffect, useMemo, useState} from 'react';
import {useToast} from "../../../../../utils/contexts/toast-context";
import {useAuth} from "../../../../../utils/contexts/auth-context";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {useQueryClient} from "@tanstack/react-query";
import useApiDeleteLwgSite from "./api-hooks/useApiDeleteLwgSite";
import {ILwgSite} from "../../../../../utils/interfaces/tannery_raw_material/ILwgSite";
import {CustomAxiosError} from "../../../../../logics/api_config";
import useApiGetLwgSites from "./api-hooks/useApiGetLwgSites";
import {
    MaterialReactTable,
    MRT_ActionMenuItem,
    MRT_ColumnDef,
    MRT_Row,
    useMaterialReactTable
} from "material-react-table";
import {convertToCsv} from "../../../../../logics/csv_converter";
import {formatDateTimeToDateString} from "../../../../../logics/helpers";
import {
    getMuiTableBodyCellProps,
    getMuiTableBodyRowProps,
    getMuiTableHeadCellProps,
    getMuiTablePaperProps
} from "../../../../ui/mui/mui_react_table/MuiReactTableStyles";
import {Delete, Edit} from "@mui/icons-material";
import {Avatar, Box, Button, Popover} from "@mui/material";
import MuiReadOnlyBoxBody from "../../../../ui/mui/mui-display-data/MuiReadOnlyBoxBody";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {CSVLink} from "react-csv";
import MuiAlertDialog from "../../../../ui/mui/mui_modal/MuiAlertDialog";
import LwgSitesForm from "./LwgSitesForm";
import customCellRenderer from "./customCellRenderer";
import AddIcon from "@mui/icons-material/Add";
import MRTMuiButton from "../../../../ui/mui/mui_react_table/MRTMuiButton";
import MuiFetchingSpinner from "../../../../ui/mui/MuiFetchingSpinner";
import Loading from "../../../../ui/Loading";
import {SiTestcafe} from "react-icons/si";


interface ILwgSiteWithCustomField extends ILwgSite {
    custom_field: JSX.Element;
}


const LwgSiteTable = () => {
    const {selectedCompany} = useAuth();
    const {displayError, displayMessage} = useToast()
    const theme = useTheme();
    const {t} = useTranslation()
    const queryClient = useQueryClient();

    const [selectedRow, setSelectedRow] = useState<ILwgSite | undefined>()
    const [rows, setRows] = useState<ILwgSite[] | undefined>()

    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const deleteMutation = useApiDeleteLwgSite(selectedCompany?.company_token, selectedRow?.id);
    const {mutateAsync: apiDelete} = deleteMutation;

    const handleConfirmDelete = async () => {
        try {
            await apiDelete({companyToken: selectedCompany?.company_token, lwg_site_id: selectedRow?.id});
            displayMessage(t('delete_successfully'));

        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data && axiosError.response.data.detail) {
                displayError(axiosError.response.data.detail);
            } else {
                displayError(t('delete_failure'));
            }
        }
        setDeleteConfirmationOpen(false);
        await queryClient.invalidateQueries({queryKey: ['getCompanyLwgSites']});
    };
    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };


    const {
        data,
        error,
        isFetching,
        isLoading,
    } = useApiGetLwgSites(selectedCompany?.company_token);
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }


    useEffect(() => {
        if (data) {
            setRows(data);
            handleSetCsvData(data);
        }
    }, [data]);

    const [lwgSitesWithCustomField, setLwgSitesWithCustomField] = useState<ILwgSiteWithCustomField[] | undefined>();
    useEffect(() => {
        const dataWithCustomField: ILwgSiteWithCustomField[] | undefined = rows?.map((row) => ({
            ...row,
            custom_field: customCellRenderer(row, theme, t),
        }));
        setLwgSitesWithCustomField(dataWithCustomField);
    }, [rows]);


    const keysToInclude = [
        "lwg_site_name",
        "lwg_site_address",
        "lwg_site_city",
        "lwg_site_state",
        "coefficient",
        "urn",
        "type",
        "audit_category",
        "audit_expiry_date",
    ];

    const customLabels = [
        t("lwg_site_name"),
        t("lwg_site_address"),
        t("lwg_site_city"),
        t("lwg_site_state"),
        t("coefficient"),
        t("urn"),
        t("type"),
        t("audit_category"),
        t("audit_expiry_date"),
    ];

    const handleExportRows = (rows: MRT_Row<ILwgSite>[]) => {
        const rowData = rows.map((row) => row.original);
        handleSetCsvData(rowData);

    };

    const [csvData, setCsvData] = useState<string | undefined>();
    const handleSetCsvData = (jsonData: ILwgSite[]) => {
        const csvData: string = convertToCsv(jsonData, keysToInclude, customLabels);
        setCsvData(csvData);
    };


    const columns = useMemo<MRT_ColumnDef<ILwgSite>[]>(
        () => [
            {
                accessorKey: 'custom_field',
                header: '',
                enableColumnActions: false,
                enableColumnFilter: false,
                enableColumnOrdering: false,
                enableSorting: false,
            },
            {
                accessorKey: 'lwg_site_name',
                header: t('lwg_site_name'),
                filterFn: 'contains',

            },
            {
                accessorKey: 'lwg_site_address',
                header: t('lwg_site_address'),
                filterFn: 'contains',

            },
            {
                accessorKey: 'lwg_site_city',
                header: t('lwg_site_city'),
                filterFn: 'contains',

            },
            {
                accessorKey: 'lwg_site_state',
                header: t('lwg_site_state'),
                filterFn: 'contains',
            },
            {
                accessorKey: 'coefficient',
                header: t('coefficient'),
                filterFn: 'contains',
            },
            {
                accessorKey: 'urn',
                header: t('urn'),
                filterFn: 'contains',
            },
            {
                accessorKey: 'type',
                header: t('type'),
                filterFn: 'contains',
            },
            {
                accessorKey: 'audit_category',
                header: t('audit_category'),
                filterFn: 'contains',
            },
            {
                accessorKey: 'audit_expiry_date',
                header: t('audit_expiry_date'),
                enableColumnFilter: false,
                accessorFn: (row) => (row.audit_expiry_date && formatDateTimeToDateString(row.audit_expiry_date)),
            },


        ],
        [rows]
    );


    const table = useMaterialReactTable<ILwgSite>({
        columns,
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 80,
        },
        data: lwgSitesWithCustomField ? lwgSitesWithCustomField : [],
        columnFilterDisplayMode: 'popover',
        globalFilterFn: 'contains',
        enableFullScreenToggle: false,
        enableFilters: true,
        enableDensityToggle: true,
        muiTableHeadCellProps: getMuiTableHeadCellProps(theme.palette.primary.main),
        muiTableBodyCellProps: getMuiTableBodyCellProps(theme),
        muiTablePaperProps: getMuiTablePaperProps,
        enablePagination: true,
        initialState: {
            density: 'compact',
            pagination: {pageSize: 25, pageIndex: 0},
        },
        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.backgroundAppGarageColor.main,

        }),
        muiTableBodyRowProps: getMuiTableBodyRowProps(theme),

        enableRowActions: true,
        renderRowActionMenuItems: ({closeMenu, row}) => [
            <MRT_ActionMenuItem
                icon={<Edit color="primary"/>}
                key="edit"
                label={t("edit")}
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setSelectedRow(row.original);
                    setIsEditFormOpen(true)
                }}
                table={table}
            />,
            <MRT_ActionMenuItem
                icon={<Delete color="warning"/>}
                key="delete"
                label={t("delete")}
                onClick={() => {
                    setSelectedRow(row.original);
                    setDeleteConfirmationOpen(true);
                    closeMenu();
                }}
                table={table}
            />,
        ],
        renderTopToolbarCustomActions: ({table}) => (
            <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                <>
                    <Avatar sx={{
                        backgroundColor: 'white',
                        border: `2px solid ${theme.palette.primary.main}`,
                        marginRight: 1
                    }}>
                        <SiTestcafe color={theme.palette.primary.main}/>
                    </Avatar>
                    <MuiReadOnlyBoxBody label={''} value={'lwg_sites'}/>
                    <MRTMuiButton
                        icon={<AddIcon style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                        onClick={() => {
                            setSelectedRow(undefined);
                            setIsEditFormOpen(true)
                        }} label={'add_lwg_site'}/>
                    {csvData &&
                        <Button
                            disabled={table.getPrePaginationRowModel().rows.length === 0}
                            onClick={() =>
                                handleExportRows(table.getPrePaginationRowModel().rows)
                            }
                            startIcon={<FileDownloadIcon/>}
                        >

                            <CSVLink data={csvData} filename={'all_rows.csv'}>
                                {t('export_to_csv')}
                            </CSVLink>
                        </Button>}
                </>
            </Box>
        ),
    });


    return <>
        {isLoading ? <Loading/> :
            <>
                <MaterialReactTable table={table}/>

                <MuiAlertDialog
                    open={isDeleteConfirmationOpen}
                    title="delete_confirmation"
                    content="ask_for_delete"
                    onClose={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                />

                <Popover
                    id={'edit_popover'}
                    open={isEditFormOpen}
                    anchorEl={anchorEl}
                    onClose={() => setIsEditFormOpen(false)}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                >
                    <LwgSitesForm lwgSiteToEdit={selectedRow ? selectedRow : null}
                                  onClose={() => setIsEditFormOpen(false)}/>
                </Popover>

                {isFetching &&
                    <MuiFetchingSpinner text="Fetching data..."/>
                }
            </>}
    </>;
};

export default LwgSiteTable;
