import React, {useState} from 'react';
import {Box} from "@mui/material";
import {IconContext} from "react-icons";

import {useTranslation} from "react-i18next";
import {IWasteCode} from "../../../../../utils/interfaces/special_wasty/waste_code/IWasteCode";
import MRTMuiButton from "../../../../ui/mui/mui_react_table/MRTMuiButton";
import {IoMdOpen} from "react-icons/io";
import {HiOutlineArrowsRightLeft} from "react-icons/hi2";
import {IoDocumentsSharp} from "react-icons/io5";
import MuiModal from "../../../../ui/mui/mui_modal/MuiModal";
import CurrentlyInUseWasteDocCard from "../waste_doc/CurrentlyInUseWasteDocCard";
import {useAppGarage} from "../../../../../utils/contexts/appGarage-context";
import {useSpecialWasty} from "../../../../../utils/contexts/special_wasty/specialWasty-context";


const useCustomCellRenderer = (
    row: IWasteCode,
    theme: any,
    onOpenLoadsTable: () => void,
    onOpenMovementsTable: () => void
) => {
    const {t} = useTranslation()
    const {selectedMyCompanySite} = useAppGarage()
    const {selectWasteCode} = useSpecialWasty()
    const [isCurrentInUserWasteCodeCardIsOpen, setIsCurrentInUserWasteCodeCardIsOpen] = useState(false)


    const getIconsForCustomField = (row: IWasteCode, theme: any) => {
        const icons = [];


        icons.push(
            <MRTMuiButton
                small={true}
                key={'IoMdOpen'}
                icon={<IoMdOpen
                    color={row.open_loads.show_alert ? theme.palette.warning.main : row.open_loads.loads.length > 0 ? theme.palette.disabledColor : theme.palette.disabledColor.main}/>}
                onClick={row.open_loads.loads.length > 0 ? onOpenLoadsTable : () => {
                }}
                animated={row.open_loads.show_alert}
                label={row.open_loads.show_alert ? 'open loads needs unload' : ''}
            />,
            <MRTMuiButton
                small={true}
                key={'HiOutlineArrowsRightLeft'}
                icon={<HiOutlineArrowsRightLeft
                    color={theme.palette.primary.main}/>}
                onClick={onOpenMovementsTable}
                label='open movements'
            />
        );

        if (row.currently_in_use_docs && row.currently_in_use_docs.docs.length > 0) {
            icons.push(
                <MRTMuiButton
                    small={true}
                    key={'IoDocumentsSharp'}
                    icon={<IoDocumentsSharp
                        color={row.currently_in_use_docs.show_alert ? theme.palette.warning.main : theme.palette.primary.main}/>}
                    onClick={() => {
                        setIsCurrentInUserWasteCodeCardIsOpen(true)
                    }}
                    animated={row.currently_in_use_docs.show_alert}
                    label={'currently in use docs'}/>
            )
        }


        return icons;
    };

    const renderIcons = () => {
        const icons = getIconsForCustomField(row, theme);
        return (
            <IconContext.Provider value={{size: "1.3rem"}}>
                <Box display="flex" flexDirection="row" sx={{gap: 0.5}}>
                    {icons}
                </Box>
                <MuiModal
                    top="50%"
                    left="50%"
                    width={'80vh'}
                    open={isCurrentInUserWasteCodeCardIsOpen}
                    onClose={() => setIsCurrentInUserWasteCodeCardIsOpen(false)}
                >
                    <CurrentlyInUseWasteDocCard
                        waste_code={row}
                        my_company_site={selectedMyCompanySite}
                    />

                </MuiModal>
            </IconContext.Provider>


        );
    };

    return {renderIcons};
};

export default useCustomCellRenderer;