import React from 'react';
import MaxWidthDiv from "../../../../ui/MaxWitdthDiv";
import WastyRecipientTable from "./WastyRecipientTable";
import SpecialWastyScreen from "../SpecialWastyScreen";


const WastyRecipientScreen = () => {


    return (
        <>
            <SpecialWastyScreen>
                <MaxWidthDiv maxWidth='1024px'>
                    <WastyRecipientTable/>
                </MaxWidthDiv>
            </SpecialWastyScreen>

        </>
    );
};

export default WastyRecipientScreen;
