import React from 'react';
import {useAppGarage} from "../../../../../utils/contexts/appGarage-context";
import useWasteApiGetSiteMovements from "./api-hooks/useWasteApiGetSiteMovements";
import WasteMovementsTable from "./WasteMovementsTable";
import SpecialWastyScreen from "../SpecialWastyScreen";
import {Box} from "@mui/material";


const WasteMovementsScreen = () => {
    const {selectedMyCompanySite} = useAppGarage()


    const {
        data,

    } = useWasteApiGetSiteMovements(selectedMyCompanySite?.id);


    return (
        <>
            <SpecialWastyScreen>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    marginTop: 0,
                    paddingLeft: 2,
                    paddingRight: 2,
                    position: 'relative',
                }}>
                    <WasteMovementsTable my_company_site={selectedMyCompanySite} waste_movements={data}/>
                </Box>

            </SpecialWastyScreen>
        </>
    );
};

export default WasteMovementsScreen;
