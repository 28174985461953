import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {IFiscalRegistry} from "../../../../../../utils/interfaces/my_documents/fiscal_registry/IFiscalRegistry";
import {useAuth} from "../../../../../../utils/contexts/auth-context";


const useApiGetFiscalRegistry = (fiscal_registry_id: number | undefined
): UseQueryResult<IFiscalRegistry, CustomAxiosError> => {
    const {selectedCompany} = useAuth();

    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined, people_id: number | undefined): Promise<IFiscalRegistry> {
        try {
            const response = await get(`/api/company/${company_token}/my_documents/fiscal_registry/${fiscal_registry_id}`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetFiscalRegistry", fiscal_registry_id],
        enabled: !!fiscal_registry_id,
        retry: false,
        queryFn: () => doGet(selectedCompany?.company_token, fiscal_registry_id),
    })
};

export default useApiGetFiscalRegistry