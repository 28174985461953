// MuiFabButtonBack.tsx
import React from 'react';
import Fab from '@mui/material/Fab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Zoom from '@mui/material/Zoom';
import {useTranslation} from "react-i18next";
import {keyframes} from '@mui/system';

interface MuiFabButtonBackProps {
    onClick: () => void;
    label?: string;
    isPulsing?: boolean;
    color?: 'primary' | 'secondary' | 'warning';
}

const pulse = keyframes`
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.35);
    }
    100% {
        transform: scale(1);
    }
`;

const MuiFabButtonBack: React.FC<MuiFabButtonBackProps> = ({
                                                               onClick,
                                                               label,
                                                               isPulsing = false,
                                                               color = 'secondary'
                                                           }) => {
    const {t} = useTranslation();

    return (
        <Zoom in={true} unmountOnExit>
            <Fab
                color={color} // Set color from props
                aria-label="back"
                onClick={onClick}
                variant="extended"
                sx={{
                    animation: isPulsing ? `${pulse} 1.5s infinite` : 'none', // Apply pulse animation conditionally
                }}
            >
                <ArrowBackIcon style={{color: 'white'}}/>
                {label && (
                    <span style={{textTransform: 'uppercase', color: 'white', marginLeft: '8px'}}>
                        {t(label)}
                    </span>
                )}
            </Fab>
        </Zoom>
    );
};

export default MuiFabButtonBack;
