import React, {useState} from 'react';
import {Checkbox as MuiCheckbox, FormControlLabel} from '@mui/material';
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";

interface CheckboxProps {
    label: string;
    checked: boolean;
    onChange: (checked: boolean) => void;
    isFilter?: boolean
}

const MuiCheckboxComponent: React.FC<CheckboxProps> = ({label, checked, onChange, isFilter = false}) => {
    const [isFocused, setIsFocused] = useState(false);
    const theme = useTheme()
    const checked_color = isFilter ? theme.palette.filterColor.main : theme.palette.primary.main
    const color = isFilter ? theme.palette.filterColor.light : theme.palette.primary.light
    const {t} = useTranslation()

    return (
        <div className="flex flex-row mb-2 items-center">
            <FormControlLabel
                control={
                    <MuiCheckbox
                        sx={{
                            color: checked ? checked_color : color,
                            '&$checked': {
                                color: checked_color,
                            },
                            '&:hover': {
                                boxShadow: isFocused ? '0 0 0 2px ' + theme.palette.tableHoverColor.main : 'none',
                            },
                        }}
                        checked={checked}
                        onChange={(e) => onChange(e.target.checked)}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                        color="default"
                    />
                }
                label={<label className="ml-2 cursor-pointer text-gray-600">{t(label)}</label>}
                sx={{
                    '& .MuiFormControlLabel-label': {
                        cursor: 'pointer',
                    },
                }}
            />
        </div>
    );
};

export default MuiCheckboxComponent;
