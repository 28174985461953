import React, {useEffect, useState} from 'react';
import {Box} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import LabelledMuiTypography from "../../../../ui/mui/mui-display-data/LabelledMuiTypography";
import {IRentriCertificate} from "../../../../../utils/interfaces/special_wasty/rentri_certificate/IRentriCertificate";
import {formatDateTimeToDateString} from "../../../../../logics/helpers";
import {FaSkull} from "react-icons/fa";
import {useTranslation} from "react-i18next";


interface Props {
    obj: IRentriCertificate | undefined,
}

const RentriCertificateCard = ({
                                   obj: initialObj,
                               }: Props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const [obj, setObj] = useState<IRentriCertificate | undefined>(initialObj)

    useEffect(() => {
        if (initialObj) {
            setObj(initialObj)
        }
    }, [initialObj]);


    return (
        <>
            <Box sx={{
                display: 'flex-1',
                flexDirection: 'column',
                alignItems: 'flex-end',
                border: '1px solid lightgrey',
                borderRadius: '4px',
                padding: '10px',
                backgroundColor: theme.palette.backgroundAppGarageColor.gray_50
            }}>
                <LabelledMuiTypography label={'Proprietario'}
                                       value={obj?.certificate_info.issuer_name}
                                       upperCaseValue={true}/>
                <LabelledMuiTypography label={'Partita IVA Proprietario'}
                                       value={obj?.certificate_info.issuer}
                                       upperCaseValue={true}/>
                <LabelledMuiTypography label={'Num. iscr. operatore'}
                                       value={obj?.num_iscr_operatore?.toString() ?? 'N/A'}
                                       upperCaseValue={true}/>
                <LabelledMuiTypography label={'Data Emissione'}
                                       value={formatDateTimeToDateString(obj?.certificate_info.not_before)}
                                       upperCaseValue={true}/>
                <LabelledMuiTypography label={'Data Scadenza'}
                                       value={formatDateTimeToDateString(obj?.certificate_info.not_after)}
                                       upperCaseValue={true}/>
                <Box sx={{display: 'flex', flexDirection: 'row', gap: 0}}>
                    <LabelledMuiTypography label={t('remaining days')}
                                           value={obj?.certificate_info.remaining_days?.toString() ?? 'N/A'}/>
                    {obj?.certificate_info.remaining_days && obj?.certificate_info.remaining_days < 60 &&
                        <FaSkull color={theme.palette.warning.main} className={'animate-pulse'}/>}
                </Box>


            </Box>
        </>

    );
}

export default RentriCertificateCard;