import useApiRentriAxiosInstance from "./useApiRentriAxiosInstance";
import useApiGetRentriAgidJwtSignatureToken from "./useApiGetRentriAgidJwtSignatureToken";
import {AxiosError, AxiosResponse} from "axios";

export function useApiRentriCalls() {
    const {fetchAgidJwtToken} = useApiGetRentriAgidJwtSignatureToken();
    const {interceptedAxiosInstance} = useApiRentriAxiosInstance({
        getAgidJwtToken: fetchAgidJwtToken
    });

    const get = async (url: string, params?: any) => {
        return await interceptedAxiosInstance.get(url, {params});
    };

    const post = async (url: string, params?: any) => {
        return await interceptedAxiosInstance.post(url, params);
    };

    const postEncoded = async (url: string, params?: any) => {
        try {
            return await interceptedAxiosInstance.post(url, new URLSearchParams({...params}));
        } catch (error) {
            throw error;
        }
    };

    const put = async (url: string, data?: any) => {
        return await interceptedAxiosInstance.put(url, data);
    };

    const DELETE = async (url: string, params?: any) => {
        return await interceptedAxiosInstance.delete(url, {params});
    };

    return {
        get,
        post,
        postEncoded,
        put,
        DELETE,
    };
}

export interface RentriAxiosError extends AxiosError<unknown, AxiosResponse<unknown>> {
    code: string,
    message: string,
    response: {
        data: {
            model_state: {
                generic: string[],
            };
        };
        status: number;
        statusText: string;
        headers: any;
        config: any;
    };
}