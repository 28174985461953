import React, {useEffect, useState} from 'react';
import AnimatedRow from "../../../../../ui/AnimatedRow";
import AgDiv from "../../../../../ui/divs/AgDiv";
import {IRecord} from "../../../../../../utils/interfaces/lwg_dashboard/reports/workings/IRecord";
import RecordRow from "./RecordRow";

interface Props {
    scrollableHeight?: number;
    records: IRecord[] | undefined,

}

const RecordList = ({scrollableHeight = 100, records: initialObjs}: Props) => {

    const [objs, setObjs] = useState(initialObjs)
    const [filteredData, setFilteredData] = useState<IRecord[] | undefined>(objs);


    useEffect(() => {
        if (objs) {
            setFilteredData(objs);
        }
    }, [objs]);

    useEffect(() => {
        setObjs(initialObjs)
    }, [initialObjs]);


    const mapped_data =
        filteredData?.map((obj: IRecord, index: any) =>
            <AnimatedRow key={obj.id} index={index / 10}>
                <RecordRow record={obj}/>
            </AnimatedRow>
        )


    return (
        <>
            <div className="p-0">
                <div
                    // style={{
                    //     height: `calc(${scrollableHeight}vh - 500px)`,
                    //     overflowY: 'auto',
                    //     overflow: 'auto',
                    //     whiteSpace: 'nowrap',
                    //     minWidth: '100%',
                    //     scrollbarWidth: 'auto',
                    //     scrollbarColor: '#02959c transparent',
                    // }}
                    className='scroll-content no-scrollbar flex flex-row font-bold mt-2'
                >
                    <AgDiv value='returning_date'
                           minWidth="130px" maxWidth="130px"
                           icons={[]}
                           className={'AgDivHeader'}
                    />
                    <AgDiv value='order_id'
                           minWidth="200px" maxWidth="200px"
                           icons={[]}
                           className={'AgDivHeader'}
                    />
                    <AgDiv value='article'
                           minWidth="200px" maxWidth="200px"
                           icons={[]}
                           className={'AgDivHeader'}
                    />
                    <AgDiv value='color'
                           minWidth="200px" maxWidth="200px"
                           icons={[]}
                           className={'AgDivHeader'}
                    />
                    <AgDiv value='um'
                           minWidth="90px" maxWidth="90px"
                           icons={[]}
                           className={'AgDivHeader'}
                    />
                    <AgDiv value='quan'
                           minWidth="90px" maxWidth="90px"
                           icons={[]}
                           className={'AgDivHeader'}
                    />
                    <AgDiv value='avg_sqm'
                           minWidth="120px" maxWidth="120px"
                           icons={[]}
                           className={'AgDivHeader'}
                    />
                    <AgDiv value='x'
                           minWidth="60px" maxWidth="60px"
                           icons={[]}
                           className={'AgDivHeader'}
                    />
                    <AgDiv value='worked_sqm'
                           minWidth="150px" maxWidth="150px"
                           icons={[]}
                           className={'AgDivHeader'}
                    />
                    <AgDiv value='working'
                           minWidth="240px" maxWidth="240px"
                           icons={[]}
                           className={'AgDivHeader'}
                    />

                </div>
                <div className={'mb-4'}>
                    {mapped_data}
                </div>

            </div>
        </>

    );
};

export default RecordList;