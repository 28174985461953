import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomAxiosError} from "../../../../../../../../logics/api_config";
import {useApiRentriCalls} from "../../../api-hooks/api_rentri_config";
import {
    IRentriNazione
} from "../../../../../../../../utils/interfaces/special_wasty/rentri/codifiche/territoriali/IRentriNazione";


const useApiGetRentriNazioni = (): UseQueryResult<IRentriNazione[], CustomAxiosError> => {
    const {get} = useApiRentriCalls()

    async function doGet(): Promise<IRentriNazione[]> {
        try {
            const response = await get(`/codifiche/v1.0/lookup/nazioni`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetRentriNazioni"],
        queryFn: () => doGet(),
    })
};

export default useApiGetRentriNazioni