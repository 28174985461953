import React, {useEffect, useState} from 'react';
import {useToast} from "../../../../../../utils/contexts/toast-context";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import {useTanneryProduction} from "../../../../../../utils/contexts/tannery_production/tannery_production-context";
import {
    IFinalMeasurement
} from "../../../../../../utils/interfaces/tannery_production/order_schedule/IFinalMeasurement";
import useApiUpdateFinalMeasurement from "./api-hooks/useApiUpdateFinalMeasurement";
import useApiCreateFinalMeasurement from "./api-hooks/useApiCreateFinalMeasurement";
import useApiDeleteFinalMeasurement from "./api-hooks/useApiDeleteFinalMeasurement";
import {useAppGarage} from "../../../../../../utils/contexts/appGarage-context";
import {Box} from "@mui/material";
import MuiFabButtonBack from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonSaveAs from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";
import MuiFabButtonDelete from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonDelete";
import MuiInputTextField from "../../../../../ui/mui/mui-input/MuiInputTextField";
import useApiGetFinalMeasurement from "./api-hooks/useApiGetFinalMeasurement";
import MuiAlertDialog from "../../../../../ui/mui/mui_modal/MuiAlertDialog";


interface Props {
    measureToEdit: IFinalMeasurement | undefined;
    onClose: () => void;
    scope?: 'current' | 'historical'
}

const WorkingsForm: React.FC<Props> = ({measureToEdit, onClose, scope}) => {
    const {displayError, displayMessage} = useToast();
    const {selectedCompany} = useAuth();
    const queryClient = useQueryClient();
    const {availableCompanyUserApplications} = useAppGarage()
    const {selectedCompanyOrderSchedule} = useTanneryProduction()

    const [measured_at, setMeasuredAt] = useState<string>();
    const [description, setDescription] = useState<string>();
    const [sqm, setSqm] = useState<number>(0);
    const [pcs, setPcs] = useState<number>(0);
    const [orderId, setOrderId] = useState<number>();
    const [isConfirmationOpen, setConfirmationOpen] = useState(false);

    const {
        data: detailedObj,
    } = useApiGetFinalMeasurement(measureToEdit?.id);

    const mutationHook = measureToEdit ? useApiUpdateFinalMeasurement : useApiCreateFinalMeasurement;
    const mutation = mutationHook(selectedCompany?.company_token);

    const deleteMutation = useApiDeleteFinalMeasurement(selectedCompany?.company_token, measureToEdit?.id);
    const {mutateAsync: apiDelete} = deleteMutation;

    useEffect(() => {
        setOrderId(selectedCompanyOrderSchedule?.id)
        if (detailedObj) {
            setMeasuredAt(detailedObj.measured_at)
            setDescription(detailedObj.description)
            setSqm(detailedObj.sqm)
            setPcs(detailedObj.pcs)
            setOrderId(selectedCompanyOrderSchedule?.id)
        }
    }, [detailedObj]);

    const handleCancelDelete = () => {
        setConfirmationOpen(false);
    };

    const handleConfirmDelete = async () => {
        if (measureToEdit) {
            try {

                await apiDelete({companyToken: selectedCompany?.company_token, pk: measureToEdit.id});

                displayMessage('Measure deleted successfully');

            } catch (error) {
                const axiosError = error as CustomAxiosError;
                if (axiosError.response && axiosError.response.data && axiosError.response.data.detail) {
                    displayError(axiosError.response.data.detail);
                } else {
                    displayError('An error occurred while deleting the Measure');
                }
            }
            setConfirmationOpen(false);
            onClose();
            await queryClient.invalidateQueries({queryKey: ['getCompanyOrderScheduleByColor']});
            await queryClient.invalidateQueries({queryKey: ['getCompanyOrderSchedule']});
            availableCompanyUserApplications?.some(app => app.in_project_application_name === 'lwg_dashboard') && await queryClient.invalidateQueries({queryKey: ['useApiGetReportProduction']});
            availableCompanyUserApplications?.some(app => app.in_project_application_name === 'lwg_dashboard') && await queryClient.invalidateQueries({queryKey: ['useApiGetPendingOrders']});
            availableCompanyUserApplications?.some(app => app.in_project_application_name === 'lwg_dashboard') && await queryClient.invalidateQueries({queryKey: ['useApiGetReportWorkings']});
            availableCompanyUserApplications?.some(app => app.in_project_application_name === 'lwg_dashboard') && await queryClient.invalidateQueries({queryKey: ['getLwgVerificationPeriodOrders']});
        }

    };


    const handleSubmit = async () => {
        try {
            const data = {
                description: description,
                measured_at: measured_at || null,
                sqm: sqm,
                pcs: pcs,
                order_id: orderId
            };

            if (measureToEdit !== undefined && measureToEdit !== null) {
                await mutation.mutateAsync({
                    pk: measureToEdit.id,
                    data: data,
                });
                displayMessage('Measure updated successfully');
            } else {
                await mutation.mutateAsync(
                    data
                );
                displayMessage('Measure added successfully');
            }

            onClose();
            await queryClient.invalidateQueries({queryKey: ['getCompanyOrderScheduleByColor']});
            await queryClient.invalidateQueries({queryKey: ['getCompanyOrderSchedule']});
            availableCompanyUserApplications?.some(app => app.in_project_application_name === 'lwg_dashboard') && await queryClient.invalidateQueries({queryKey: ['useApiGetReportProduction']});
            availableCompanyUserApplications?.some(app => app.in_project_application_name === 'lwg_dashboard') && await queryClient.invalidateQueries({queryKey: ['useApiGetPendingOrders']});
            availableCompanyUserApplications?.some(app => app.in_project_application_name === 'lwg_dashboard') && await queryClient.invalidateQueries({queryKey: ['useApiGetReportWorkings']});
            availableCompanyUserApplications?.some(app => app.in_project_application_name === 'lwg_dashboard') && await queryClient.invalidateQueries({queryKey: ['getLwgVerificationPeriodOrders']});
        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data && axiosError.response.data.detail) {
                displayError(axiosError.response.data.detail);
            } else {
                console.log(axiosError.response)
                displayError('An error occurred while saving the Measure');
            }
        }
    };

    return (
        <Box>
            <form>
                <MuiInputTextField
                    label="measured_at"
                    type="date"
                    value={measured_at}
                    onChange={setMeasuredAt}
                    required={true}
                />

                <MuiInputTextField
                    label="sqm"
                    type="number"
                    value={sqm}
                    onChange={(e) => setSqm(parseFloat(e.target.value))}
                    required={true}
                    // disabled={scope !== 'current'}
                />

                <MuiInputTextField
                    label="pcs"
                    type="number"
                    value={pcs}
                    onChange={(e) => setPcs(parseInt(e.target.value, 10))}
                    required={true}
                    // disabled={scope !== 'current'}
                />

                <MuiInputTextField
                    label="description"
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required={false}
                />


                <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                    <MuiFabButtonBack onClick={onClose}/>
                    {measureToEdit &&
                        <MuiFabButtonDelete
                            onClick={() => {
                                setConfirmationOpen(true)
                            }}
                        />},
                    {measureToEdit ?
                        <MuiFabButtonSaveAs onClick={handleSubmit}/>
                        :
                        <MuiFabButtonSave onClick={handleSubmit}/>}
                </Box>
            </form>
            <MuiAlertDialog
                open={isConfirmationOpen}
                title="delete_confirmation"
                content="ask_for_delete"
                onClose={handleCancelDelete}
                onConfirm={handleConfirmDelete}
            />
        </Box>
    );
};

export default WorkingsForm;
