import React, {useState} from 'react';
import {Box} from "@mui/material";
import {useAppGarage} from "../../../../utils/contexts/appGarage-context";
import {SpecialWastyProvider} from "../../../../utils/contexts/special_wasty/specialWasty-context";
import {IMyCompanySite} from "../../../../utils/interfaces/my_company/my_company_site/IMyCompanySite";
import MyCompanySiteSelect from "../my_company/my_company_site/MyCompanySiteSelect";
import MaxWidthDiv from "../../../ui/MaxWitdthDiv";
import {FaRecycle} from "react-icons/fa";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {HiOutlineArrowsRightLeft} from "react-icons/hi2";
import {IoMdOpen} from "react-icons/io";
import {TbBuildingFactory, TbFileCertificate} from "react-icons/tb";
import {MdLocalShipping, MdOutlineLocalShipping} from "react-icons/md";
import {useNavigate} from "react-router-dom";
import MuiModal from "../../../ui/mui/mui_modal/MuiModal";
import RentriCertificateForm from "./rentri_certificate/RentriCertificateForm";
import useApiGetRentriCertificate from "./rentri_certificate/api-hooks/useApiGetRentriCertificate";
import MuiCustomSpeedDial from "../../../ui/mui/MuiCustomSpeedDial";
import {useToast} from "../../../../utils/contexts/toast-context";
import MuiFixedMessage from "../../../ui/mui/MuiFixedMessage";

type Props = {
    children?: React.ReactNode,
}

const SpecialWastyScreen = ({children}: Props) => {
    const {selectMyCompanySite, selectedMyCompanySite} = useAppGarage()
    const {displayWarning} = useToast()
    const theme = useTheme();
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [rentriCertificateForm, setRentriCertificateForm] = useState<boolean>(false)

    const handleMyCompanySiteChange = (obj: IMyCompanySite | undefined) => {
        selectMyCompanySite(obj);
    }

    const {data: rentriCertificate} = useApiGetRentriCertificate();


    const actions = [
        {
            icon: <TbFileCertificate/>,
            name: (rentriCertificate && rentriCertificate.certificate_info && rentriCertificate.certificate_info.remaining_days && rentriCertificate.certificate_info.remaining_days < 60)
                ? 'Certificato Rentri in scadenza'
                : 'Certificato Rentri',
            onClick: () => {
                setRentriCertificateForm(true);
            },
            isPulsing: !(rentriCertificate && rentriCertificate.id) || (
                rentriCertificate &&
                rentriCertificate.certificate_info &&
                rentriCertificate.certificate_info.remaining_days !== undefined &&
                rentriCertificate.certificate_info.remaining_days < 60
            ),
        },
        {
            icon: <FaRecycle/>,
            name: 'waste_codes',
            onClick: () => {
                navigate('/apps/special_wasty/waste_code');
            },
            isPulsing: false,
        },
        {
            icon: <HiOutlineArrowsRightLeft/>,
            name: 'Registro Carico/Scarico',
            onClick: () => {
                navigate('/apps/special_wasty/waste_movements');
            },
            isPulsing: false,
        },
        {
            icon: <IoMdOpen/>,
            name: 'open_loads',
            onClick: () => {
                navigate('/apps/special_wasty/waste_open_loads');
            },
            isPulsing: false,
        },
        {
            icon: <TbBuildingFactory/>,
            name: 'wasty_recipient',
            onClick: () => {
                navigate('/apps/special_wasty/wasty_recipient');
            },
            isPulsing: false,
        },
        {
            icon: <MdOutlineLocalShipping/>,
            name: 'wasty_intermediaries',
            onClick: () => {
                navigate('/apps/special_wasty/wasty_intermediary');
            },
            isPulsing: false,
        },
        {
            icon: <MdLocalShipping/>,
            name: 'wasty_carrier',
            onClick: () => {
                navigate('/apps/special_wasty/wasty_carrier');
            },
            isPulsing: false,
        },

    ];


    return (
        <>
            <SpecialWastyProvider>
                <MaxWidthDiv maxWidth='2500px'>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                        marginTop: 2,
                        paddingLeft: 2,
                        paddingRight: 2,
                        position: 'relative',
                    }}>
                        <MyCompanySiteSelect
                            obj={undefined}
                            onChange={handleMyCompanySiteChange}
                            className={''}
                            withDefaultValueIfEmpty
                        />
                        {/*{selectedMyCompanySite && selectedMyCompanySite.num_iscr_sito && rentriCertificate && rentriCertificate.num_iscr_operatore &&*/}
                        {/*    <Box sx={{*/}
                        {/*        width: '100%',*/}
                        {/*        marginTop: 2,*/}
                        {/*    }}>*/}
                        {/*        <RentriRegistroTable*/}
                        {/*            num_iscr_operatore={rentriCertificate.num_iscr_operatore}*/}
                        {/*            num_iscr_sito={selectedMyCompanySite.num_iscr_sito.num_iscr_sito}*/}
                        {/*        />*/}
                        {/*    </Box>*/}

                        {/*}*/}
                    </Box>

                    <Box sx={{height: 320, transform: 'translateZ(0px)', flexGrow: 1}}>
                        {children}
                    </Box>

                    <MuiModal
                        top="50%"
                        left="50%"
                        width={'80vh'}
                        open={rentriCertificateForm}
                        disableBackdropClick={true}
                        onClose={() => setRentriCertificateForm(false)}
                    >

                        <RentriCertificateForm
                            onClose={() => setRentriCertificateForm(false)}
                        />

                    </MuiModal>
                </MaxWidthDiv>

                <MuiCustomSpeedDial actions={actions}
                                    customIcon={<FaRecycle style={{fontSize: 30}}/>}
                />
            </SpecialWastyProvider>
            {!rentriCertificate && <MuiFixedMessage
                text={'Devi caricare il Certificato Rentri se vuoi usare la connessione al RENTRI (usa il menù in alto a destra)'}/>}


        </>
    );
};

export default SpecialWastyScreen;