import React from 'react';
import {Box} from "@mui/material";
import {IconContext} from "react-icons";
import {IWasteDoc} from "../../../../../utils/interfaces/special_wasty/waste_doc/IWasteDoc";
import {FiDownloadCloud} from "react-icons/fi";
import {GrStatusGoodSmall} from "react-icons/gr";
import {useTranslation} from "react-i18next";


const useCustomCellRenderer = (row: IWasteDoc, theme: any, onToggle: (row: IWasteDoc) => void) => {
    const {t} = useTranslation()

    const handleDownloadPdf = (row: IWasteDoc) => {
        if (row.pdf_doc) {
            const anchor = document.createElement('a');
            anchor.href = row.pdf_doc;
            anchor.target = '_blank';
            anchor.download = 'document.pdf';
            anchor.click();
            anchor.remove();
        }
    };


    const getIconsForCustomField = (row: IWasteDoc, theme: any) => {
        const icons = [];


        icons.push(
            <GrStatusGoodSmall
                title={row.currently_in_use ? t('currently in use') : t("not in use")}
                color={row.currently_in_use ? row.show_alert ? theme.palette.warning.main : theme.palette.primary.main : theme.palette.disabledColor.main}
                onClick={() => {
                    onToggle(row)
                }}
                className={row.show_alert && row.currently_in_use ? 'animate-pulse' : ''}
                style={{cursor: 'pointer'}}
            />,

            row.pdf_doc ? <FiDownloadCloud
                    title='PDF DOC INSIDE'
                    color={theme.palette.primary.main} onClick={() => {
                    handleDownloadPdf(row)
                }}
                    style={{cursor: 'pointer'}}
                /> :
                <FiDownloadCloud title='' color={theme.palette.disabledColor.main}/>,
        )


        return icons;
    };

    const renderIcons = () => {
        const icons = getIconsForCustomField(row, theme);
        return (
            <IconContext.Provider value={{size: "1.3rem"}}>
                <Box display="flex" flexDirection="row" sx={{gap: 0.5}}>
                    {icons}
                </Box>
            </IconContext.Provider>
        );
    };

    return {renderIcons};
};

export default useCustomCellRenderer;