import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {useAppGarage} from "../../../../../../utils/contexts/appGarage-context";
import {useSpecialWasty} from "../../../../../../utils/contexts/special_wasty/specialWasty-context";


const useApiDeleteWasteMovement = (pk: number | undefined): UseMutationResult<void, CustomAxiosError, {
    pk?: number
}, unknown> => {
    const {selectedCompany} = useAuth()
    const queryClient = useQueryClient();
    const {selectedMyCompanySite} = useAppGarage()
    const {selectedWasteCode} = useSpecialWasty()

    const {DELETE} = useApiCalls()

    async function doDelete({companyToken, pk}: {
        companyToken?: string,
        pk: number | undefined
    }): Promise<void> {
        try {
            await DELETE(`/api/company/${companyToken}/special_wasty/waste_movement/${pk}?site_id=${selectedMyCompanySite?.id}`);
        } catch (error) {
            throw error;
        }
    }

    return useMutation<void, CustomAxiosError, { pk?: number }>(
        {
            mutationFn: () => doDelete({
                companyToken: selectedCompany?.company_token,
                pk: pk,
            }),
            onSuccess: async () => {
                // await queryClient.invalidateQueries({queryKey: ["useWasteApiGetWasteCodeMovements", selectedMyCompanySite?.id, selectedWasteCode?.id]});
                // await queryClient.invalidateQueries({queryKey: ["useApiGetWasteOpenLoadsByWasteCodeId", selectedMyCompanySite?.id, selectedWasteCode?.id]});
                // await queryClient.invalidateQueries({queryKey: ["useApiGetWasteCodes", selectedMyCompanySite?.id]});
                // await queryClient.invalidateQueries({queryKey: ["useWasteApiGetOpenLoads", selectedMyCompanySite?.id]});
                // await queryClient.invalidateQueries({queryKey: ["useApiGetMyCompanySite", selectedMyCompanySite?.id]});
                // await queryClient.invalidateQueries({queryKey: ["useApiGetMyCompanySites"]});
            },
        })
}


export default useApiDeleteWasteMovement;