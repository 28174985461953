import React, {useEffect, useState} from 'react';
import Loading from "../../../../ui/Loading";
import {useToast} from "../../../../../utils/contexts/toast-context";
import {useTranslation} from "react-i18next";
import {Box, Popover, TextField} from "@mui/material";
import MRTMuiButton from "../../../../ui/mui/mui_react_table/MRTMuiButton";
import {Add, Edit} from "@mui/icons-material";
import MuiAutocomplete from "../../../../ui/mui/mui-input/MuiAutoComplete";
import {useTheme} from "@mui/material/styles";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import MuiModal from "../../../../ui/mui/mui_modal/MuiModal";
import {IMyCompanyWarehouse} from "../../../../../utils/interfaces/my_company/my_company_warehouse/IMyCompanyWarehouse";
import useApiGetMyCompanyWarehouses from "./api-hooks/useApiGetMyCompanyWarehouses";
import useApiGetMyCompanyWarehouse from "./api-hooks/useApiGetMyCompanyWarehouse";
import MyCompanyWarehouseForm from "./MyCompanyWarehouseForm";
import MyCompanyWarehouseTable from "./MyCompanyWarehouseTable";


interface Props {
    obj: IMyCompanyWarehouse | undefined,
    onChange: (obj: IMyCompanyWarehouse | undefined) => void,
    className: string,
    label?: string,
    required?: boolean
    withDefaultValueIfEmpty?: boolean
}

const MyCompanyWarehouseSelect = ({
                                      obj: initialObj,
                                      label,
                                      onChange,
                                      required,
                                      withDefaultValueIfEmpty = false,
                                      ...props
                                  }: Props) => {

    const {displayError} = useToast()
    const theme = useTheme();
    const {t} = useTranslation()

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)
    const [isListTableOpen, setIsListTableOpen] = useState<boolean>(false)


    const [objs, setObjs] = useState<IMyCompanyWarehouse[]>([]);
    const [obj, setObj] = useState<IMyCompanyWarehouse | undefined>(initialObj)


    useEffect(() => {
        setObj(undefined);
        if (initialObj) {
            setObj(initialObj)
        }
    }, [initialObj]);


    const {
        data,
        isLoading,
    } = useApiGetMyCompanyWarehouses();


    useEffect(() => {
        if (data) {
            setObjs(data)
            if (!initialObj && data.length > 0 && withDefaultValueIfEmpty) {
                setObj(data[0]);
                onChange(data[0]);
            }
        }
    }, [data]);


    const {
        data: detailedObj,
    } = useApiGetMyCompanyWarehouse(obj?.id);


    useEffect(() => {
        if (detailedObj) {
            setObj(detailedObj);
        }
    }, [detailedObj]);

    const handleOnChange = (event: React.SyntheticEvent, newValue: IMyCompanyWarehouse | null) => {
        if (newValue) {
            onChange(newValue);
        } else {
            onChange(undefined);
        }
    };

    const handleChange = (obj: IMyCompanyWarehouse | undefined) => {
        setObj(obj);
        onChange(obj)
    }

    useEffect(() => {
        onChange(obj);

    }, [obj]);


    return (
        <>
            {isLoading ? (
                <Loading/>
            ) : (
                <>
                    <Box sx={{alignItems: 'center', width: '100%'}}>
                        <MuiAutocomplete
                            id="warehouse-select"
                            options={objs}
                            getOptionLabel={(option) => option.name}
                            value={detailedObj ? detailedObj : null}
                            onChange={handleOnChange}
                            isOptionEqualToValue={(option, value) => option.id === value?.id && option.name === value?.name}
                            renderInput={(params) => (
                                <TextField
                                    sx={{textTransform: 'capitalize'}}
                                    {...params}
                                    label={label ? t(label) : '' || t('warehouse')}
                                    required={required}
                                />
                            )}
                            borderColor={theme.palette.primary.light}
                            onFocusBorderColor={theme.palette.primary.dark}
                            minWidth={300}
                        >
                            <MRTMuiButton
                                icon={<FormatListBulletedIcon sx={{color: theme.palette.primary.main}}/>}
                                onClick={() => setIsListTableOpen(true)}
                                small={true}
                                label={'show_list'}
                            />
                            <MRTMuiButton
                                icon={obj ? <Edit sx={{color: theme.palette.primary.main}}/> :
                                    <Add sx={{color: theme.palette.primary.main}}/>}
                                onClick={() => setIsEditFormOpen(true)}
                                small={true}
                                label={obj ? 'edit warehouse' : 'add warehouse'}
                            />
                        </MuiAutocomplete>
                    </Box>

                    <Popover
                        id={'edit_popover'}
                        open={isEditFormOpen}
                        // anchorEl={anchorEl}
                        onClose={() => setIsEditFormOpen(false)}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                    >
                        <MyCompanyWarehouseForm objToEdit={obj ? obj : null}
                                                onSelect={onChange}
                                                onClose={() => setIsEditFormOpen(false)}
                        />
                    </Popover>

                    <MuiModal top="50%" left="50%" width={'120vh'} open={isListTableOpen}
                              onClose={() => setIsListTableOpen(false)}
                              disableBackdropClick={true}
                    >
                        <MyCompanyWarehouseTable
                            alreadySelectedObj={obj}
                            onSelect={handleChange}
                            onClose={() => setIsListTableOpen(false)}
                        />
                    </MuiModal>


                </>
            )}
        </>
    );
}

export default MyCompanyWarehouseSelect;