import React, {useEffect, useState} from 'react';
import {useToast} from "../../../../../../utils/contexts/toast-context";
import {IFiscalRegistry} from "../../../../../../utils/interfaces/my_documents/fiscal_registry/IFiscalRegistry";
import {Box, FormControlLabel} from "@mui/material";
import MuiFabButtonBack from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonSaveAs from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";
import MuiInputTextField from "../../../../../ui/mui/mui-input/MuiInputTextField";
import {useQueryClient} from "@tanstack/react-query";
import {IStatementOfIntent} from "../../../../../../utils/interfaces/my_documents/fiscal_registry/IStatementOfIntent";
import useApiCreateStatementOfIntent from "./api-hooks/useApiCreateStatementOfIntent";
import useApiUpdateStatementOfIntent from "./api-hooks/useApiUpdateStatementOfIntent";
import useApiGetStatementOfIntent from "./api-hooks/useApiGetStatementOfIntent";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import {useTranslation} from "react-i18next";


interface Props {
    objToEdit: IStatementOfIntent | null | undefined;
    onClose: () => void;
    fiscal_registry: IFiscalRegistry | undefined,
    onSelect?: (obj: IStatementOfIntent | undefined) => void,
}

const StatementOfIntentForm: React.FC<Props> = ({objToEdit, onClose, fiscal_registry, onSelect}) => {
    const {displayError, displayMessage} = useToast();
    const queryClient = useQueryClient();
    const {t} = useTranslation()

    const [year, setYear] = useState<number | undefined>();
    const [registrationDate, setRegistrationDate] = useState<string | undefined>();
    const [startValidityDate, setStartValidityDate] = useState<string | undefined>();
    const [endValidityDate, setEndValidityDate] = useState<string | undefined>();
    const [fiscalRegistryStatementOfIntentDate, setFiscalRegistryStatementOfIntentDate] = useState<string | undefined>();
    const [maxValue, setMaxValue] = useState<number | undefined>();
    const [isMaxValue, setIsMaxValue] = useState<boolean | undefined>();
    const [isSingleOperation, setIsSingleOperation] = useState<boolean | undefined>();
    const [fiscalRegistryReferenceNumber, setFiscalRegistryReferenceNumber] = useState<string | undefined>();

    const createMutation = useApiCreateStatementOfIntent();
    const updateMutation = useApiUpdateStatementOfIntent();


    const {
        data: detailedObj,
    } = useApiGetStatementOfIntent(fiscal_registry?.id, objToEdit?.id);


    useEffect(() => {
        if (detailedObj) {
            setYear(detailedObj.year);
            setRegistrationDate(detailedObj.registration_date);
            setStartValidityDate(detailedObj.start_validity_date);
            setEndValidityDate(detailedObj.end_validity_date);
            setFiscalRegistryStatementOfIntentDate(detailedObj.fiscal_registry_statement_of_intent_date);
            setMaxValue(detailedObj.max_value);
            setIsMaxValue(detailedObj.is_max_value);
            setIsSingleOperation(detailedObj.is_single_operation);
            setFiscalRegistryReferenceNumber(detailedObj.fiscal_registry_reference_number);
        }
    }, [detailedObj]);

    const handleSubmit = async () => {

        try {
            const payload = {
                year: year,
                registration_date: registrationDate,
                start_validity_date: startValidityDate,
                end_validity_date: endValidityDate,
                fiscal_registry_statement_of_intent_date: fiscalRegistryStatementOfIntentDate,
                max_value: maxValue,
                is_max_value: isMaxValue,
                is_single_operation: isSingleOperation,
                fiscal_registry_reference_number: fiscalRegistryReferenceNumber
            };

            let newData;

            if (detailedObj !== null && detailedObj !== undefined) {
                newData = await updateMutation.mutateAsync({
                    id: detailedObj.id,
                    fiscal_registry_id: fiscal_registry?.id,
                    data: payload
                });
                displayMessage('Updated successfully');
                await queryClient.invalidateQueries({queryKey: ["useApiGetStatementOfIntent", fiscal_registry?.id, detailedObj.id]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetStatementOfIntents", fiscal_registry?.id]});
            } else {
                newData = await createMutation.mutateAsync({fiscal_registry_id: fiscal_registry?.id, data: payload});
                displayMessage('Added successfully');
                await queryClient.invalidateQueries({queryKey: ["useApiGetStatementOfIntents", fiscal_registry?.id]});
            }
            onClose();
            onSelect && onSelect(newData);
        } catch (error) {

        }
    }

    const [allRequirementAreSatisfied, setAllRequirementAreSatisfied] = useState(false);
    useEffect(() => {
        setAllRequirementAreSatisfied(
            !!(
                year &&
                registrationDate &&
                startValidityDate &&
                endValidityDate &&
                fiscalRegistryStatementOfIntentDate &&
                isMaxValue !== undefined &&
                isSingleOperation !== undefined &&
                fiscalRegistryReferenceNumber
            )
        );
        if (isMaxValue && !maxValue) {
            setAllRequirementAreSatisfied(false);
        }
    }, [year, registrationDate, startValidityDate, endValidityDate, fiscalRegistryStatementOfIntentDate, maxValue, isMaxValue, isSingleOperation, fiscalRegistryReferenceNumber]);


    return (
        <Box sx={{padding: 2}}>
            <form>
                <MuiInputTextField
                    onChange={(e) => setYear(parseFloat(e.target.value))}
                    label='year'
                    type="text"
                    value={year || ''}
                    required={true}
                />

                <MuiInputTextField
                    onChange={setRegistrationDate}
                    label="Registration Date"
                    type="date"
                    value={registrationDate}
                    required
                />

                <MuiInputTextField
                    onChange={setStartValidityDate}
                    label="Start Validity Date"
                    type="date"
                    value={startValidityDate}
                    required
                />

                <MuiInputTextField
                    onChange={setEndValidityDate}
                    label="End Validity Date"
                    type="date"
                    value={endValidityDate}
                    required
                />

                <MuiInputTextField
                    onChange={setFiscalRegistryStatementOfIntentDate}
                    label="Customer Statement Of intent Date"
                    type="date"
                    value={fiscalRegistryStatementOfIntentDate}
                    required
                />


                <MuiInputTextField
                    onChange={(e) => setFiscalRegistryReferenceNumber(e.target.value)}
                    label="Fiscal Registry Reference Number"
                    type="text"
                    value={fiscalRegistryReferenceNumber || ''}
                    required
                />

                <MuiInputTextField
                    onChange={(e) => setMaxValue(parseFloat(e.target.value))}
                    label="max value"
                    type="number"
                    value={maxValue || ''}
                    required
                    disabled={!isMaxValue}
                />


                <FormControl>
                    <RadioGroup
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                    >
                        <FormControlLabel
                            value='isMaxValue'
                            control={
                                <Radio
                                    checked={isMaxValue}
                                    onChange={() => {
                                        setIsMaxValue(true);
                                        setIsSingleOperation(false);
                                    }}
                                />
                            }
                            label={t("is_max_value")}
                        />

                        <FormControlLabel
                            value='isSingleOperation'
                            control={
                                <Radio
                                    checked={isSingleOperation}
                                    onChange={() => {
                                        setIsMaxValue(false);
                                        setIsSingleOperation(true);
                                    }}
                                />
                            }
                            label={t("is_single_operation")}
                        />
                    </RadioGroup>
                </FormControl>


                <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                    <MuiFabButtonBack onClick={onClose}/>
                    {/*{objToEdit &&*/}
                    {/*    <MuiFabButtonDelete onClick={() => {*/}
                    {/*        setConfirmationOpen(true)*/}
                    {/*    }}/>}*/}
                    {objToEdit ?
                        <MuiFabButtonSaveAs onClick={handleSubmit} isDisabled={!allRequirementAreSatisfied}/>
                        :
                        <MuiFabButtonSave onClick={handleSubmit} isDisabled={!allRequirementAreSatisfied}/>}
                </Box>
            </form>
        </Box>
    );
};

export default StatementOfIntentForm;