import React, {useEffect, useState} from 'react';
import Loading from "../../../../ui/Loading";
import {useTranslation} from "react-i18next";
import {Box, TextField} from "@mui/material";
import MuiAutocomplete from "../../../../ui/mui/mui-input/MuiAutoComplete";
import {useTheme} from "@mui/material/styles";
import {IWasteMovement} from "../../../../../utils/interfaces/special_wasty/waste_movement/IWasteMovement";
import useApiGetWasteMovement from "./api-hooks/useApiGetWasteMovement";
import {useAppGarage} from "../../../../../utils/contexts/appGarage-context";
import useWasteApiGetSiteMovements from "./api-hooks/useWasteApiGetSiteMovements";
import {IWasteMovementType} from "../../../../../utils/interfaces/special_wasty/waste_movement/IWasteMovementType";
import useWasteApiGetSiteNULLABLEMovements from "./api-hooks/useWasteApiGetSiteNULLABLEMovements";
import useWasteApiGetSiteRECTIFIABLEMovements from "./api-hooks/useWasteApiGetSiteRECTIFIABLEMovements";
import {IWasteCode} from "../../../../../utils/interfaces/special_wasty/waste_code/IWasteCode";


interface Props {
    obj: IWasteMovement | undefined,
    onChange: (obj: IWasteMovement | undefined) => void,
    className: string,
    label?: string,
    required?: boolean,
    movement_type: IWasteMovementType,
    withDefaultValueIfEmpty?: boolean
    waste_code: IWasteCode | undefined
}

const WasteMovementSelect = ({
                                 obj: initialObj,
                                 label,
                                 onChange,
                                 required,
                                 withDefaultValueIfEmpty = false,
                                 movement_type,
                                 waste_code,
                             }: Props) => {


    const theme = useTheme();
    const {t} = useTranslation()
    const {selectedMyCompanySite} = useAppGarage()

    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)

    const [objs, setObjs] = useState<IWasteMovement[]>([]);
    const [obj, setObj] = useState<IWasteMovement | undefined>(initialObj)


    useEffect(() => {
        setObj(undefined);
        if (initialObj) {
            setObj(initialObj)
        }
    }, [initialObj]);


    const {
        data,
        isLoading,
    } = useWasteApiGetSiteMovements(selectedMyCompanySite?.id);

    const {
        data: only_nullable,
    } = useWasteApiGetSiteNULLABLEMovements(selectedMyCompanySite?.id, waste_code?.id);

    const {
        data: only_rectifiable,
    } = useWasteApiGetSiteRECTIFIABLEMovements(selectedMyCompanySite?.id, waste_code?.id);


    useEffect(() => {
        if (only_nullable && movement_type === 'annulment') {
            setObjs(only_nullable)
            if (!initialObj && only_nullable.length > 0 && withDefaultValueIfEmpty) {
                setObj(only_nullable[0]);
                onChange(only_nullable[0]);
            }
        }
        if (only_rectifiable && movement_type === 'rectification') {
            setObjs(only_rectifiable)
            if (!initialObj && only_rectifiable.length > 0 && withDefaultValueIfEmpty) {
                setObj(only_rectifiable[0]);
                onChange(only_rectifiable[0]);
            }
        }
    }, [data, movement_type, only_nullable]);


    const {
        data: detailedObj,
    } = useApiGetWasteMovement(obj?.id);


    useEffect(() => {
        if (detailedObj) {
            setObj(detailedObj);
        }
    }, [detailedObj]);

    const handleOnChange = (event: React.SyntheticEvent, newValue: IWasteMovement | null) => {
        if (newValue) {
            onChange(newValue);
        } else {
            onChange(undefined);
        }
    };

    const handleChange = (obj: IWasteMovement | undefined) => {
        setObj(obj);
        onChange(obj)
    }

    useEffect(() => {
        onChange(obj);

    }, [obj]);


    return (
        <>
            {isLoading ? (
                <Loading/>
            ) : (
                <>
                    <Box sx={{alignItems: 'center', width: '100%'}}>
                        <MuiAutocomplete
                            id="IWasteMovement-select"
                            options={objs}
                            getOptionLabel={(option) => {
                                const date = new Date(option.movement_date);
                                return `${option.movement_number}/${date.getFullYear()}`;
                            }}
                            value={detailedObj ? detailedObj : null}
                            onChange={handleOnChange}
                            isOptionEqualToValue={(option, value) => option.id === value?.id && option.id === value?.id}
                            renderInput={(params) => (
                                <TextField
                                    sx={{textTransform: 'capitalize'}}
                                    {...params}
                                    label={label ? t(label) : '' || t('waste_movement')}
                                    required={required}
                                />
                            )}
                            borderColor={theme.palette.primary.light}
                            onFocusBorderColor={theme.palette.primary.dark}
                            minWidth={300}
                        >


                        </MuiAutocomplete>
                    </Box>


                </>
            )}
        </>
    );
}

export default WasteMovementSelect;