import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {ITanneryDyeingSchedule} from "../../../../../../utils/interfaces/tannery_dyeing/ITanneryDyeingSchedule";


const useApiGetTanneryDyeingScheduleCurrents = (
    companyToken: string | undefined
): UseQueryResult<ITanneryDyeingSchedule[], CustomAxiosError> => {

    const {get} = useApiCalls();

    async function doGet(company_token: string | undefined): Promise<ITanneryDyeingSchedule[]> {
        try {
            const response = await get(`/api/company/${company_token}/tannery_dyeing/only_open_dyeings`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ['getTanneryDyeingSchedule'],
        queryFn: () => doGet(companyToken),
        refetchInterval: 5000,
    })
};

export default useApiGetTanneryDyeingScheduleCurrents