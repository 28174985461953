import React, {useEffect, useMemo, useState} from 'react';
import {useToast} from "../../../../../../utils/contexts/toast-context";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import {IFiscalRegistry} from "../../../../../../utils/interfaces/my_documents/fiscal_registry/IFiscalRegistry";
import {MaterialReactTable, MRT_ActionMenuItem, MRT_ColumnDef, useMaterialReactTable} from "material-react-table";
import {
    getMuiTableBodyCellProps,
    getMuiTableBodyRowProps,
    getMuiTableHeadCellProps,
    getMuiTablePaperProps
} from "../../../../../ui/mui/mui_react_table/MuiReactTableStyles";
import {Delete, Edit} from "@mui/icons-material";
import {Avatar, Box, Grid} from "@mui/material";
import MuiReadOnlyBoxBody from "../../../../../ui/mui/mui-display-data/MuiReadOnlyBoxBody";
import MRTMuiButton from "../../../../../ui/mui/mui_react_table/MRTMuiButton";
import AddIcon from "@mui/icons-material/Add";
import Loading from "../../../../../ui/Loading";
import MuiAlertDialog from "../../../../../ui/mui/mui_modal/MuiAlertDialog";
import MuiFetchingSpinner from "../../../../../ui/mui/MuiFetchingSpinner";
import AdsClickIcon from '@mui/icons-material/AdsClick';
import {IStatementOfIntent} from "../../../../../../utils/interfaces/my_documents/fiscal_registry/IStatementOfIntent";
import useApiDeleteStatementOfIntent from "./api-hooks/useApiDeleteStatementOfIntent";
import useApiGetStatementOfIntents from "./api-hooks/useApiGetStatementOfIntents";
import StatementOfIntentForm from "./StatementOfIntentForm";
import NewspaperOutlinedIcon from '@mui/icons-material/NewspaperOutlined';
import MuiModal from "../../../../../ui/mui/mui_modal/MuiModal";
import {formatDateTimeToDateString} from "../../../../../../logics/helpers";
import MuiFabButtonBack from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";


interface Props {
    fiscal_registry: IFiscalRegistry | undefined,
    onSelect?: (obj: IStatementOfIntent | undefined) => void,
    onClose?: () => void;
    alreadySelectedObj?: IStatementOfIntent | undefined,
}


const StatementOfIntentTable = ({fiscal_registry, onSelect, onClose, alreadySelectedObj}: Props) => {
    const {displayError} = useToast()
    const theme = useTheme();
    const {t} = useTranslation()

    const [selectedRow, setSelectedRow] = useState<IStatementOfIntent | undefined>()
    const [rows, setRows] = useState<IStatementOfIntent[] | undefined>()

    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const deleteMutation = useApiDeleteStatementOfIntent(fiscal_registry?.id, selectedRow?.id);
    const {mutateAsync: apiDelete} = deleteMutation;

    const handleConfirmDelete = async () => {
        try {
            if (onSelect && (alreadySelectedObj?.id === selectedRow?.id)) {
                onSelect(undefined);
            }
            await apiDelete({fiscal_registry_id: fiscal_registry?.id, pk: selectedRow?.id});
        } catch (error) {
            console.log(error)
        }
        setDeleteConfirmationOpen(false);

    };
    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };


    const {
        data,
        isLoading,
        error,
        isFetching
    } = useApiGetStatementOfIntents(fiscal_registry?.id);
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }


    useEffect(() => {
        if (data) {
            setRows(data);
        }
    }, [data]);


    const columns = useMemo<MRT_ColumnDef<IStatementOfIntent>[]>(
        () => [
            {
                accessorKey: 'custom_field',
                header: '',
                enableColumnActions: false,
                enableColumnFilter: false,
                enableColumnOrdering: false,
                enableSorting: false,
            },
            {
                accessorKey: 'year',
                header: t('year'),
                filterFn: 'contains',

            },
            {
                accessorKey: 'registration_date',
                header: t('Registration Date'),
                accessorFn: (row) => formatDateTimeToDateString(row.registration_date),
            },
            {
                accessorKey: 'start_validity_date',
                header: t('Start Validity Date'),
                accessorFn: (row) => formatDateTimeToDateString(row.start_validity_date),
            },
            {
                accessorKey: 'end_validity_date',
                header: t('End Validity Date'),
                accessorFn: (row) => formatDateTimeToDateString(row.end_validity_date),
            },
            {
                accessorKey: 'fiscal_registry_statement_of_intent_date',
                header: t('Customer Statement Of intent Date'),
                accessorFn: (row) => formatDateTimeToDateString(row.fiscal_registry_statement_of_intent_date),
            },
            {
                accessorKey: 'max_value',
                header: t('max value'),
                accessorFn: (row) => (row.max_value).toFixed(2) + ' €',
            },
            {
                accessorKey: 'fiscal_registry_reference_number',
                header: t('fiscal_registry_reference_number'),
                filterFn: 'contains',
            },

        ],
        [rows]
    );


    const table = useMaterialReactTable<IStatementOfIntent>({
        columns,
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 80,
        },
        data: rows ? rows : [],
        columnFilterDisplayMode: 'popover',
        globalFilterFn: 'contains',
        enableFullScreenToggle: false,
        enableFilters: true,
        enableDensityToggle: true,
        muiTableHeadCellProps: getMuiTableHeadCellProps(theme.palette.primary.main),
        muiTableBodyCellProps: getMuiTableBodyCellProps(theme),
        muiTablePaperProps: getMuiTablePaperProps,
        enablePagination: rows && rows.length > 25,
        initialState: {
            density: 'compact',
            pagination: {pageSize: 25, pageIndex: 0},
        },
        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.backgroundAppGarageColor.main,

        }),
        muiTableBodyRowProps: getMuiTableBodyRowProps(theme),

        enableRowActions: true,
        renderRowActionMenuItems: ({closeMenu, row}) => [
            onSelect && onClose && (
                <MRT_ActionMenuItem
                    icon={<AdsClickIcon color="primary"/>}
                    key="select"
                    label={t("select")}
                    onClick={() => {
                        onSelect(row.original);
                        onClose();
                    }}
                    table={table}
                />),
            <MRT_ActionMenuItem
                icon={<Edit color="primary"/>}
                key="edit"
                label={t("edit")}
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setSelectedRow(row.original);
                    setIsEditFormOpen(true)
                    closeMenu();
                }}
                table={table}
            />,
            <MRT_ActionMenuItem
                icon={<Delete color="warning"/>}
                key="delete"
                label={t("delete")}
                onClick={() => {
                    setSelectedRow(row.original);
                    setDeleteConfirmationOpen(true)
                    closeMenu();
                }}
                disabled={!row.original.can_be_deleted}
                table={table}
            />,

        ],
        renderTopToolbarCustomActions: ({table}) => (
            <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                <>
                    <Avatar sx={{
                        backgroundColor: 'white',
                        border: `2px solid ${theme.palette.primary.main}`,
                        marginRight: 1
                    }}>
                        <NewspaperOutlinedIcon color={'primary'}/>
                    </Avatar>
                    <MuiReadOnlyBoxBody label={''} value={'statement of intent'}/>
                    <MRTMuiButton
                        icon={<AddIcon style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                        onClick={() => {
                            setSelectedRow(undefined);
                            setIsEditFormOpen(true)
                        }} label={'add statement of intent'}/>
                </>
            </Box>
        ),
    });


    return <>
        {isLoading ? <Loading/> :
            <>
                {onClose &&
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                        <Box className={'mt-2 gap-x-2 mb-2'}>
                            <MuiFabButtonBack onClick={onClose}/>
                        </Box>
                    </Grid>}

                <MaterialReactTable table={table}/>

                <MuiAlertDialog
                    open={isDeleteConfirmationOpen}
                    title="delete_confirmation"
                    content="ask_for_delete"
                    onClose={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                />

                <MuiModal
                    top="50%"
                    left="50%"
                    width="50vh"
                    open={isEditFormOpen}
                    onClose={() => setIsEditFormOpen(false)}
                    disableBackdropClick={true}
                >
                    <StatementOfIntentForm objToEdit={selectedRow ? selectedRow : null}
                                           fiscal_registry={fiscal_registry}
                                           onClose={() => setIsEditFormOpen(false)}/>
                </MuiModal>

                {isFetching &&
                    <MuiFetchingSpinner text="Fetching data..."/>
                }
            </>}
    </>;
};

export default StatementOfIntentTable;
