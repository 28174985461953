import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {
    IRentriCertificate
} from "../../../../../../utils/interfaces/special_wasty/rentri_certificate/IRentriCertificate";


const useApiGetRentriCertificate = (): UseQueryResult<IRentriCertificate, CustomAxiosError> => {
    const {selectedCompany} = useAuth();

    const {get} = useApiCalls()

    async function doGet(): Promise<IRentriCertificate> {
        try {
            const response = await get(`/api/company/${selectedCompany?.company_token}/special_wasty/rentri_certificate`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetRentriCertificate"],
        queryFn: () => doGet(),
        enabled: !!selectedCompany,
    })

};

export default useApiGetRentriCertificate