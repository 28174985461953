import React, {useEffect, useState} from 'react';
import {ITanneryDyeingProduct} from "../../../../../../utils/interfaces/tannery_dyeing/ITanneryDyeingProduct";
import {useToast} from "../../../../../../utils/contexts/toast-context";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import useApiGetTanneryDyeingProducts from "./api-hooks/useApiGetTanneryDyeingProducts";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import Loading from "../../../../../ui/Loading";
import {useTranslation} from "react-i18next";
import MuiAutocomplete from "../../../../../ui/mui/mui-input/MuiAutoComplete";
import {Box, TextField} from "@mui/material";
import MRTMuiButton from "../../../../../ui/mui/mui_react_table/MRTMuiButton";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import {useTheme} from "@mui/material/styles";
import MuiModal from "../../../../../ui/mui/mui_modal/MuiModal";
import PeopleTable from "../../../service/people/PeopleTable";


interface Props {
    product: ITanneryDyeingProduct | null,
    onChange: (product: ITanneryDyeingProduct | null) => void,
    className: string,
    label?: string
    clearable?: boolean
    required?: boolean
    withDefaultValue?: boolean
    isDisabled?: boolean
}

const TanneryDyeingProductSelect = ({
                                        product: initialObj,
                                        onChange,
                                        label,
                                        clearable,
                                        required,
                                        withDefaultValue = true,
                                        isDisabled = false
                                    }: Props) => {
    const {t} = useTranslation();
    const {displayError, displayMessage} = useToast()
    const {selectedCompany} = useAuth()
    const theme = useTheme();

    const [obj, setObj] = useState<ITanneryDyeingProduct | null>(initialObj);
    const [objs, setObjs] = useState<ITanneryDyeingProduct[]>([]);
    const [isListTableOpen, setIsListTableOpen] = useState<boolean>(false)

    const {
        data,
        isLoading,
        error,
    } = useApiGetTanneryDyeingProducts(selectedCompany?.company_token);
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }

    useEffect(() => {
        if (initialObj) {
            setObj(initialObj)
        }

    }, [initialObj]);


    useEffect(() => {
        if (data) {
            setObjs(data);
        }
    }, [data]);

    const handleOnChange = (event: React.SyntheticEvent, newValue: ITanneryDyeingProduct | null) => {
        onChange(newValue);
    };


    return (
        isLoading ? (
            <Loading/>
        ) : (
            // <MuiAutocomplete
            //     id="tannery_dyeing_product-select"
            //     options={objs}
            //     getOptionLabel={(option) => option.people_name + ' - ' + option.product_name}
            //     value={initialObj ? obj : null}
            //     onChange={handleOnChange}
            //     isDisabled={isDisabled}
            //     isOptionEqualToValue={(option, value) => option.id === value?.id && option.id === value?.id}
            //     renderInput={(params) => (
            //         <TextField
            //             sx={{textTransform: 'capitalize'}}
            //             {...params}
            //             label={label || t('um')}
            //             required={required}
            //
            //         />
            //
            //     )}
            //
            // />
            <>
                <Box sx={{alignItems: 'center', width: '100%'}}>
                    <MuiAutocomplete
                        id="tannery_dyeing_product-select"
                        options={objs}
                        getOptionLabel={(option) => option.people_name + ' - ' + option.product_name}
                        value={initialObj ? obj : null}
                        onChange={handleOnChange}
                        isOptionEqualToValue={(option, value) => option.id === value?.id && option.id === value?.id}
                        renderInput={(params) => (
                            <TextField
                                sx={{textTransform: 'capitalize'}}
                                {...params}
                                label={label ? t(label) : '' || t('product')}
                                required={required}
                            />
                        )}
                        borderColor={theme.palette.primary.light}
                        onFocusBorderColor={theme.palette.primary.dark}
                        minWidth={300}
                    >
                        <MRTMuiButton
                            icon={<FormatListBulletedIcon sx={{color: theme.palette.primary.main}}/>}
                            onClick={() => setIsListTableOpen(true)}
                            small={true}
                            label={'show_list'}
                        />

                    </MuiAutocomplete>
                </Box>
                <MuiModal top="50%" left="50%" width={'100vh'} open={isListTableOpen}
                          onClose={() => setIsListTableOpen(false)}>
                    <Box sx={{padding: 0}}>
                        <PeopleTable category_person={'chemical_supplier'} onClose={() => setIsListTableOpen(false)}/>
                    </Box>
                </MuiModal>
            </>


        )
    );
}

export default TanneryDyeingProductSelect;