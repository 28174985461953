import React, {useEffect, useState} from 'react';
import {TextField} from '@mui/material';
import {IPrice} from '../../../../../../../utils/interfaces/service/people/product_list/price/IPrice';
import {useToast} from '../../../../../../../utils/contexts/toast-context';
import {useTranslation} from 'react-i18next';
import useApiGetPrices from './api-hooks/useApiGetPrices';
import {CustomAxiosError} from '../../../../../../../logics/api_config';
import Loading from '../../../../../../ui/Loading';
import {formatDateTimeToDateString} from "../../../../../../../logics/helpers";
import MuiAutocomplete from "../../../../../../ui/mui/mui-input/MuiAutoComplete";

interface Props {
    person_id: number | undefined;
    product_id: number | undefined;
    obj: IPrice | null;
    onChange: (obj: IPrice | null) => void;
    className: string;
    label?: string;
    required?: boolean;
    disableClearable?: boolean
}

const PriceSelect = ({
                         person_id,
                         product_id,
                         obj: initialObj,
                         label,
                         onChange,
                         required,
                         disableClearable
                     }: Props) => {
    const {displayError} = useToast();
    const {t} = useTranslation();

    const [obj, setObj] = useState<IPrice | null>(initialObj);
    const [objs, setObjs] = useState<IPrice[]>([]);

    useEffect(() => {
        setObj(initialObj);
    }, [initialObj]);

    const {
        data,
        isLoading,
        error,
    } = useApiGetPrices(person_id, product_id);
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }

    useEffect(() => {
        if (data) {
            setObjs(data);
        }
    }, [data]);

    const handleOnChange = (event: React.SyntheticEvent, newValue: IPrice | null) => {
        onChange(newValue);
    };

    useEffect(() => {
        onChange(obj);
    }, [obj]);

    return (
        isLoading ? (
            <Loading/>
        ) : (
            <MuiAutocomplete
                id="price-select"
                options={objs}
                disableClearable={disableClearable}
                getOptionLabel={(option) =>
                    (option.price ? option.price.toFixed(2) : ' ') +
                    ' €/' +
                    (option.um && option.um.um ? option.um.um : ' ') +
                    ' - ' +
                    formatDateTimeToDateString(option.validity_start_date)
                }
                value={obj}
                onChange={handleOnChange}
                isOptionEqualToValue={(option, value) => option.id === value?.id && option.validity_start_date === value?.validity_start_date}
                renderInput={(params) => (
                    <TextField
                        sx={{textTransform: 'capitalize'}}
                        {...params}
                        label={label || t('price')}
                        required={required}
                    />
                )}
            />
        )
    );
};

export default PriceSelect;
