import React, {useState} from 'react';
import {Box} from "@mui/material";
import {IconContext} from "react-icons";

import {useTranslation} from "react-i18next";
import {IWastyRecipient} from "../../../../../utils/interfaces/special_wasty/wasty_recipient/IWastyRecipient";
import {IoDocumentsSharp} from "react-icons/io5";
import CurrentlyInUseWasteDocCard from "../waste_doc/CurrentlyInUseWasteDocCard";
import MuiModal from "../../../../ui/mui/mui_modal/MuiModal";
import MRTMuiButton from "../../../../ui/mui/mui_react_table/MRTMuiButton";

// note_marco: customCellRenderer in hook example
const useCustomCellRenderer = (row: IWastyRecipient, theme: any,) => {
    const {t} = useTranslation()
    const [isCurrentInUserWasteCodeCardIsOpen, setIsCurrentInUserWasteCodeCardIsOpen] = useState(false)


    const handleSubmit = async (mutation: any) => {
        try {
            await mutation.mutateAsync({id: row.id});
        } catch (error) {
        }
    }

    const getIconsForCustomField = (row: IWastyRecipient, theme: any) => {
        const icons = [];

        if (row.currently_in_use_docs && row.currently_in_use_docs.docs.length > 0) {
            icons.push(
                <MRTMuiButton
                    small={true}
                    icon={<IoDocumentsSharp
                        color={row.currently_in_use_docs.show_alert ? theme.palette.warning.main : theme.palette.primary.main}/>}
                    onClick={() => {
                        setIsCurrentInUserWasteCodeCardIsOpen(true)
                    }}
                    animated={row.currently_in_use_docs.show_alert}
                    label={'currently in use docs'}/>
            )
        }


        return icons;
    };

    const renderIcons = () => {
        const icons = getIconsForCustomField(row, theme);
        return (
            <IconContext.Provider value={{size: "1.3rem"}}>
                <Box display="flex" flexDirection="row" sx={{gap: 0.5}}>
                    {icons}
                </Box>
                <MuiModal
                    top="50%"
                    left="50%"
                    width={'80vh'}
                    open={isCurrentInUserWasteCodeCardIsOpen}
                    onClose={() => setIsCurrentInUserWasteCodeCardIsOpen(false)}
                >
                    <CurrentlyInUseWasteDocCard
                        recipient={row}
                    />

                </MuiModal>
            </IconContext.Provider>
        );
    };

    return {renderIcons};
};

export default useCustomCellRenderer;