import React, {useEffect, useState} from 'react';
import AnimatedRow from "../../../../../ui/AnimatedRow";
import AgDiv from "../../../../../ui/divs/AgDiv";
import {ISubcontractor} from "../../../../../../utils/interfaces/lwg_dashboard/reports/workings/ISubcontractor";
import SubcontractorRow from "./SubcontractorRow";

interface Props {
    scrollableHeight?: number;
    subcontractors: ISubcontractor[] | undefined,
    totalWorkedSqm: number | undefined;
}

const SubContractorList = ({scrollableHeight = 100, subcontractors: initialObjs, totalWorkedSqm}: Props) => {

    const [objs, setObjs] = useState(initialObjs)
    const [filteredData, setFilteredData] = useState<ISubcontractor[] | undefined>(objs);


    useEffect(() => {
        if (objs) {
            setFilteredData(objs);
        }
    }, [objs]);

    useEffect(() => {
        setObjs(initialObjs)
    }, [initialObjs]);


    const mapped_data =
        filteredData?.map((obj: ISubcontractor, index: any) =>
            <AnimatedRow key={obj.id} index={index / 3}>
                <SubcontractorRow subcontractor={obj}/>
            </AnimatedRow>
        )


    return (
        <>
            <div className="p-4">
                <div
                    style={{
                        height: `calc(${scrollableHeight}vh - 250px)`,
                        overflowY: 'auto',
                        overflow: 'auto',
                        whiteSpace: 'nowrap',
                        minWidth: '100%',
                        scrollbarWidth: 'auto',
                        scrollbarColor: '#02959c transparent',
                    }}
                    className='scroll-content no-scrollbar'
                >
                    <div className='flex flex-row capitalize font-bold'>
                        <AgDiv label={'people_name'}
                               value={''}
                               minWidth="320px"
                               maxWidth="320px"
                               className={'AgDivHeader'}
                        />

                        <AgDiv label={'lwg_site_name'}
                               value={''}
                               minWidth="320px"
                               maxWidth="320px"
                               className={'AgDivHeader'}
                        />

                        <AgDiv label='sqm_produced'
                               value={''}
                               minWidth="320px"
                               maxWidth="320px"
                               className={'AgDivHeader'}
                        />

                        <AgDiv label='pcs_produced'
                               value={''}
                               minWidth="320px"
                               maxWidth="320px"
                               className={'AgDivHeader'}
                        />

                        <AgDiv label='lwg_working_description'
                               value={''}
                               minWidth="320px"
                               maxWidth="320px"
                               className={'AgDivHeader'}
                        />

                        <AgDiv label='coeff'
                               value={''}
                               minWidth="80px"
                               maxWidth="80px"
                               className={'AgDivHeader'}
                        />
                    </div>
                    <div className={''}>
                        {mapped_data}
                    </div>

                </div>


            </div>
        </>

    );
};

export default SubContractorList;