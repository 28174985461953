import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {
    IWastyIntermediary
} from "../../../../../../utils/interfaces/special_wasty/wasty_intermediary/IWastyIntermediary";


const useApiGetWastyIntermediaries = (): UseQueryResult<IWastyIntermediary[], CustomAxiosError> => {
    const {selectedCompany} = useAuth();

    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined): Promise<IWastyIntermediary[]> {
        try {
            const response = await get(`/api/company/${company_token}/special_wasty/wasty_intermediary`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetWastyIntermediaries"],
        queryFn: () => doGet(selectedCompany?.company_token,),
    })
};

export default useApiGetWastyIntermediaries