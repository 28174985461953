import React from 'react';
import MaxWidthDiv from "../../../../ui/MaxWitdthDiv";
import WastyCarrierTable from "./WastyCarrierTable";
import SpecialWastyScreen from "../SpecialWastyScreen";


const WastyCarrierScreen = () => {


    return (
        <>
            <SpecialWastyScreen>

                <MaxWidthDiv maxWidth='1024px'>
                    <WastyCarrierTable/>
                </MaxWidthDiv>

            </SpecialWastyScreen>

        </>
    );
};

export default WastyCarrierScreen;
