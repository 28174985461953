import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Box, TextField} from "@mui/material";
import {useToast} from "../../../../../../../utils/contexts/toast-context";
import Loading from "../../../../../../ui/Loading";
import MuiAutocomplete from "../../../../../../ui/mui/mui-input/MuiAutoComplete";
import {
    IRentriCausaleOperazione
} from "../../../../../../../utils/interfaces/special_wasty/rentri/codifiche/fir_registro/IRentriCausaleOperazione";
import useApiGetRentriCausaliOperazione from "./api-hooks/useApiGetRentriCausaliOperazione";
import {
    IWasteMovementType
} from "../../../../../../../utils/interfaces/special_wasty/waste_movement/IWasteMovementType";


interface Props {
    obj: IRentriCausaleOperazione | undefined,
    onChange: (obj: IRentriCausaleOperazione | undefined) => void,
    className: string,
    label?: string,
    clearable?: boolean
    required?: boolean
    movement_type?: IWasteMovementType
}

const RentriCausaliOperazioneSelect = ({
                                           obj: initialObj,
                                           onChange,
                                           label,
                                           clearable,
                                           required,
                                           movement_type,
                                           ...props
                                       }: Props) => {
    const {displayError, displayMessage} = useToast()
    const {t} = useTranslation();
    const {className = ''} = props;
    const [objs, setObjs] = useState<IRentriCausaleOperazione[]>([]);
    const [obj, setObj] = useState<IRentriCausaleOperazione | undefined>(initialObj)


    const {
        data,
        isLoading,
        error,
    } = useApiGetRentriCausaliOperazione();


    useEffect(() => {
        if (data) {
            setObjs(data);
        }
    }, [data]);

    useEffect(() => {
        if (initialObj) {
            setObj(initialObj);
        } else {
            const dtOBj = objs.find(obj => obj.id === 1);
            const atOBj = objs.find(obj => obj.id === 6);
            if (dtOBj && !obj && movement_type === 'load') {
                setObj(dtOBj);
            }
            if (atOBj && !obj && movement_type === 'download') {
                setObj(atOBj);
            }
        }
    }, [initialObj, objs, movement_type, obj]);

    const handleOnChange = (event: React.SyntheticEvent, newValue: IRentriCausaleOperazione | null) => {
        setObj(undefined)
        if (newValue) {
            onChange(newValue);
        } else {
            onChange(undefined);
        }

    };


    useEffect(() => {
        onChange(obj);
    }, [obj]);


    return (
        <>
            {isLoading ? (
                <Loading/>
            ) : (
                <>
                    <Box sx={{display: 'flex', alignItems: 'center', width: '100%'}}>

                        <MuiAutocomplete
                            id="rentri_attivita-select"
                            options={objs}
                            disableClearable={true}
                            getOptionLabel={(option) => option.name}
                            value={obj ? obj : null}
                            onChange={handleOnChange}
                            isOptionEqualToValue={(option, value) => option.id === value?.id && option.code === value?.code}
                            renderInput={(params) => (
                                <TextField
                                    sx={{textTransform: 'capitalize'}}
                                    {...params}
                                    label={t(label ? label : '') || t('')}
                                    required={required}
                                />
                            )}
                        />
                    </Box>

                </>
            )}
        </>
    );
}

export default RentriCausaliOperazioneSelect;