import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {useAppGarage} from "../../../../../../utils/contexts/appGarage-context";


const useApiDeleteWasteCode = (pk: number | undefined): UseMutationResult<void, CustomAxiosError, {
    pk?: number
}, unknown> => {
    const {selectedCompany} = useAuth()
    const queryClient = useQueryClient();
    const {selectedMyCompanySite} = useAppGarage()

    const {DELETE} = useApiCalls()

    async function doDelete({companyToken, pk}: {
        companyToken?: string,
        pk: number | undefined
    }): Promise<void> {
        try {
            await DELETE(`/api/company/${companyToken}/special_wasty/waste_code/${pk}`);
        } catch (error) {
            throw error;
        }
    }

    return useMutation<void, CustomAxiosError, { pk?: number }>(
        {
            mutationFn: () => doDelete({
                companyToken: selectedCompany?.company_token,
                pk: pk,
            }),
            onSuccess: async () => {
                await queryClient.invalidateQueries({queryKey: ['useApiGetWasteCodes', selectedMyCompanySite?.id]});
            },
        })
}


export default useApiDeleteWasteCode;