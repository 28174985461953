import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {IMyCompanySite} from "../../../../../../utils/interfaces/my_company/my_company_site/IMyCompanySite";


const useApiGetMyCompanySites = (): UseQueryResult<IMyCompanySite[], CustomAxiosError> => {
    const {selectedCompany} = useAuth();

    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined): Promise<IMyCompanySite[]> {
        try {
            const response = await get(`/api/company/${company_token}/my_company/my_company_site`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetMyCompanySites"],
        queryFn: () => doGet(selectedCompany?.company_token,),
    })
};

export default useApiGetMyCompanySites