import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {IWastyCarrier} from "../../../../../../utils/interfaces/special_wasty/wasty_carrier/IWastyCarrier";


const useApiGetWastyCarrier = (pk: number | undefined): UseQueryResult<IWastyCarrier, CustomAxiosError> => {
    const {selectedCompany} = useAuth();

    const {get} = useApiCalls()

    async function doGet(pk: number | undefined): Promise<IWastyCarrier> {
        try {
            const response = await get(`/api/company/${selectedCompany?.company_token}/special_wasty/wasty_carrier/${pk}`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetWastyCarrier", pk],
        queryFn: () => doGet(pk),
        enabled: !!pk,
    })

};

export default useApiGetWastyCarrier