import {useMutation, UseMutationResult} from "@tanstack/react-query";
import {CustomAxiosError} from "../../../../../../../../logics/api_config";
import {useApiRentriCalls} from "../../../api-hooks/api_rentri_config";
import {
    IRentriRegistro
} from "../../../../../../../../utils/interfaces/special_wasty/rentri/rentri_registro/IRentriRegistro";


const useApiCreateRentriRegistro = (): UseMutationResult<IRentriRegistro, CustomAxiosError, any> => {
    const {post} = useApiRentriCalls()

    async function doCreate({data}: {
        data: any
    }): Promise<IRentriRegistro> {
        try {
            const response = await post(`/anagrafiche/v1.0/operatore/registri`, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    }


    return useMutation<IRentriRegistro, CustomAxiosError, any>(
        {
            mutationFn: (
                data
            ) => {
                return doCreate({
                    data
                })
            },
        }
    );
};


export default useApiCreateRentriRegistro;