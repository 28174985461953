import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {IDocPayment} from "../../../../../../../utils/interfaces/my_documents/doc_payment/IDocPayment";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";


const useApiDocPaymentManuallyModifiedAtToggle = (): UseMutationResult<IDocPayment, CustomAxiosError, {
    id: number;
}, unknown> => {
    const queryClient = useQueryClient();
    const {selectedCompany} = useAuth()

    const {put} = useApiCalls()


    async function doUpdate(company_token: string | undefined,
                            pk: number | undefined): Promise<IDocPayment> {
        try {
            const response = await put(`/api/company/${company_token}/my_documents/doc_payment/${pk}/toggle_manually_modified_at`);
            return response.data as IDocPayment;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<IDocPayment, CustomAxiosError, {
        id: number;
    }, unknown>({
        mutationFn: async ({id}) => {
            return await doUpdate(selectedCompany?.company_token, id);
        },
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({queryKey: ['useApiGetDocPayment', data.id]});
            await queryClient.invalidateQueries({queryKey: ["useApiGetDocPayments", data.doc?.id]});
            await queryClient.invalidateQueries({queryKey: ['useApiGetRicevuteBancarie']});
        },
    });
};


export default useApiDocPaymentManuallyModifiedAtToggle;