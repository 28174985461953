import React, {useEffect, useState} from 'react';

import {Box} from "@mui/material";
import MuiInputTextField from "../../../../ui/mui/mui-input/MuiInputTextField";
import MuiFabButtonBack from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonSaveAs from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";
import useApiCreateRentriCertificate from "./api-hooks/useApiCreateRentriCertificate";
import useApiUpdateRentriCertificate from "./api-hooks/useApiUpdateRentriCertificate";
import MuiUploadFile from "../../../../ui/mui/mui-input/MuiUploadFile";
import useApiGetRentriCertificate from "./api-hooks/useApiGetRentriCertificate";
import RentriCertificateCard from "./RentriCertificateCard";
import MuiAlertDialog from "../../../../ui/mui/mui_modal/MuiAlertDialog";


interface Props {
    onClose: () => void;
}

const RentriCertificateForm: React.FC<Props> = ({onClose}) => {

    const [password, setPassword] = useState<string>();
    const [file, setFile] = useState<File | null>(null);
    const [fileName, setFileName] = useState<string>();
    const [numIscrOperatore, setNumIscrOperatore] = useState<string>();

    const [alertTitle, setAlertTitle] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    const [isAlertOpen, setIsAlertOpen] = useState(false);

    const createMutation = useApiCreateRentriCertificate();
    const updateMutation = useApiUpdateRentriCertificate();

    const {data: objToEdit} = useApiGetRentriCertificate();


    useEffect(() => {
        if (objToEdit) {
            setNumIscrOperatore(objToEdit.num_iscr_operatore);
            // const fileUrl = objToEdit.rentri_company_certificate;
            // if (fileUrl) {
            //     const fetchFile = async (url: string) => {
            //         try {
            //             const response = await fetch(url);
            //             const blob = await response.blob();
            //             let fileName = decodeURIComponent(new URL(url).pathname.split('/').pop() as string);
            //
            //             const lastUnderscoreIndex = fileName.lastIndexOf('_');
            //             if (lastUnderscoreIndex !== -1) {
            //                 fileName = `${fileName.substring(0, lastUnderscoreIndex)}.p12`;
            //             }
            //
            //             const file = new File([blob], fileName, {type: blob.type});
            //             setFile(file);
            //             setFileName(fileName);
            //         } catch (error) {
            //             console.error("Error fetching the file:", error);
            //         }
            //     };
            //
            //     fetchFile(fileUrl);
            // }
        }

    }, [objToEdit]);


    const handleSubmit = async () => {
        try {
            const payload = {
                rentri_company_password: password ? password : null,
                rentri_company_certificate: file ? file : null,
                num_iscr_operatore: numIscrOperatore ? numIscrOperatore : null,
            };

            let newData;

            if (objToEdit !== null && objToEdit !== undefined && objToEdit.id !== null && objToEdit.id !== undefined) {
                newData = await updateMutation.mutateAsync({id: objToEdit.id, data: payload});
            } else {
                newData = await createMutation.mutateAsync(payload);
            }
            if (newData.certificate_info && newData.num_iscr_operatore) {
                setAlertTitle('Successo');
                setAlertMessage('Certificato caricato con successo');
                //onClose();
            } else {
                if (!newData.num_iscr_operatore) {
                    setAlertTitle('Errore');
                    setAlertMessage('Devi inserire il numero iscr. operatore');
                } else {
                    setAlertTitle('Errore');
                    setAlertMessage('Errore nel caricamento del certificato (file/password)');
                }
            }
            setIsAlertOpen(true);
        } catch (error) {
        }
    }

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];
        setFile(file);
        if (file) {
            setFileName(file.name);
        }
    };


    return (
        <Box sx={{padding: 2}}>
            <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
                <MuiInputTextField
                    onChange={(e) => setNumIscrOperatore(e.target.value)}
                    label='Num. iscr. operatore'
                    type="text"
                    value={numIscrOperatore}
                    required={false}
                    toolTipInfo={'Lo trovi su Pratiche, consultazione archivio pratiche, cliccando sulla piccola icona "Dettaglio Pratica"'}
                />
                <MuiInputTextField
                    onChange={(e) => setPassword(e.target.value)}
                    label='password certificato p12'
                    type="text"
                    value={password}
                    required={true}
                    toolTipInfo={'Ti viene comunicata nel momento in cui attivi e scarichi il certificato.'}
                />
                <MuiUploadFile
                    pdfFileName={fileName}
                    handleFileChange={handleFileChange}
                    label={'carica certificato .p12'}
                    toolTipInfo={'Lo trovi su Interoperabilità, Emisssione certificati digitali RENTRI'}
                />
            </Box>

            {objToEdit && objToEdit.id && objToEdit.certificate_info && <RentriCertificateCard obj={objToEdit}/>}

            <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                <MuiFabButtonBack onClick={onClose}/>
                {/*{objToEdit &&*/}
                {/*    <MuiFabButtonDelete onClick={() => {*/}
                {/*        setConfirmationOpen(true)*/}
                {/*    }}/>}*/}
                {objToEdit ?
                    <MuiFabButtonSaveAs onClick={handleSubmit}/>
                    :
                    <MuiFabButtonSave onClick={handleSubmit}/>}
            </Box>
            <MuiAlertDialog
                open={isAlertOpen}
                title={alertTitle}
                content={alertMessage}
                onClose={() => setIsAlertOpen(false)}
                onConfirm={() => {
                    setIsAlertOpen(false)
                    objToEdit?.certificate_info && objToEdit?.num_iscr_operatore && onClose();
                }
                }
                ask_for_delete={false}
            />
        </Box>
    );
};

export default RentriCertificateForm;