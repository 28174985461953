import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomAxiosError} from "../../../../../../../../logics/api_config";
import {useApiRentriCalls} from "../../../api-hooks/api_rentri_config";
import {
    IRentriUnitaMisura
} from "../../../../../../../../utils/interfaces/special_wasty/rentri/codifiche/rifiuto/IRentriUnitaMisura";


const useApiGetRentriUnitaMisura = (): UseQueryResult<IRentriUnitaMisura[], CustomAxiosError> => {
    const {get} = useApiRentriCalls()

    async function doGet(): Promise<IRentriUnitaMisura[]> {
        try {
            const response = await get(`/codifiche/v1.0/lookup/unita-misura`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetRentriUnitaMisura"],
        queryFn: () => doGet(),
    })
};

export default useApiGetRentriUnitaMisura