import React, {useEffect, useState} from 'react';

import {Box, Grid} from "@mui/material";
import {IWasteCode} from "../../../../../utils/interfaces/special_wasty/waste_code/IWasteCode";
import useApiCreateWasteCode from "./api-hooks/useApiCreateWasteCode";
import {IMyCompanySite} from "../../../../../utils/interfaces/my_company/my_company_site/IMyCompanySite";
import useApiUpdateWasteCode from "./api-hooks/useApiUpdateWasteCode";
import useApiGetWasteCode from "./api-hooks/useApiGetWasteCode";
import MuiInputTextField from "../../../../ui/mui/mui-input/MuiInputTextField";
import MuiFabButtonBack from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonSaveAs from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";
import {
    IRentriCodiceEer
} from "../../../../../utils/interfaces/special_wasty/rentri/codifiche/rifiuto/IRentriCodiceEer";
import RentriCodiciEerSelect from "../rentri/codifiche/rifiuto/RentriCodiciEerSelect";
import RentriClassiAdrSelect from "../rentri/codifiche/rifiuto/RentriClassiAdrSelect";
import {
    IRentriClasseAdr
} from "../../../../../utils/interfaces/special_wasty/rentri/codifiche/rifiuto/IRentriClasseAdr";
import {IRentriAttivita} from "../../../../../utils/interfaces/special_wasty/rentri/codifiche/rifiuto/IRentriAttivita";
import RentriAttivitaSelect from "../rentri/codifiche/rifiuto/RentriAttivitaSelect";
import {
    IRentriAttivitaRs
} from "../../../../../utils/interfaces/special_wasty/rentri/codifiche/rifiuto/IRentriAttivitaRs";
import RentriAttivitaRsSelect from "../rentri/codifiche/rifiuto/RentriAttivitaRsSelect";
import {
    IRentriCaratteristichePericolo
} from "../../../../../utils/interfaces/special_wasty/rentri/codifiche/rifiuto/IRentriCaratteristichePericolo";
import RentriCarateristichePericoloSelect from "../rentri/codifiche/rifiuto/RentriCarateristichePericoloSelect";
import {
    IRentriCategoriaRaee
} from "../../../../../utils/interfaces/special_wasty/rentri/codifiche/rifiuto/IRentriCategoriaRaee";
import {
    IRentriMateriale
} from "../../../../../utils/interfaces/special_wasty/rentri/codifiche/rifiuto/IRentriMateriale";
import {
    IRentriProvenienza
} from "../../../../../utils/interfaces/special_wasty/rentri/codifiche/rifiuto/IRentriProvenienza";
import {
    IRentriStatoFisico
} from "../../../../../utils/interfaces/special_wasty/rentri/codifiche/rifiuto/IRentriStatoFisico";
import {
    IRentriUnitaMisura
} from "../../../../../utils/interfaces/special_wasty/rentri/codifiche/rifiuto/IRentriUnitaMisura";
import RentriCategoriaRaeeSelect from "../rentri/codifiche/rifiuto/RentriCategoriaRaeeSelect";
import RentriMaterialeSelect from "../rentri/codifiche/rifiuto/RentriMaterialeSelect";
import RentriProvenienzaSelect from "../rentri/codifiche/rifiuto/RentriProvenienzaSelect";
import RentriStatoFisicoSelect from "../rentri/codifiche/rifiuto/RentriStatoFisicoSelect";
import RentriUnitaMisuraSelect from "../rentri/codifiche/rifiuto/RentriUnitaMisuraSelect";
import MuiBorderedBox from "../../../../ui/mui/MuiBorderedBox";


interface Props {
    objToEdit: IWasteCode | null | undefined;
    my_company_site: IMyCompanySite | undefined,
    onClose: () => void;
    onSelect?: (obj: IWasteCode | undefined) => void,
}

const WasteCodeForm: React.FC<Props> = ({objToEdit, onClose, onSelect, my_company_site}) => {
    const [codeEer, setCodeEer] = useState<IRentriCodiceEer | undefined>();
    const [classeAdr, setClasseADr] = useState<IRentriClasseAdr | undefined>();
    const [attivita, setAttivita] = useState<IRentriAttivita | undefined>();
    const [attivitaRs, setAttivitaRs] = useState<IRentriAttivitaRs | undefined>();
    const [caratteristichePericolo, setCaratteristichePericolo] = useState<IRentriCaratteristichePericolo | undefined>();
    const [categoriaRaee, setCategoriaRaee] = useState<IRentriCategoriaRaee | undefined>();
    const [materiale, setMateriale] = useState<IRentriMateriale | undefined>();
    const [provenienza, setProvenienza] = useState<IRentriProvenienza | undefined>();
    const [statoFisico, setStatoFisico] = useState<IRentriStatoFisico | undefined>();
    const [unitaMisura, setUnitaMisura] = useState<IRentriUnitaMisura | undefined>();
    const [internalNotes, setInternalNotes] = useState<string>();
    const [wasteType, setWasteType] = useState<string>();

    const {
        data: detailedObj,
    } = useApiGetWasteCode(objToEdit?.id, my_company_site?.id);


    useEffect(() => {
        if (detailedObj) {
            setCodeEer(detailedObj.codice_eer);
            setInternalNotes(detailedObj.internal_notes);
            setWasteType(detailedObj.waste_type);
            setClasseADr(detailedObj.classe_adr);
            setAttivita(detailedObj.attivita)
            setAttivitaRs(detailedObj.attivita_rs)
            setCaratteristichePericolo(detailedObj.caratteristiche_pericolo)
            setCategoriaRaee(detailedObj.categoria_raee);
            setMateriale(detailedObj.materiale);
            setProvenienza(detailedObj.provenienza);
            setStatoFisico(detailedObj.stato_fisico);
            setUnitaMisura(detailedObj.unita_misura);
        }
    }, [detailedObj]);


    const createMutation = useApiCreateWasteCode(my_company_site?.id);
    const updateMutation = useApiUpdateWasteCode(my_company_site?.id);


    const handleSubmit = async () => {
        try {
            const payload = {
                codice_eer_id: codeEer ? codeEer.id : null,
                internal_notes: internalNotes ? internalNotes : null,
                waste_type: wasteType ? wasteType : null,
                classe_adr_id: classeAdr ? classeAdr.id : null,
                attivita_id: attivita ? attivita.id : null,
                attivita_rs_id: attivitaRs ? attivitaRs.id : null,
                caratteristiche_pericolo_id: caratteristichePericolo ? caratteristichePericolo.id : null,
                categoria_raee_id: categoriaRaee ? categoriaRaee.id : null,
                materiale_id: materiale ? materiale.id : null,
                provenienza_id: provenienza ? provenienza.id : null,
                stato_fisico_id: statoFisico ? statoFisico.id : null,
                unita_misura_id: unitaMisura ? unitaMisura.id : null,
            };

            let newData;

            if (detailedObj !== null && detailedObj !== undefined) {
                newData = await updateMutation.mutateAsync({id: detailedObj.id, data: payload});
            } else {
                newData = await createMutation.mutateAsync(payload);
            }
            onClose();
            onSelect && onSelect(newData);

        } catch (error) {
        }
    }


    return (
        <Box sx={{padding: 0}}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <RentriCodiciEerSelect
                        label={'codice eer'}
                        obj={codeEer}
                        onChange={(obj) => setCodeEer(obj)}
                        className={''}
                        required={false}
                    />

                    <RentriClassiAdrSelect
                        label={'classe adr'}
                        obj={classeAdr}
                        onChange={(obj) => setClasseADr(obj)}
                        className={''}
                        required={false}
                    />

                    <RentriAttivitaSelect
                        label={'attivita'}
                        obj={attivita}
                        onChange={(obj) => setAttivita(obj)}
                        className={''}
                        required={false}
                    />

                    <RentriAttivitaRsSelect
                        label={'attivita rs'}
                        obj={attivitaRs}
                        onChange={(obj) => setAttivitaRs(obj)}
                        className={''}
                        required={false}
                    />

                    <RentriCarateristichePericoloSelect
                        label={'caratteristiche pericolo'}
                        obj={caratteristichePericolo}
                        onChange={(obj) => setCaratteristichePericolo(obj)}
                        className={''}
                        required={false}
                    />

                    <RentriCategoriaRaeeSelect
                        label={'categoria raee'}
                        obj={categoriaRaee}
                        onChange={(obj) => setCategoriaRaee(obj)}
                        className={''}
                        required={false}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <RentriMaterialeSelect
                        label={'materiale'}
                        obj={materiale}
                        onChange={(obj) => setMateriale(obj)}
                        className={''}
                        required={false}
                    />

                    <RentriProvenienzaSelect
                        label={'provenienza'}
                        obj={provenienza}
                        onChange={(obj) => setProvenienza(obj)}
                        className={''}
                        required={false}
                    />

                    <RentriStatoFisicoSelect
                        label={'stato fisico'}
                        obj={statoFisico}
                        onChange={(obj) => setStatoFisico(obj)}
                        className={''}
                        required={false}
                    />

                    <RentriUnitaMisuraSelect
                        label={'unita misura'}
                        obj={unitaMisura}
                        onChange={(obj) => setUnitaMisura(obj)}
                        className={''}
                        required={false}
                    />

                    <MuiBorderedBox label={'campi liberi'} className={'mt-6 mb-4'}>
                        <>
                            <MuiInputTextField
                                onChange={(e) => setWasteType(e.target.value)}
                                label='waste type'
                                type="text"
                                value={wasteType}
                                required={false}
                            />


                            <MuiInputTextField
                                onChange={(e) => setInternalNotes(e.target.value)}
                                label='internal notes'
                                type="text"
                                value={internalNotes}
                            />
                        </>
                    </MuiBorderedBox>


                </Grid>
            </Grid>

            <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                <MuiFabButtonBack onClick={onClose}/>
                {/*{objToEdit &&*/}
                {/*    <MuiFabButtonDelete onClick={() => {*/}
                {/*        setConfirmationOpen(true)*/}
                {/*    }}/>}*/}
                {objToEdit ?
                    <MuiFabButtonSaveAs onClick={handleSubmit}/>
                    :
                    <MuiFabButtonSave onClick={handleSubmit}/>}
            </Box>
        </Box>
    );
};

export default WasteCodeForm;