import React, {useEffect, useState} from 'react';
import {InputAdornment, TextField, Tooltip} from '@mui/material';
import Box from '@mui/material/Box';
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import {ClearIcon, DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";

interface MuiTextFieldProps {
    label: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement> | any) => void;
    inputRef?: React.RefObject<HTMLInputElement>;
    borderColor?: string;
    onFocusBorderColor?: string;
    type?: string;
    value?: string | number | null;
    required?: boolean;
    step?: string;
    rows?: number;
    disabled?: boolean;
    toolTipInfo?: string;
    children?: React.ReactNode;
}

const MuiInputTextField: React.FC<MuiTextFieldProps> = ({
                                                            label,
                                                            onChange,
                                                            inputRef,
                                                            borderColor,
                                                            onFocusBorderColor,
                                                            type = 'text',
                                                            value,
                                                            required,
                                                            step,
                                                            rows,
                                                            disabled = false,
                                                            toolTipInfo,
                                                            children,
                                                        }) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const [isFocused, setIsFocused] = useState(false);

    const _borderColor = borderColor ? borderColor : theme.palette.primary.light;
    const _onFocusBorderColor = onFocusBorderColor ? onFocusBorderColor : theme.palette.primary.dark;

    useEffect(() => {
        if (inputRef && inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputRef]);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (type === 'number' && e.key === 'e') {
            e.preventDefault();
        }
    };


    return (
        <Tooltip
            placement="top"
            disableFocusListener
            title={t(toolTipInfo ? toolTipInfo : '')}
            sx={{fontWeight: 'bold'}}
        >
            <Box sx={{position: 'relative', width: '100%'}}>
                {children && (
                    <Box sx={{position: 'absolute', top: -10, right: 0, display: 'flex', alignItems: 'center', gap: 2}}
                    >
                        {children}
                    </Box>
                )}
                <Box sx={{marginTop: children ? '24px' : '24px'}}>
                    {type === 'date' ? (
                        <DatePicker
                            name={t(label)}
                            label={t(label)}
                            views={['year', 'month', 'day']}
                            sx={{
                                bgcolor: 'white',

                                '& label.Mui-focused': {
                                    color: _borderColor,
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderWidth: 1,
                                    borderColor: _borderColor,
                                },
                                '& .MuiInput-underline:after': {
                                    borderBottomColor: _onFocusBorderColor,
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: _borderColor,
                                },
                                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: _borderColor,
                                },
                                '& .MuiFilledInput-underline:after': {
                                    borderBottomColor: _onFocusBorderColor,
                                },
                                '& .MuiFilledInput-underline:before': {
                                    borderBottomColor: _borderColor,
                                },
                                '&:hover .MuiFilledInput-underline:before': {
                                    borderBottomColor: _borderColor,
                                },
                                '& .MuiFilledInput-root.Mui-focused .MuiFilledInput-underline:after': {
                                    borderBottomColor: _borderColor,
                                },

                            }}
                            slotProps={{
                                textField: {
                                    size: 'medium',
                                    fullWidth: true,
                                    InputProps: {
                                        startAdornment: (
                                            <>
                                                {value && (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => {
                                                                onChange('')
                                                            }}
                                                            disabled={disabled || !value}
                                                        >
                                                            <ClearIcon/>
                                                        </IconButton>
                                                    </InputAdornment>
                                                )}
                                            </>
                                        ),
                                    },
                                },
                            }}
                            // sx={{width: "18rem"}}
                            format="DD/MM/YY"
                            disabled={disabled}
                            value={value ? dayjs(value) : null}
                            onChange={(value) => {
                                if (value && value.format() === "Invalid Date") return
                                const dateValue = value ? value.format("YYYY-MM-DD") : undefined
                                onChange(dateValue || "");
                            }}
                        />
                    ) : (
                        <TextField
                            type={type}
                            label={t(label)}
                            onKeyDown={handleKeyDown}
                            onChange={onChange}
                            onFocus={() => setIsFocused(true)}
                            onBlur={() => setIsFocused(false)}
                            inputRef={inputRef}
                            fullWidth
                            value={value !== undefined && value !== null ? value : ''}
                            required={required}
                            multiline={!!(rows && rows > 1)}
                            rows={rows}
                            margin="dense"
                            autoComplete="off"
                            size="small"
                            variant="filled"
                            disabled={disabled}
                            sx={{
                                bgcolor: 'white',
                                '& label.Mui-focused': {
                                    color: _borderColor,
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderWidth: 1,
                                    borderColor: _borderColor,
                                },
                                '& .MuiInput-underline:after': {
                                    borderBottomColor: _onFocusBorderColor,
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: _borderColor,
                                },
                                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: _borderColor,
                                },
                                '& .MuiFilledInput-underline:after': {
                                    borderBottomColor: _onFocusBorderColor,
                                },
                                '& .MuiFilledInput-underline:before': {
                                    borderBottomColor: _borderColor,
                                },
                                '&:hover .MuiFilledInput-underline:before': {
                                    borderBottomColor: _borderColor,
                                },
                                '& .MuiFilledInput-root.Mui-focused .MuiFilledInput-underline:after': {
                                    borderBottomColor: _borderColor,
                                },
                                ...(type === 'number' && {
                                    '& input[type=number]': {
                                        MozAppearance: 'textfield',
                                    },
                                    '& input[type=number]::-webkit-outer-spin-button': {
                                        WebkitAppearance: 'none',
                                        margin: 0,
                                    },
                                    '& input[type=number]::-webkit-inner-spin-button': {
                                        WebkitAppearance: 'none',
                                        margin: 0,
                                    },
                                }),
                            }}
                        />
                    )}
                </Box>

            </Box>

        </Tooltip>

    );
};

export default MuiInputTextField;
