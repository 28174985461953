import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomAxiosError} from "../../../../../../../logics/api_config";
import {useApiRentriCalls} from "../../api-hooks/api_rentri_config";
import {IRentriSite} from "../../../../../../../utils/interfaces/special_wasty/rentri/anagrafiche/IRentriSite";


const useApiGetRentriSiti = (num_iscr_operatore: string | undefined): UseQueryResult<IRentriSite[], CustomAxiosError> => {
    const {get} = useApiRentriCalls()

    async function doGet(): Promise<IRentriSite[]> {
        try {
            const response = await get(`/anagrafiche/v1.0/operatore/${num_iscr_operatore}/siti`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetRentriSiti", num_iscr_operatore],
        queryFn: () => doGet(),
        enabled: !!num_iscr_operatore,
    })
};

export default useApiGetRentriSiti