import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomAxiosError} from "../../../../../../../../logics/api_config";
import {useApiRentriCalls} from "../../../api-hooks/api_rentri_config";
import {
    IRentriProvince
} from "../../../../../../../../utils/interfaces/special_wasty/rentri/codifiche/territoriali/IRentriProvince";


const useApiGetRentriProvince = (): UseQueryResult<IRentriProvince[], CustomAxiosError> => {
    const {get} = useApiRentriCalls()

    async function doGet(): Promise<IRentriProvince[]> {
        try {
            const response = await get(`/codifiche/v1.0/lookup/province`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetRentriProvince"],
        queryFn: () => doGet(),
    })
};

export default useApiGetRentriProvince