import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {IWastyRecipient} from "../../../../../../utils/interfaces/special_wasty/wasty_recipient/IWastyRecipient";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useAuth} from "../../../../../../utils/contexts/auth-context";


const useApiUpdateWastyRecipient = (): UseMutationResult<IWastyRecipient, CustomAxiosError, {
    id: number;
    data: any
}, unknown> => {
    const queryClient = useQueryClient();
    const {selectedCompany} = useAuth()

    const {put} = useApiCalls()


    async function doUpdate(company_token: string | undefined,
                            pk: number | undefined,
                            data: any): Promise<IWastyRecipient> {
        try {
            const response = await put(`/api/company/${company_token}/special_wasty/wasty_recipient/${pk}`, data);
            return response.data as IWastyRecipient;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<IWastyRecipient, CustomAxiosError, {
        id: number;
        data: any
    }, unknown>({
        mutationFn: async ({id, data}) => {
            return await doUpdate(selectedCompany?.company_token, id, data);
        },
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({queryKey: ['useApiGetWastyRecipient', data.id]});
            await queryClient.invalidateQueries({queryKey: ['useApiGetWastyRecipients']});
        },
    });
};


export default useApiUpdateWastyRecipient;