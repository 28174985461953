import React, {useEffect, useState} from 'react';
import {IPeopleArticle} from "../../../../../../utils/interfaces/tannery_production/articles/IPeopleArticle";
import {IArticle} from "../../../../../../utils/interfaces/tannery_production/articles/IArticle";
import {useToast} from "../../../../../../utils/contexts/toast-context";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {useQueryClient} from "@tanstack/react-query";
import {IMacroSpessore} from "../../../../../../utils/interfaces/tannery_production/articles/IMacroSpessore";
import {IMacroLinea} from "../../../../../../utils/interfaces/tannery_production/articles/IMacroLinea";
import {IMacroCategory} from "../../../../../../utils/interfaces/tannery_production/articles/IMacroCategory";
import useApiUpdateArticle from "./api-hooks/useApiUpdateArticle";
import useApiCreateArticle from "./api-hooks/useApiCreateArticle";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import {Box} from "@mui/material";
import MuiInputTextField from "../../../../../ui/mui/mui-input/MuiInputTextField";
import MacroSpessoriSelect from "./macro_spessori/MacroSpessoriSelect";
import MacroCategorySelect from "./macro_category/MacroCategorySelect";
import MacroLineeProduzioneSelect from "./macro_linee/MacroLineeProduzioneSelect";
import MuiFabButtonBack from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonSaveAs from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";


interface ArticleFormProps {
    person: IPeopleArticle;
    articleToEdit: IArticle | null;
    onClose: () => void;
}

const ArticleForm: React.FC<ArticleFormProps> = ({articleToEdit, person: initialPerson, onClose}) => {
    const {displayError, displayMessage} = useToast();
    const {selectedCompany} = useAuth();
    const queryClient = useQueryClient();

    const [personObj, setPersonObj] = useState<IPeopleArticle>(initialPerson)
    const [name, setName] = useState(articleToEdit?.product_name);
    const [thickness, setThickness] = useState(articleToEdit?.thickness);
    const [size, setSize] = useState(articleToEdit?.size);
    const [macroSpessore, setMacroSpessore] = useState<IMacroSpessore | undefined | null>()
    const [macroLinea, setMacroLinea] = useState<IMacroLinea | undefined>()
    const [macroCategory, setMacroCategory] = useState<IMacroCategory | undefined | null>()
    const [peopleId, setPeopleId] = useState<number>()
    const [description, setDescription] = useState<{ id: number, text: string }>()

    const mutationHook = articleToEdit ? useApiUpdateArticle : useApiCreateArticle;
    const mutation = mutationHook(selectedCompany?.company_token, personObj.id);

    const handleMacroSpessore = (macroSpessore: IMacroSpessore | null) => {
        setMacroSpessore(macroSpessore);
    }
    const handleMacroLinea = (macroLinea: IMacroLinea | null) => {
        if (macroLinea) {
            setMacroLinea(macroLinea);
        } else {
            setMacroLinea(undefined);
        }

    }

    const handleMacroCategory = (macroCategory: IMacroCategory | null) => {
        setMacroCategory(macroCategory);
    }

    useEffect(() => {
        if (articleToEdit) {
            setMacroLinea(articleToEdit.macro_linea)
            setName(articleToEdit.product_name)
            setMacroSpessore(articleToEdit.macro_spessore)
            setMacroCategory(articleToEdit.macro_category)
            setPeopleId(personObj.id)
            setThickness(articleToEdit.thickness)
            setSize(articleToEdit.size)
            setDescription(articleToEdit.description)
        }
    }, [articleToEdit]);


    const handleSubmit = async () => {

        try {
            const articleData = {
                product_name: name,
                macro_spessore_id: macroSpessore?.id,
                macro_linea_id: macroLinea?.id,
                macro_category_id: macroCategory?.id,
                thickness: thickness,
                size: size,
                parent_id: null,
                people_id: personObj.id,
                description_id: description?.id,
                // Add other fields as needed
            };

            if (articleToEdit !== null) {
                // Editing an existing article
                await mutation.mutateAsync({people_id: personObj.id, id: articleToEdit.id, data: articleData});
                displayMessage('Article updated successfully');
            } else {
                // Adding a new person
                await mutation.mutateAsync(articleData);
                displayMessage('Article added successfully');
            }

            onClose(); // Close the form
            await queryClient.invalidateQueries({queryKey: ['getPeopleArticles']});
        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data && axiosError.response.data.detail) {
                displayError(axiosError.response.data.detail);
            } else {
                displayError('An error occurred while saving the Article');
            }
        }
    };

    return (
        <Box sx={{padding: 2}}>
            <form>
                <MuiInputTextField
                    onChange={(e) => setName(e.target.value)}
                    label='name'
                    type="text"
                    value={name || ''}
                    required={true}
                />

                <MuiInputTextField
                    onChange={(e) => setThickness(e.target.value)}
                    label='thickness'
                    type="text"
                    value={thickness || ''}
                    required={true}
                />

                <MuiInputTextField
                    onChange={(e) => setSize(e.target.value)}
                    label='size'
                    type="text"
                    value={size || ''}
                    required={true}
                />

                <MacroSpessoriSelect spessore={articleToEdit?.macro_spessore} onChange={handleMacroSpessore}/>

                <MacroCategorySelect category={articleToEdit?.macro_category} onChange={handleMacroCategory}/>

                <MacroLineeProduzioneSelect linea={articleToEdit?.macro_linea} onChange={handleMacroLinea}/>

                {/*<DescriptionSelect obj={description} onChange={(obj) => setDescription(obj)} className={''}*/}
                {/*                   scope={'tannery_article'}/>*/}


                <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                    <MuiFabButtonBack onClick={onClose}/>
                    {/*<MuiFabButtonDelete onClick={() => {*/}
                    {/*    setDeleteConfirmationOpen(true)*/}
                    {/*}}/>*/}
                    {articleToEdit ?
                        <MuiFabButtonSaveAs onClick={handleSubmit}/>
                        :
                        <MuiFabButtonSave onClick={handleSubmit}/>}
                </Box>
            </form>
        </Box>
    );
};

export default ArticleForm;
