import React, {useEffect, useState} from 'react';
import {useQueryClient} from "@tanstack/react-query";
import {ITanneryDyeingSchedule} from "../../../../../utils/interfaces/tannery_dyeing/ITanneryDyeingSchedule";
import {useToast} from "../../../../../utils/contexts/toast-context";
import {useAuth} from "../../../../../utils/contexts/auth-context";
import useApiUpdateTanneryDyeingSchedule from "./api-hooks/useApiUpdateTanneryDyeingSchedule";
import useApiCreateTanneryDyeing from "./api-hooks/useApiCreateTanneryDyeing";
import {getCurrentDate} from "../../../../../logics/helpers";
import {CustomAxiosError} from "../../../../../logics/api_config";
import {useTranslation} from "react-i18next";
import useApiGetNextTanneryDyeingId from "./api-hooks/useApiGetNextTanneryDyeingId";
import {useTanneryDyeing} from "../../../../../utils/contexts/tannery_dyeing/tannery_dyeing-context";
import {useLocation, useNavigate} from 'react-router-dom';
import {Box, CardContent, Grid, Typography} from "@mui/material";
import MuiInputTextField from "../../../../ui/mui/mui-input/MuiInputTextField";
import MuiFabButtonBack from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonDelete from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonDelete";
import MuiFabButtonSaveAs from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";
import useApiDeleteTanneryDyeingSchedule from "./api-hooks/useApiDeleteTanneryDyeingSchedule";
import MuiAlertDialog from "../../../../ui/mui/mui_modal/MuiAlertDialog";
import useApiGetTanneryDyeingScheduleCurrent from "./api-hooks/useApiGetTanneryDyeingScheduleCurrent";


interface Props {
    objToEdit: ITanneryDyeingSchedule | null;
    onClose: () => void;
    oldTanneryDyeing?: ITanneryDyeingSchedule | null;
    refecthCurrentData?: () => void;
}

const TanneryDyeingScheduleForm: React.FC<Props> = ({
                                                        objToEdit,
                                                        oldTanneryDyeing,
                                                        onClose,
                                                        refecthCurrentData
                                                    }) => {
    const {t} = useTranslation();
    const {displayError, displayMessage} = useToast();
    const {selectedCompany} = useAuth();
    const queryClient = useQueryClient();
    const {selectTanneryDyeing, isEditable} = useTanneryDyeing()
    const navigate = useNavigate();

    const [listDataType, setListDataType] = useState<string>()
    const location = useLocation();
    useEffect(() => {
        if (location.pathname.startsWith('apps/tannery_dyeing/dyeing_schedule/history')) {
            setListDataType('historical');
        } else if (location.pathname.startsWith('apps/tannery_dyeing/dyeing_schedule/current')) {
            setListDataType('current');
        } else if (location.pathname.startsWith('apps/tannery_dyeing/dyeing_schedule/drafts')) {
            setListDataType('drafts');
        } else {
            setListDataType(undefined);
        }
    }, [location.pathname]);


    const mutationHook = objToEdit ? useApiUpdateTanneryDyeingSchedule : useApiCreateTanneryDyeing;
    const mutation = mutationHook(selectedCompany?.company_token);
    const [allRequirementsAreSatisfied, setAllRequirementsAreSatisfied] = useState(false)
    const [inSubmit, setInSubmit] = useState(false)


    const [tanneryDyeingId, setTanneryDyeingId] = useState<string | undefined>()
    const [weight, setWeight] = useState<number>(0)
    const [drum, setDrum] = useState<number>()
    const [inDrumAt, setInDrumAt] = useState<string>(getCurrentDate)
    const [description, setDescription] = useState<string>()
    const [closedAt, setClosedAt] = useState<string | null>()
    const [customer, setCustomer] = useState<string | undefined>()
    const [article, setArticle] = useState<string | undefined>()
    const [color, setColor] = useState<string | undefined>()

    const {data, isLoading, error} = useApiGetNextTanneryDyeingId(selectedCompany?.company_token);
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }

    useEffect(() => {
        if (!objToEdit && data && data.suggested_id) {
            setTanneryDyeingId(data.suggested_id);
        }
    }, [objToEdit, data]);

    const {
        data: detailedObj,
    } = useApiGetTanneryDyeingScheduleCurrent(selectedCompany?.company_token, objToEdit?.id);

    useEffect(() => {
        setAllRequirementsAreSatisfied(true)
        if (!tanneryDyeingId || !drum || !inDrumAt) {
            setAllRequirementsAreSatisfied(false)
        }
        if (objToEdit?.orders_schedule?.length === 0 && !weight) {
            setAllRequirementsAreSatisfied(false)
        }
    }, [drum, inDrumAt, objToEdit?.orders_schedule?.length, tanneryDyeingId, weight]);


    useEffect(() => {
        if (detailedObj) {
            setTanneryDyeingId(detailedObj.tannery_dyeing_id);
            setWeight(detailedObj.orders_schedule.length > 0 ? 0 : detailedObj.weight);
            setDrum(detailedObj.drum);
            setInDrumAt(detailedObj.in_drum_at);
            setDescription(detailedObj.description);
            setClosedAt(detailedObj.closed_at);
            setCustomer(detailedObj.customer);
            setArticle(detailedObj.article);
            setColor(detailedObj.color);
        }
    }, [detailedObj]);


    const handleSubmit = async () => {
        setInSubmit(true);

        const _closedAt = closedAt ? closedAt : null;

        try {
            const data = {
                tannery_dyeing_id: tanneryDyeingId,
                weight: weight || 0,
                drum: drum || 0,
                in_drum_at: inDrumAt,
                description: description,
                closed_at: _closedAt,
                old_tannery_dyeing_id: oldTanneryDyeing?.id,
                customer: customer,
                article: article,
                color: color,
            };

            if (objToEdit !== null) {
                await mutation.mutateAsync({
                    id: objToEdit.id,
                    data: data,
                });
                displayMessage('TanneryDyeing updated successfully');
                queryClient.setQueryData<ITanneryDyeingSchedule[]>(['getTanneryDyeingSchedule'], (old) => {
                    if (!old) return [];
                    return old.map((item) =>
                        item.id === objToEdit.id ? {...item, ...data} : item
                    );
                });
            } else {
                const newWorking = await mutation.mutateAsync(
                    data,
                );
                displayMessage('TanneryDyeing added successfully');
                queryClient.setQueryData<ITanneryDyeingSchedule[]>(['getTanneryDyeingSchedule'], (old) => {
                    const updatedData = [...(old || []), newWorking];
                    return updatedData.filter((item): item is ITanneryDyeingSchedule => item !== null && item !== undefined);
                });
            }

            setInSubmit(false);
            onClose();

            if (_closedAt) {
                selectTanneryDyeing(undefined);
            }
            if (oldTanneryDyeing) {
                navigate('/apps/tannery_dyeing/dyeing_schedule/current');
            }
            await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingScheduleCurrent']});
            await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingSchedule']});
            await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingRecipe']})
            await queryClient.invalidateQueries({queryKey: ['getDyeToDye']})
            await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingSchedule']});
            await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingScheduleHistorical']});
            await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingScheduleDrafts']});
            await queryClient.invalidateQueries({queryKey: ['getDyeByOrder']});
            await queryClient.invalidateQueries({queryKey: ['getCompanyOrderScheduleByColor']});
            await queryClient.invalidateQueries({queryKey: ['getCompanyOrderSchedule']});

        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data && axiosError.response.data.detail) {
                displayError(axiosError.response.data.detail);
            } else {
                displayError('An error occurred while saving the TanneryDyeing');
            }
        }
        refecthCurrentData && refecthCurrentData()
    };

    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const deleteMutation = useApiDeleteTanneryDyeingSchedule(selectedCompany?.company_token, objToEdit?.id);
    const {mutateAsync: apiDelete} = deleteMutation;
    const handleConfirmDelete = async () => {
        if (objToEdit) {
            try {
                await apiDelete({companyToken: selectedCompany?.company_token, pk: objToEdit.id});
                await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingSchedule']});
                await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingScheduleHistorical']});
                await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingScheduleDrafts']});
                displayMessage('TanneryDyeing deleted successfully');

            } catch (error) {
                const axiosError = error as CustomAxiosError;
                if (axiosError.response && axiosError.response.data && axiosError.response.data.detail) {
                    displayError(axiosError.response.data.detail);
                } else {
                    displayError('An error occurred while deleting the TanneryDyeing');
                }
            }
            onClose();
            refecthCurrentData && refecthCurrentData()
            selectTanneryDyeing(undefined);
        }

    };
    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };


    return (
        <Grid className="">
            <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                        <MuiInputTextField
                            label="tannery_dyeing_id"
                            type="text"
                            value={tanneryDyeingId || ''}
                            onChange={(e) => setTanneryDyeingId(e.target.value)}
                            required={true}
                            disabled={objToEdit && !isEditable ? true : undefined}
                        />
                        <MuiInputTextField
                            label="drum"
                            type="number"
                            value={drum || ''}
                            onChange={(e) => setDrum(parseInt(e.target.value, 10))}
                            required={true}
                            disabled={objToEdit && !isEditable ? true : undefined}
                        />

                        <MuiInputTextField
                            label="weight"
                            type="number"
                            value={weight || ''}
                            onChange={(e) => setWeight(parseInt(e.target.value, 10))}
                            required={true}
                            disabled={!!(detailedObj?.closed_at || (detailedObj?.orders_schedule?.length ?? 0) > 0)}

                        />

                        <MuiInputTextField
                            label="in_drum_at"
                            type="date"
                            value={inDrumAt}
                            onChange={setInDrumAt}
                            required={true}
                            disabled={!isEditable}
                        />

                        <MuiInputTextField
                            label="closed_at"
                            type="date"
                            value={closedAt}
                            onChange={setClosedAt}
                            required={false}
                        />
                        <MuiInputTextField
                            label="description"
                            type="text"
                            value={description || ''}
                            onChange={(e) => setDescription(e.target.value)}
                            required={false}
                            rows={3}
                            disabled={objToEdit && !isEditable ? true : undefined}
                        />

                    </Grid>


                    <Grid item xs={12} md={6}>
                        <MuiInputTextField
                            label="customer"
                            type="text"
                            value={customer || ''}
                            onChange={(e) => setCustomer(e.target.value)}
                            required={false}
                            disabled={!!(detailedObj?.closed_at || (detailedObj?.orders_schedule?.length ?? 0) > 0)}

                        />

                        <MuiInputTextField
                            label="article"
                            type="text"
                            value={article || ''}
                            onChange={(e) => setArticle(e.target.value)}
                            required={false}
                            disabled={!!(detailedObj?.closed_at || (detailedObj?.orders_schedule?.length ?? 0) > 0)}

                        />

                        <MuiInputTextField
                            label="color"
                            type="text"
                            value={color || ''}
                            onChange={(e) => setColor(e.target.value)}
                            required={false}
                            disabled={!!(detailedObj?.closed_at || (detailedObj?.orders_schedule?.length ?? 0) > 0)}

                        />


                        {!(weight > 0) &&
                            <CardContent>
                                {detailedObj?.orders_schedule ? null :
                                    <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                                        {t('tannery_dyeing_model_note')}
                                    </Typography>}
                            </CardContent>
                        }
                    </Grid>


                </Grid>
                <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                    <MuiFabButtonBack onClick={onClose}/>
                    {listDataType == 'current' &&
                        <MuiFabButtonDelete onClick={() => {
                            setDeleteConfirmationOpen(true)
                        }}/>}
                    {objToEdit ? (
                        <MuiFabButtonSaveAs onClick={handleSubmit}/>
                    ) : (
                        <MuiFabButtonSave onClick={handleSubmit}/>
                    )}
                </Box>
            </form>
            <MuiAlertDialog
                open={isDeleteConfirmationOpen}
                title="delete_confirmation"
                content="ask_for_delete"
                onClose={handleCancelDelete}
                onConfirm={handleConfirmDelete}
            />
        </Grid>
    );
};

export default TanneryDyeingScheduleForm;
