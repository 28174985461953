import React, {useEffect, useState} from 'react';
import {useToast} from '../../../../../../../utils/contexts/toast-context';
import {useQueryClient} from '@tanstack/react-query';
import {useTranslation} from "react-i18next";
import {useMyDocuments} from "../../../../../../../utils/contexts/my_documents/my_documents-context";
import {IDocRow} from "../../../../../../../utils/interfaces/my_documents/doc/IDocRow";
import useApiUpdateDocRow from "./api-hooks/useApiUpdateDocRow";
import useApiCreateDocRow from "./api-hooks/useApiCreateDocRow";
import VatCodeSelect from "../../../VatCodeSelect";
import {IVatCode} from "../../../../../../../utils/interfaces/my_documents/IVatCode";
import UmSelect from "../../../../service/UmSelect";
import {IUm} from "../../../../../../../utils/interfaces/service/IUm";
import {useAppGarage} from "../../../../../../../utils/contexts/appGarage-context";
import {Box} from "@mui/material";
import MuiFabButtonBack from "../../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonSaveAs from "../../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";
import MuiInputTextField from "../../../../../../ui/mui/mui-input/MuiInputTextField";
import useApiGetDocRow from "./api-hooks/useApiGetDocRow";


interface Props {
    docRow: IDocRow | undefined;
    enforcedVatCode?: IVatCode | undefined,
    suggestedVatCode?: IVatCode | undefined,
    onClose: () => void;
}

const DocRowForm: React.FC<Props> = ({docRow: initialDocRow, onClose, enforcedVatCode, suggestedVatCode}) => {
    const {displayError, displayMessage} = useToast();
    const queryClient = useQueryClient();
    const {t} = useTranslation();
    const {selectedSystemYear} = useAppGarage()


    const {selectedDocVersion, selectDocVersion, selectedDocDetail} = useMyDocuments()

    const [docRow, setDocRow] = useState<IDocRow | undefined>()
    const [quantity, setQuantity] = useState<number | undefined>()
    const [price, setPrice] = useState<number | undefined>()
    const [vatCode, setVatCode] = useState<IVatCode | null | undefined>(enforcedVatCode ? enforcedVatCode : suggestedVatCode)
    const [description, setDescription] = useState<string | undefined>()
    const [um, setUm] = useState<IUm | null>()
    const [discountPerc, setDiscountPerc] = useState<number | undefined>()


    const [allRequirementsAreSatisfied, setAllRequirementsAreSatisfied] = useState<boolean>(false)

    const handleClose = () => {
        onClose()
    };

    const handleVatCodeChange = (obj: IVatCode | null | undefined) => {
        setVatCode(obj);
    };
    const handleUmChange = (obj: IUm | null | undefined) => {
        setUm(obj);
    };


    const handleSubmit = async () => {
        try {
            const payload = {
                quantity: quantity,
                price: price,
                vat_code: vatCode?.id,
                description: description,
                um: um?.id,
                discount_perc: discountPerc,
                version: selectedDocVersion,
            };

            if (docRow !== null && docRow !== undefined) {
                const _docRow = await updateMutation.mutateAsync({
                    id: docRow.id,
                    doc_id: docRow.doc,
                    doc_year: selectedSystemYear,
                    data: payload
                });
                displayMessage('DocRow updated successfully');
                setDocRow(_docRow);
                selectDocVersion(selectedDocVersion + 1)
            } else {
                const _docRow = await createMutation.mutateAsync({
                    doc_id: selectedDocDetail?.id,
                    doc_year: selectedSystemYear,
                    data: payload
                });
                displayMessage('DocRow created successfully');
                setDocRow(_docRow);
                selectDocVersion(selectedDocVersion + 1)
            }

            handleClose();


        } catch (error) {
        }

    };

    const updateMutation = useApiUpdateDocRow()
    const createMutation = useApiCreateDocRow()


    useEffect(() => {
        setAllRequirementsAreSatisfied(true)
        if (!vatCode) {
            setAllRequirementsAreSatisfied(false);
        }
    }, [price, quantity, selectedDocVersion, vatCode]);

    const {
        data: detailedObj,
    } = useApiGetDocRow(initialDocRow?.id);


    useEffect(() => {
        if (detailedObj) {
            setDocRow(detailedObj);
            setQuantity(detailedObj.quantity)
            setPrice(detailedObj.price)
            setVatCode(detailedObj.vat_code)
            setUm(detailedObj.um)
            setDescription(detailedObj.description)
            setDiscountPerc(detailedObj.discount_perc)
        }
    }, [detailedObj]);

    //note_marco: form box con gap y


    return (
        <Box sx={{padding: 0}}>
            <form>
                <Box className="grid grid-cols-1 gap-4">
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: 0.5}}>
                        <MuiInputTextField
                            onChange={(e) => setDescription(e.target.value)}
                            label='description'
                            type="text"
                            value={description || ''}
                            required={false}
                            rows={5}
                        />
                        <MuiInputTextField
                            label="quantity"
                            type="text"
                            value={quantity}
                            onChange={(e) => setQuantity(parseFloat(e.target.value))}
                            required={!!price}
                        />

                        <MuiInputTextField
                            label="price"
                            type="number"
                            value={price}
                            onChange={(e) => setPrice(parseFloat(e.target.value))}
                            required={!!quantity}
                        />
                        <MuiInputTextField
                            label="discount perc"
                            type="number"
                            value={discountPerc}
                            onChange={(e) => setDiscountPerc(parseFloat(e.target.value))}
                            required={false}
                        />

                        <VatCodeSelect
                            label={'vat_code'}
                            obj={vatCode ? vatCode : undefined}
                            onChange={handleVatCodeChange}
                            className={''}
                            required={true}
                            clearable={false}
                            isDisabled={!!enforcedVatCode}
                        ></VatCodeSelect>
                        <UmSelect obj={um ? um : undefined} onChange={handleUmChange} className={''}/>

                    </Box>
                </Box>
                <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                    <MuiFabButtonBack onClick={onClose}/>
                    {/*<MuiFabButtonDelete onClick={() => {*/}
                    {/*    setDeleteConfirmationOpen(true)*/}
                    {/*}}/>*/}
                    {initialDocRow ?
                        <MuiFabButtonSaveAs onClick={handleSubmit} isDisabled={!allRequirementsAreSatisfied}/>
                        :
                        <MuiFabButtonSave onClick={handleSubmit} isDisabled={!allRequirementsAreSatisfied}/>}
                </Box>
            </form>
        </Box>

    );
};

export default DocRowForm;
