import React, {useEffect, useMemo, useState} from 'react';
import {IDocRow} from "../../../../../../../utils/interfaces/my_documents/doc/IDocRow";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import {useToast} from "../../../../../../../utils/contexts/toast-context";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {useQueryClient} from "@tanstack/react-query";
import useApiDeleteDocRow from "./api-hooks/useApiDeleteDocRow";
import {useMyDocuments} from "../../../../../../../utils/contexts/my_documents/my_documents-context";
import {CustomAxiosError} from "../../../../../../../logics/api_config";
import useApiGetDocRows from "./api-hooks/useApiGetDocRows";
import customCellRenderer from "./customCellRenderer";
import {
    MaterialReactTable,
    MRT_ActionMenuItem,
    MRT_ColumnDef,
    MRT_Row,
    useMaterialReactTable
} from "material-react-table";
import {
    getMuiTableBodyCellProps,
    getMuiTableBodyRowProps,
    getMuiTableHeadCellProps,
    getMuiTablePaperProps
} from "../../../../../../ui/mui/mui_react_table/MuiReactTableStyles";
import {Delete, Edit} from "@mui/icons-material";
import {Avatar, Box} from "@mui/material";
import {IoDocuments} from "react-icons/io5";
import MuiReadOnlyBoxBody from "../../../../../../ui/mui/mui-display-data/MuiReadOnlyBoxBody";
import MRTMuiButton from "../../../../../../ui/mui/mui_react_table/MRTMuiButton";
import AddIcon from "@mui/icons-material/Add";
import Loading from "../../../../../../ui/Loading";
import MuiAlertDialog from "../../../../../../ui/mui/mui_modal/MuiAlertDialog";
import MuiFetchingSpinner from "../../../../../../ui/mui/MuiFetchingSpinner";
import DocRowForm from "./DocRowForm";
import MuiModal from "../../../../../../ui/mui/mui_modal/MuiModal";
import {IDocDetail} from "../../../../../../../utils/interfaces/my_documents/doc/IDocDetail";
import useApiGetVatCode from "../../../api-hooks/useApiGetVatCode";
import useApiMoveDocRow from "./api-hooks/useApiMoveDocRow";


interface IDocRowWithCustomField extends IDocRow {
    custom_field: JSX.Element;
}

interface Props {
    docDetailed: IDocDetail | undefined,
}


const DocRowTable = ({docDetailed}: Props) => {
    const {selectedCompany} = useAuth();
    const {displayError, displayMessage} = useToast()
    const theme = useTheme();
    const {t} = useTranslation()
    const queryClient = useQueryClient();
    const {
        selectedDocDetail,
        selectedDocRow,
        selectDocRow,
        selectedDocVersion,
        selectDocVersion
    } = useMyDocuments()

    const moveMutation = useApiMoveDocRow();


    const [selectedRow, setSelectedRow] = useState<IDocRow | undefined>()
    const [rows, setRows] = useState<IDocRow[] | undefined>()

    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const deleteMutation = useApiDeleteDocRow(selectedDocDetail?.doc_year, selectedDocDetail?.id, selectedRow?.id, selectedDocVersion);
    const {mutateAsync: apiDelete} = deleteMutation;

    const handleConfirmDelete = async () => {
        try {
            await apiDelete({
                doc_year: selectedDocDetail?.doc_year,
                doc_id: selectedDocDetail?.id,
                pk: selectedRow?.id,
                version: selectedDocVersion
            });
        } catch (error) {
        }
        selectDocVersion(selectedDocVersion + 1);
        setDeleteConfirmationOpen(false);
    };
    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };


    const {data, isLoading, error, isFetching} = useApiGetDocRows();
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }


    useEffect(() => {
        if (data) {
            setRows(data);
        }
    }, [data]);

    const [docRowsWithCustomField, setDocRowsWithCustomField] = useState<IDocRowWithCustomField[] | undefined>();
    useEffect(() => {
        const dataWithCustomField: IDocRowWithCustomField[] | undefined = rows?.map((row) => ({
            ...row,
            custom_field: customCellRenderer(row, theme, t),
        }));
        setDocRowsWithCustomField(dataWithCustomField);
    }, [rows]);


    const columns = useMemo<MRT_ColumnDef<IDocRow>[]>(
        () => [
            {
                accessorKey: 'custom_field',
                header: '',
                enableColumnActions: false,
                enableColumnFilter: false,
                enableColumnOrdering: false,
                enableSorting: false,
            },
            {
                accessorKey: 'row_position',
                header: t('row_position'),
                enableColumnFilter: false,
            },
            {
                accessorKey: 'description',
                header: t('description'),
                filterFn: 'contains',

            },
            {
                accessorKey: 'um.um',
                header: t('um'),
                enableColumnFilter: false,
            },
            {
                accessorKey: 'quantity',
                header: t('quantity'),
                enableColumnFilter: false,
            },
            {
                accessorKey: 'price',
                header: t('price'),
                enableColumnFilter: false,
            },
            {
                accessorKey: 'discount_perc',
                header: t('discount perc'),
                enableColumnFilter: false,
            },
            {
                accessorKey: 'net_amount',
                header: t('net amount'),
                enableColumnFilter: false,
                accessorFn: (row) => {
                    return row.total_price ? row.total_price.toFixed(2) : ''
                },
            },
            // {
            //     accessorKey: 'net_amount (- discount)',
            //     header: t('net_amount (-discount)'),
            //     enableColumnFilter: false,
            //     accessorFn: (row) => {
            //         return row.net_amount_for_total_doc_calculation ? row.net_amount_for_total_doc_calculation.toFixed(2) : ''
            //     },
            // },
            // {
            //     accessorKey: 'vat_amount',
            //     header: t('vat_amount'),
            //     enableColumnFilter: false,
            //     accessorFn: (row) => {
            //         return row.vat_amount_for_total_doc_calculation ? row.vat_amount_for_total_doc_calculation.toFixed(2) : ''
            //     },
            // },
            // {
            //     accessorKey: 'amount_with_vat',
            //     header: t('amount_with_vat'),
            //     enableColumnFilter: false,
            //     accessorFn: (row) => {
            //         return row.amount_with_vat_for_total_doc_calculation ? row.amount_with_vat_for_total_doc_calculation.toFixed(2) : ''
            //     },
            // },
            {
                header: t('vat_code'),
                enableColumnFilter: false,
                accessorFn: (row) => {
                    return row.vat_code && row.vat_code.fe_natura + ' - ' + row.vat_code.rate + '%'
                },
            },


        ],
        [rows]
    );


    const table = useMaterialReactTable<IDocRow>({
        columns,
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 80,
        },
        data: docRowsWithCustomField ? docRowsWithCustomField : [],
        columnFilterDisplayMode: 'popover',
        globalFilterFn: 'contains',
        enableFullScreenToggle: false,
        enableFilters: true,
        enableDensityToggle: true,
        muiTableHeadCellProps: getMuiTableHeadCellProps(theme.palette.primary.main),
        muiTableBodyCellProps: getMuiTableBodyCellProps(theme),
        muiTablePaperProps: getMuiTablePaperProps,
        enablePagination: true,
        initialState: {
            density: 'compact',
            pagination: {pageSize: 10, pageIndex: 0},
        },
        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.backgroundAppGarageColor.main,

        }),
        muiTableBodyRowProps: getMuiTableBodyRowProps(theme),

        enableRowActions: true,
        renderRowActionMenuItems: ({closeMenu, row}) => [
            <MRT_ActionMenuItem
                icon={<Edit color="primary"/>}
                key="edit"
                label={t("edit")}
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setSelectedRow(row.original);
                    setIsEditFormOpen(true)
                    closeMenu();
                }}
                table={table}
            />,
            <MRT_ActionMenuItem
                icon={<Delete color="warning"/>}
                key="delete"
                label={t("delete")}
                onClick={() => {
                    setSelectedRow(row.original);
                    setDeleteConfirmationOpen(true)
                    closeMenu();
                }}
                table={table}
            />,
        ],
        renderTopToolbarCustomActions: ({table}) => (
            <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                <>
                    <Avatar sx={{
                        backgroundColor: 'white',
                        border: `2px solid ${theme.palette.primary.main}`,
                        marginRight: 1
                    }}>
                        <IoDocuments color={theme.palette.primary.main}/>
                    </Avatar>
                    <MuiReadOnlyBoxBody label={''} value={'doc_rows'}/>
                    <MRTMuiButton
                        icon={<AddIcon style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                        onClick={() => {
                            setSelectedRow(undefined);
                            setIsEditFormOpen(true)
                        }} label={'add_lwg_site'}/>
                </>
            </Box>
        ),
        enableRowOrdering: true,
        muiRowDragHandleProps: ({table}) => ({
            onDragEnd: async () => {
                const {draggingRow, hoveredRow} = table.getState();
                if (hoveredRow && draggingRow && docRowsWithCustomField) {
                    docRowsWithCustomField.splice(
                        (hoveredRow as MRT_Row<IDocRow>).index,
                        0,
                        docRowsWithCustomField.splice(draggingRow.index, 1)[0],
                    );
                    setDocRowsWithCustomField([...docRowsWithCustomField]);
                    await moveMutation.mutateAsync({
                        row_position: hoveredRow.index ? hoveredRow.index + 1 : 0,
                        id: draggingRow.original.id,
                    });
                }
            },
        }),
    });


    const {
        data: enforcedVatCode,
    } = useApiGetVatCode(2);


    return <>
        {isLoading ? <Loading/> :
            <>

                {docDetailed &&
                    <>
                        <>
                            <MaterialReactTable table={table}/>

                            <MuiAlertDialog
                                open={isDeleteConfirmationOpen}
                                title="delete_confirmation"
                                content="ask_for_delete"
                                onClose={handleCancelDelete}
                                onConfirm={handleConfirmDelete}
                            />
                        </>

                        <MuiModal
                            top="50%"
                            left="50%"
                            width={'50vh'}
                            open={isEditFormOpen}
                            disableBackdropClick={true}
                            onClose={() => setIsEditFormOpen(false)}
                        >
                            <DocRowForm docRow={selectedRow}
                                        enforcedVatCode={docDetailed.statement_of_intent ? enforcedVatCode : undefined}
                                        suggestedVatCode={docDetailed.fiscal_registry.default_vat_code}
                                        onClose={() => setIsEditFormOpen(false)}/>
                        </MuiModal>

                        {isFetching &&
                            <MuiFetchingSpinner text="Fetching data..."/>
                        }
                    </>}

            </>}
    </>;
};

export default DocRowTable;
