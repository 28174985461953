import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {
    IRentriCertificate
} from "../../../../../../utils/interfaces/special_wasty/rentri_certificate/IRentriCertificate";
import {useAppGarage} from "../../../../../../utils/contexts/appGarage-context";


const useApiCreateWastyRecipient = (): UseMutationResult<IRentriCertificate, CustomAxiosError, any> => {
    const {selectedCompany} = useAuth()
    const {selectedMyCompanySite} = useAppGarage()
    const queryClient = useQueryClient();


    const {postWithFile} = useApiCalls()

    async function doCreate({companyToken, data}: {
        companyToken?: string,
        data: any
    }): Promise<IRentriCertificate> {
        try {
            const response = await postWithFile(`/api/company/${companyToken}/special_wasty/rentri_certificate`, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    }


    return useMutation<IRentriCertificate, CustomAxiosError, any>(
        {
            mutationFn: (
                data
            ) => {
                return doCreate({
                    companyToken: selectedCompany?.company_token,
                    data
                })
            },
            onSuccess: async (obj) => {
                await queryClient.invalidateQueries({queryKey: ["useApiGetRentriCertificate"]});
                await queryClient.invalidateQueries({queryKey: ['useApiGetWasteCodes', selectedMyCompanySite?.id]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetWasteDocs"]});
                await queryClient.invalidateQueries({queryKey: ["useWasteApiGetSiteMovements", selectedMyCompanySite?.id]});
                await queryClient.invalidateQueries({queryKey: ["useWasteApiGetSiteRECTIFIABLEMovements", selectedMyCompanySite?.id]});
                await queryClient.invalidateQueries({queryKey: ["useWasteApiGetSiteNULLABLEMovements", selectedMyCompanySite?.id]});
                await queryClient.invalidateQueries({queryKey: ["useWasteApiGetOpenLoads", selectedMyCompanySite?.id]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetWastyCarriers"]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetWastyCarriers"]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetWastyIntermediaries"]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetWastyRecipients"]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetRentriCodiciEer"]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetRentriAttivita"]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetRentriClassiAdr"]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetRentriAttivitaRs"]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetRentriCaratteristichePericolo"]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetRentriCategorieRaee"]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetRentriUnitaMisura"]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetRentriMateriale"]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetRentriProvenienza"]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetRentriStatoFisico"]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetRentriCausaliOperazione"]});


            },
        }
    );
};


export default useApiCreateWastyRecipient;