import {useMutation, UseMutationResult} from "@tanstack/react-query";
import {RentriAxiosError, useApiRentriCalls} from "../../../api-hooks/api_rentri_config";


const useApiDeleteRentriRegistro = (pk: string | undefined): UseMutationResult<void, RentriAxiosError, {
    pk?: string
}, unknown> => {

    const {DELETE} = useApiRentriCalls()

    async function doDelete({pk}: {
        pk: string | undefined
    }): Promise<void> {
        try {
            await DELETE(`/anagrafiche/v1.0/operatore/registri/${pk}`);
        } catch (error) {
            throw error;
        }
    }

    return useMutation<void, RentriAxiosError, { pk?: string }>(
        {
            mutationFn: () => doDelete({
                pk: pk,
            }),
            onSuccess: async () => {

            },
        })
}


export default useApiDeleteRentriRegistro;