import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import {ICompanyDocType} from "../../../../../../../utils/interfaces/my_documents/doc_type/ICompanyDocType";


const useApiGetCompanyDocTypes = (): UseQueryResult<ICompanyDocType[], CustomAxiosError> => {
    const {selectedCompany} = useAuth();
    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined): Promise<ICompanyDocType[]> {
        try {
            const response = await get(`/api/company/${company_token}/my_documents/doc/company_doc_type`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetCompanyDocTypes"],
        queryFn: () => doGet(selectedCompany?.company_token)
    })
};

export default useApiGetCompanyDocTypes