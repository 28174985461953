import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Box, TextField} from "@mui/material";
import {useToast} from "../../../../../../utils/contexts/toast-context";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import Loading from "../../../../../ui/Loading";
import MuiAutocomplete from "../../../../../ui/mui/mui-input/MuiAutoComplete";
import useApiGetRentriSiti from "./api-hooks/useApiGetRentriSiti";
import {IRentriSite} from "../../../../../../utils/interfaces/special_wasty/rentri/anagrafiche/IRentriSite";


interface Props {
    obj: IRentriSite | undefined,
    onChange: (obj: IRentriSite | undefined) => void,
    className: string,
    label?: string,
    clearable?: boolean
    required?: boolean
    num_iscr_operatore: string | undefined
}

const RentriSitiSelect = ({
                              obj: initialObj,
                              onChange,
                              label,
                              clearable,
                              required,
                              num_iscr_operatore,
                              ...props
                          }: Props) => {
    const {displayError, displayMessage} = useToast()
    const {t} = useTranslation();
    const {className = ''} = props;
    const [objs, setObjs] = useState<IRentriSite[]>([]);
    const [obj, setObj] = useState<IRentriSite | undefined>(initialObj)

    const {
        data,
        isLoading,
        error,
    } = useApiGetRentriSiti(num_iscr_operatore);
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }

    useEffect(() => {
        if (data) {
            setObjs(data);
        }
    }, [data]);

    useEffect(() => {
        if (initialObj) {
            setObj(initialObj);
        }
    }, [initialObj, objs]);

    const handleOnChange = (event: React.SyntheticEvent, newValue: IRentriSite | null) => {
        setObj(undefined)
        if (newValue) {
            onChange(newValue);
        } else {
            onChange(undefined);
        }

    };


    useEffect(() => {
        onChange(obj);
    }, [obj]);


    return (
        <>
            {isLoading ? (
                <Loading/>
            ) : (
                <>
                    <Box sx={{display: 'flex', alignItems: 'center', width: '100%'}}>

                        <MuiAutocomplete
                            toolTipInfo={'I siti vanno inseriti dal portale di Rentri'}
                            id="rentri_sites-select"
                            options={objs}
                            getOptionLabel={(option) => option.num_iscr_sito + ' - ' + option.indirizzo}
                            value={obj ? obj : null}
                            onChange={handleOnChange}
                            isOptionEqualToValue={(option, value) => option.num_iscr_sito === value?.num_iscr_sito}
                            renderInput={(params) => (
                                <TextField
                                    sx={{textTransform: 'capitalize'}}
                                    {...params}
                                    label={t(label ? label : '') || t('')}
                                    required={required}
                                />
                            )}
                        />
                    </Box>

                </>
            )}
        </>
    );
}

export default RentriSitiSelect;