import React from 'react';
import {Box, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import LabelledMuiTypography from "../../../../ui/mui/mui-display-data/LabelledMuiTypography";
import {formatDate} from "../../../../../logics/helpers";
import MotionDivider from "../../../../ui/MotionDivider";
import {IWasteMovement} from "../../../../../utils/interfaces/special_wasty/waste_movement/IWasteMovement";


interface Props {
    waste_movement?: IWasteMovement,

}

const WasteMovementReferencesDocCard = ({
                                            waste_movement,
                                        }: Props) => {
    const theme = useTheme();
    const {t} = useTranslation();


    return (
        <>
            <Box sx={{
                marginTop: 1,
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                border: '1px solid lightgrey',
                borderRadius: '4px',
                padding: '10px',
                backgroundColor: theme.palette.backgroundAppGarageColor.gray_50,
            }}>
                <Typography
                    variant="subtitle2"
                    sx={{
                        color: theme.palette.primary.main,
                        position: 'absolute',
                        top: -12,
                        left: 0,
                        backgroundColor: 'transparent',
                        paddingX: 2,
                        textTransform: 'capitalize',
                    }}
                >
                    {waste_movement?.movement_type === 'load' ? t('downloads') : t('loads')}
                </Typography>

                {waste_movement?.movement_references && waste_movement.movement_references.map((doc) => {


                    return (

                        <Box key={doc.movement_number}
                             sx={{marginTop: 1, paddingLeft: 2, borderLeft: '1px solid lightgrey'}}>
                            <Box sx={{
                                position: 'relative'
                            }}>


                                <MotionDivider/>

                                <LabelledMuiTypography label={t('movement number')}
                                                       value={doc.movement_number}/>
                                <LabelledMuiTypography label={t('movement date')}
                                                       value={formatDate(doc.movement_date)}/>
                                <LabelledMuiTypography label={t('estimated weight')}
                                                       value={doc.estimated_weight + 'kg'}/>

                            </Box>
                        </Box>
                    )
                })}
            </Box>
        </>
    );
}

export default WasteMovementReferencesDocCard;