import React from 'react';
import {Box} from "@mui/material";
import {IconContext} from "react-icons";


const useCustomCellRenderer = (
    row: { id: number, text: string },
    theme: any
) => {


    const getIconsForCustomField = (row: { id: number, text: string }, theme: any) => {
        const icons = [];

        icons.push(null);

        // if (row.currently_in_use_docs && row.currently_in_use_docs.docs.length > 0) {
        //     icons.push(
        //         <MRTMuiButton
        //             small={true}
        //             icon={<IoDocumentsSharp
        //                 color={row.currently_in_use_docs.show_alert ? theme.palette.warning.main : theme.palette.primary.main}/>}
        //             onClick={() => {
        //                 setIsCurrentInUserWasteCodeCardIsOpen(true)
        //             }}
        //             animated={row.currently_in_use_docs.show_alert}
        //             label={'currently in use docs'}/>
        //     )
        // }
        //
        //
        // icons.push(
        //     <MRTMuiButton
        //         small={true}
        //         icon={<IoMdOpen
        //             color={row.open_loads.show_alert ? theme.palette.warning.main : row.open_loads.loads.length > 0 ? theme.palette.disabledColor : theme.palette.disabledColor.main}/>}
        //         onClick={row.open_loads.loads.length > 0 ? onOpenLoadsTable : () => {
        //         }}
        //         animated={row.open_loads.show_alert}
        //         label={row.open_loads.show_alert ? 'open loads needs unload' : ''}
        //     />,
        //     <MRTMuiButton
        //         small={true}
        //         icon={<HiOutlineArrowsRightLeft
        //             color={theme.palette.primary.main}/>}
        //         onClick={onOpenMovementsTable}
        //         label='open movements'
        //     />
        // );


        return icons;
    };

    const renderIcons = () => {
        const icons = getIconsForCustomField(row, theme);
        return (
            <IconContext.Provider value={{size: "1.3rem"}}>
                <Box display="flex" flexDirection="row" sx={{gap: 0.5}}>
                    {icons}
                </Box>
            </IconContext.Provider>


        );
    };

    return {renderIcons};
};

export default useCustomCellRenderer;