import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {IWasteDoc} from "../../../../../../utils/interfaces/special_wasty/waste_doc/IWasteDoc";


const useApiCreateWasteDoc = (): UseMutationResult<IWasteDoc, CustomAxiosError, any> => {
    const {selectedCompany} = useAuth()
    const queryClient = useQueryClient();

    const {postWithFile} = useApiCalls()

    async function doCreate({companyToken, data}: {
        companyToken?: string,
        data: any
    }): Promise<IWasteDoc> {
        try {
            const response = await postWithFile(`/api/company/${companyToken}/special_wasty/waste_doc`, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    }


    return useMutation<IWasteDoc, CustomAxiosError, any>(
        {
            mutationFn: (
                data
            ) => {
                return doCreate({
                    companyToken: selectedCompany?.company_token,
                    data
                })
            },
            onSuccess: async (obj) => {
            },
        }
    );
};


export default useApiCreateWasteDoc;