import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomAxiosError} from "../../../../../../../../logics/api_config";
import {useApiRentriCalls} from "../../../api-hooks/api_rentri_config";
import {
    IRentriComune
} from "../../../../../../../../utils/interfaces/special_wasty/rentri/codifiche/territoriali/IRentriComune";


const useApiGetRentriComuni = (provincia_id: string | undefined): UseQueryResult<IRentriComune[], CustomAxiosError> => {
    const {get} = useApiRentriCalls()

    async function doGet(): Promise<IRentriComune[]> {
        try {
            if (provincia_id) {
                const response = await get(`/codifiche/v1.0/lookup/comuni/${provincia_id}`);
                return response?.data;
            } else {
                const response = await get(`/codifiche/v1.0/lookup/comuni`);
                return response?.data;
            }

        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetRentriComuni", provincia_id],
        queryFn: () => doGet(),
    })
};

export default useApiGetRentriComuni