import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {IWastyRecipient} from "../../../../../../utils/interfaces/special_wasty/wasty_recipient/IWastyRecipient";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {
    IWastyIntermediary
} from "../../../../../../utils/interfaces/special_wasty/wasty_intermediary/IWastyIntermediary";


const useApiCreateWastyIntermediary = (): UseMutationResult<IWastyIntermediary, CustomAxiosError, any> => {
    const {selectedCompany} = useAuth()
    const queryClient = useQueryClient();

    const {post} = useApiCalls()

    async function doCreate({companyToken, data}: {
        companyToken?: string,
        data: any
    }): Promise<IWastyRecipient> {
        try {
            const response = await post(`/api/company/${companyToken}/special_wasty/wasty_intermediary`, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    }


    return useMutation<IWastyRecipient, CustomAxiosError, any>(
        {
            mutationFn: (
                data
            ) => {
                return doCreate({
                    companyToken: selectedCompany?.company_token,
                    data
                })
            },
            onSuccess: async (obj) => {
                await queryClient.invalidateQueries({queryKey: ["useApiGetWastyIntermediaries"]});
            },
        }
    );
};


export default useApiCreateWastyIntermediary;