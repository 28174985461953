import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {IDescription} from "../../../../../../utils/interfaces/service/description/IDescription";


const useApiGetDescriptions = (scope: string): UseQueryResult<IDescription[], CustomAxiosError> => {

    const {get} = useApiCalls()
    const {selectedCompany} = useAuth()

    async function doGet(): Promise<IDescription[]> {
        try {
            const response = await get(`/api/company/${selectedCompany?.company_token}/service/description?scope=${scope}`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ['useApiGetDescriptions', scope],
        queryFn: () => doGet(),
    })
};

export default useApiGetDescriptions