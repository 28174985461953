import React, {useEffect, useMemo, useState} from 'react';
import {MaterialReactTable, MRT_ActionMenuItem, MRT_ColumnDef, useMaterialReactTable,} from 'material-react-table';
import {useTheme} from '@mui/material/styles';
import {useTranslation} from 'react-i18next';
import {Box} from '@mui/material';
import {Delete, Edit} from '@mui/icons-material';
import {
    getMuiTableBodyCellProps,
    getMuiTableBodyRowProps,
    getMuiTableHeadCellProps,
    getMuiTablePaperProps,
} from '../../../../../ui/mui/mui_react_table/MuiReactTableStyles';
import MuiAlertDialog from '../../../../../ui/mui/mui_modal/MuiAlertDialog';
import MuiModal from '../../../../../ui/mui/mui_modal/MuiModal';
import useApiDeleteDye from './api-hooks/useApiDeleteDye';
import {CustomAxiosError} from '../../../../../../logics/api_config';
import {useQueryClient} from '@tanstack/react-query';
import DyeForm from './DyeForm';
import {
    IOrderScheduleTanneryDyeing
} from "../../../../../../utils/interfaces/tannery_production/order_schedule/IOrderScheduleTanneryDyeing";
import {useToast} from "../../../../../../utils/contexts/toast-context";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import useApiGetDyeByOrder from "./api-hooks/useApiGetDyeByOrder";
import {useTanneryProduction} from "../../../../../../utils/contexts/tannery_production/tannery_production-context";
import {FaPlus} from "react-icons/fa";
import MuiFabButtonBack from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import {formatDateTimeToDateString} from "../../../../../../logics/helpers";
import MuiTypography from "../../../../../ui/mui/mui-display-data/MuiTypography";
import MRTMuiButton from "../../../../../ui/mui/mui_react_table/MRTMuiButton";

interface Props {
    onClose: () => void;
}

const DyeTable = ({onClose}: Props) => {
    const {displayError, displayMessage} = useToast();
    const {t} = useTranslation();
    const theme = useTheme();
    const queryClient = useQueryClient();
    const {selectedCompany} = useAuth()
    const {selectedCompanyOrderSchedule} = useTanneryProduction()

    const [dye, setDye] = useState<IOrderScheduleTanneryDyeing | undefined>();
    const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

    const deleteMutation = useApiDeleteDye(selectedCompany?.company_token, dye?.id);
    const {mutateAsync: apiDelete} = deleteMutation;

    const {
        data,
        isLoading,
        error
    } = useApiGetDyeByOrder(selectedCompany?.company_token, selectedCompanyOrderSchedule?.id);
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }

    const handleConfirmDelete = async () => {
        try {
            await apiDelete({companyToken: selectedCompany?.company_token, pk: dye?.id});
            displayMessage('DyeList deleted successfully');
        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data && axiosError.response.data.detail) {
                displayError(axiosError.response.data.detail);
            } else {
                displayError('An error occurred while deleting the DyeList');
            }
        }
        setDeleteConfirmationOpen(false);
        await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingRecipe']})
        await queryClient.invalidateQueries({queryKey: ['getDyeToDye']})
        await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingScheduleCurrent']});
        await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingScheduleHistorical']});
        await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingScheduleDrafts']});
        await queryClient.invalidateQueries({queryKey: ['getDyeByOrder']});
        await queryClient.invalidateQueries({queryKey: ['getCompanyOrderScheduleByColor']});
        await queryClient.invalidateQueries({queryKey: ['getCompanyOrderSchedule']});
    };

    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };


    function checkIfAllDyeClosed(data: IOrderScheduleTanneryDyeing[]) {
        for (const item of data) {
            if (item.closed_at === null) {
                return false;
            }
        }
        return true;
    }

    const [isAllDyeClosed, setIsAllDyeClosed] = useState(false)
    useEffect(() => {
        if (data) {
            setIsAllDyeClosed(checkIfAllDyeClosed(data));
        }
    }, [data]);

    const columns = useMemo<MRT_ColumnDef<IOrderScheduleTanneryDyeing>[]>(
        () => [
            {
                accessorKey: 'ready_to_dye_at',
                header: t('ready_for_drums'),
                Cell: ({cell}) => <div>{formatDateTimeToDateString(cell.getValue())}</div>,

            },
            {
                accessorKey: 'weight',
                header: t('weight'),
            },
            {
                accessorKey: 'closed_at',
                header: t('closed_at'),
                Cell: ({cell}) => <div>{formatDateTimeToDateString(cell.getValue())}</div>,
            },
        ],
        []
    );

    const table = useMaterialReactTable<IOrderScheduleTanneryDyeing>({
        columns,
        data: data ? data : [],
        enableFilters: false,
        enableSorting: false,
        enablePagination: false,
        enableToolbarInternalActions: false,
        enableColumnActions: false,
        muiTableHeadCellProps: getMuiTableHeadCellProps(theme.palette.primary.main),
        muiTableBodyCellProps: getMuiTableBodyCellProps(theme),
        muiTablePaperProps: getMuiTablePaperProps,
        initialState: {density: 'compact', pagination: {pageSize: 30, pageIndex: 0}},
        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.backgroundAppGarageColor.main,
        }),
        muiTableBodyRowProps: getMuiTableBodyRowProps(theme),
        enableRowActions: true,
        renderRowActionMenuItems: ({closeMenu, row}) => [
            <MRT_ActionMenuItem
                icon={<Edit color="primary"/>}
                key="edit"
                label={t('edit')}
                onClick={() => {
                    setDye(row.original);
                    setIsFormOpen(true);
                    closeMenu();
                }}
                table={table}
            />,
            <MRT_ActionMenuItem
                icon={<Delete color="warning"/>}
                key="delete"
                label={t('delete')}
                onClick={() => {
                    setDye(row.original);
                    setDeleteConfirmationOpen(true);
                    closeMenu();
                }}
                table={table}
            />,
        ],
        renderTopToolbarCustomActions: ({table}) => (
            <Box sx={{display: 'flex', gap: '1rem', p: '4px', alignItems: 'center'}}>
                <>
                    {isAllDyeClosed ?
                        <MRTMuiButton
                            icon={<FaPlus style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                            onClick={() => {
                                setDye(undefined);
                                setIsFormOpen(true);
                            }} label={'add_dye'}/>
                        :
                        <MuiTypography value={'please_close_current_weights'} italic={true}/>
                    }
                </>
            </Box>
        ),
    });

    return (
        <>
            <MaterialReactTable table={table}/>
            <MuiAlertDialog
                open={isDeleteConfirmationOpen}
                title="delete_confirmation"
                content="ask_for_delete"
                onClose={handleCancelDelete}
                onConfirm={handleConfirmDelete}
            />

            <MuiModal top="50%" left="50%" width={'50vh'} open={isFormOpen} onClose={() => setIsFormOpen(false)}>
                <DyeForm dyeToEdit={dye ? dye : null} onClose={() => setIsFormOpen(false)}/>
            </MuiModal>

            <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                <MuiFabButtonBack onClick={onClose}/>
            </Box>
        </>
    );
};

export default DyeTable;
