import React, {useEffect, useState} from 'react';
import {
    IOrderScheduleWorking
} from "../../../../../../utils/interfaces/tannery_production/order_schedule/IOrderScheduleWorking";
import {useToast} from "../../../../../../utils/contexts/toast-context";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {useQueryClient} from "@tanstack/react-query";
import useApiUpdateWorking from "./api-hooks/useApiUpdateWorking";
import useApiCreateWorking from "./api-hooks/useApiCreateWorking";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import {getCurrentDate} from "../../../../../../logics/helpers";
import {useTanneryProduction} from "../../../../../../utils/contexts/tannery_production/tannery_production-context";
import {Box, Grid} from "@mui/material";
import MuiFabButtonBack from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonSaveAs from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";
import MuiInputTextField from "../../../../../ui/mui/mui-input/MuiInputTextField";


interface Props {
    workingToEdit: IOrderScheduleWorking | null;
    price_id?: number;
    onClose: () => void;
}

const WorkingsForm: React.FC<Props> = ({workingToEdit, onClose, price_id}) => {
    const {displayError, displayMessage} = useToast();
    const {selectedCompany} = useAuth();
    const queryClient = useQueryClient();

    const {selectedCompanyOrderSchedule} = useTanneryProduction()

    const mutationHook = workingToEdit ? useApiUpdateWorking : useApiCreateWorking;
    const mutation = mutationHook(selectedCompany?.company_token);

    const [outgoing_date, setOutgoing_date] = useState<string>(getCurrentDate);
    const [returning_date, setReturning_date] = useState<string | undefined | null>();
    const [quantity, setQuantity] = useState<number>();
    const [priceId, setPriceId] = useState<number | undefined>();
    const [orderId, setOrderId] = useState<number>();

    useEffect(() => {
        setOrderId(selectedCompanyOrderSchedule?.id)
        setPriceId(price_id)
        if (workingToEdit) {
            setOutgoing_date(workingToEdit.outgoing_date)
            setReturning_date(workingToEdit.returning_date)
            setQuantity(workingToEdit.quantity)
            setPriceId(workingToEdit.price_id)
            setOrderId(workingToEdit.order)
        }
    }, [workingToEdit]);


    const handleSubmit = async () => {
        const returning = returning_date ? returning_date : null;

        if (priceId && orderId) {
            try {
                const data = {
                    outgoing_date: outgoing_date || '',
                    returning_date: returning,
                    quantity: quantity || 0,
                    price_id: priceId,
                    order_id: orderId
                };

                if (workingToEdit !== null) {
                    await mutation.mutateAsync({
                        pk: workingToEdit.id,
                        data: data,
                    });
                    displayMessage('Working updated successfully');
                    queryClient.setQueryData<IOrderScheduleWorking[]>(['getWorkingOrders'], (old) => {
                        if (!old) return [];
                        return old.map((item) =>
                            item.id === workingToEdit.id ? {...item, ...data} : item
                        );
                    });
                } else {
                    const newWorking = await mutation.mutateAsync(
                        data,
                    );
                    displayMessage('Working added successfully');
                    queryClient.setQueryData<IOrderScheduleWorking[]>(['getWorkingOrders'], (old) => {
                        const updatedData = [...(old || []), newWorking];
                        return updatedData.filter((item): item is IOrderScheduleWorking => item !== null && item !== undefined);
                    });
                }

                onClose(); // Close the form
                await queryClient.invalidateQueries({queryKey: ['getWorkingOrdersByOrder']});
                await queryClient.invalidateQueries({queryKey: ['getCompanyOrderScheduleByColor']});
                await queryClient.invalidateQueries({queryKey: ['getWorkingOrders']});
                await queryClient.invalidateQueries({queryKey: ['getCompanyOrderSchedule']});
            } catch (error) {
                const axiosError = error as CustomAxiosError;
                if (axiosError.response && axiosError.response.data && axiosError.response.data.detail) {
                    displayError(axiosError.response.data.detail);
                } else {
                    displayError('An error occurred while saving the Working');
                }
            }
        }


    };

    return (
        <Box sx={{padding: 2}}>
            <form>
                <MuiInputTextField
                    onChange={setOutgoing_date}
                    label='outgoing_date'
                    type="date"
                    value={outgoing_date}
                    required={true}
                />

                <MuiInputTextField
                    onChange={setReturning_date}
                    label='returning_date'
                    type="date"
                    value={returning_date}
                    required={false}
                />

                <MuiInputTextField
                    onChange={(e) => setQuantity(parseFloat(e.target.value))}
                    label='quantity'
                    type="number"
                    value={quantity}
                    required={false}
                />

                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <Box className={'mt-2 gap-x-2'}>
                        <MuiFabButtonBack onClick={onClose}/>
                        {workingToEdit ?
                            <MuiFabButtonSaveAs onClick={handleSubmit}/>
                            :
                            <MuiFabButtonSave onClick={handleSubmit}/>
                        }
                    </Box>
                </Grid>
            </form>

        </Box>
    );
};

export default WorkingsForm;
