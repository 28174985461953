import React, {useEffect, useMemo, useState} from 'react';
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {MaterialReactTable, MRT_ActionMenuItem, MRT_ColumnDef, useMaterialReactTable} from "material-react-table";
import {Delete, Edit} from "@mui/icons-material";
import {Avatar, Box} from "@mui/material";
import Grid from '@mui/material/Grid';
import AddIcon from "@mui/icons-material/Add";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import {IWastyRecipient} from "../../../../../utils/interfaces/special_wasty/wasty_recipient/IWastyRecipient";
import {useQueryClient} from "@tanstack/react-query";
import useApiDeleteWastyRecipient from "./api-hooks/useApiDeleteWastyRecipient";
import useApiGetWastyRecipients from "./api-hooks/useApiGetWastyRecipients";
import useCustomCellRenderer from "./useCustomCellRenderer";
import {
    getMuiTableBodyCellProps,
    getMuiTableBodyRowProps,
    getMuiTableHeadCellProps,
    getMuiTablePaperProps
} from "../../../../ui/mui/mui_react_table/MuiReactTableStyles";
import {TbBuildingFactory} from "react-icons/tb";
import MuiReadOnlyBoxBody from "../../../../ui/mui/mui-display-data/MuiReadOnlyBoxBody";
import MRTMuiButton from "../../../../ui/mui/mui_react_table/MRTMuiButton";
import Loading from "../../../../ui/Loading";
import MuiFabButtonBack from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiAlertDialog from "../../../../ui/mui/mui_modal/MuiAlertDialog";
import MuiFetchingSpinner from "../../../../ui/mui/MuiFetchingSpinner";
import WastyRecipientForm from "./WastyRecipientForm";
import MuiModal from "../../../../ui/mui/mui_modal/MuiModal";
import {IoDocumentsSharp} from "react-icons/io5";
import WasteDocTable from "../waste_doc/WasteDocTable";


interface IWastyRecipientWithCustomField extends IWastyRecipient {
    custom_field: JSX.Element;
}

interface Props {
    onSelect?: (obj: IWastyRecipient | undefined) => void,
    onClose?: () => void;
}


const WastyRecipientTable = ({onSelect, onClose}: Props) => {
    const theme = useTheme();
    const {t} = useTranslation()
    const queryClient = useQueryClient();


    const [selectedRow, setSelectedRow] = useState<IWastyRecipient | undefined>()
    const [selectedRows, setSelectedRows] = useState<IWastyRecipient[] | undefined>()
    const [rows, setRows] = useState<IWastyRecipient[] | undefined>()

    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)
    const [isWasteDocTableOpen, setIsWasteDocTableOpen] = useState<boolean>(false)


    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const deleteMutation = useApiDeleteWastyRecipient(selectedRow?.id);
    const {mutateAsync: apiDelete} = deleteMutation;


    const handleConfirmDelete = async () => {
        try {
            if (onSelect) {
                onSelect(undefined);
            }
            await apiDelete({pk: selectedRow?.id});
        } catch (error) {
        }
        setDeleteConfirmationOpen(false);
        await queryClient.invalidateQueries({queryKey: ['useApiGetWastyRecipient', selectedRow?.id]});
        await queryClient.invalidateQueries({queryKey: ["useApiGetWastyRecipients"]});
    };
    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };


    const {
        data,
        isLoading,
        isFetching,
    } = useApiGetWastyRecipients();


    useEffect(() => {
        if (data) {
            setRows(data);
        }
    }, [data]);

    // note_marco: customCellRenderer in hook example
    const CustomCellRenderer: React.FC<{ row: IWastyRecipient, theme: any }> = ({row, theme}) => {
        const {renderIcons} = useCustomCellRenderer(row, theme);
        return renderIcons();
    };


    const [objsWithCustomField, setObjsWithCustomField] = useState<IWastyRecipientWithCustomField[] | undefined>();
    useEffect(() => {
        const dataWithCustomField: IWastyRecipientWithCustomField[] | undefined = rows?.map((row) => ({
            ...row,
            custom_field: <CustomCellRenderer row={row} theme={theme}/>
        }));
        setObjsWithCustomField(dataWithCustomField);
    }, [rows, theme]);


    const columns = useMemo<MRT_ColumnDef<IWastyRecipient>[]>(
        () => {
            const baseColumns: MRT_ColumnDef<IWastyRecipient>[] = [
                {
                    id: "icons",
                    header: "ICONS",
                    columnDefType: "display",
                    Cell: ({row}) => (<CustomCellRenderer row={row.original} theme={theme}/>)
                },
                {
                    accessorKey: 'name',
                    header: t('name'),
                },

            ];


            // if (doc) {
            //     baseColumns.push({
            //         accessorKey: 'doc_type',
            //         header: t('payment type'),
            //         accessorFn: (row) => row.payment_type.name,
            //     });
            // }
            //
            // if (!doc) {
            //     baseColumns.push({
            //         accessorKey: 'registry',
            //         header: t('registry'),
            //         accessorFn: (row) => row.doc?.fiscal_registry.registry?.business_name,
            //     });
            // }

            return baseColumns;
        },
        [t]
    );


    const table = useMaterialReactTable<IWastyRecipient>({
        columns,
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 80,
        },
        data: objsWithCustomField ? objsWithCustomField : [],
        columnFilterDisplayMode: 'popover',
        globalFilterFn: 'contains',
        enableFullScreenToggle: false,
        enableFilters: false,
        enableDensityToggle: false,
        muiTableHeadCellProps: getMuiTableHeadCellProps(theme.palette.primary.main),
        muiTableBodyCellProps: getMuiTableBodyCellProps(theme),
        muiTablePaperProps: getMuiTablePaperProps,
        enablePagination: false,
        initialState: {
            density: 'compact',
            pagination: {pageSize: 25, pageIndex: 0},
        },
        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.backgroundAppGarageColor.main,

        }),
        muiTableBodyRowProps: getMuiTableBodyRowProps(theme),

        enableRowActions: true,
        renderRowActionMenuItems: ({closeMenu, row}) => [
            onSelect && onClose && (
                <MRT_ActionMenuItem
                    icon={<AdsClickIcon color="primary"/>}
                    key="select"
                    label={t("select")}
                    onClick={() => {
                        onSelect(row.original);
                        onClose();
                    }}
                    table={table}
                />),
            <MRT_ActionMenuItem
                icon={<Edit color="primary"/>}
                key="edit"
                label={t("edit")}
                onClick={(event) => {
                    setSelectedRow(row.original);
                    setIsEditFormOpen(true)
                    closeMenu()
                }}
                table={table}
            />,
            <MRT_ActionMenuItem
                icon={<Delete color="warning"/>}
                key="delete"
                label={t("delete")}
                onClick={() => {
                    setSelectedRow(row.original);
                    setDeleteConfirmationOpen(true)
                    closeMenu()
                }}
                table={table}
            />,
            <MRT_ActionMenuItem
                icon={<IoDocumentsSharp color={theme.palette.primary.main}/>}
                key="waste_docs"
                label={t("authorizations")}
                onClick={() => {
                    setSelectedRow(row.original);
                    setIsWasteDocTableOpen(true)
                    closeMenu()
                }}
                table={table}
            />,
        ],
        renderTopToolbarCustomActions: ({table}) => (
            <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                <>
                    <Avatar sx={{
                        backgroundColor: 'white',
                        border: `2px solid ${theme.palette.primary.main}`,
                        marginRight: 1
                    }}>
                        <TbBuildingFactory color={theme.palette.primary.main}/>
                    </Avatar>
                    <MuiReadOnlyBoxBody label={''} value={'wasty_recipient'}/>
                    <MRTMuiButton
                        icon={<AddIcon style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                        onClick={() => {
                            setSelectedRow(undefined);
                            setIsEditFormOpen(true)
                        }} label={'add'}/>
                </>
            </Box>
        ),
    });


    return <>
        {isLoading ? <Loading/> :
            <>
                {onClose &&
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                        <Box className={'mt-2 gap-x-2 mb-2'}>
                            <MuiFabButtonBack onClick={onClose}/>
                        </Box>
                    </Grid>
                }

                <MaterialReactTable table={table}/>

                <MuiAlertDialog
                    open={isDeleteConfirmationOpen}
                    title="delete_confirmation"
                    content="ask_for_delete"
                    onClose={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                />

                <MuiModal
                    top="50%"
                    left="50%"
                    width={'80vh'}
                    open={isEditFormOpen}
                    onClose={() => setIsEditFormOpen(false)}
                    disableBackdropClick={true}
                >


                    <WastyRecipientForm
                        objToEdit={selectedRow || null}
                        onClose={() => setIsEditFormOpen(false)}
                    />

                </MuiModal>

                <MuiModal
                    top="50%"
                    left="50%"
                    width={'80vh'}
                    open={isWasteDocTableOpen}
                    onClose={() => setIsWasteDocTableOpen(false)}
                    disableBackdropClick={true}
                >

                    <WasteDocTable
                        doc_type={'authorization'}
                        recipient={selectedRow}
                        onClose={() => setIsWasteDocTableOpen(false)}
                    />

                </MuiModal>


                {isFetching &&
                    <MuiFetchingSpinner text="Fetching data..."/>
                }
            </>}
    </>;
};

export default WastyRecipientTable;
