import React from 'react';
import {Avatar, Box, SpeedDial, SpeedDialAction, SpeedDialIcon, useTheme} from '@mui/material';
import {keyframes} from "@emotion/react";
import {useTranslation} from "react-i18next";

// Define the interface for the Action prop
interface Action {
    name: string;
    icon: React.ReactNode;
    onClick: () => void;
    isPulsing?: boolean;
}

interface CustomSpeedDialProps {
    actions: Action[];
    customIcon?: React.ReactNode; // Aggiunta della prop opzionale customIcon
}

const MuiCustomSpeedDial: React.FC<CustomSpeedDialProps> = ({actions, customIcon}) => {
    const theme = useTheme();
    const {t} = useTranslation()


    const pulse = keyframes`
        0%, 100% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.2);
        }
    `;

    return (
        <Box sx={{position: 'relative'}}>
            <SpeedDial
                direction="down"
                ariaLabel="SpeedDial basic example"
                sx={{
                    position: 'fixed',
                    top: '16px',
                    right: '20px',
                    mt: '2',
                    mr: '6',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    zIndex: 9999,
                    '& .MuiFab-primary': {
                        backgroundColor: theme.palette.primary.light,
                        color: 'white',
                        border: `2px solid white`,
                        boxShadow: '0 0 12px rgba(0, 0, 0, 0.6)',
                        '&:hover': {
                            backgroundColor: theme.palette.primary.main,
                            boxShadow: '0 0 16px rgba(0, 0, 0, 0.6)',
                        }
                    },
                }}
                icon={customIcon || <SpeedDialIcon/>}
            >
                {actions
                    .map((action) => (
                        <SpeedDialAction
                            key={action.name}
                            icon={
                                <Avatar
                                    sx={{
                                        backgroundColor: theme.palette.primary.light,
                                        border: `2px solid white`,
                                        color: 'white',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        boxShadow: '0 0 12px rgba(0, 0, 0, 0.6)',
                                        '&:hover': {
                                            backgroundColor: theme.palette.primary.main,
                                            boxShadow: '0 0 16px rgba(0, 0, 0, 0.6)',
                                        }
                                    }}
                                >
                                    {action.icon}
                                </Avatar>
                            }
                            tooltipTitle={t(action.name).toUpperCase()}
                            onClick={action.onClick}
                            sx={{
                                backgroundColor: 'transparent',
                                boxShadow: 'none',
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                    boxShadow: 'none'
                                },
                                '& .MuiSpeedDialAction-fab': {
                                    backgroundColor: 'transparent',
                                    boxShadow: 'none',
                                    '&:hover': {
                                        backgroundColor: theme.palette.primary.dark,
                                        boxShadow: 'none',
                                    }
                                },
                                animation: action.isPulsing ? `${pulse} 1.5s infinite` : 'none',
                            }}
                        />
                    ))}
            </SpeedDial>
        </Box>
    );
};

export default MuiCustomSpeedDial;