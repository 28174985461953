import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {IWastyCarrier} from "../../../../../../utils/interfaces/special_wasty/wasty_carrier/IWastyCarrier";


const useApiUpdateWastyCarrier = (): UseMutationResult<IWastyCarrier, CustomAxiosError, {
    id: number;
    data: any
}, unknown> => {
    const queryClient = useQueryClient();
    const {selectedCompany} = useAuth()

    const {put} = useApiCalls()


    async function doUpdate(company_token: string | undefined,
                            pk: number | undefined,
                            data: any): Promise<IWastyCarrier> {
        try {
            const response = await put(`/api/company/${company_token}/special_wasty/wasty_carrier/${pk}`, data);
            return response.data as IWastyCarrier;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<IWastyCarrier, CustomAxiosError, {
        id: number;
        data: any
    }, unknown>({
        mutationFn: async ({id, data}) => {
            return await doUpdate(selectedCompany?.company_token, id, data);
        },
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({queryKey: ['useApiGetWastyCarrier', data.id]});
            await queryClient.invalidateQueries({queryKey: ['useApiGetWastyCarriers']});
        },
    });
};


export default useApiUpdateWastyCarrier;