import React, {useEffect, useState} from 'react';
import {useToast} from "../../../../../utils/contexts/toast-context";
import {useQueryClient} from "@tanstack/react-query";
import useApiCreateFiscalRegistry from "./api-hooks/useApiCreateFiscalRegistry";
import useApiUpdateFiscalRegistry from "./api-hooks/useApiUpdateFiscalRegistry";
import {IRegistry} from "../../../../../utils/interfaces/my_documents/fiscal_registry/IRegistry";
import IncotermSelect from "./incoterm/IncotermSelect";
import {IIncoterm} from "../../../../../utils/interfaces/my_documents/IIncoterm";
import TransportCompanySelect from "./transport_company/TransportCompanySelect";
import {ITransportCompany} from "../../../../../utils/interfaces/my_documents/ITransportCompany";
import OtherDestinationSelect from "./other_destination/OtherDestinationSelect";
import {IOtherDestination} from "../../../../../utils/interfaces/my_documents/fiscal_registry/IOtherDestination";
import OtherDestinationCard from "./other_destination/OtherDestinationCard";
import TransportCompanyCard from "./transport_company/TransportCompanyCard";
import IncotermCard from "./incoterm/IncotermCard";
import useApiGetFiscalRegistry from "./api-hooks/useApiGetFiscalRegistry";
import {Box, Tab, Tabs} from "@mui/material";
import MuiFabButtonBack from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonSaveAs from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";
import MuiInputTextField from "../../../../ui/mui/mui-input/MuiInputTextField";
import {useService} from "../../../../../utils/contexts/service/service-context";
import PaymentForm from "./payment/PaymentForm";
import MuiModal from "../../../../ui/mui/mui_modal/MuiModal";
import MuiButton from "../../../../ui/mui/mui_buttons/MuiButton";
import {useTranslation} from "react-i18next";
import BankSelect from "./bank/BankSelect";
import {IBank} from "../../../../../utils/interfaces/my_documents/IBank";
import BankCard from "./bank/BankCard";
import PaymentCard from "./payment/PaymentCard";
import {useMyDocuments} from "../../../../../utils/contexts/my_documents/my_documents-context";
import {IStatementOfIntent} from "../../../../../utils/interfaces/my_documents/fiscal_registry/IStatementOfIntent";
import {IVatCode} from "../../../../../utils/interfaces/my_documents/IVatCode";
import useApiGetMyCompanyRegistry from "../../my_company/my_company_registry/api-hooks/useApiGetMyCompanyRegistry";
import {IFiscalRegistry} from "../../../../../utils/interfaces/my_documents/fiscal_registry/IFiscalRegistry";
import {IPeople} from "../../../../../utils/interfaces/service/people/IPeople";
import PeopleSelect from "../../service/people/PeopleSelect";
import RegistryCard from "./registry/RegistryCard";
import RegistrySelect from "./registry/RegistrySelect";
import StatementOfIntentSelect from "./statement_of_intent/StatementOfIntentSelect";
import VatCodeSelect from "../VatCodeSelect";
import MuiReadOnlyBoxBody from "../../../../ui/mui/mui-display-data/MuiReadOnlyBoxBody";


interface Props {
    objToEdit?: IFiscalRegistry | null,
    onClose: () => void;
}

const FiscalRegistryForm: React.FC<Props> = ({onClose, objToEdit: initialObjToEdit}: Props) => {
    const {displayError, displayMessage} = useToast();
    const queryClient = useQueryClient();
    const {t} = useTranslation()
    const {selectFiscalRegistry} = useService()
    const {selectedDocDetail} = useMyDocuments()

    const [isDocPaymentFormOpen, setIsDocPaymentFormOpen] = useState(false);

    const [fiscalCode, setFiscalCode] = useState<string | undefined>();
    const [vatNumber, setVatNumber] = useState<string | undefined>();
    const [registry, setRegistry] = useState<IRegistry | undefined>()
    const [incoterm, setIncoterm] = useState<IIncoterm | undefined>()
    const [bank, setBank] = useState<IBank | undefined>()
    const [iban, setIBan] = useState<string | undefined>()
    const [transportCompany, setTransportCompany] = useState<ITransportCompany | undefined>()
    const [otherDestination, setOtherDestination] = useState<IOtherDestination | undefined>()
    const [statementOfIntent, setStatementOfIntent] = useState<IStatementOfIntent | undefined>()
    const [paymentMethod, setPaymentMethod] = useState<string | undefined>()
    const [paymentMethodDescription, setPaymentMethodDescription] = useState<string | undefined>()
    const [sdiCode, setSdiCode] = useState<string | undefined>()
    const [sdiPEcAddress, setSdiPecAddress] = useState<string | undefined>()
    const [fisicPersonName, setFisicPersonName] = useState<string | undefined>()
    const [fisicPersonSurname, setFisicPersonSurname] = useState<string | undefined>()
    const [defaultVatCode, setDefaultVatCode] = useState<IVatCode>()
    const [people, setPeople] = useState<IPeople | undefined>()
    const [riferimentoPubblicaAmministrazione, setRiferimentoPubblicaAmministrazione] = useState<string | undefined>()
    const [objToEdit, setObjToEdit] = useState<IFiscalRegistry | undefined>()

    const updateMutation = useApiUpdateFiscalRegistry();
    const createMutation = useApiCreateFiscalRegistry();

    useEffect(() => {
        if (initialObjToEdit) {
            setObjToEdit(initialObjToEdit);
        }
    }, [initialObjToEdit]);

    const {
        data: detailedFiscalRegistry
    } = useApiGetFiscalRegistry(objToEdit?.id);

    useEffect(() => {
        selectFiscalRegistry(detailedFiscalRegistry)
        setRegistry(undefined);
        setIncoterm(undefined);
        setTransportCompany(undefined);
        setOtherDestination(undefined);
        setFiscalCode('');
        setVatNumber('');
        setPaymentMethodDescription('');
        setPaymentMethod(undefined);
        setStatementOfIntent(undefined);
        setDefaultVatCode(undefined);
        if (detailedFiscalRegistry) {
            setFiscalCode(detailedFiscalRegistry.fiscal_code || '');
            setVatNumber(detailedFiscalRegistry.vat_number || '');
            setRegistry(detailedFiscalRegistry.registry);
            setIncoterm(detailedFiscalRegistry.incoterm);
            setTransportCompany(detailedFiscalRegistry.transport_company);
            setOtherDestination(detailedFiscalRegistry.other_destination);
            setPaymentMethod(detailedFiscalRegistry.payment_method);
            setPaymentMethodDescription(detailedFiscalRegistry.payment_method_description);
            setBank(detailedFiscalRegistry.bank);
            setIBan(detailedFiscalRegistry.iban);
            setStatementOfIntent(detailedFiscalRegistry.statement_of_intent);
            setDefaultVatCode(detailedFiscalRegistry.default_vat_code);
            setSdiCode(detailedFiscalRegistry.sdi_code);
            setSdiPecAddress(detailedFiscalRegistry.sdi_pec_address);
            setFisicPersonSurname(detailedFiscalRegistry.fisic_person_surname);
            setFisicPersonName(detailedFiscalRegistry.fisic_person_name)
            setRiferimentoPubblicaAmministrazione(detailedFiscalRegistry.riferimento_pubblica_amministrazione)
            setPeople(detailedFiscalRegistry.people)
        }
    }, [detailedFiscalRegistry]);


    const handleRegistryChange = (registry: IRegistry | undefined) => {
        setRegistry(registry);
    };

    const handlePeopleChange = (people: IPeople | null) => {
        if (people) {
            setPeople(people);
            !detailedFiscalRegistry && handleSubmit(people);
        } else {
            setPeople(undefined)
        }

    };

    const handleIncotermChange = (obj: IIncoterm | undefined) => {
        setIncoterm(obj);
    };

    const handleBankChange = (obj: IBank | undefined) => {
        setBank(obj);
    };

    const handleTransportCompanyChange = (obj: ITransportCompany | undefined) => {
        setTransportCompany(obj);
    };

    const handleOtherDestinationChange = (obj: IOtherDestination | undefined) => {
        setOtherDestination(obj);
    };

    const handlePaymentMethodChange = (_paymentMethod: string | undefined, _paymentMethodDescription: string | undefined) => {
        setPaymentMethod(_paymentMethod)
        setPaymentMethodDescription(_paymentMethodDescription)
    }


    const handleSubmit = async (_people?: IPeople) => {
        try {
            const mutation_data = {
                fiscal_code: fiscalCode || null,
                vat_number: vatNumber || null,
                registry_id: registry?.id || undefined,
                incoterm: incoterm ? incoterm.id : null,
                transport_company: transportCompany ? transportCompany.id : null,
                other_destination: otherDestination ? otherDestination.id : null,
                payment_method: paymentMethod,
                bank: bank ? bank.id : null,
                iban: iban,
                statement_of_intent: statementOfIntent ? statementOfIntent.id : null,
                sdi_code: sdiCode,
                sdi_pec_address: sdiPEcAddress,
                fisic_person_name: fisicPersonName,
                fisic_person_surname: fisicPersonSurname,
                riferimento_pubblica_amministrazione: riferimentoPubblicaAmministrazione,
                people_id: _people ? _people.id : people?.id,
            };

            if (detailedFiscalRegistry?.id) {
                await updateMutation.mutateAsync({
                    id: detailedFiscalRegistry.id,
                    data: mutation_data
                });
                await queryClient.invalidateQueries({queryKey: ['useApiGetFiscalRegistries']});
                await queryClient.invalidateQueries({queryKey: ["useApiGetDocPayments", selectedDocDetail?.id]});
                displayMessage('Fiscal Registry updated successfully');
            } else {
                const new_obj = await createMutation.mutateAsync({data: mutation_data});
                await queryClient.invalidateQueries({queryKey: ['useApiGetFiscalRegistries']});
                displayMessage('Fiscal Registry added successfully');
                setObjToEdit(new_obj)
            }
            detailedFiscalRegistry && onClose();

        } catch (error) {

        }
    }

    const {data: myCompanyRegistry} = useApiGetMyCompanyRegistry();

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    interface TabPanelProps {
        children?: React.ReactNode;
        index: number;
        value: number;
    }

    function CustomTabPanel(props: TabPanelProps) {
        const {children, value, index, ...other} = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box sx={{p: 3}}>{children}</Box>}
            </div>
        );
    }

    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };


    return (
        <Box sx={{padding: 0}}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label={t("registry")} {...a11yProps(0)} />
                    <Tab label={t("payment")} {...a11yProps(1)} />
                    <Tab label={t("Fatturazione Elettronica")} {...a11yProps(2)} />
                    <Tab label={t("transport")} {...a11yProps(3)} />
                </Tabs>
            </Box>
            {detailedFiscalRegistry ?
                <Box sx={{marginTop: 2}}>
                    <MuiReadOnlyBoxBody label={''} value={detailedFiscalRegistry.people.people_name}/>
                </Box>
                :
                <PeopleSelect people={people} onChange={handlePeopleChange} className={''}
                              isDisabled={!!detailedFiscalRegistry}/>
            }

            <CustomTabPanel value={value} index={0}>
                <Box className="flex flex-col">
                    {detailedFiscalRegistry && (<>
                        <RegistryCard obj={registry}/>
                        <RegistrySelect
                            label="select_registry"
                            onChange={handleRegistryChange}
                            className=""
                            registry={registry}
                            fiscal_registry={detailedFiscalRegistry}
                        />
                        <StatementOfIntentSelect
                            label="select a statement of intent"
                            onChange={(obj) => setStatementOfIntent(obj)}
                            className=""
                            obj={statementOfIntent}
                            fiscal_registry={detailedFiscalRegistry}
                        />
                        <VatCodeSelect obj={defaultVatCode} onChange={(obj) => setDefaultVatCode(obj)}
                                       className={''}/>
                    </>)}

                    <MuiInputTextField
                        label="vat_number"
                        type="text"
                        value={vatNumber}
                        onChange={(e) => setVatNumber(e.target.value)}
                        required={false}
                    />
                    <MuiInputTextField
                        label="fiscal_code"
                        type="text"
                        value={fiscalCode}
                        onChange={(e) => setFiscalCode(e.target.value)}
                        required={false}
                    />
                </Box>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
                <Box className="flex flex-col">
                    {bank && <BankCard obj={bank}/>}
                    <BankSelect
                        label="select bank for this customer"
                        onChange={handleBankChange}
                        className=""
                        obj={bank}
                    />


                    <MuiInputTextField
                        label="customer iban"
                        type="text"
                        value={iban}
                        onChange={(e) => setIBan(e.target.value)}
                        required={false}
                    />


                    <Box className="mt-1.5 mb-2">
                        <MuiButton
                            onClick={() => setIsDocPaymentFormOpen(true)}>{t('doc_payment')}</MuiButton>
                    </Box>
                    {paymentMethodDescription && <PaymentCard obj={paymentMethodDescription}/>}
                </Box>
            </CustomTabPanel>


            <CustomTabPanel value={value} index={2}>
                <Box className="flex flex-col ">
                    <MuiInputTextField
                        label="sdi code"
                        type="text"
                        value={sdiCode}
                        onChange={(e) => setSdiCode(e.target.value)}
                        required={false}
                    />
                    <MuiInputTextField
                        label="sdi pec address"
                        type="text"
                        value={sdiPEcAddress}
                        onChange={(e) => setSdiPecAddress(e.target.value)}
                        required={false}
                    />
                    <MuiInputTextField
                        label="fisic person name"
                        type="text"
                        value={fisicPersonName}
                        onChange={(e) => setFisicPersonName(e.target.value)}
                        required={false}
                    />
                    <MuiInputTextField
                        label="fisic person surnname"
                        type="text"
                        value={fisicPersonSurname}
                        onChange={(e) => setFisicPersonSurname(e.target.value)}
                        required={false}
                    />
                    {myCompanyRegistry?.nation.iso_code === 'IT' &&
                        <MuiInputTextField
                            label="riferimento pubblica amministrazione"
                            type="text"
                            value={riferimentoPubblicaAmministrazione}
                            onChange={(e) => setRiferimentoPubblicaAmministrazione(e.target.value)}
                            required={false}
                            toolTipInfo="Se il cliente è Pubblica Amministrazione, qui va inserito un codice di 20 caratteri che poi andrà riportato in fattura elettronica"
                        />}
                </Box>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={3}>
                {detailedFiscalRegistry && (
                    <Box className="flex flex-col gap-2 mt-2">
                        <IncotermSelect
                            label="select_incoterm"
                            onChange={handleIncotermChange}
                            className=""
                            obj={incoterm}
                        />
                        {incoterm && <IncotermCard obj={incoterm}/>}
                        <TransportCompanySelect
                            label="select_transport_company"
                            onChange={handleTransportCompanyChange}
                            className=""
                            obj={transportCompany}
                        />
                        {transportCompany && <TransportCompanyCard obj={transportCompany}/>}
                        <OtherDestinationSelect
                            label="select_other_destination"
                            onChange={handleOtherDestinationChange}
                            className=""
                            obj={otherDestination}
                            fiscal_registry={detailedFiscalRegistry}
                        />
                        {otherDestination && (
                            <OtherDestinationCard
                                fiscal_registry={detailedFiscalRegistry}
                                obj={otherDestination}
                            />
                        )}
                    </Box>
                )}
            </CustomTabPanel>


            {/* note_marco: Action Buttons static on bottom */}
            <Box
                className="flex justify-center gap-x-2"
                sx={{
                    position: 'fixed',   // Keep it fixed on the screen
                    bottom: 10,           // Stick it to the bottom of the screen
                    left: 0,
                    right: 0,            // Full width
                    padding: '16px',     // Add some padding
                }}
            >
                <MuiFabButtonBack onClick={onClose}/>
                {detailedFiscalRegistry ? (
                    <MuiFabButtonSaveAs onClick={() => handleSubmit()}/>
                ) : (
                    <MuiFabButtonSave onClick={() => handleSubmit()}/>
                )}
            </Box>


            <MuiModal
                top="50%"
                left="50%"
                width="50vh"
                open={isDocPaymentFormOpen}
                onClose={() => setIsDocPaymentFormOpen(false)}
                disableBackdropClick={true}
            >
                <PaymentForm
                    payment_method={paymentMethod}
                    onChange={handlePaymentMethodChange}
                    onClose={() => setIsDocPaymentFormOpen(false)}
                />
            </MuiModal>
        </Box>
    );
};

export default FiscalRegistryForm;
