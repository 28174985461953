import React from 'react';
import {Box} from "@mui/material";
import {IconContext} from "react-icons";
import {IMyCompanySite} from "../../../../../utils/interfaces/my_company/my_company_site/IMyCompanySite";
import MRTMuiButton from "../../../../ui/mui/mui_react_table/MRTMuiButton";
import {FaRecycle} from "react-icons/fa";
import {useNavigate} from "react-router-dom";
import {useAppGarage} from "../../../../../utils/contexts/appGarage-context";

const useCustomCellRenderer = (row: IMyCompanySite, theme: any) => {
    const navigate = useNavigate(); // Move the hook call to the top level
    const {availableCompanyUserApplications} = useAppGarage(); // Move the hook call to the top level

    const getIconsForCustomField = (row: IMyCompanySite, theme: any) => {
        const icons = [];

        icons.push(
            null
        );

        if (availableCompanyUserApplications?.some(app => app.in_project_application_name === 'special_wasty')) {
            icons.push(
                <MRTMuiButton
                    small={true}
                    icon={
                        <FaRecycle
                            color={row.show_special_wasty_alert ? theme.palette.warning.main : theme.palette.primary.main}
                        />
                    }
                    onClick={() => {
                        navigate('/apps/special_wasty/waste_code');
                    }}
                    animated={row.show_special_wasty_alert}
                    label={row.show_special_wasty_alert ? 'open loads needs unload' : ''}
                />
            )
        }


        return icons;
    };

    const renderIcons = () => {
        const icons = getIconsForCustomField(row, theme);
        return (
            <IconContext.Provider value={{size: "1.3rem"}}>
                <Box display="flex" sx={{gap: 0.5}}>
                    {icons && <span>{icons}</span>}
                </Box>
            </IconContext.Provider>
        );
    };

    return {renderIcons};
};

export default useCustomCellRenderer;