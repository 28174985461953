import React from 'react';
import {motion} from 'framer-motion';
import {useTheme} from "@mui/material/styles";

type DividerProps = {
    duration?: number;
    marginTop?: string;
    marginBottom?: string;
    height?: string;
    backgroundColor?: string;
};

const MotionDivider: React.FC<DividerProps> = ({
                                                   duration = 0.6,
                                                   marginTop = '8px',
                                                   marginBottom = '8px',
                                                   height = '3px',
                                                   backgroundColor,
                                               }) => {
    const theme = useTheme();

    return (
        <motion.div
            className="filter_motion_div-divider-bar"
            initial={{scaleX: 0, transformOrigin: "50% 100%"}}
            animate={{scaleX: 1}}
            transition={{duration}}
            style={{
                height: height,
                backgroundColor: backgroundColor || theme.palette.primary.main,
                marginTop: marginTop,
                marginBottom: marginBottom,
            }}
        />
    );
};

export default MotionDivider;